import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'filecard',
  templateUrl: './filecard.component.html',
  styleUrls: ['./filecard.component.css']
})
export class FilecardComponent implements OnInit {
  @Input() nombre = 'test';
  @Input() fichero; 
  constructor() { }

  ngOnInit() {
  }

}
