<div class="main">
    <label class="titulo">PARROQUIA SANTIAGO APÓSTOL</label><br><br>
    <hr><br><br>
    <label class="bienvenido">Bienvenido</label><br><br>
    <label class="ingresar">Introduce tus datos para ingresar</label><br>
    <form action="" >
        <input class="usuario" type="text" placeholder="Correo electrónico" [(ngModel)]="email" name="email">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.04 16.5">
            <defs>
                <style>.g{fill:#311c38;}
                </style>
            </defs>
            <g id="a"/>
            <g id="b">
                <g id="c">
                    <g id="d">
                        <circle id="e" class="g" cx="5.93" cy="3.39" r="3.39"/>
                        <path id="f" class="g" d="M6.02,7.86c4.19,0,6.02,1.3,6.02,5.5S0,17.55,0,13.36,1.83,7.86,6.02,7.86Z"/>
                    </g>
                </g>
            </g>
        </svg>
        <input class="contrasenya" type="password" placeholder="Contraseña" [(ngModel)]="pass" name="pass">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 14.4 13.6" style="enable-background:new 0 0 14.4 13.6;" xml:space="preserve">
        <style type="text/css">
            .st0{fill:#311C38;}
        </style>
        <g id="a">
        </g>
        <g id="b">
            <g id="c">
                <g id="d" transform="translate(0 86.57)">
                    <path id="e" class="st0" d="M7.2-77.3c-2.6,0-4.7-2.1-4.7-4.7c0-1.9,1.1-3.6,2.8-4.3c2.4-1,5.1,0.1,6.1,2.5
                        c1,2.4-0.1,5.1-2.5,6.1C8.5-77.4,7.8-77.2,7.2-77.3L7.2-77.3z M7.2-84.8c-1.6,0-2.9,1.3-2.9,2.9S5.6-79,7.2-79s2.9-1.3,2.9-2.9
                        c0-1.2-0.7-2.2-1.8-2.7C8-84.8,7.6-84.8,7.2-84.8z"/>
                    <path id="f" class="st0" d="M3-81.5h8.4c1.7,0,3,1.3,3,3v2.5c0,1.7-1.3,3-3,3H3c-1.7,0-3-1.3-3-3v-2.5C0-80.1,1.3-81.5,3-81.5z"
                        />
                </g>
            </g>
        </g>
        </svg>
        <br><br><br>
        <button type="submit"  class="buttonsinimg" (click)="sendLogin()">Iniciar sesión</button>
    </form>
    <a [routerLink]="['/lossPassword']" >He olvidado mi contraseña</a>
</div>