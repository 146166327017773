import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Firma } from 'src/app/objects/Firma';
import { Parroquia } from 'src/app/objects/Parroquia';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popUps/confirmPopup/confirmPopup.component';
import { FirmaFormPopupComponent } from 'src/app/popUps/firmaFormPopup/firmaFormPopup.component';
import { FirmaService } from 'src/app/services/api/firma.service';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';
import { PopupService } from 'src/app/services/util/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar-ajustes-firmas',
  templateUrl: './navbar-ajustes-firmas.component.html',
  styleUrls: ['./navbar-ajustes-firmas.component.css']
})
export class NavbarAjustesFirmasComponent extends UnsubscribesDestroy implements OnInit {
  imgSelect="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

  firmas:Array<Firma> = [];
  parroquias:Array<Parroquia> = [];
  firmaSelect = -1;
  activeDesplegable = -1;
  urlImages=environment.urlImg

  constructor(private firmaSe:FirmaService,private popup:PopupService,private parroquiaSe:ParroquiaService,private notificaciones:NotificacionesService,private loader:LoaderService) {
    super();
  }

  ngOnInit(): void {
    this.parroquiaSe.getAll();
    this.parroquiaSe.getResult().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return;}
      this.parroquias=value;
    })
    this.firmaSe.getAll();
    this.firmaSe.getResult().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return;}
      this.firmas=value;
    })
  }
  selectFirma(index){
    this.firmaSelect=index;
    this.imgSelect=this.urlImages+this.firmas[index].url;
  }
  openFirmaForm(){
    this.popup.openPopup(FirmaFormPopupComponent,{parroquias:this.parroquias});
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this._unsubInd.next(" ");
      if(typeof value.returnValue != "boolean"){
        let firma=new Firma();
        firma.nombre=value.returnValue.nombre;
        firma.url=value.returnValue.url;
        firma.parroquia=value.returnValue.parroquia;
        this.firmaSe.create(firma);
        this.firmaSe.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.notificaciones.showFeedBack("Firma creada correctamente");
          this.firmaSe.getAll();
        })
        this.firmaSe.getResultUpdateError().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.notificaciones.showError(value2.mensaje);
        })
      }
    })
  }
  toggleDesplegable(index){
    if(this.activeDesplegable==index){
      this.activeDesplegable=-1;
    }else{
      this.activeDesplegable=index;
    }
  }
  editFirma(firma){
    this.popup.openPopup(FirmaFormPopupComponent,{firma:firma,parroquias:this.parroquias});
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this._unsubInd.next(" ");
      if(typeof value.returnValue != "boolean"){
        let fim=new Firma();
        fim.id=firma.id;
        fim.nombre=value.returnValue.nombre;
        fim.url=value.returnValue.url;
        fim.parroquia=value.returnValue.parroquia;
        this.firmaSe.update(firma.id,fim);
        this.firmaSe.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.notificaciones.showFeedBack("Firma editada correctamente");
          this.imgSelect=value.returnValue.url;
          this.firmaSe.getAll();
        })
        this.firmaSe.getResultUpdateError().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.notificaciones.showError(value2.mensaje);
        })
      }
    })
  }
  deleteFirma(firma){
    this.popup.openPopup(ConfirmPopupComponent,{titulo:"Eliminar firma",description:"quieres eliminar la firma",accion:"Eliminar"});
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this._unsubInd.next(" ");
      if(value.returnValue){
        this.loader.open();
        this.firmaSe.delete([firma.id]);
        this.firmaSe.getResultDelete().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.loader.closeSlow();
          this.firmaSe.getAll();
          this.firmaSelect=-1;
          this.activeDesplegable=-1;
          this.imgSelect="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
          this.notificaciones.showFeedBack(value2.mensaje);
        })
        this.firmaSe.getResultDeleteError().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.loader.closeSlow();
          this.notificaciones.showError(value2.mensaje);
        })
      }
    })
  }
}
