<!--COPIAR-->
<div class="crearPartidaGeneralContainer">
  <div class="cancelarGuardarContainer">
    <span (click)="sendData('borrador')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8.621"
        height="14.414"
        viewBox="0 0 8.621 14.414"
      >
        <path
          id="Trazado_125"
          data-name="Trazado 125"
          d="M-9108.06,2156.32l-6.5,6.5,6.5,6.5"
          transform="translate(9115.974 -2155.613)"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
      </svg>
    </span>
    <label for="">Cancelar y guardar</label>
  </div>
  <div class="headerCrearPartidaContainer">
    <div>
      <div class="etiquetaPartida">
        <label for="">Bautismo</label>
      </div>
      <span *ngIf="!editar">Nueva partida de bautizo</span>
      <span *ngIf="editar">Editar partida de bautizo</span>
    </div>
    <div>
      <button
        type="buttton"
        class="modificarDocumento"
        (click)="changeDocumentoPrevio()"
      >
        Modificar documento adjunto
      </button>
    </div>
  </div>
  <div class="dataGeneralContainer">
    <div class="dataContainer">
      <div class="timelineContainer">
        <div>
          <span [class.active]="pasoCreacionPartida >= 0"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 1"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 2"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 3"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 4"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida == 5"></span>
        </div>
        <div>
          <span
            [class.active]="pasoCreacionPartida == 0"
            [class.error]="!paso0Valido"
            >Información sacramento</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 1"
            [class.error]="!paso1Valido"
            >Información nacimiento</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 2"
            [class.error]="!paso2Valido"
            >Información familiar</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 3"
            [class.error]="!paso3Valido"
            >Padrinos</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 4"
            [class.error]="!paso4Valido"
            >Parrocos implicados y Firma</span
          >
          <div></div>
          <span [class.active]="pasoCreacionPartida == 5"
            >Revisar y finalizar</span
          >
        </div>
      </div>
      <div class="formContainer">
        <ng-container *ngIf="pasoCreacionPartida == 0">
          <div class="paso0Container">
            <div>
              <div>
                <label for="" [class.error]="numeroPartidaVacio"
                  >Nº de partida:
                </label>
                <input
                  type="number"
                  min="0"
                  (input)="
                    validateNegativeNumbers(numeroPartida, 'numeroPartida')
                  "
                  [class.error]="numeroPartidaVacio"
                  class="input"
                  [(ngModel)]="numeroPartida"
                />
              </div>
              <div>
                <label for="" [class.error]="libroPartidaVacio"
                  >Tomo:
                </label>
                <input
                  type="text"
                  min="0"
                  [class.error]="libroPartidaVacio"
                  class="input"
                  [(ngModel)]="libroPartida"
                />
              </div>

              <div>
                <label for="" [class.error]="folioPartidaVacio"
                  >Número de folio:
                </label>
                <input
                  type="number"
                  min="0"
                  (input)="
                    validateNegativeNumbers(folioPartida, 'folioPartida')
                  "
                  [class.error]="folioPartidaVacio"
                  class="input"
                  [(ngModel)]="folioPartida"
                />
              </div>
              <div>
                <label for="" [class.error]="anyoVacio">Año: </label>
                <input
                  type="number"
                  min="0"
                  (input)="validateNegativeNumbers(anyo, 'anyo')"
                  [class.error]="anyoVacio"
                  [(ngModel)]="anyo"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div>
                <label for="" [class.error]="fechaCelebracionVacio"
                  >Fecha de celebración:
                </label>
                <input
                  type="date"
                  [class.error]="fechaCelebracionVacio"
                  [(ngModel)]="fechaCelebracion"
                  class="input"
                />
              </div>
              <div class="">
              <label for="" [class.error]="firmaVacio">Parroquia de la celebración: </label>
              <ng-select
                [class.error]="parroquiaVacio"
                class="secundaryTheme fix_height"
                [placeholder]="'Selecciona una Parroquia'"
                [items]="parroquias"
                [(ngModel)]="parroquiaSelect"
                [clearable]="false"
                bindLabel="nombre"
                bindValue="id"
              ></ng-select>
            </div>
            </div>
            <div>
              <div class="campoEspecial">
                <input
                  type="hidden"
                  class="input"
                  readonly
                  [(ngModel)]="ubicacionParroquiaCeremonia"
                />
              </div>
            </div>
            <div>
              <div>
                <label for="">Notas marginales: </label>
                <textarea
                  style="
                    resize: none;
                    height: 100px;
                    background: var(--color-suave);
                  "
                  [(ngModel)]="notas"
                  (keyup)="splitNotas()"
                  class="input"
                ></textarea>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 1">
          <div class="paso1Container">
            <div>
              <div>
                <label for="" [class.error]="nombreBautizadoVacio"
                  >Nombre:
                </label>
                <input
                  type="text"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="nombreBautizadoVacio"
                  [(ngModel)]="nombreBautizado"
                  class="input"
                />
              </div>
              <div>
                <label for="" [class.error]="primerApellidoVacio"
                  >Primer apellido del Laico:
                </label>
                <input
                  type="text"
                  [class.error]="primerApellidoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="primerApellido"
                  class="input"
                />
              </div>
              <div>
                <label for="" [class.error]="segundoApellidoVacio"
                  >Segundo apellido del Laico:
                </label>
                <input
                  type="text"
                  [class.error]="segundoApellidoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="segundoApellido"
                  class="input"
                />
              </div>
              <div class="campoEspecial sexo">
                <label for="" [class.error]="sexoBautizadoVacio">Sexo: </label>
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.236"
                  height="14.996"
                  viewBox="0 0 9.236 14.996"
                >
                  <path
                    id="female"
                    d="M11.069,15.1V9.388m0,0A2.319,2.319,0,1,0,8.75,7.069,2.319,2.319,0,0,0,11.069,9.388ZM8.75,12.6h4.638"
                    transform="translate(-8.25 -0.599)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="male"
                    d="M9.734,4.75h3.045m0,0V7.8m0-3.045L8.765,8.765m.692,1.661A2.353,2.353,0,1,1,7.1,8.072,2.353,2.353,0,0,1,9.457,10.426Z"
                    transform="translate(-4.25 -4.043)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </svg> -->

                <ng-select
                  class="fix_height"
                  [class.error]="sexoBautizadoVacio"
                  [(ngModel)]="sexoBautizado"
                  [items]="generos"
                  [clearable]="false"
                ></ng-select>
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="lugarNacimientoBautizadoVacio"
                  >Lugar de nacimiento:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectángulo_3320"
                        data-name="Rectángulo 3320"
                        width="14"
                        height="16"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Grupo_1672"
                    data-name="Grupo 1672"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Trazado_765"
                      data-name="Trazado 765"
                      d="M13.25,7c0,4-6.25,8.25-6.25,8.25S.75,11,.75,7A6.308,6.308,0,0,1,7,.75,6.308,6.308,0,0,1,13.25,7Z"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <circle
                      id="Elipse_591"
                      data-name="Elipse 591"
                      cx="2.25"
                      cy="2.25"
                      r="2.25"
                      transform="translate(4.75 4.75)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <input
                  type="text"
                  [class.error]="lugarNacimientoBautizadoVacio"
                  class="input"
                  [(ngModel)]="lugarNacimientoBautizado"
                />
              </div>
              <div class="campoEspecial">
                <label for="" [class.error]="calleNacimientoBautizadoVacio"
                  >Calle:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectángulo_3320"
                        data-name="Rectángulo 3320"
                        width="14"
                        height="16"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Grupo_1672"
                    data-name="Grupo 1672"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Trazado_765"
                      data-name="Trazado 765"
                      d="M13.25,7c0,4-6.25,8.25-6.25,8.25S.75,11,.75,7A6.308,6.308,0,0,1,7,.75,6.308,6.308,0,0,1,13.25,7Z"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <circle
                      id="Elipse_591"
                      data-name="Elipse 591"
                      cx="2.25"
                      cy="2.25"
                      r="2.25"
                      transform="translate(4.75 4.75)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <input
                  type="text"
                  [class.error]="calleNacimientoBautizadoVacio"
                  class="input"
                  [(ngModel)]="calleNacimientoBautizado"
                />
              </div>
              <div class="campoEspecial">
                <label for="" [class.error]="numeroNacimientoBautizadoVacio"
                  >Número:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectángulo_3320"
                        data-name="Rectángulo 3320"
                        width="14"
                        height="16"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Grupo_1672"
                    data-name="Grupo 1672"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Trazado_765"
                      data-name="Trazado 765"
                      d="M13.25,7c0,4-6.25,8.25-6.25,8.25S.75,11,.75,7A6.308,6.308,0,0,1,7,.75,6.308,6.308,0,0,1,13.25,7Z"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <circle
                      id="Elipse_591"
                      data-name="Elipse 591"
                      cx="2.25"
                      cy="2.25"
                      r="2.25"
                      transform="translate(4.75 4.75)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <input
                  type="text"
                  [class.error]="numeroNacimientoBautizadoVacio"
                  class="input"
                  [(ngModel)]="numeroNacimientoBautizado"
                />
              </div>

              <div>
                <label for="" [class.error]="cuandoNacimientoBautizadoVacio"
                  >Fecha y hora de nacimiento:
                </label>
                <input
                  type="datetime-local"
                  (input)="trocearFechaNacimiento(cuandoNacimientoBautizado)"
                  [class.error]="cuandoNacimientoBautizadoVacio"
                  class="input"
                  [(ngModel)]="cuandoNacimientoBautizado"
                />
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 2">
          <div class="paso2Container">
            <div class="desplegable" [class.open]="openPadres">
              <div class="infoContainer">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g
                      id="Grupo_1695"
                      data-name="Grupo 1695"
                      transform="translate(-1094 -584)"
                    >
                      <line
                        id="Línea_213"
                        data-name="Línea 213"
                        y2="2"
                        transform="translate(1102 593)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Trazado_857"
                        data-name="Trazado 857"
                        d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                        transform="translate(1094 584)"
                        fill="#0d1423"
                      />
                      <circle
                        id="Elipse_603"
                        data-name="Elipse 603"
                        cx="7.25"
                        cy="7.25"
                        r="7.25"
                        transform="translate(1094.75 584.75)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                  <label
                    for=""
                    [class.error]="
                      nombrePadreVacio ||
                      apellidosPadreVacio ||
                      nombreMadreVacio ||
                      apellidosMadreVacio ||
                      feligresesParroquiaVacio ||
                      ubicacionParroquiaFeligresesVacio ||
                      casadosParroquiaVacio
                    "
                    >Información padres</label
                  >
                </div>
                <div>
                  <span (click)="openPadresDesplegable()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.828"
                      height="8.414"
                      viewBox="0 0 14.828 8.414"
                    >
                      <path
                        id="Trazado_855"
                        data-name="Trazado 855"
                        d="M-6345.379-17421.977l6,6,6-6"
                        transform="translate(6346.793 17423.391)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div class="camposContainer" *ngIf="openPadres">
                <div>
                  <div class="campoEspecial">
                    <label for="" [class.error]="nombrePadreVacio"
                      >Nombre padre:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.203"
                      height="10.203"
                      viewBox="0 0 10.203 10.203"
                    >
                      <path
                        id="male"
                        d="M10.333,4.75h3.412m0,0V8.162m0-3.412-4.5,4.5m.775,1.861A2.637,2.637,0,1,1,7.387,8.472,2.637,2.637,0,0,1,10.023,11.109Z"
                        transform="translate(-4.25 -4.043)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>

                    <input
                      type="text"
                      [ngStyle]="{ 'text-transform': 'capitalize' }"
                      [class.error]="nombrePadreVacio"
                      class="input"
                      [(ngModel)]="nombrePadre"
                    />
                  </div>
                  <div class="campoEspecial">
                    <label for=" " [class.error]="apellidosPadreVacio"
                      >Apellidos padre:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.203"
                      height="10.203"
                      viewBox="0 0 10.203 10.203"
                    >
                      <path
                        id="male"
                        d="M10.333,4.75h3.412m0,0V8.162m0-3.412-4.5,4.5m.775,1.861A2.637,2.637,0,1,1,7.387,8.472,2.637,2.637,0,0,1,10.023,11.109Z"
                        transform="translate(-4.25 -4.043)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>
                    <input
                      [class.error]="apellidosPadreVacio"
                      [ngStyle]="{ 'text-transform': 'capitalize' }"
                      [(ngModel)]="apellidosPadre"
                      type="text"
                      class="input"
                    />
                  </div>
                  <div>
                    <label for="" [class.error]="naturalPadreVacio"
                      >Natural de:
                    </label>

                    <input
                      type="text"
                      [ngStyle]="{ 'text-transform': 'capitalize' }"
                      [class.error]="naturalPadreVacio"
                      class="input"
                      [(ngModel)]="naturalPadre"
                    />
                  </div>
                </div>
                <div>
                  <div class="campoEspecial">
                    <label for="" [class.error]="nombreMadreVacio"
                      >Nombre madre:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.573"
                      height="13.431"
                      viewBox="0 0 6.573 13.431"
                    >
                      <path
                        id="female"
                        d="M11.536,17.181V10.323m0,0A2.786,2.786,0,1,0,8.75,7.536,2.786,2.786,0,0,0,11.536,10.323ZM8.75,14.181h5.573"
                        transform="translate(-8.25 -4.25)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>

                    <input
                      [ngStyle]="{ 'text-transform': 'capitalize' }"
                      [class.error]="nombreMadreVacio"
                      type="text"
                      [(ngModel)]="nombreMadre"
                      class="input"
                    />
                  </div>
                  <div class="campoEspecial">
                    <label for="" [class.error]="apellidosMadreVacio"
                      >Apellidos madre:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.573"
                      height="13.431"
                      viewBox="0 0 6.573 13.431"
                    >
                      <path
                        id="female"
                        d="M11.536,17.181V10.323m0,0A2.786,2.786,0,1,0,8.75,7.536,2.786,2.786,0,0,0,11.536,10.323ZM8.75,14.181h5.573"
                        transform="translate(-8.25 -4.25)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>

                    <input
                      type="text"
                      [ngStyle]="{ 'text-transform': 'capitalize' }"
                      [class.error]="apellidosMadreVacio"
                      [(ngModel)]="apellidosMadre"
                      class="input"
                    />
                  </div>
                  <div>
                    <label for="" [class.error]="naturalMadreVacio"
                      >Natural de:
                    </label>

                    <input
                      type="text"
                      [ngStyle]="{ 'text-transform': 'capitalize' }"
                      [class.error]="naturalMadreVacio"
                      class="input"
                      [(ngModel)]="naturalMadre"
                    />
                  </div>
                </div>
                <div>
                  <div class="campoEspecial">
                    <label for="" [class.error]="feligresesParroquiaVacio"
                      >Feligreses de la parroquia:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="16"
                      viewBox="0 0 10 16"
                    >
                      <path
                        id="crucifix"
                        d="M12,4.75v5m0,0v9.5m0-9.5H7.75m4.25,0h4.25"
                        transform="translate(-7 -4)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                    </svg>
                    <input
                      type="text"
                      [class.error]="feligresesParroquiaVacio"
                      [(ngModel)]="feligresesParroquia"
                      class="input"
                    />
                  </div>
                  <div class="campoEspecial">
                    <label
                      for=""
                      [class.error]="ubicacionParroquiaFeligresesVacio"
                      >Ubicación parroquia:
                    </label>
                    <svg
                      id="Grupo_1699"
                      data-name="Grupo 1699"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectángulo_3320"
                            data-name="Rectángulo 3320"
                            width="14"
                            height="16"
                            fill="none"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Grupo_1672"
                        data-name="Grupo 1672"
                        clip-path="url(#clip-path)"
                      >
                        <path
                          id="Trazado_765"
                          data-name="Trazado 765"
                          d="M13.25,7c0,4-6.25,8.25-6.25,8.25S.75,11,.75,7A6.308,6.308,0,0,1,7,.75,6.308,6.308,0,0,1,13.25,7Z"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Elipse_591"
                          data-name="Elipse 591"
                          cx="2.25"
                          cy="2.25"
                          r="2.25"
                          transform="translate(4.75 4.75)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>

                    <input
                      type="text"
                      [class.error]="ubicacionParroquiaFeligresesVacio"
                      [(ngModel)]="ubicacionParroquiaFeligreses"
                      class="input"
                    />
                  </div>
                </div>
                <div>
                  <div class="campoEspecial">
                    <label for="" [class.error]="casadosParroquiaVacio"
                      >Casados en la parroquía:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.152"
                      height="15.897"
                      viewBox="0 0 17.152 15.897"
                    >
                      <path
                        id="Trazado_497"
                        data-name="Trazado 497"
                        d="M8.89,4.445A4.445,4.445,0,1,1,4.445,0,4.445,4.445,0,0,1,8.89,4.445Z"
                        transform="translate(0.6 6.636) rotate(-13)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                      <path
                        id="Trazado_498"
                        data-name="Trazado 498"
                        d="M8.89,4.445A4.445,4.445,0,1,1,4.445,0,4.445,4.445,0,0,1,8.89,4.445Z"
                        transform="translate(5.891 5.415) rotate(-13)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                      <path
                        id="Trazado_499"
                        data-name="Trazado 499"
                        d="M2.539,1.356,1.634,0,.065.442,0,2.071l1.529.565Z"
                        transform="translate(10.786 1.171) rotate(-13)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>

                    <input
                      [class.error]="casadosParroquiaVacio"
                      [(ngModel)]="casadosParroquia"
                      type="text"
                      class="input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="desplegable" [class.open]="openAbuelos">
              <div class="infoContainer">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g
                      id="Grupo_1695"
                      data-name="Grupo 1695"
                      transform="translate(-1094 -584)"
                    >
                      <line
                        id="Línea_213"
                        data-name="Línea 213"
                        y2="2"
                        transform="translate(1102 593)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Trazado_857"
                        data-name="Trazado 857"
                        d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                        transform="translate(1094 584)"
                        fill="#0d1423"
                      />
                      <circle
                        id="Elipse_603"
                        data-name="Elipse 603"
                        cx="7.25"
                        cy="7.25"
                        r="7.25"
                        transform="translate(1094.75 584.75)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                  <label
                    for=""
                    [class.error]="
                      abueloMaternoVacio ||
                      abuelaMaternaVacio ||
                      abueloPaternoVacio ||
                      abuelaPaternaVacio
                    "
                    >Información abuelos</label
                  >
                </div>
                <div>
                  <span (click)="openAbuelosDesplegable()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.828"
                      height="8.414"
                      viewBox="0 0 14.828 8.414"
                    >
                      <path
                        id="Trazado_855"
                        data-name="Trazado 855"
                        d="M-6345.379-17421.977l6,6,6-6"
                        transform="translate(6346.793 17423.391)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div class="camposContainer" *ngIf="openAbuelos">
                <div>
                  <div class="campoEspecial">
                    <label for="" [class.error]="abueloPaternoVacio"
                      >Abuelo paterno:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.203"
                      height="10.203"
                      viewBox="0 0 10.203 10.203"
                    >
                      <path
                        id="male"
                        d="M10.333,4.75h3.412m0,0V8.162m0-3.412-4.5,4.5m.775,1.861A2.637,2.637,0,1,1,7.387,8.472,2.637,2.637,0,0,1,10.023,11.109Z"
                        transform="translate(-4.25 -4.043)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>

                    <input
                      type="text"
                      [class.error]="abueloPaternoVacio"
                      [(ngModel)]="abueloPaterno"
                      class="input"
                    />
                  </div>
                  <div class="campoEspecial">
                    <label for="" [class.error]="abuelaPaternaVacio"
                      >Abuela paterna:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.573"
                      height="13.431"
                      viewBox="0 0 6.573 13.431"
                    >
                      <path
                        id="female"
                        d="M11.536,17.181V10.323m0,0A2.786,2.786,0,1,0,8.75,7.536,2.786,2.786,0,0,0,11.536,10.323ZM8.75,14.181h5.573"
                        transform="translate(-8.25 -4.25)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>

                    <input
                      type="text"
                      [class.error]="abuelaPaternaVacio"
                      [(ngModel)]="abuelaPaterna"
                      class="input"
                    />
                  </div>
                </div>
                <div>
                  <div class="campoEspecial">
                    <label for="" [class.error]="abueloMaternoVacio"
                      >Abuelo materno:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.203"
                      height="10.203"
                      viewBox="0 0 10.203 10.203"
                    >
                      <path
                        id="male"
                        d="M10.333,4.75h3.412m0,0V8.162m0-3.412-4.5,4.5m.775,1.861A2.637,2.637,0,1,1,7.387,8.472,2.637,2.637,0,0,1,10.023,11.109Z"
                        transform="translate(-4.25 -4.043)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>

                    <input
                      type="text"
                      [class.error]="abueloMaternoVacio"
                      [(ngModel)]="abueloMaterno"
                      class="input"
                    />
                  </div>
                  <div class="campoEspecial">
                    <label for="" [class.error]="abuelaMaternaVacio"
                      >Abuela materna:
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.573"
                      height="13.431"
                      viewBox="0 0 6.573 13.431"
                    >
                      <path
                        id="female"
                        d="M11.536,17.181V10.323m0,0A2.786,2.786,0,1,0,8.75,7.536,2.786,2.786,0,0,0,11.536,10.323ZM8.75,14.181h5.573"
                        transform="translate(-8.25 -4.25)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>
                    <input
                      type="text"
                      [class.error]="abuelaMaternaVacio"
                      [(ngModel)]="abuelaMaterna"
                      class="input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 3">
          <div class="paso3Container">
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="nombrePadrinoVacio"
                  >Nombre y apellidos padrino:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.203"
                  height="10.203"
                  viewBox="0 0 10.203 10.203"
                >
                  <path
                    id="male"
                    d="M10.333,4.75h3.412m0,0V8.162m0-3.412-4.5,4.5m.775,1.861A2.637,2.637,0,1,1,7.387,8.472,2.637,2.637,0,0,1,10.023,11.109Z"
                    transform="translate(-4.25 -4.043)"
                    fill="none"
                    stroke="#0e1422"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </svg>

                <input
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="nombrePadrinoVacio"
                  [(ngModel)]="nombrePadrino"
                  type="text"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="nombreMadrinaVacio"
                  >Nombre y apellidos madrina:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.573"
                  height="13.431"
                  viewBox="0 0 6.573 13.431"
                >
                  <path
                    id="female"
                    d="M11.536,17.181V10.323m0,0A2.786,2.786,0,1,0,8.75,7.536,2.786,2.786,0,0,0,11.536,10.323ZM8.75,14.181h5.573"
                    transform="translate(-8.25 -4.25)"
                    fill="none"
                    stroke="#0e1422"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </svg>
                <input
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  type="text"
                  [class.error]="nombreMadrinaVacio"
                  [(ngModel)]="nombreMadrina"
                  class="input"
                />
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 4">
          <div class="paso4Container">
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="infrafirmadoVacio"
                  >Infrafirmado:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  type="text"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="infrafirmadoVacio"
                  [(ngModel)]="infrafirmado"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="parrocoSacramentoVacio"
                  >Sacerdote que realizó el sacramento:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  type="text"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="parrocoSacramentoVacio"
                  [(ngModel)]="parrocoSacramento"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="firmaVacio">Nombre y firma del sacerdote: </label>
                <ng-select
                  [class.error]="firmaVacio"
                  class="secundaryTheme fix_height"
                  [placeholder]="'Selecciona una firma'"
                  [items]="firmas"
                  [(ngModel)]="firmaSelect"
                  [clearable]="false"
                  bindLabel="nombre"
                  bindValue="id"
                ></ng-select>
              </div>
            </div>
            <div class="firma">
              <ng-container *ngFor="let image of firmas">
                <img
                  *ngIf="firmaSelect == image.id"
                  [src]="urlImages + image.url"
                />
              </ng-container>
            </div>
            <div></div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 5">
          <div class="documentoGeneradoContainer">
            <div class="documentoGenerado" #documentoImprimir>
              <header>
                <b>Parroquía</b> {{ parroquiaNombre }} <b>de</b>
                {{ ubicacionParroquia }}
              </header>
              <p>
                <b><i>El infrafirmado D.</i></b> {{ infrafirmado }} <br>
                Cura párroco
              </p>
              <div class="columnas">
                <div class="col_izq">
                  <b>CERTIFICO:</b>

                  <div class="feligres">
                    <div class="campo">
                      <span>{{ primerApellido }}</span>
                      <label><i>primer apellido</i></label>
                    </div>
                    <div class="campo">
                      <span>{{ segundoApellido }}</span>
                      <label><i>segundo apellido</i></label>
                    </div>
                    <div class="campo">
                      <span>{{ nombreBautizado }}</span>
                      <label><i>nombre</i></label>
                    </div>
                  </div>
                  <div class="notas">
                    <p>Notas marginales:</p>
                    <p *ngFor="let nota of notasArray">{{ nota }}</p>
                  </div>
                </div>
                <div class="col_der">
                  <b
                    ><i>Que en el Libro de Bautismos, tomo </i>{{ libroPartida
                    }}<i> al folio </i>{{ folioPartida }}<i> num. </i
                    >{{ numeroPartida
                    }}<i>
                      de este Archivo Parroquial se encuentra una partida que
                      dice así:</i
                    ></b
                  >
                  <p class="texto">
                    <i>En la Iglesia Parroquial de </i>{{ parroquiaNombre }}
                    <i>de</i> {{ ubicacionParroquia
                    }}<i
                      >, diócesis de Valencia, provincia de Valencia, el día </i
                    >{{ fechaCelebracion | date: "d" }} <i>de</i>
                    {{ fechaCelebracion | date: "MMMM" }} <i>de</i>
                    {{ fechaCelebracion | date: "yyyy" }}<i>; Don</i>
                    {{ parrocoSacramento }} <i>bautizó solemnemente  a un(a)</i>
                    {{ sexoBautizado=="Niño" ? "niño":"niña" }} <i>y le puso por nombre</i>
                    {{ nombreBautizado }}<i>, que nació el día</i>
                    {{ cuandoNacimientoBautizoDia }} <i>de</i>
                    {{ cuandoNacimientoBautizado | date: "MMMM" }} <i>del año</i>
                    {{ cuandoNacimientoBautizoAnio }}<i> a las </i
                    >{{ cuandoNacimientoBautizado | date: "HH:mm"
                    }}<i> horas, en </i>{{ lugarNacimientoBautizado
                    }}<i>, calle {{ calleNacimientoBautizado }}, num </i
                    >{{ numeroNacimientoBautizado }}<i>, hijo(a) de </i
                    >{{ nombrePadre }} {{ apellidosPadre }}<i> natural de </i
                    >{{ naturalPadre }}<i>, y de </i>{{ nombreMadre }}
                    {{ apellidosMadre }}<i> natural de </i>{{ naturalMadre
                    }}{{casadosFeligreses()}}{{textoDinamico}}
                    <!-- <i>, casados según declararon, en</i>
                    {{ casadosParroquia
                    }}<i> y feligreses de la parroquia de</i>
                    {{ feligresesParroquia }}<i> de </i
                    >{{ ubicacionParroquiaFeligreses }}. -->
                  </p>
                  <span><i>Abuelos paternos: </i></span>
                  <div class="abuelos">
                    <span>D. {{ abueloPaterno }}</span>
                    <br />
                    <span>Dª. {{ abuelaPaterna }}</span>
                  </div>

                  <span><i>Abuelos maternos: </i></span>
                  <div class="abuelos">
                    <span>D. {{ abueloMaterno }}</span>
                    <br />
                    <span>Dª. {{ abuelaMaterna }}</span>
                  </div>

                  <span><i>Padrinos: </i></span>
                  <div class="abuelos">
                     <span>D. {{ nombrePadrino }} <!--{{ apellidosPadrino }}--></span> 
                    <br />
                    <span>Dª. {{ nombreMadrina }} <!--{{ apellidosMadrina }}--></span> 
                  </div>
                  <p><i>De que, como Cura Certifico.</i></p>
                  <b><i>Y para que conste, declaramos auténtica la firma que la autoriza. {{parroco}} firma y sella la presente en Valencia
                    a {{ hoy.dia }} de {{ hoy.mesNombre() }} de {{ hoy.year }}</i></b>
                  <p style="text-align: left">Firmado:</p>
                  <p style="text-align: left">{{parroco}} </p>
                  <div class="firma">
                    <img [src]="imgSelect" alt="" />
                  </div>
                </div>
              </div>
              <p class="arreglo">
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="buttonsContainer">
      <div>
        <button type="button" (click)="atras()">Atrás</button>
        <button
          type="button"
          (click)="siguiente()"
          *ngIf="pasoCreacionPartida != 5"
        >
          Siguiente
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 5 && !editar"
          [class.disabled]="
            !paso0Valido ||
            !paso1Valido ||
            !paso2Valido ||
            !paso3Valido ||
            !paso4Valido
          "
          (click)="sendData('nuevo')"
        >
          Crear partida
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 5 && editar"
          [class.disabled]="
            !paso0Valido ||
            !paso1Valido ||
            !paso2Valido ||
            !paso3Valido ||
            !paso4Valido
          "
          (click)="sendData('nuevo')"
        >
          Editar partida
        </button>
      </div>
    </div>
  </div>
</div>
<!-- FIN COPIAR-->
