import { BaseObject } from "./Base";
import { Parroquia } from "./Parroquia";

export interface Firma{
    id?: number,
    nombre: string,
    url: string,
    parroquia: Parroquia|number,
}
export class Firma extends BaseObject implements Firma{
    static convertFrontObject(data){
        let firm= new Firma();
        firm.id=data.id;
        firm.nombre=data.nombre;
        firm.url=data.ubicacion || data.url;
        firm.parroquia=Parroquia.convertFrontObject(data.parroquia);
        return firm;
    }
}