<div class="containerMain">
  <div class="main" [ngClass]="{newPassMain: haveToken}">
    <h1 class="titulo">parroquia santiago apóstol</h1>
    <hr>
    <div *ngIf="!haveToken">
      <div class="titleDescription">
        <h2 class="bienvenido">¿Has perdido la contraseña?</h2>
        <p class="ingresar">Introduce tu correo eléctronico para recuperar tu contraseña</p>
      </div>
      <div class="inputContainer">
        <span class="material-icons emailIcon" translate="no">mail_outline</span>
        <input class="input full_width" type="mail" placeholder="Correo eléctronico" [(ngModel)]="email">
      </div>
      <div class="btnContainer">
        <button type="submit"  class="buttonsinimg btn" (click)="lostPassword()">Recuperar contraseña</button>
      </div>
      <div class="returnContainer">
        <a [routerLink]="['/login']" >Volver al login</a>
      </div>
    </div>
    <div *ngIf="haveToken">
      <div class="titleDescription">
        <h2 class="bienvenido">Nueva contraseña</h2>
        <p class="ingresar">Introduce tu nueva contraseña</p>
      </div>
      <div class="inputContainer">
        <span class="material-icons emailIcon" translate="no">lock</span>
        <input class="input full_width" type="password" placeholder="Contraseña" [(ngModel)]="pass">
      </div>
      <br>
      <div class="inputContainer">
        <span class="material-icons emailIcon" translate="no">lock</span>
        <input class="input full_width" type="password" placeholder="Repetir contraseña" [(ngModel)]="repass">
      </div>
      <div class="btnContainer">
        <button type="submit"  class="buttonsinimg btn" (click)="sendNewPass()">Cambiar contraseña</button>
      </div>
      <div class="returnContainer">
        <a [routerLink]="['/login']" >Volver al login</a>
      </div>
    </div>
  </div>
</div>