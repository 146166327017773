import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/objects/Fecha';
import { Firma } from 'src/app/objects/Firma';
import { Parroquia } from 'src/app/objects/Parroquia';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popUps/confirmPopup/confirmPopup.component';
import { PopupFileComponent } from 'src/app/popUps/popupFile/popupFile.component';
import { FirmaService } from 'src/app/services/api/firma.service';
import { PartidaService } from 'src/app/services/api/partida.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';
import { PopupService } from 'src/app/services/util/popup.service';
import { environment } from 'src/environments/environment';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';

@Component({
  selector: 'app-bautizo',
  templateUrl: './bautizo.component.html',
  styleUrls: ['./bautizo.component.css'],
})
export class BautizoComponent extends UnsubscribesDestroy implements OnInit {
  @ViewChild('documentoImprimir') documentoImprimir!: ElementRef;
  pasoCreacionPartida = 0;
  notasArray:string[]= [];
  generos = ['Niño', 'Niña'];
  openPadres = false;
  openAbuelos = false;
  firmas: Array<Firma> = [];
  parroquias: Array<Parroquia> =[];
  urlImages = environment.urlImg;
  imgSelect =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  today = new Date();
  mesActual = '';
  objFecha: Fecha = new Fecha();
  days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  dayName = this.days[new Date().getDay()];
  /*VALIDACIONES PASO 0*/
  numeroPartida: any = null;
  folioPartida: number | null = null;
  libroPartida: string | null = null;
  anyo: number | null = null;
  notas: string = '';
  fechaCelebracion: any = null;
  ubicacionParroquiaCeremonia: string | null = null;
  parroquiaSelect: number | null = null;

  numeroPartidaVacio: boolean = false;
  folioPartidaVacio: boolean = false;
  libroPartidaVacio: boolean = false;
  anyoVacio: boolean = false;
  fechaCelebracionVacio: boolean = false;
  ubicacionParroquiaCeremoniaVacio: boolean = false;
  parroquiaVacio: boolean = false;

  paso0Valido = true;
  /*FIN VALIDACIONES PASO 0*/

  /*VALIDACIONES PASO 1*/
  nombreBautizado: string | null = null;
  primerApellido: string = '';
  segundoApellido: String= '';
  sexoBautizado: string | null = 'Niño';
  lugarNacimientoBautizado: string | null = null;
  calleNacimientoBautizado: string | null = null;
  numeroNacimientoBautizado: string | null = null;
  cuandoNacimientoBautizado: any = null;

  nombreBautizadoVacio: boolean = false;
  primerApellidoVacio: boolean = false;
  segundoApellidoVacio: boolean = false;
  sexoBautizadoVacio: boolean = false;
  lugarNacimientoBautizadoVacio: boolean = false;
  calleNacimientoBautizadoVacio: boolean = false;
  numeroNacimientoBautizadoVacio: boolean = false;
  cuandoNacimientoBautizadoVacio: boolean = false;

  paso1Valido = true;
  /*FIN VALIDACIONES PASO 1*/

  /*VALIDACIONES PASO 2*/
  nombrePadre: string | null = null;
  apellidosPadre: string | null = null;
  naturalPadre: string | null = null;
  nombreMadre: string | null = null;
  apellidosMadre: string | null = null;
  naturalMadre: string | null = null;
  feligresesParroquia: string | null = null;
  ubicacionParroquiaFeligreses: string | null = null;
  casadosParroquia: string | null = null;

  abueloMaterno: string | null = null;
  abuelaMaterna: string | null = null;
  abueloPaterno: string | null = null;
  abuelaPaterna: string | null = null;

  nombrePadreVacio: boolean = false;
  apellidosPadreVacio: boolean = false;
  naturalPadreVacio: boolean = false;
  nombreMadreVacio: boolean = false;
  apellidosMadreVacio: boolean = false;
  naturalMadreVacio: boolean = false;
  feligresesParroquiaVacio: boolean = false;
  ubicacionParroquiaFeligresesVacio: boolean = false;
  casadosParroquiaVacio: boolean = false;

  abueloMaternoVacio: boolean = false;
  abuelaMaternaVacio: boolean = false;
  abueloPaternoVacio: boolean = false;
  abuelaPaternaVacio: boolean = false;

  paso2Valido = true;
  /*FIN VALIDACIONES PASO 2*/

  /*VALIDACIONES PASO 3*/
  nombreMadrina: string | null = null;
  // apellidosMadrina: string | null = null;
  // vecinoMadrina: string | null = null;
  nombrePadrino: string | null = null;
  // apellidosPadrino: string | null = null;
  // vecinoPadrino: string | null = null;

  nombreMadrinaVacio: boolean = false;
  // apellidosMadrinaVacio: boolean = false;
  // vecinoMadrinaVacio: boolean = false;
  nombrePadrinoVacio: boolean = false;
  // apellidosPadrinoVacio: boolean = false;
  // vecinoPadrinoVacio: boolean = false;

  paso3Valido = true;
  /*FIN VALIDACIONES PASO 3*/

  /*VALIDACIONES PASO 4*/
  firmaSelect: number | null = null;
  parroco: string | null = null;
  infrafirmado: string | null = null;
  parrocoSacramento: string | null = null;

  firmaVacio: boolean = false;
  parrocoVacio: boolean = false;
  infrafirmadoVacio: boolean = false;
  parrocoSacramentoVacio: boolean = false;

  paso4Valido: boolean = true;
  /*FIN VALIDACIONES PASO 4*/

  //TEXTO DINAMICO QUE CAMBIA SI ESTAN CASADO, SI SON FELIGRESES O NO
  textoDinamico: string | null = null;
  //FIN TEXTO

  //FECHA NACIMIENTO A TEXTO
  cuandoNacimientoBautizoAnio: string = "";
  cuandoNacimientoBautizoDia: string = "";
  //FIN FECHA

  parroquiaNombre: string = '';
  ubicacionParroquia: string = '';
  parroquiaId: number | null = null;
  idFiel = -1;
  editar = false;
  constructor(
    private firmaSe: FirmaService,
    private notificaciones: NotificacionesService,
    private router: Router,
    private popupSE: PopupService,
    private partidaSE: PartidaService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private parroquiaSE: ParroquiaService
  ) {
    super();
  }

  splitNotas(){
    this.notasArray = this.notas.split("\n");
  }
  siguiente() {
    if (this.pasoCreacionPartida != 5) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida++;
    }
  }
  validateNegativeNumbers(number, tipo) {
    console.log(number);
    switch (tipo) {
      case 'numeroPartida':
        if (number != null && number < 0) {
          this.numeroPartida = 0;
        }
        break;
      case 'anyo':
        if (number != null && number < 0) {
          this.anyo = 0;
        }
        break;
    }
  }
  atras() {
    if (this.pasoCreacionPartida > 0) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida--;
    }
  }
  openPadresDesplegable() {
    if (!this.openPadres) {
      this.openPadres = true;
    } else {
      this.openPadres = false;
    }
  }
  openAbuelosDesplegable() {
    if (!this.openAbuelos) {
      this.openAbuelos = true;
    } else {
      this.openAbuelos = false;
    }
  }
  downloadAsPDF() {
    const pdfTable = this.documentoImprimir.nativeElement;
    var divContents = pdfTable.innerHTML;
    var a = window.open('', '', 'height=500, width=500')!;
    a.document.write(
      '<html> <style>body{padding:3em 2em}.abuelos{margin-top:1em;margin-bottom:1em;display:flex;flex-direction:column;}.firma{width:100% !important;display:flex !important;justify-content:center;align-items:center;flex-direction:column}.firma img{width:30%;margin-top:3em}header{border-bottom:solid 1px;padding-bottom:25px;font-size:15pt;display:flex;justify-content:space-between;position:relative;}.columnas{display:flex;gap:25px;}.columnas .col_izq{width:270px !important;}.columnas .col_der{width:100% !important;}.campo > *{display:block;text-align:center;width:100%;}.campo span{text-transform:uppercase;font-size:14pt;border-bottom:solid 1px;margin-bottom:5px;}.campo{margin-bottom:15px;}.feligres{margin-top:140px;height:auto!important;margin-bottom:50px;}p.texto{text-align:justify;}u{display:block;font-weight:600;font-size:14pt;}u+p{font-size:13pt;}.columnas.separacion .col_izq{border-right:solid 2px;padding-right:15px;}.notas>p{word-break: break-word;}</style>'
    );
    a.document.write('<body >');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.onload = function () {
      a.print();
    };
  }

  checkData(paso) {
    switch (paso) {
      case 0:
        if (this.numeroPartida == null) {
          this.numeroPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.numeroPartidaVacio = false;
        }
        if (this.folioPartida == null) {
          this.folioPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.folioPartidaVacio = false;
        }
        if (this.libroPartida == null) {
          this.libroPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.libroPartidaVacio = false;
        }
        if (this.anyo == null) {
          this.anyoVacio = true;
          this.paso0Valido = false;
        } else {
          this.anyoVacio = false;
        }
        if (this.fechaCelebracion == null) {
          this.fechaCelebracionVacio = true;
          this.paso0Valido = false;
        } else {
          this.fechaCelebracionVacio = false;
        }

        if (this.parroquiaSelect == null) {
          this.parroquiaVacio = true;
          this.paso0Valido = false;
        } else {
          this.parroquiaVacio = false;

          this.parroquias.forEach((element) => {
            if (element.id == this.parroquiaSelect) {
              this.parroquiaId = element['id'];
              this.parroquiaNombre = element['nombre'];
              this.ubicacionParroquia = element['ubicacion'];
              this.ubicacionParroquiaCeremonia = this.ubicacionParroquia;
            }
          });
        }

        if (
          !this.numeroPartidaVacio &&
          !this.anyoVacio &&
          !this.fechaCelebracionVacio &&
          !this.folioPartidaVacio &&
          !this.parroquiaVacio &&
          !this.libroPartidaVacio
        ) {
          this.paso0Valido = true;
        }
        break;

      case 1:
        if (this.nombreBautizado == null || this.nombreBautizado == '') {
          this.nombreBautizadoVacio = true;
          this.paso1Valido = false;
        } else {
          this.nombreBautizadoVacio = false;
        }
        if (this.primerApellido == '' || this.primerApellido == null) {
          this.primerApellidoVacio = true;
          this.paso1Valido = false;
        } else {
          this.primerApellidoVacio = false;
        }
        if (this.segundoApellido == '' || this.segundoApellido == null) {
          this.segundoApellidoVacio = true;
          this.paso1Valido = false;
        } else {
          this.segundoApellidoVacio = false;
        }
        if (this.sexoBautizado == null || this.sexoBautizado == '') {
          this.sexoBautizadoVacio = true;
          this.paso1Valido = false;
        } else {
          this.sexoBautizadoVacio = false;
        }
        if (
          this.lugarNacimientoBautizado == null ||
          this.lugarNacimientoBautizado == ''
        ) {
          this.lugarNacimientoBautizadoVacio = true;
          this.paso1Valido = false;
        } else {
          this.lugarNacimientoBautizadoVacio = false;
        }

        if (
          this.calleNacimientoBautizado == null ||
          this.calleNacimientoBautizado == ''
        ) {
          this.calleNacimientoBautizadoVacio = true;
          this.paso1Valido = false;
        } else {
          this.calleNacimientoBautizadoVacio = false;
        }
        if (
          this.numeroNacimientoBautizado == null ||
          this.numeroNacimientoBautizado == ''
        ) {
          this.numeroNacimientoBautizadoVacio = true;
          this.paso1Valido = false;
        } else {
          this.numeroNacimientoBautizadoVacio = false;
        }
        if (
          this.cuandoNacimientoBautizado == null ||
          this.cuandoNacimientoBautizado == ''
        ) {
          this.cuandoNacimientoBautizadoVacio = true;
          this.paso1Valido = false;
        } else {
          this.cuandoNacimientoBautizadoVacio = false;
        }

        if (
          !this.nombreBautizadoVacio &&
          !this.primerApellidoVacio &&
          !this.segundoApellidoVacio &&
          !this.sexoBautizadoVacio &&
          !this.lugarNacimientoBautizadoVacio &&
          !this.calleNacimientoBautizadoVacio &&
          !this.numeroNacimientoBautizadoVacio &&
          !this.cuandoNacimientoBautizadoVacio
        ) {
          this.paso1Valido = true;
        }
        break;
      case 2:
        if (this.nombrePadre == null) {
          this.nombrePadreVacio = true;
          this.paso2Valido = false;
        } else {
          this.nombrePadreVacio = false;
        }

        if (this.apellidosPadre == null) {
          this.apellidosPadreVacio = true;
          this.paso2Valido = false;
        } else {
          this.apellidosPadreVacio = false;
        }

        if (this.naturalPadre == null) {
          this.naturalPadreVacio = true;
          this.paso2Valido = false;
        } else {
          this.naturalPadreVacio = false;
        }

        if (this.nombreMadre == null) {
          this.nombreMadreVacio = true;
          this.paso2Valido = false;
        } else {
          this.nombreMadreVacio = false;
        }
        if (this.apellidosMadre == null) {
          this.apellidosMadreVacio = true;
          this.paso2Valido = false;
        } else {
          this.apellidosMadreVacio = false;
        }

        if (this.naturalMadre == null) {
          this.naturalMadreVacio = true;
          this.paso2Valido = false;
        } else {
          this.naturalMadreVacio = false;
        }
        if (this.feligresesParroquia == null) {
          this.feligresesParroquiaVacio = true;
          this.paso2Valido = false;
        } else {
          this.feligresesParroquiaVacio = false;
        }
        if (this.ubicacionParroquiaFeligreses == null) {
          this.ubicacionParroquiaFeligresesVacio = true;
          this.paso2Valido = false;
        } else {
          this.ubicacionParroquiaFeligresesVacio = false;
        }
        if (this.casadosParroquia == null) {
          this.casadosParroquiaVacio = true;
          this.paso2Valido = false;
        } else {
          this.casadosParroquiaVacio = false;
        }

        if (this.abueloMaterno == null) {
          this.abueloMaternoVacio = true;
          this.paso2Valido = false;
        } else {
          this.abueloMaternoVacio = false;
        }
        if (this.abuelaMaterna == null) {
          this.abuelaMaternaVacio = true;
          this.paso2Valido = false;
        } else {
          this.abuelaMaternaVacio = false;
        }

        if (this.abueloPaterno == null) {
          this.abueloPaternoVacio = true;
          this.paso2Valido = false;
        } else {
          this.abueloPaternoVacio = false;
        }
        if (this.abuelaPaterna == null) {
          this.abuelaPaternaVacio = true;
          this.paso2Valido = false;
        } else {
          this.abuelaPaternaVacio = false;
        }

        if (
          !this.nombrePadreVacio &&
          !this.apellidosPadreVacio &&
          !this.naturalPadreVacio &&
          !this.nombreMadreVacio &&
          !this.apellidosMadreVacio &&
          !this.naturalMadreVacio &&
          !this.feligresesParroquiaVacio &&
          !this.ubicacionParroquiaFeligresesVacio &&
          !this.casadosParroquiaVacio &&
          !this.abueloMaternoVacio &&
          !this.abuelaMaternaVacio &&
          !this.abueloPaternoVacio &&
          !this.abuelaPaternaVacio
        ) {
          this.paso2Valido = true;
        }
        break;
      case 3:
        if (this.nombreMadrina == null) {
          this.nombreMadrinaVacio = true;
          this.paso3Valido = false;
        } else {
          this.nombreMadrinaVacio = false;
        }
        // if (this.apellidosMadrina == null) {
        //   this.apellidosMadrinaVacio = true;
        //   this.paso3Valido = false;
        // } else {
        //   this.apellidosMadrinaVacio = false;
        // }
        // if (this.vecinoMadrina == null) {
        //   this.vecinoMadrinaVacio = true;
        //   this.paso3Valido = false;
        // } else {
        //   this.vecinoMadrinaVacio = false;
        // }
        if (this.nombrePadrino == null) {
          this.nombrePadrinoVacio = true;
          this.paso3Valido = false;
        } else {
          this.nombrePadrinoVacio = false;
        }
        // if (this.apellidosPadrino == null) {
        //   this.apellidosPadrinoVacio = true;
        //   this.paso3Valido = false;
        // } else {
        //   this.apellidosPadrinoVacio = false;
        // }
        // if (this.vecinoPadrino == null) {
        //   this.vecinoPadrinoVacio = true;
        //   this.paso3Valido = false;
        // } else {
        //   this.vecinoPadrinoVacio = false;
        // }
        if (
          !this.nombreMadrinaVacio &&
          // !this.apellidosMadrinaVacio &&
          // !this.vecinoMadrinaVacio &&
          !this.nombrePadrinoVacio 
          // !this.apellidosPadrinoVacio &&
          // !this.vecinoPadrinoVacio
        ) {
          this.paso3Valido = true;
        }
        break;

      case 4:
        if (this.firmaSelect == null) {
          this.firmaVacio = true;
          this.paso4Valido = false;
        } else {
          this.firmaVacio = false;

          this.firmas.forEach((element) => {
            if (element.id == this.firmaSelect) {
              this.parroco = element.nombre;
              // this.parroquiaId = element.parroquia['id'];
              // this.parroquiaNombre = element.parroquia['nombre'];
              // this.ubicacionParroquia = element.parroquia['ubicacion'];
              this.imgSelect = this.urlImages + element.url;
              // this.ubicacionParroquiaCeremonia = this.ubicacionParroquia;
            }
          });
        }
        if (this.parroco == null) {
          this.parrocoVacio = true;
          this.paso4Valido = false;
        } else {
          this.parrocoVacio = false;
        }
        if (this.infrafirmado == null) {
          this.infrafirmadoVacio = true;
          this.paso4Valido = false;
        } else {
          this.infrafirmadoVacio = false;
        }
        if (this.parrocoSacramento == null) {
          this.parrocoSacramentoVacio = true;
          this.paso4Valido = false;
        } else {
          this.parrocoSacramentoVacio = false;
        }
        if (!this.parrocoVacio && !this.firmaVacio && !this.infrafirmadoVacio && !this.parrocoSacramentoVacio) {
          this.paso4Valido = true;
        }
        break;
    }
  }

//1231-03-12T12:03
trocearFechaNacimiento(fecha: any){
  console.log(fecha)
  this.cuandoNacimientoBautizoAnio=fecha.split('T')[0]
  this.cuandoNacimientoBautizoDia=this.cuandoNacimientoBautizoAnio.split('-')[2]
  this.cuandoNacimientoBautizoAnio=this.cuandoNacimientoBautizoAnio.split('-')[0]
  this.cuandoNacimientoBautizoAnio=this.convertirAnioATexto(this.cuandoNacimientoBautizoAnio);
  this.cuandoNacimientoBautizoDia=this.convertirDiaATexto(this.cuandoNacimientoBautizoDia)
  console.log(this.cuandoNacimientoBautizoDia);
  console.log(this.cuandoNacimientoBautizoAnio)
}
convertirAnioATexto(numero: string): string {
  const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
  const decenas = ['', 'diez', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
  const especiales = ['','diez','once','doce','trece','catorce','quince'];
  let anioTexto = '';
  let anioMil = '';
  let anioCent = '';
  let anioDec = '';
  let anioUni = '';

  if(parseInt(numero[0])==1){
    anioMil = "mil "
  }else{
    anioMil = unidades[parseInt(numero[0])] + " mil "
  }

  if(parseInt(numero[1])!=0){
    if(parseInt(numero[1])==1){
      anioCent = "ciento "
    }else if(parseInt(numero[1])==5){
      anioCent = "quinientos "
    }else if(parseInt(numero[1])==7){
      anioCent = "setecientos "
    }else{
      anioCent = unidades[parseInt(numero[1])] +"cientos "
    }
  }

  if(parseInt(numero.slice(-2))<16){
    anioDec = especiales[parseInt(numero[1])]
  }else if(parseInt(numero[2])==1){
    anioDec="dieci"
    anioUni=unidades[parseInt(numero[3])]
  }else if(parseInt(numero[2])==2){
    anioDec="venti"
    anioUni=unidades[parseInt(numero[3])]
  }else{
    anioDec=decenas[parseInt(numero[2])]
    if(parseInt(numero[3])!=0){

      anioUni=" y "+unidades[parseInt(numero[3])]
      
    }else{
      anioUni=unidades[parseInt(numero[3])]
    }
  }

  anioTexto= anioMil+anioCent+anioDec+anioUni

  return anioTexto;
}
convertirDiaATexto(numero: string): string {
  const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
  const especiales = { '10': 'diez', '11': 'once', '12': 'doce', '13': 'trece', '14': 'catorce', '15': 'quince', '20': 'veinte', '30': 'treinta', '31': 'treinta y uno'};
  let diaTexto = '';
  if(numero.startsWith("0")) { // si el número tiene un cero al inicio, omitirlo
    numero=numero.split("0")[1]
    console.log(numero)
  }
  if(numero.length<2){
    diaTexto=unidades[parseInt(numero)]
  }else if(numero in especiales) { // números especiales del 10 al 15
    diaTexto = especiales[numero];
  } else if(parseInt(numero) < 20) { // números del 16 al 19
    diaTexto = 'dieci' + unidades[parseInt(numero) % 10];
  } else if(parseInt(numero) < 30) { // números del 20 al 29
    diaTexto = 'veinti' + unidades[parseInt(numero) % 10];
  } else if(parseInt(numero) % 10 == 0) { // múltiplos de 10
    diaTexto = unidades[parseInt(numero) / 10] + 'enta';
  } else { // otros números
    diaTexto = unidades[Math.floor(parseInt(numero) / 10)] + 'enta y ' + unidades[parseInt(numero) % 10];
  }
  return diaTexto;
}

  casadosFeligreses(){
    if((this.casadosParroquia==null || this.casadosParroquia=="") && (this.feligresesParroquia == null || this.feligresesParroquia == "")){
      this.textoDinamico = '';

    }else if(this.casadosParroquia!=null && (this.feligresesParroquia == null || this.feligresesParroquia == "")){
      this.textoDinamico = ', casados según declararon, en '+this.casadosParroquia;
 
    }else if((this.casadosParroquia==null || this.casadosParroquia=="") && this.feligresesParroquia != null && (this.ubicacionParroquiaFeligreses == null || this.ubicacionParroquiaFeligreses == "")){
      this.textoDinamico = ', feligreses de la parroquia de '+this.feligresesParroquia;

    }else if((this.casadosParroquia==null || this.casadosParroquia=="") && this.feligresesParroquia != null){
      this.textoDinamico = ', feligreses de la parroquia de '+this.feligresesParroquia+' de '+this.ubicacionParroquiaFeligreses;

    }else if(this.casadosParroquia!=null && this.feligresesParroquia != null && (this.ubicacionParroquiaFeligreses == null || this.ubicacionParroquiaFeligreses == "")){
      this.textoDinamico = ', casados según declararon, en '+this.casadosParroquia+' y feligreses de la parroquia de '+this.feligresesParroquia;


    }else if(this.casadosParroquia!=null && this.feligresesParroquia != null){
      this.textoDinamico = ', casados según declararon, en '+this.casadosParroquia+' y feligreses de la parroquia de '+this.feligresesParroquia+' de '+this.ubicacionParroquiaFeligreses;

    }
  }



  sendData(tipo) {
    this.loader.open();
    var borrador;
    (tipo == 'borrador') ? (borrador = true) : (borrador = false);

    var partida = {
      fecha: this.fechaCelebracion,
      numero: this.numeroPartida,
      folio: this.folioPartida, //
      libro: this.libroPartida, //
      anyo: this.anyo,
      hora: '',
      parroco: this.infrafirmado+','+this.parrocoSacramento+','+this.parroco,
      testigos:
        this.nombrePadrino +
        ',' +
        this.nombreMadrina,
      borrador: borrador,
      firma: this.firmaSelect,
      url: sessionStorage.getItem('documentoAdjunto'),
      sacramento: 1,
      parroquia: this.parroquiaId,
      fiel: {
        nombre: this.nombreBautizado,
        sexo: this.sexoBautizado,
        padrinos:
        this.nombrePadrino +
        ',' +
        this.nombreMadrina,
        padre: this.nombrePadre + ' ' + this.apellidosPadre,
        madre: this.nombreMadre + ' ' + this.apellidosMadre,
        lugarnacimientomadre: this.naturalMadre,
        lugarnacimientopadre: this.naturalPadre,
        abuelosmaternales: this.abueloMaterno + ', ' + this.abuelaMaterna,
        abuelospaternales: this.abueloPaterno + ', ' + this.abuelaPaterna,
        apellido1: this.primerApellido,
        apellido2: this.segundoApellido,
        direccion: ''
      },
      extraData: JSON.stringify({
        lugarNacimientoBautizado: this.lugarNacimientoBautizado,
        calleNacimientoBautizado: this.calleNacimientoBautizado,
        numeroNacimientoBautizado: this.numeroNacimientoBautizado,
        cuandoNacimientoBautizado: this.cuandoNacimientoBautizado,
        feligresesParroquia: this.feligresesParroquia,
        ubicacionParroquiaFeligreses: this.ubicacionParroquiaFeligreses,
        casadosParroquia: this.casadosParroquia,
        notas: this.notas,
        apellido1: this.primerApellido,
        apellido2: this.segundoApellido
      }),
    };
    if (tipo == 'borrador') {
      this.popupSE.openPopup(ConfirmPopupComponent, {
        titulo: 'Guardar borrador',
        description: 'quieres guardar el borrador',
        accion: 'Guardar',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd2))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this._unsubInd2.next('');
          if (value.returnValue) {
            this.partidaSE.create(partida);
            this.partidaSE
              .getResultUpdate()
              .pipe(takeUntil(this._unsubInd))
              .subscribe((value) => {
                if (value == null) {
                  return;
                }
                this.loader.closeSlow();
                this.notificaciones.showFeedBack(value.message);
                this.router.navigate(['/inicio']);
                this._unsubInd.next('');
              });
          } else {
            this.loader.closeSlow();
            this.router.navigate(['/inicio']);
          }
        });
    } else if (!this.editar) {
      this.partidaSE.create(partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              this.loader.closeSlow();
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
                this.router.navigate(['/inicio']);
                sessionStorage.removeItem('documentoAdjunto');
              } else {
                this.router.navigate(['/inicio']);
                sessionStorage.removeItem('documentoAdjunto');
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    } else {
      partida.fiel['id'] = this.idFiel;
      this.partidaSE.update(this.route.snapshot.params['id'], partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              this.loader.closeSlow();
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
                this.router.navigate(['/inicio']);
              } else {
                this.router.navigate(['/inicio']);
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    }
  }
  changeDocumentoPrevio() {
    this.popupSE.openPopup(PopupFileComponent, { action: 'Añadir' });
  }
  override ngOnDestroy() {
    sessionStorage.removeItem('documentoAdjunto');
  }
  hoy: Fecha = new Fecha();
  ngOnInit() {
    if (this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
      this.partidaSE.getIndividual(this.route.snapshot.params['id']);
      this.loader.open();
      this.partidaSE
        .getResultIndividual()
        .pipe(takeUntil(this._unsubInd5))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.loader.closeSlow();
          console.log(value);
          this.numeroPartida = value.numero;
          this.libroPartida = value.libro;
          this.folioPartida = value.folio;
          this.anyo = value.anyo;
          if (value.fecha !== null) {
            this.fechaCelebracion = value.fecha.split('T')[0];
          }
          if (value.parroquia !== null) {
            this.ubicacionParroquiaCeremonia = value.parroquia.ubicacion;
            this.parroquiaSelect = value.parroquia.id;
          }
          if (value.fiel !== null) {
            this.nombreBautizado = value.fiel.nombre;
            this.primerApellido = value.fiel.apellido1;
            this.segundoApellido = value.fiel.apellido2;
            this.sexoBautizado = value.fiel.sexo;
            this.nombrePadre = value.fiel.padre.split(' ')[0];
            if (
              value.fiel.padre.split(' ')[2] != undefined ||
              value.fiel.padre.split(' ')[2] != null
            ) {
              this.apellidosPadre =
                value.fiel.padre.split(' ')[1] +
                ' ' +
                value.fiel.padre.split(' ')[2];
            } else {
              this.apellidosPadre = value.fiel.padre.split(' ')[1];
            }

            this.nombreMadre = value.fiel.madre.split(' ')[0];
            if (
              value.fiel.padre.split(' ')[2] != undefined ||
              value.fiel.madre.split(' ')[2] != null
            ) {
              this.apellidosMadre =
                value.fiel.madre.split(' ')[1] +
                ' ' +
                value.fiel.madre.split(' ')[2];
            } else {
              this.apellidosMadre = value.fiel.madre.split(' ')[1];
            }
            this.naturalMadre = value.fiel.lugarnacimientomadre;
            this.naturalPadre = value.fiel.lugarnacimientopadre;
            this.abueloMaterno = value.fiel.abuelosmaternales.split(',')[0];
            this.abuelaMaterna = value.fiel.abuelosmaternales.split(',')[1];
            this.abueloPaterno = value.fiel.abuelospaternales.split(',')[0];
            this.abuelaPaterna = value.fiel.abuelospaternales.split(',')[1];

            this.nombrePadrino = value.fiel.padrinos
              .split(',')[0]
              .split(' ')[0];

            this.nombreMadrina = value.fiel.padrinos
              .split(',')[1]
              .split(' ')[0];
          }
          var extraData = JSON.parse(value.extra_data);
          if (extraData !== undefined) {
            this.feligresesParroquia = extraData.feligresesParroquia;
            this.ubicacionParroquiaFeligreses =
              extraData.ubicacionParroquiaFeligreses;
            this.casadosParroquia = extraData.casadosParroquia;
            this.lugarNacimientoBautizado = extraData.lugarNacimientoBautizado;
            this.notas = extraData.notas;
            this.calleNacimientoBautizado = extraData.calleNacimientoBautizado;
            this.numeroNacimientoBautizado =
              extraData.numeroNacimientoBautizado;
            this.cuandoNacimientoBautizado =
              extraData.cuandoNacimientoBautizado;
          }
          this.infrafirmado = value.parroco.split(',')[0];
          this.parrocoSacramento = value.parroco.split(',')[1];
          this.parroco = value.parroco.split(',')[2];
          if (value.firma !== null) {
            this.firmaSelect = value.firma.id;
          }
          this.idFiel = value.fiel.id;
          this._unsubInd5.next('');
        });
    }
    this.hoy = Fecha.javascriptConvert(new Date());
    this.firmaSe.getAll();
    this.firmaSe
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.firmas = value;
      });
    
    this.parroquiaSE.getAll();
    this.parroquiaSE
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value)=>{
        if(value == null) {
          return;
        }
        this.parroquias = value;
      })


  }
}
