<div class="ajustesBusqueda-generalContainer">
    <div class="ajustesBusqueda-left"></div>
    <div class="ajustesBusqueda-right">
        <div class="ajustesBusqueda-right-close">
            <button type="button" (click)="busqueda()">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32"
                    style="enable-background:new 0 0 32 32;" xml:space="preserve">
                    <style type="text/css">
                        .st1 {
                            fill: none;
                            stroke: #FFFFFF;
                            stroke-width: 2;
                        }
                    </style>
                    <g id="a">
                    </g>
                    <g id="b">
                        <g id="c">
                            <g id="d" transform="translate(-364 -52)">
                                <circle id="e" class="st0" cx="380" cy="68" r="16" />
                                <path id="f" class="st1" d="M382.2,61.5l-6.5,6.5l6.5,6.5" />
                            </g>
                        </g>
                    </g>
                </svg>
                <span>Aplicar filtros</span>
            </button>
        </div>
        <div class="ajustesBusqueda-right-data">
            <div class="check">
                <span>
                    <checkRadioBox [id]="'bautismo'" name="sacramento" (click)="setSacramento({nombre:'Bautizo', id: 1})"></checkRadioBox>
                    <label for="bautismo">Bautismo</label>
                </span>
                <!-- <span>
                    <checkRadioBox [id]="'comunion'" name="sacramento" (click)="setSacramento({nombre:'Comunión', id: 2})"></checkRadioBox>
                    <label for="comunion">Comunión</label>
                </span> -->
                <span>
                    <checkRadioBox [id]="'confirmacion'" name="sacramento" (click)="setSacramento({nombre:'Confirmación', id: 3})"></checkRadioBox>
                    <label for="confirmacion">Confirmación</label>
                </span>
                <span>
                    <checkRadioBox [id]="'matrimonio'" name="sacramento" (click)="setSacramento({nombre:'Matrimonio', id: 4})"></checkRadioBox>
                    <label for="matrimonio">Matrimonio</label>
                </span>
                <span>
                    <checkRadioBox [id]="'defuncion'" name="sacramento" (click)="setSacramento({nombre:'Defunción', id: 5})"></checkRadioBox>
                    <label for="defuncion">Defunción</label>
                </span>
                <!-- <span class="nulidad">
                    <checkRadioBox [id]="'nulidad'" name="sacramento" (click)="setSacramento({nombre:'Nulidad Matrimonial', id: 6})"></checkRadioBox>
                    <label for="nulidad">Nulidad matrimonial</label>
                </span> -->
            </div>
            <div class="ajustesBusqueda-right-data-fecha">
                <div class="from">
                    <label for="">De</label>
                    <input type="month" [(ngModel)]="fechaDesde"class="input inputde">
                </div>
                <div class="to">
                    <label for="">Hasta</label>
                    <input type="month" [(ngModel)]="fechaHasta" class="input inputhasta">
                </div>
                <div class="parroquiaContainer">
                    <label for="">Parroquia</label>
                    <ng-select class="parroquia" [items]="parroquias" [(ngModel)]="parroquiaFiltro" bindLabel="nombre" bindValue="id" [clearable]="false"></ng-select>
                </div>
               
            </div>
            
            <div class="ajustesBusqueda-right-data-inputs">
                <input type="text" class="input" placeholder="Nombre y apellidos del padre ..." [(ngModel)]="nombrePadre">
                <input type="text" class="input" placeholder="Nombre y apellidos de la madre ..." [(ngModel)]="nombreMadre">
                <input type="text" class="input" placeholder="Nombre y apellidos de un padrino o testigo ..." [(ngModel)]="nombrePadrino">
                <input type="text" class="input" placeholder="Párroco responsable del oficio ..." [(ngModel)]="nombreParroco">
            </div>
        </div>

    </div>
</div>