import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  element;
  constructor() {}
  private addNotification(title,type,text?){
    if(document.getElementsByClassName("notificacionesContainer").length==0){this.addElementNotifica()}
    let container=document.getElementsByClassName("notificacionesContainer")[0];
    if(text!=null){type+=" notificacion_containText";}
    let notif='<div class="notificacion '+type+'"><div class="notificacion_body">';
    notif+='<h4>'+title+'</h4>';
    if(text!=null){
      notif+='<p>'+text+'</p>';
    }
    notif+='</div><div class="notificacion_cerrarContainer"><div class="notificacion_cerrar" onClick="this.parentElement.parentElement.classList.add(\'notificacion_animationRemove\');setTimeout(()=>{this.parentElement.parentElement.remove()},1000)"></div></div>';
    container.insertAdjacentHTML('beforeend',notif);
    let epm=container.children[container.children.length - 1];
    setTimeout(function(){
      epm.classList.add("notificacion_animationOpen");
    },100);
    setTimeout(function(){
      epm.classList.add("notificacion_animationRemove");
      setTimeout(function(){
        epm.remove();
      },1000);
    },4500);
  }
  showError(title,text?){
    this.addNotification(title,"notificacionError",text);
  }
  showWarning(title,text?){
    this.addNotification(title,"notificacionWarning",text);
  }
  showInfo(title,text?){
    this.addNotification(title,"notificacionInfo",text);
  }
  showFeedBack(title,text?){
    this.addNotification(title,"notificacionFeedback",text);
  }
  private addElementNotifica(){
    let container='<div class="notificacionesContainer"></div>';
    document.body.insertAdjacentHTML('beforeend',container);
  }
}
