import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Parroquia } from 'src/app/objects/Parroquia';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popUps/confirmPopup/confirmPopup.component';
import { ParroquiaFormPopupComponent } from 'src/app/popUps/parroquiaFormPopup/parroquiaFormPopup.component';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';
import { PopupService } from 'src/app/services/util/popup.service';

@Component({
  selector: 'app-navbar-ajustes-parroquias',
  templateUrl: './navbar-ajustes-parroquias.component.html',
  styleUrls: ['./navbar-ajustes-parroquias.component.css']
})
export class NavbarAjustesParroquiasComponent extends UnsubscribesDestroy implements OnInit {
  
  parroquias:Array<Parroquia> = [];
  parroquiaSelect = -1;
  activeDesplegable = -1;
  constructor(private popup:PopupService,private parroquiaSe:ParroquiaService,private notificaciones:NotificacionesService,private loader:LoaderService) {
    super();
  }

  ngOnInit(): void {
    this.parroquiaSe.getAll();
    this.parroquiaSe.getResult().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return;}
      this.parroquias=value;
    })
  }
  selectParroquia(index){
    this.parroquiaSelect=index;
  }
  toggleDesplegable(index){
    if(this.activeDesplegable==index){
      this.activeDesplegable=-1;
    }else{
      this.activeDesplegable=index;
    }
  }
  openParroquiaForm(){
    this.popup.openPopup(ParroquiaFormPopupComponent);
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this._unsubInd.next(" ");
      if(typeof value.returnValue != "boolean"){
        let parr=new Parroquia();
        parr.nombre=value.returnValue.nombre;
        parr.ubicacion=value.returnValue.ubicacion;
        this.parroquiaSe.create(parr);
        this.parroquiaSe.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.notificaciones.showFeedBack("Parroquia creada correctamente");
          this.parroquiaSe.getAll();
        })
        this.parroquiaSe.getResultUpdateError().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.notificaciones.showError(value2.mensaje);
        })
      }
    })
  }
  editParroquia(parroquia){
    this.popup.openPopup(ParroquiaFormPopupComponent,parroquia);
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this._unsubInd.next(" ");
      if(typeof value.returnValue != "boolean"){
        let parr=new Parroquia();
        parr.nombre=value.returnValue.nombre;
        parr.ubicacion=value.returnValue.ubicacion;
        this.parroquiaSe.update(parroquia.id,parr);
        this.parroquiaSe.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.notificaciones.showFeedBack("Parroquia editada correctamente");
          this.parroquiaSe.getAll();
        })
        this.parroquiaSe.getResultUpdateError().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.notificaciones.showError(value2.mensaje);
        })
      }
    })
  }
  deleteParroquia(parroquia){
    this.popup.openPopup(ConfirmPopupComponent,{titulo:"Eliminar parroquia",description:"quieres eliminar la parroquia "+parroquia.nombre,accion:"Eliminar"});
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this._unsubInd.next(" ");
      if(value.returnValue){
        this.loader.open();
        this.parroquiaSe.delete([parroquia.id]);
        this.parroquiaSe.getResultDelete().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.loader.closeSlow();
          this.parroquiaSe.getAll();
          this.parroquiaSelect=-1;
          this.activeDesplegable=-1;
          this.notificaciones.showFeedBack(value2.mensaje);
        })
        this.parroquiaSe.getResultDeleteError().pipe(takeUntil(this._unsubInd2)).subscribe(value2=>{
          if(value2==null){return;}
          this._unsubInd2.next(" ");
          this.loader.closeSlow();
          this.notificaciones.showError(value2.mensaje);
        })
      }
    })
  }

}
