<div class="panelfirma">
    <button type="submit" class="buttonsinimg" (click)="openFirmaForm()">Añadir firma</button>
    <div class="abajo">
        <div class="panelizq">
            <div class="firma" *ngFor="let firma of firmas; let i = index" [ngClass]="{openDesplegable: activeDesplegable == i, active: firmaSelect==i}" (click)="selectFirma(i)">
                <span class="firmaNombreText">Firma {{i+1}} - {{firma.nombre}}</span>
                <div class="firmaContextMenu">
                    <span class="iconSubmenu" (click)="toggleDesplegable(i)"><span class="material-icons" translate="no">more_horiz</span></span>
                    <div class="actionsList">
                        <div (click)="editFirma(firma)">Editar firma</div>
                        <div (click)="deleteFirma(firma)">Eliminar firma</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panelder">
            <img [src]="imgSelect" class="imageFirma">
        </div>
    </div>
</div>