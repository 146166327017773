<div class="globalPopupContainer">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent confirmarPopups">
    <ng-container *ngIf="tipoPopup != 'creacionPartida'">
      <div class="popupHead">
        <h3 class="popupTitle">{{titulo}}</h3>
      </div>
      <div class="popupBody popupCenterHeightContainer">
        <div>
          <p class="descriptionText">¿Estás seguro que quieres {{description}}?</p>
          <p>Este paso no tiene vuelta atrás</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tipoPopup == 'creacionPartida'">
      <div class="popupHead">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="46.321" height="36.191" viewBox="0 0 46.321 36.191">
            <path id="Trazado_460" data-name="Trazado 460" d="M-1526,8175.693l8.886,8.886,12.4-12.4,16.553-16.553"
              transform="translate(1530.243 -8151.388)" fill="none" stroke="#fff" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="6" />
          </svg>
        </div>
        <h3 class="popupTitle">Documento creado</h3>
      </div>
      <div class="popupBody popupCenterHeightContainer">
        <div>
          <p class="descriptionText">¿Quieres descargarlo?</p>
        </div>
      </div>
    </ng-container>
    <div class="popupButtons">
      <button class="btn btn_secondary dobleButton" (click)="closePopup(false)">SALIR</button>
      <button class="btn dobleButton" (click)="closePopup(true)">{{accion}}</button>
    </div>
  </div>
</div>