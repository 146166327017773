import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from './objects/unsubscribesDestroy';
import { LoginService } from './services/api/Login.service';
import { StaticUtilitiesServiceService, UtilitiesStaticInitValues } from './services/api/StaticUtilitiesService.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends UnsubscribesDestroy implements OnInit {
  title = 'parroquiafront-end';
  listenerStart=false;

  constructor(private loginSe:LoginService,private utilitiesStatic:UtilitiesStaticInitValues) {
    super();
  }
  ngOnInit(): void {
    this.utilitiesStatic.initValues()
    StaticUtilitiesServiceService.listenerStartRefreshToken().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(!this.listenerStart){
        this.listenerStart=true;
        this.loginSe.refreshToken();
      }
    })
    StaticUtilitiesServiceService.listenerRefreshToken().pipe(takeUntil(this._unsub)).subscribe(value=>{
      setTimeout(() => {
        this.listenerStart=false;
      }, 1000);
    })
    let color=localStorage.getItem("color");
    if (color==null){
      color="morado";
      localStorage.setItem("sizeLetter","morado");
    }
    this.cambioColor(color)
    let tamanyo = localStorage.getItem("sizeLetter");
    if(tamanyo==null){
      tamanyo="2";
      localStorage.setItem("sizeLetter","2");
    }
    this.cambioletra(parseInt(tamanyo));
  }

  cambioletra(indexSize:number){
    if(indexSize==1){
      document.documentElement.style.setProperty('--tamanyo-letra', '14px');
    }
    if(indexSize==2){
      document.documentElement.style.setProperty('--tamanyo-letra', '16px');
    }
    if(indexSize==3){
      document.documentElement.style.setProperty('--tamanyo-letra', '20px');
    }
  }
  cambioColor(tema: string){
    if(tema == "verde"){
      document.documentElement.style.setProperty('--color-principal', "#384c2c");
      document.documentElement.style.setProperty('--color-fondo-encima', "#a4b29a");
      document.documentElement.style.setProperty('--color-suave', "#e5edde");
      document.documentElement.style.setProperty('--color-oscuro', "#1c7621");
      document.documentElement.style.setProperty('--color-fondo-popUp', "#53614880");
      document.documentElement.style.setProperty('--color-muysuave', "#f6faf3");
    }
    else if(tema == "morado"){
      document.documentElement.style.setProperty('--color-principal', "#2f1d4b");
      document.documentElement.style.setProperty('--color-fondo-encima', "#a594af");
      document.documentElement.style.setProperty('--color-suave', "#e5e1e6");
      document.documentElement.style.setProperty('--color-oscuro', "#6b357f");
      document.documentElement.style.setProperty('--color-fondo-popUp', "#e5e1e680");
      document.documentElement.style.setProperty('--color-muysuave', "#fcf8ff");
    }
    else if(tema == "azul"){
      document.documentElement.style.setProperty('--color-principal', "#00367f");
      document.documentElement.style.setProperty('--color-fondo-encima', "#8e9fad");
      document.documentElement.style.setProperty('--color-suave', "#eaf2fc");
      document.documentElement.style.setProperty('--color-oscuro', "#2b64b1");
      document.documentElement.style.setProperty('--color-fondo-popUp', "#79a0d080");
      document.documentElement.style.setProperty('--color-muysuave', "#f3faff");
    }
  }
}