<div class="centrado">
    <label for="">Color de la interfaz</label><br><br><br>
    <div class="radio">
        <div class="radioCheckCont">
            <div class="containerRadioCheck">
                <input type="radio" name="radio" id="verde" class="radioCheckStyle" value="verde" [(ngModel)]="color" (change)="modifyColor()">
                <span class="auxRadioCheckStyle"></span>
            </div>
        </div>
        <label for="verde">
            <div class="colores">
                <div class="suaveverde"></div>
                <div class="normalverde"></div>
                <div class="oscuroverde"></div>
            </div>
        </label>
    </div>
    <div class="radio">
        <div class="radioCheckCont">
            <div class="containerRadioCheck">
                <input type="radio" name="radio" id="azul" class="radioCheckStyle" value="azul" [(ngModel)]="color" (change)="modifyColor()">
                <span class="auxRadioCheckStyle"></span>
            </div>
        </div>
        <label for="azul">
            <div class="colores">
                <div class="suaveazul"></div>
                <div class="normalazul"></div>
                <div class="oscuroazul"></div>
            </div>
        </label>
    </div>
    <div class="radio">
        <div class="radioCheckCont">
            <div class="containerRadioCheck">
                <input type="radio" id="morado" name="radio" class="radioCheckStyle" value="morado" [(ngModel)]="color" (change)="modifyColor()">
                <span class="auxRadioCheckStyle"></span>
            </div>
        </div>
        <label for="morado">
            <div class="colores">
                <div class="suavemorado"></div>
                <div class="normalmorado"></div>
                <div class="oscuromorado"></div>
            </div>
        </label>
    </div>
    <br><br><br>
    <label for="">Tamaño del texto</label><br><br><br>
    <div>
        <div class="selector">
            <ng-select [items]="optionTamanyo" [(ngModel)]="tamanyo" (change)="modifyLetter()" [searchable]="false" [clearable]="false"></ng-select>
        </div>        
    </div>
</div>