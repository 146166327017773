import { BaseObject } from "./Base";

export interface Parroquia{
    id?: number,
    nombre: string,
    ubicacion: string
}
export class Parroquia extends BaseObject implements Parroquia{
    static convertFrontObject(data){
        let parr= new Parroquia();
        parr.id=data.id;
        parr.nombre=data.nombre;
        parr.ubicacion=data.ubicacion;
        return parr;
    }
}