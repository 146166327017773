import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/objects/Fecha';
import { Firma } from 'src/app/objects/Firma';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popUps/confirmPopup/confirmPopup.component';
import { PopupFileComponent } from 'src/app/popUps/popupFile/popupFile.component';
import { FirmaService } from 'src/app/services/api/firma.service';
import { PartidaService } from 'src/app/services/api/partida.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';
import { PopupService } from 'src/app/services/util/popup.service';
import { environment } from 'src/environments/environment';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';
import { Parroquia } from 'src/app/objects/Parroquia';

@Component({
  selector: 'app-defuncion',
  templateUrl: './defuncion.component.html',
  styleUrls: ['./defuncion.component.css'],
})
export class DefuncionComponent extends UnsubscribesDestroy implements OnInit {
  @ViewChild('documentoImprimir') documentoImprimir!: ElementRef;

  pasoCreacionPartida = 0;
  notasArray: string[] = [];
  firmas: Array<Firma> = [];
  parroquias: Array<Parroquia> = [];

  urlImages = environment.urlImg;
  imgSelect =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  today = new Date();
  mesActual = '';
  objFecha: Fecha = new Fecha();
  days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  dayName = this.days[new Date().getDay()];
  /*PASO 0*/
  numeroPartida: number | null = null;
  folioPartida: number | null = null;
  libroPartida: string | number | null = null;
  anyo: number | null = null;
  sacramentos: string | null = null;
  nombreDifunto: string | null = null;
  primerApellido: string | null = null;
  segundoApellido: string | null = null;
  edad: number | null = null;
  estado: string | null = null;
  nombrePadre: string | null = null;
  nombreMadre: string | null = null;
  direccionDifunto: string | null = null;
  parroquiaSelect: number | null = null;

  numeroPartidaVacio: boolean = false;
  folioPartidaVacio: boolean = false;
  libroPartidaVacio: boolean = false;
  anyoVacio: boolean = false;
  sacramentosVacio: boolean = false;
  nombreDifuntoVacio: boolean = false;
  primerApellidoVacio: boolean = false;
  segundoApellidoVacio: boolean = false;
  edadVacio: boolean = false;
  estadoVacio: boolean = false;
  nombrePadreVacio: boolean = false;
  nombreMadreVacio: boolean = false;
  parroquiaVacio: boolean = false;


  paso0Valido = true;
  /*FIN PASO 0*/

  /*PASO 1*/
  fechaDefuncion: string = '';
  horaDefuncion: string | null = null;
  enterrado: string | null = null;
  parrocoSacramento: string | null = null;
  parroco: string | null = null;
  infrafirmado: string | null = null;

  notas: string = '';

  fechaDefuncionVacio: boolean = false;
  horaDefuncionVacio: boolean = false;
  enterradoVacio: boolean = false;
  parrocoSacramentoVacio: boolean = false;
  parrocoVacio: boolean = false;
  infrafirmadoVacio: boolean = false;
  direccionDifuntoVacio: boolean = false;

  paso1Valido: boolean = true;
  /*FIN PASO 1*/

  /*VALIDACIONES PASO 2*/
  curaOriginal: string |null=null;
  firmaSelect: number | null = null;

  curaOriginalVacio: boolean = false;
  firmaVacio: boolean = false;

  paso2Valido: boolean = true;
  /*FIN VALIDACIONES PASO 2*/

  parroquiaNombre: string = '';
  ubicacionParroquia: string = '';
  parroquiaId: number | null = null;
  editar = false;
  idFiel = -1;
  constructor(
    private firmaSe: FirmaService,
    private notificaciones: NotificacionesService,
    private router: Router,
    private popupSE: PopupService,
    private partidaSE: PartidaService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private parroquiaSE: ParroquiaService
  ) {
    super();
  }

  checkData(paso) {
    switch (paso) {
      case 0:
        if (this.numeroPartida == null) {
          this.numeroPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.numeroPartidaVacio = false;
        }
        if (this.direccionDifunto == null) {
          this.direccionDifuntoVacio = true;
          this.paso0Valido = false;
        } else {
          this.direccionDifuntoVacio = false;
        }
        if (this.folioPartida == null) {
          this.folioPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.folioPartidaVacio = false;
        }
        if (this.libroPartida == null) {
          this.libroPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.libroPartidaVacio = false;
        }
        if (this.anyo == null) {
          this.anyoVacio = true;
          this.paso0Valido = false;
        } else {
          this.anyoVacio = false;
        }
        if (this.nombreDifunto == null) {
          this.nombreDifuntoVacio = true;
          this.paso0Valido = false;
        } else {
          this.nombreDifuntoVacio = false;
        }
        if (this.primerApellido == null) {
          this.primerApellidoVacio = true;
          this.paso0Valido = false;
        } else {
          this.primerApellidoVacio = false;
        }
        if (this.segundoApellido == null) {
          this.segundoApellidoVacio = true;
          this.paso0Valido = false;
        } else {
          this.segundoApellidoVacio = false;
        }
        if (this.edad == null) {
          this.edadVacio = true;
          this.paso0Valido = false;
        } else {
          this.edadVacio = false;
        }
        if (this.estado == null) {
          this.estadoVacio = true;
          this.paso0Valido = false;
        } else {
          this.estadoVacio = false;
        }
        if (this.nombrePadre == null) {
          this.nombrePadreVacio = true;
          this.paso0Valido = false;
        } else {
          this.nombrePadreVacio = false;
        }

        if (this.nombreMadre == null) {
          this.nombreMadreVacio = true;
          this.paso0Valido = false;
        } else {
          this.nombreMadreVacio = false;
        }
        if (this.parroquiaSelect == null) {
          this.parroquiaVacio = true;
          this.paso0Valido = false;
        } else {
          this.parroquiaVacio = false;

          this.parroquias.forEach((element) => {
            if (element.id == this.parroquiaSelect) {
              this.parroquiaId = element['id'];
              this.parroquiaNombre = element['nombre'];
              this.ubicacionParroquia = element['ubicacion'];
            }
          });
        }

        if (
          !this.numeroPartidaVacio &&
          !this.folioPartidaVacio &&
          !this.libroPartidaVacio &&
          !this.parroquiaVacio &&
          !this.anyoVacio &&
          !this.nombreDifuntoVacio &&
          !this.primerApellidoVacio &&
          !this.segundoApellidoVacio &&
          !this.edadVacio &&
          !this.estadoVacio &&
          !this.nombrePadreVacio &&
          !this.nombreMadreVacio
        ) {
          this.paso0Valido = true;
        }
        break;

      case 1:
        if (this.fechaDefuncion == '') {
          this.fechaDefuncionVacio = true;
          this.paso1Valido = false;
        } else {
          this.fechaDefuncionVacio = false;
        }
        if (this.horaDefuncion == null) {
          this.horaDefuncionVacio = true;
          this.paso1Valido = false;
        } else {
          this.horaDefuncionVacio = false;
        }
        if (this.enterrado == null) {
          this.enterradoVacio = true;
          this.paso1Valido = false;
        } else {
          this.enterradoVacio = false;
        }
        if (this.parroco == null) {
          this.parrocoVacio = true;
          this.paso1Valido = false;
        } else {
          this.parrocoVacio = false;
        }
        if (this.sacramentos == null) {
          this.sacramentosVacio = true;
          this.paso1Valido = false;
        } else {
          this.sacramentosVacio = false;
        }

        if (
          !this.sacramentosVacio &&
          !this.enterradoVacio &&
          !this.fechaDefuncionVacio &&
          !this.horaDefuncionVacio //&&
          //!this.parrocoVacio
        ) {
          this.paso1Valido = true;
        }
        break;
      case 2:
        if (this.infrafirmado == null) {
          this.infrafirmadoVacio = true;
          this.paso2Valido = false;
        } else {
          this.infrafirmadoVacio = false;
        }

        if(this.curaOriginal==null){
          this.curaOriginalVacio = true;
          this.paso2Valido = false;
        }else{
          this.curaOriginalVacio=false;
        }

        if (this.firmaSelect == null) {
          this.firmaVacio = true;
          this.paso2Valido = false;
        } else {
          this.firmaVacio = false;
          this.paso2Valido = true;

          this.firmas.forEach((element) => {
            if (element.id == this.firmaSelect) {
              this.parroco = element.nombre;
              // console.log(element.parroquia['id']);
              // this.parroquiaId = element.parroquia['id'];
              // this.parrocoNombre = element.nombre;
              // this.parroquiaNombre = element.parroquia['nombre'];
              // this.ubicacionParroquia = element.parroquia['ubicacion'];
              this.imgSelect = element.url;
            }
          });

        }
        break;
    }
  }
  splitNotas() {
    this.notasArray = this.notas.split("\n");
  }
  siguiente() {
    if (this.pasoCreacionPartida != 3) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida++;
    }
  }

  atras() {
    if (this.pasoCreacionPartida > 0) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida--;
    }
  }

  downloadAsPDF() {
    const pdfTable = this.documentoImprimir.nativeElement;
    var divContents = pdfTable.innerHTML;
    var a = window.open('', '', 'height=500, width=500')!;
    a.document.write(
      '<html> <style>body{padding:3em 2em}.abuelos{margin-top:1em;margin-bottom:1em;display:flex;flex-direction:column;}.firma{width:100% !important;display:flex !important;justify-content:center;align-items:center;flex-direction:column}.firma img{width:30%;margin-top:3em}header{border-bottom:solid 1px;padding-bottom:25px;font-size:15pt;display:flex;justify-content:space-between;position:relative;}.columnas{display:flex;gap:25px;}.columnas .col_izq{width:270px !important;}.columnas .col_der{width:100% !important;}.campo > *{display:block;text-align:center;width:100%;}.campo span{text-transform:uppercase;font-size:14pt;border-bottom:solid 1px;margin-bottom:5px;}.campo{margin-bottom:15px;}.feligres{margin-top:140px;height:auto!important;margin-bottom:50px;}p.texto{text-align:justify;}u{display:block;font-weight:600;font-size:14pt;}u+p{font-size:13pt;}.columnas.separacion .col_izq{border-right:solid 2px;padding-right:15px;}.notas>p{word-break: break-word;}</style>'
    );
    a.document.write('<body >');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.onload = function () {
      a.print();
    };
  }

  sendData(tipo) {
    var borrador;
    if (tipo == 'borrador') {
      borrador = true;
    } else {
      borrador = false;
    }
    var partida = {
      fecha: this.fechaDefuncion,
      numero: this.numeroPartida,
      folio: this.folioPartida, //
      libro: this.libroPartida, //
      anyo: this.anyo,
      hora: this.horaDefuncion,
      parroco: this.infrafirmado + ',' + this.parroco,
      testigos: '',
      borrador: borrador,
      firma: this.firmaSelect,
      url: sessionStorage.getItem('documentoAdjunto'),
      sacramento: 5,
      parroquia: this.parroquiaId,
      fiel: {
        nombre: this.nombreDifunto,
        apellido1: this.primerApellido,
        apellido2: this.segundoApellido,
        sexo: '',
        padrinos: '',
        padre: this.nombrePadre,
        madre: this.nombreMadre,
        lugarnacimientomadre: '',
        lugarnacimientopadre: '',
        abuelosmaternales: '',
        abuelospaternales: '',
        direccion: this.direccionDifunto,
      },
      extraData: JSON.stringify({
        edadDifunto: this.edad,
        estadoDifunto: this.estado,
        sacramentos: this.sacramentos,
        enterradoEn: this.enterrado,
        notas: this.notas,
        curaOriginal:this.curaOriginal
      }),
    };
    if (tipo == 'borrador') {
      this.popupSE.openPopup(ConfirmPopupComponent, {
        titulo: 'Guardar borrador',
        description: 'quieres guardar el borrador',
        accion: 'Guardar',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd2))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this._unsubInd2.next('');
          if (value.returnValue) {
            this.partidaSE.create(partida);
            this.partidaSE
              .getResultUpdate()
              .pipe(takeUntil(this._unsubInd))
              .subscribe((value) => {
                if (value == null) {
                  return;
                }
                this.notificaciones.showFeedBack(value.message);
                this.router.navigate(['/inicio']);
                sessionStorage.removeItem('documentoAdjunto');
                this._unsubInd.next('');
              });
          } else {
            this.router.navigate(['/inicio']);
            sessionStorage.removeItem('documentoAdjunto');
          }
        });
    } else if (!this.editar) {
      this.partidaSE.create(partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
                this.router.navigate(['/inicio']);
              } else {
                this.router.navigate(['/inicio']);
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    } else {
      partida.fiel['id'] = this.idFiel;
      this.partidaSE.update(this.route.snapshot.params['id'], partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
                this.router.navigate(['/inicio']);
              } else {
                this.router.navigate(['/inicio']);
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    }
  }
  validateNegativeNumbers(number, tipo) {
    console.log(number);
    switch (tipo) {
      case 'numeroPartida':
        if (number != null && number < 0) {
          this.numeroPartida = 0;
        }
        break;
      case 'folioPartida':
        if (number != null && number < 0) {
          this.folioPartida = 0;
        }
        break;
      case 'anyo':
        if (number != null && number < 0) {
          this.anyo = 0;
        }
        break;
      case 'edad':
        if (number != null && number < 0) {
          this.edad = 0;
        }
        break;
    }
  }
  changeDocumentoPrevio() {
    this.popupSE.openPopup(PopupFileComponent, { action: 'Añadir' });
  }
  override ngOnDestroy() {
    sessionStorage.removeItem('documentoAdjunto');
  }
  hoy: Fecha = new Fecha();
  ngOnInit() {
    if (this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
      this.partidaSE.getIndividual(this.route.snapshot.params['id']);
      this.loader.open();
      this.partidaSE
        .getResultIndividual()
        .pipe(takeUntil(this._unsubInd5))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.loader.closeSlow();
          var extraData = JSON.parse(value.extra_data);
          if (extraData !== undefined) {
            this.edad = extraData.edadDifunto;
            this.estado = extraData.estadoDifunto;
            this.enterrado = extraData.enterradoEn;
            this.sacramentos = extraData.sacramentos;
          }
          console.log(value);
          this.numeroPartida = value.numero;
          this.libroPartida = value.libro;
          this.folioPartida = value.folio;
          this.anyo = value.anyo;
          if (value.fiel !== null) {
            this.nombreDifunto = value.fiel.nombre;
            this.primerApellido = value.fiel.apellido1;
            this.segundoApellido = value.fiel.apellido2
            this.nombrePadre = value.fiel.padre.split(' ')[0];
            this.direccionDifunto = value.fiel.direccion;

            this.nombreMadre = value.fiel.madre.split(' ')[0];

            this.idFiel = value.fiel.id;
          }
          this.horaDefuncion = value.hora;
          this.infrafirmado = value.parroco.split(',')[0];
          this.parroco = value.parroco.split(',')[2];
          if (value.firma !== null) {
            this.firmaSelect = value.firma.id;
          }
          if (value.fecha !== null) {
            this.fechaDefuncion = value.fecha.split('T')[0];
          }
          if (value.parroquia !== null) {
            this.parroquiaSelect = value.parroquia.id;
          }
          this._unsubInd5.next('');
        });



    }
    this.hoy = Fecha.javascriptConvert(new Date());
    this.objFecha.year = this.today.getFullYear();
    this.objFecha.mes = this.today.getMonth() + 1;
    this.objFecha.dia = this.today.getDate();
    this.mesActual = this.objFecha.mesNombre();
    this.firmaSe.getAll();
    this.firmaSe
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.firmas = value;
      });
    this.parroquiaSE.getAll();
    this.parroquiaSE
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.parroquias = value;
      })
  }
  cambioFecha() {
    this.objFecha = Fecha.javascriptConvert(new Date(this.fechaDefuncion));
  }

}
