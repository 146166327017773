import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';

import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeES, 'es');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormularioComponent } from './navegacion/formulario/formulario.component';
import { NavbarComponent } from './navegacion/navbar/navbar.component';
import { NavbarAjustesComponent } from './navegacion/navbar-ajustes/navbar-ajustes.component';
import { NavbarAjustesAparienciaComponent } from './ajustes/navbar-ajustes-apariencia/navbar-ajustes-apariencia.component';
import { NavbarAjustesFirmasComponent } from './ajustes/navbar-ajustes-firmas/navbar-ajustes-firmas.component';
import { NavbarAjustesParroquiasComponent } from './ajustes/navbar-ajustes-parroquias/navbar-ajustes-parroquias.component';
import { NavbarAjustesSoporteComponent } from './ajustes/navbar-ajustes-soporte/navbar-ajustes-soporte.component';
import { NavbarInicioComponent } from './navegacion/navbar-inicio/navbar-inicio.component';
import { NavbarInicioResultadosComponent } from './documentos/navbar-inicio-resultados/navbar-inicio-resultados.component';
import { NavbarAnyadirVerborradoresComponent } from './documentos/navbar-anyadir-verborradores/navbar-anyadir-verborradores.component';
import { NavbarDocumentosComponent } from './navegacion/navbar-documentos/navbar-documentos.component';
import { NavbarDocumentosPulsadoComponent } from './documentos/navbar-documentos-pulsado/navbar-documentos-pulsado.component';
import { NavbarDocumentosDocumentoComponent } from './documentos/navbar-documentos-documento/navbar-documentos-documento.component';
import { NavbarDocumentosModificacionesComponent } from './popUps/navbar-documentos-modificaciones/navbar-documentos-modificaciones.component';
import { NavbarAnyadirCancelarComponent } from './popUps/navbar-anyadir-cancelar/navbar-anyadir-cancelar.component';
import { NavbarAnyadirExaminarComponent } from './popUps/navbar-anyadir-examinar/navbar-anyadir-examinar.component';
import { NavbarAnyadirGuardadoexitoComponent } from './popUps/navbar-anyadir-guardadoexito/navbar-anyadir-guardadoexito.component';
import { NavbarAnyadirFinalComponent } from './popUps/navbar-anyadir-final/navbar-anyadir-final.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationService } from './services/util/navigation.service';
import { GridResultadosComponent } from './documentos/grid-resultados/grid-resultados.component';
import { NavbarInicioAvanzadoLateralComponent } from './popUps/navbar-inicio-avanzado-lateral/navbar-inicio-avanzado-lateral.component';
import { ParroquiaComponent } from './ajustes/parroquia/parroquia.component';
import { ParroquiaService } from './services/api/parroquia.service';
import { CheckRadioBoxComponent } from './elements/checkRadioBox/checkRadioBox.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileInputDirective } from './directive/fileInput.directive';
import { FirmaFormPopupComponent } from './popUps/firmaFormPopup/firmaFormPopup.component';
import { BusquedaComponent } from './components/busqueda/busqueda.component';
import { FilecardComponent } from './components/filecard/filecard.component';
import { ConfirmPopupComponent } from './popUps/confirmPopup/confirmPopup.component';
import { ParroquiaFormPopupComponent } from './popUps/parroquiaFormPopup/parroquiaFormPopup.component';
import { LossPasswordComponent } from './pages/lossPassword/lossPassword.component';
import { CrearPartidaComponent } from './Pages/crearPartida/crearPartida.component';
import { BautizoComponent } from './pages/crearPartida/bautizo/bautizo.component';
import { DefuncionComponent } from './pages/crearPartida/defuncion/defuncion.component';
import { MatrimonioCatolicoComponent } from './pages/crearPartida/matrimonioCatolico/matrimonioCatolico.component';
import { MatrimonioMixtoComponent } from './pages/crearPartida/matrimonioMixto/matrimonioMixto.component';
import { ConfirmacionComponent } from './pages/crearPartida/confirmacion/confirmacion.component';
import { PopupFileComponent } from './popUps/popupFile/popupFile.component';
import { SafePipe } from './pipes/SafePipe.pipe';
@NgModule({
  declarations: [
    AppComponent,
    FormularioComponent,
    NavbarComponent,
    NavbarAjustesComponent,
    NavbarAjustesAparienciaComponent,
    NavbarAjustesFirmasComponent,
    NavbarAjustesParroquiasComponent,
    NavbarAjustesSoporteComponent,
    NavbarInicioComponent,
    NavbarInicioResultadosComponent,
    NavbarAnyadirVerborradoresComponent,
    NavbarDocumentosComponent,
    NavbarDocumentosPulsadoComponent,
    NavbarDocumentosDocumentoComponent,
    NavbarDocumentosModificacionesComponent,
    NavbarAnyadirCancelarComponent,
    NavbarAnyadirExaminarComponent,
    NavbarAnyadirGuardadoexitoComponent,
    NavbarAnyadirFinalComponent,
    GridResultadosComponent,
    NavbarInicioAvanzadoLateralComponent,
    ParroquiaComponent,
    BusquedaComponent,
    FilecardComponent,

    //Pages
    LossPasswordComponent,
    CrearPartidaComponent,

    BautizoComponent,
    DefuncionComponent,
    MatrimonioCatolicoComponent,
    MatrimonioMixtoComponent,
    ConfirmacionComponent,
    //Elementos
    CheckRadioBoxComponent,
    //Popup
    FirmaFormPopupComponent,
    ConfirmPopupComponent,
    ParroquiaFormPopupComponent,
    PopupFileComponent,
    //Directivas
    FileInputDirective,
    //Pipes
    SafePipe,
  ],
  imports: [
    BrowserModule,
    NgSelectModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
  ],
  providers: [
    NavigationService,
    ParroquiaService,
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
