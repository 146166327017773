<!--COPIAR-->
<div class="crearPartidaGeneralContainer">
  <div class="cancelarGuardarContainer">
    <span (click)="sendData('borrador')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8.621"
        height="14.414"
        viewBox="0 0 8.621 14.414"
      >
        <path
          id="Trazado_125"
          data-name="Trazado 125"
          d="M-9108.06,2156.32l-6.5,6.5,6.5,6.5"
          transform="translate(9115.974 -2155.613)"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
      </svg>
    </span>
    <label for="">Cancelar y guardar</label>
  </div>
  <div class="headerCrearPartidaContainer">
    <div>
      <div class="etiquetaPartida">
        <label for="">Matrimonio</label>
      </div>
      <span *ngIf="!editar">Nueva partida</span>
      <span *ngIf="editar">Editar partida</span>
    </div>
    <div>
      <button
        type="buttton"
        class="modificarDocumento"
        (click)="changeDocumentoPrevio()"
      >
        Modificar documento adjunto
      </button>
    </div>
  </div>
  <div class="dataGeneralContainer">
    <div class="dataContainer">
      <div class="timelineContainer">
        <div>
          <span [class.active]="pasoCreacionPartida >= 0"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 1"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 2"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 3"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 4"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 5"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida == 6"></span>
        </div>
        <div>
          <span
            [class.active]="pasoCreacionPartida == 0"
            [class.error]="!paso0Valido"
            >Información sacramento</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 1"
            [class.error]="!paso1Valido"
            >Información personal del laico</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 2"
            [class.error]="!paso2Valido"
            >Información padres</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 3"
            [class.error]="!paso3Valido"
            >Testigos</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 4"
            [class.error]="!paso4Valido"
            >Parrocos implicados y Firma</span
          >
          <div></div>
          <span [class.active]="pasoCreacionPartida == 5"
          [class.error]="!paso5Valido"
            >Jurisdicción</span>
          <div></div>
          <span [class.active]="pasoCreacionPartida == 6"
            >Revisar y finalizar</span
          >
        </div>
      </div>
      <div class="formContainer">
        <ng-container *ngIf="pasoCreacionPartida == 0">
          <div class="paso0Container">
            <div>
              <div>
                <label for="" [class.error]="numeroPartidaVacio"
                  >Nº de partida:
                </label>
                <input
                  type="number"
                  min="0"
                  (input)="
                    validateNegativeNumbers(numeroPartida, 'numeroPartida')
                  "
                  [class.error]="numeroPartidaVacio"
                  class="input"
                  [(ngModel)]="numeroPartida"
                />
              </div>
              <div>
                <label for="" [class.error]="libroPartidaVacio"
                  >Tomo:
                </label>
                <input
                  type="text"
                  [class.error]="libroPartidaVacio"
                  class="input"
                  [(ngModel)]="libroPartida"
                />
              </div>

              <div>
                <label for="" [class.error]="folioPartidaVacio"
                  >Numero de partida:
                </label>
                <input
                  type="number"
                  min="0"
                  (input)="
                    validateNegativeNumbers(folioPartida, 'folioPartida')
                  "
                  [class.error]="folioPartidaVacio"
                  class="input"
                  [(ngModel)]="folioPartida"
                />
              </div>
              <div>
                <label for="" [class.error]="anyoVacio">Año: </label>
                <input
                  type="number"
                  min="0"
                  (input)="validateNegativeNumbers(anyo, 'anyo')"
                  [class.error]="anyoVacio"
                  [(ngModel)]="anyo"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div>
                <label for="" [class.error]="fechaCelebracionVacio"
                  >Fecha de celebración del matrimonio:
                </label>
                <input
                  type="date"
                  (input)="trocearFecha(fechaCelebracion)"
                  [class.error]="fechaCelebracionVacio"
                  [(ngModel)]="fechaCelebracion"
                  class="input"
                />
              </div>
              <!-- <div>
                <label for="" [class.error]="horaCelebracionVacio"
                  >Hora de celebración del sacramento:
                </label>
                <input
                  type="time"
                  [class.error]="horaCelebracionVacio"
                  [(ngModel)]="horaCelebracion"
                  class="input"
                />
              </div> -->
              <div class="">
                <label for="" [class.error]="firmaVacio">Parroquia de la celebracion: </label>
                <ng-select
                  [class.error]="parroquiaVacio"
                  class="secundaryTheme fix_height"
                  [placeholder]="'Selecciona una Parroquia'"
                  [items]="parroquias"
                  [(ngModel)]="parroquiaSelect"
                  [clearable]="false"
                  bindLabel="nombre"
                  bindValue="id"
                ></ng-select>
              </div>
            </div>
            <div>
              <div>
                <label for="">Notas marginales: </label>
                <textarea
                  style="
                    resize: none;
                    height: 100px;
                    background: var(--color-suave);
                  "
                  [(ngModel)]="notas"
                  (keyup)="splitNotas()"
                  class="input"
                ></textarea>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 1">
          <div class="desplegable">
            <div class="infoContainer">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g
                    id="Grupo_1695"
                    data-name="Grupo 1695"
                    transform="translate(-1094 -584)"
                  >
                    <line
                      id="Línea_213"
                      data-name="Línea 213"
                      y2="2"
                      transform="translate(1102 593)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_857"
                      data-name="Trazado 857"
                      d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                      transform="translate(1094 584)"
                      fill="#0d1423"
                    />
                    <circle
                      id="Elipse_603"
                      data-name="Elipse 603"
                      cx="7.25"
                      cy="7.25"
                      r="7.25"
                      transform="translate(1094.75 584.75)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <label
                  for=""
                  [class.error]="
                    bautizoMaridoVacio ||
                    nombreMaridoVacio ||
                    apellidosMaridoVacio ||
                    estadoCivilVacio ||
                    nacimientoMaridoVacio
                  "
                  >Información del marido</label
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.726"
                  height="20.726"
                  viewBox="0 0 20.726 20.726"
                >
                  <path
                    id="male"
                    d="M16.116,4.75h6.946m0,0V11.7m0-6.946-9.156,9.156m1.579,3.789a5.367,5.367,0,1,1-5.367-5.367A5.367,5.367,0,0,1,15.485,17.695Z"
                    transform="translate(-3.75 -3.336)"
                    fill="none"
                    stroke="#0d1423"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div>
                <span (click)="openMarido()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.828"
                    height="8.414"
                    viewBox="0 0 14.828 8.414"
                  >
                    <path
                      id="Trazado_855"
                      data-name="Trazado 855"
                      d="M-6345.379-17421.977l6,6,6-6"
                      transform="translate(6346.793 17423.391)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="camposContainer" *ngIf="openedMarido">
              <div>
                <div>
                  <label for="" [class.error]="nombreMaridoVacio"
                    >Nombre:
                  </label>
                  <input
                    type="text"
                    [class.error]="nombreMaridoVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="nombreMarido"
                    class="input"
                  />
                </div>
                <div>
                  <label for="" [class.error]="apellidosMaridoVacio"
                    >Apellidos:
                  </label>
                  <input
                    type="text"
                    [class.error]="apellidosMaridoVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="apellidosMarido"
                    class="input"
                  />
                </div>
              </div>
              <div>
                <div class="campoEspecial">
                  <label for="" [class.error]="estadoCivilVacio">Estado Civil: </label>
                  <ng-select
                    class="fix_height"
                    [class.error]="estadoCivilVacio"
                    [(ngModel)]="estadoCivil"
                    [items]="estadosCiviles"
                    [clearable]="false"
                  ></ng-select>
                </div>
                <div>
                  <label for="" [class.error]="nacimientoMaridoVacio"
                    >Fecha de nacimiento:
                  </label>
                  <input
                    type="date"
                    (input)="trocearFechaNacimientoMarido(nacimientoMarido)"
                    [class.error]="nacimientoMaridoVacio"
                    [(ngModel)]="nacimientoMarido"
                    class="input"
                  />
                </div>
              </div>
              <div>
                <div class="campoEspecial">
                  <label for="" [class.error]="bautizoMaridoVacio"
                    >Bautizado en la parroquia:
                  </label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                  >
                    <path
                      id="crucifix"
                      d="M12,4.75v5m0,0v9.5m0-9.5H7.75m4.25,0h4.25"
                      transform="translate(-7 -4)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  <input
                    type="text"
                    class="input"
                    [class.error]="bautizoMaridoVacio"
                    [(ngModel)]="bautizoMarido"
                  />
                </div>
                <div>
                  <label for="" [class.error]="bautizoLugarMaridoVacio"
                    >Lugar:
                  </label>
                  <input
                    type="text"
                    class="input"
                    [class.error]="bautizoLugarMaridoVacio"
                    [(ngModel)]="bautizoLugarMarido"
                  />
                </div>
              </div>

              <div>
                <div class="campoEspecial">
                  <label for="" [class.error]="libroMaridoVacio">Tomo: </label>

                  <input
                    type="text"
                    class="input"
                    [class.error]="libroMaridoVacio"
                    [(ngModel)]="libroMarido"
                  />
                </div>
                <div>
                  <label for="" [class.error]="folioMaridoVacio">Folio: </label>
                  <input
                    type="text"
                    class="input"
                    [class.error]="folioMaridoVacio"
                    [(ngModel)]="folioMarido"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="desplegable">
            <div class="infoContainer">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g
                    id="Grupo_1695"
                    data-name="Grupo 1695"
                    transform="translate(-1094 -584)"
                  >
                    <line
                      id="Línea_213"
                      data-name="Línea 213"
                      y2="2"
                      transform="translate(1102 593)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_857"
                      data-name="Trazado 857"
                      d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                      transform="translate(1094 584)"
                      fill="#0d1423"
                    />
                    <circle
                      id="Elipse_603"
                      data-name="Elipse 603"
                      cx="7.25"
                      cy="7.25"
                      r="7.25"
                      transform="translate(1094.75 584.75)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <label
                  for=""
                  [class.error]="
                    bautizoEsposaVacio ||
                    nombreEsposaVacio ||
                    apellidosEsposaVacio ||
                    estadoCivilEsposaVacio ||
                    fechaNacimientoEsposaVacio
                  "
                  >Información de la esposa</label
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.344"
                  height="27.306"
                  viewBox="0 0 13.344 27.306"
                >
                  <path
                    id="female"
                    d="M14.422,30.056V16.094m0,0A5.672,5.672,0,1,0,8.75,10.422,5.672,5.672,0,0,0,14.422,16.094ZM8.75,23.948H20.094"
                    transform="translate(-7.75 -3.75)"
                    fill="none"
                    stroke="#0d1423"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div>
                <span (click)="openEsposa()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.828"
                    height="8.414"
                    viewBox="0 0 14.828 8.414"
                  >
                    <path
                      id="Trazado_855"
                      data-name="Trazado 855"
                      d="M-6345.379-17421.977l6,6,6-6"
                      transform="translate(6346.793 17423.391)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="camposContainer" *ngIf="openedEsposa">
              <div>
                <div>
                  <label for="" [class.error]="nombreEsposaVacio"
                    >Nombre:
                  </label>
                  <input
                    type="text"
                    [class.error]="nombreEsposaVacio"
                    [(ngModel)]="nombreEsposa"
                    class="input"
                  />
                </div>
                <div>
                  <label for="" [class.error]="apellidosEsposaVacio"
                    >Apellidos:
                  </label>
                  <input
                    type="text"
                    [class.error]="apellidosEsposaVacio"
                    [(ngModel)]="apellidosEsposa"
                    class="input"
                  />
                </div>
              </div>
              <div>
                <div class="campoEspecial">
                  <label for="" [class.error]="estadoCivilEsposaVacio">Estado Civil: </label>
                  <ng-select
                    class="fix_height"
                    [class.error]="estadoCivilEsposaVacio"
                    [(ngModel)]="estadoCivilEsposa"
                    [items]="estadosCivilesEsposa"
                    [clearable]="false"
                  ></ng-select>
                </div>
                <div>
                  <label for="" [class.error]="fechaNacimientoEsposaVacio"
                    >Fecha de nacimiento:
                  </label>
                  <input
                    type="date"
                    (input)="trocearFechaNacimientoEsposa(fechaNacimientoEsposa)"
                    [class.error]="fechaNacimientoEsposaVacio"
                    [(ngModel)]="fechaNacimientoEsposa"
                    class="input"
                  />
                </div>
              </div>
              <div>
                <div class="campoEspecial">
                  <label for="" [class.error]="bautizoEsposaVacio"
                    >Bautizado en la parroquia:
                  </label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                  >
                    <path
                      id="crucifix"
                      d="M12,4.75v5m0,0v9.5m0-9.5H7.75m4.25,0h4.25"
                      transform="translate(-7 -4)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  <input
                    type="text"
                    class="input"
                    [class.error]="bautizoEsposaVacio"
                    [(ngModel)]="bautizoEsposa"
                  />
                </div>
                <div>
                  <label for="" [class.error]="bautizoLugarEsposaVacio"
                    >Lugar:
                  </label>
                  <input
                    type="text"
                    class="input"
                    [class.error]="bautizoLugarEsposaVacio"
                    [(ngModel)]="bautizoLugarEsposa"
                  />
                </div>
              </div>

              <div>
                <div class="campoEspecial">
                  <label for="" [class.error]="libroEsposaVacio">Tomo: </label>

                  <input
                    type="text"
                    class="input"
                    [class.error]="libroEsposaVacio"
                    [(ngModel)]="libroEsposa"
                  />
                </div>
                <div>
                  <label for="" [class.error]="folioEsposaVacio">Folio: </label>
                  <input
                    type="text"
                    class="input"
                    [class.error]="folioEsposaVacio"
                    [(ngModel)]="folioEsposa"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 2">
          <div class="desplegable">
            <div class="infoContainer">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g
                    id="Grupo_1695"
                    data-name="Grupo 1695"
                    transform="translate(-1094 -584)"
                  >
                    <line
                      id="Línea_213"
                      data-name="Línea 213"
                      y2="2"
                      transform="translate(1102 593)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_857"
                      data-name="Trazado 857"
                      d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                      transform="translate(1094 584)"
                      fill="#0d1423"
                    />
                    <circle
                      id="Elipse_603"
                      data-name="Elipse 603"
                      cx="7.25"
                      cy="7.25"
                      r="7.25"
                      transform="translate(1094.75 584.75)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <label
                  for=""
                  [class.error]="
                    nombrePadreMaridoVacio ||
                    apellidosPadreMaridoVacio ||
                    nombreMadreMaridoVacio ||
                    apellidosMadreMaridoVacio
                  "
                  >Información padres esposo:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.726"
                  height="20.726"
                  viewBox="0 0 20.726 20.726"
                >
                  <path
                    id="male"
                    d="M16.116,4.75h6.946m0,0V11.7m0-6.946-9.156,9.156m1.579,3.789a5.367,5.367,0,1,1-5.367-5.367A5.367,5.367,0,0,1,15.485,17.695Z"
                    transform="translate(-3.75 -3.336)"
                    fill="none"
                    stroke="#0d1423"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div>
                <span (click)="openPadresMarido()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.828"
                    height="8.414"
                    viewBox="0 0 14.828 8.414"
                  >
                    <path
                      id="Trazado_855"
                      data-name="Trazado 855"
                      d="M-6345.379-17421.977l6,6,6-6"
                      transform="translate(6346.793 17423.391)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="camposContainer" *ngIf="openedPadresMarido">
              <div>
                <div>
                  <label for="" [class.error]="nombrePadreMaridoVacio"
                    >Nombre padre:
                  </label>
                  <input
                    type="text"
                    [class.error]="nombrePadreMaridoVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="nombrePadreMarido"
                    class="input"
                  />
                </div>
                <!--<div>
                  <label for="" [class.error]="apellidosPadreMaridoVacio"
                    >Apellidos padre:
                  </label>
                  <input
                    type="text"
                    [class.error]="apellidosPadreMaridoVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="apellidosPadreMarido"
                    class="input"
                  />
                </div>-->
              </div>
              <div>
                <div>
                  <label for="" [class.error]="nombreMadreMaridoVacio"
                    >Nombre madre:
                  </label>
                  <input
                    type="text"
                    [class.error]="nombreMadreMaridoVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="nombreMadreMarido"
                    class="input"
                  />
                </div>
               <!--<div>
                  <label for="" [class.error]="apellidosMadreMaridoVacio"
                    >Apellidos madre:
                  </label>
                  <input
                    type="text"
                    [class.error]="apellidosMadreMaridoVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="apellidosMadreMarido"
                    class="input"
                  />
                </div>-->
              </div>
            </div>
          </div>
          <div class="desplegable">
            <div class="infoContainer">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g
                    id="Grupo_1695"
                    data-name="Grupo 1695"
                    transform="translate(-1094 -584)"
                  >
                    <line
                      id="Línea_213"
                      data-name="Línea 213"
                      y2="2"
                      transform="translate(1102 593)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_857"
                      data-name="Trazado 857"
                      d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                      transform="translate(1094 584)"
                      fill="#0d1423"
                    />
                    <circle
                      id="Elipse_603"
                      data-name="Elipse 603"
                      cx="7.25"
                      cy="7.25"
                      r="7.25"
                      transform="translate(1094.75 584.75)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <label
                  for=""
                  [class.error]="
                    nombrePadreEsposaVacio ||
                    apellidosPadreEsposaVacio ||
                    nombreMadreEsposaVacio ||
                    apellidosMadreEsposaVacio
                  "
                  >Información padres esposa</label
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.344"
                  height="27.306"
                  viewBox="0 0 13.344 27.306"
                >
                  <path
                    id="female"
                    d="M14.422,30.056V16.094m0,0A5.672,5.672,0,1,0,8.75,10.422,5.672,5.672,0,0,0,14.422,16.094ZM8.75,23.948H20.094"
                    transform="translate(-7.75 -3.75)"
                    fill="none"
                    stroke="#0d1423"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div>
                <span (click)="openPadresEsposa()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.828"
                    height="8.414"
                    viewBox="0 0 14.828 8.414"
                  >
                    <path
                      id="Trazado_855"
                      data-name="Trazado 855"
                      d="M-6345.379-17421.977l6,6,6-6"
                      transform="translate(6346.793 17423.391)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="camposContainer" *ngIf="openedPadresEsposa">
              <div>
                <div>
                  <label for="" [class.error]="nombrePadreEsposaVacio"
                    >Nombre padre:
                  </label>
                  <input
                    type="text"
                    [class.error]="nombrePadreEsposaVacio"
                    [(ngModel)]="nombrePadreEsposa"
                    class="input"
                  />
                </div>
                <!--<div>
                  <label for="" [class.error]="apellidosPadreEsposaVacio"
                    >Apellidos padre:
                  </label>
                  <input
                    type="text"
                    [class.error]="apellidosPadreEsposaVacio"
                    [(ngModel)]="apellidosPadreEsposa"
                    class="input"
                  />
                </div>-->
              </div>
              <div>
                <div>
                  <label for="" [class.error]="nombreMadreEsposaVacio"
                    >Nombre madre:
                  </label>
                  <input
                    type="text"
                    [class.error]="nombreMadreEsposaVacio"
                    [(ngModel)]="nombreMadreEsposa"
                    class="input"
                  />
                </div>
                <!--<div>
                  <label for="" [class.error]="apellidosMadreEsposaVacio"
                    >Apellidos madre:
                  </label>
                  <input
                    type="text"
                    [class.error]="apellidosMadreEsposaVacio"
                    [(ngModel)]="apellidosMadreEsposa"
                    class="input"
                  />
                </div>-->
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 3">
          <div class="desplegable">
            <div class="infoContainer">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g
                    id="Grupo_1695"
                    data-name="Grupo 1695"
                    transform="translate(-1094 -584)"
                  >
                    <line
                      id="Línea_213"
                      data-name="Línea 213"
                      y2="2"
                      transform="translate(1102 593)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_857"
                      data-name="Trazado 857"
                      d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                      transform="translate(1094 584)"
                      fill="#0d1423"
                    />
                    <circle
                      id="Elipse_603"
                      data-name="Elipse 603"
                      cx="7.25"
                      cy="7.25"
                      r="7.25"
                      transform="translate(1094.75 584.75)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <label
                  for=""
                  [class.error]="
                    nombrePadrinoVacio ||
                    apellidosPadrinoVacio ||
                    vecinoPadrinoVacio
                  "
                  >Testigo 1:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.726"
                  height="20.726"
                  viewBox="0 0 20.726 20.726"
                >
                  <path
                    id="male"
                    d="M16.116,4.75h6.946m0,0V11.7m0-6.946-9.156,9.156m1.579,3.789a5.367,5.367,0,1,1-5.367-5.367A5.367,5.367,0,0,1,15.485,17.695Z"
                    transform="translate(-3.75 -3.336)"
                    fill="none"
                    stroke="#0d1423"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div>
                <span (click)="openPadrino()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.828"
                    height="8.414"
                    viewBox="0 0 14.828 8.414"
                  >
                    <path
                      id="Trazado_855"
                      data-name="Trazado 855"
                      d="M-6345.379-17421.977l6,6,6-6"
                      transform="translate(6346.793 17423.391)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="camposContainer" *ngIf="openedPadrino">
              <div>
                <div>
                  <label for="" [class.error]="nombrePadrinoVacio"
                    >Nombre testigo 1:
                  </label>
                  <input
                    type="text"
                    [class.error]="nombrePadrinoVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="nombrePadrino"
                    class="input"
                  />
                </div>
                <div>
                  <label for="" [class.error]="apellidosPadrinoVacio"
                    >Apellidos testigo 1:
                  </label>
                  <input
                    type="text"
                    [class.error]="apellidosPadrinoVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="apellidosPadrino"
                    class="input"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label for="" [class.error]="vecinoPadrinoVacio"
                    >Vecino de:
                  </label>
                  <input
                    type="text"
                    [class.error]="vecinoPadrinoVacio"
                    [(ngModel)]="vecinoPadrino"
                    class="input"
                  />
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="desplegable">
            <div class="infoContainer">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g
                    id="Grupo_1695"
                    data-name="Grupo 1695"
                    transform="translate(-1094 -584)"
                  >
                    <line
                      id="Línea_213"
                      data-name="Línea 213"
                      y2="2"
                      transform="translate(1102 593)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_857"
                      data-name="Trazado 857"
                      d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                      transform="translate(1094 584)"
                      fill="#0d1423"
                    />
                    <circle
                      id="Elipse_603"
                      data-name="Elipse 603"
                      cx="7.25"
                      cy="7.25"
                      r="7.25"
                      transform="translate(1094.75 584.75)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <label
                  for=""
                  [class.error]="
                    nombreMadrinaVacio ||
                    apellidosMadrinaVacio ||
                    vecinoMadrinaVacio
                  "
                  >Testigo 2:</label
                >
              </div>
              <div>
                <span (click)="openMadrina()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.828"
                    height="8.414"
                    viewBox="0 0 14.828 8.414"
                  >
                    <path
                      id="Trazado_855"
                      data-name="Trazado 855"
                      d="M-6345.379-17421.977l6,6,6-6"
                      transform="translate(6346.793 17423.391)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="camposContainer" *ngIf="openedMadrina">
              <div>
                <div>
                  <label for="" [class.error]="nombreMadrinaVacio"
                    >Nombre testigo 2:
                  </label>
                  <input
                    type="text"
                    [class.error]="nombreMadrinaVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="nombreMadrina"
                    class="input"
                  />
                </div>
                <div>
                  <label for="" [class.error]="apellidosMadrinaVacio"
                    >Apellidos testigo 2:
                  </label>
                  <input
                    type="text"
                    [class.error]="apellidosMadrinaVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [(ngModel)]="apellidosMadrina"
                    class="input"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label for="" [class.error]="vecinoMadrinaVacio"
                    >Vecino de:
                  </label>
                  <input
                    type="text"
                    [class.error]="vecinoMadrinaVacio"
                    [(ngModel)]="vecinoMadrina"
                    class="input"
                  />
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 4">
          <div class="paso0Container">
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="infrafirmadoVacio"
                  >Infrafirmado:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  type="text"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="infrafirmadoVacio"
                  [(ngModel)]="infrafirmado"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="parrocoSacramentoVacio"
                  >Sacerdote que realizó el sacramento:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  type="text"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="parrocoSacramentoVacio"
                  [(ngModel)]="parrocoSacramento"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="firmaVacio">Nombre y firma del sacerdote encargado del oficio: </label>
                <ng-select
                  [class.error]="firmaVacio"
                  class="secundaryTheme fix_height"
                  [placeholder]="'Selecciona una firma'"
                  [items]="firmas"
                  [(ngModel)]="firmaSelect"
                  [clearable]="false"
                  bindLabel="nombre"
                  bindValue="id"
                ></ng-select>
              </div>
            </div>
            <div class="firma">
              <ng-container *ngFor="let image of firmas">
                <img
                  *ngIf="firmaSelect == image.id"
                  [src]="urlImages + image.url"
                />
              </ng-container>
            </div>
            <div></div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 5">
          <div class="paso0Container">
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="jurisdiccionVacio">Jurisdicción </label>
                <ng-select
                  [class.error]="jurisdiccionVacio"
                  class="secundaryTheme fix_height"
                  [placeholder]="'Selecciona un tipo de jurisdicción'"
                  [items]="juridiscciones"
                  [(ngModel)]="jurisdisccionSelect"
                  [clearable]="false"
                  bindLabel="nombre"
                  bindValue="id"
                ></ng-select>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 6">
          <div class="documentoGeneradoContainer">
            <div class="documentoGenerado" #documentoImprimir>
              <header>
                <b>Parroquía</b> {{ parroquiaNombre }} <b>de</b>
                {{ ubicacionParroquia }}
              </header>
              <p>
                <b><i>El infrafirmado D.</i></b> {{ infrafirmado }} <b><i>de</i></b>
                {{ parroquiaNombre }}
              </p>
              <div class="columnas">
                <div class="col_izq">
                  <b>CERTIFICO:</b>

                  <div class="feligres">
                    <div class="campo">
                      <p>Nº {{ numeroPartida }}</p>
                    </div>
                    <div class="campo">
                      <p>Año {{ anyo }}</p>
                    </div>
                    <div class="campo">
                      <p>Folio {{ folioPartida }}</p>
                    </div>
                    <div class="campo">
                      <p>Libro {{ libroPartida }}</p>
                    </div>
                  </div>
                  <div class="notas">
                    <p>Notas marginales:</p>
                    <p *ngFor="let nota of notasArray">{{ nota }}</p>
                  </div>
                </div>
                <div class="col_der">
                  <b
                    ><i>Que en el Libro de Matrimonios, tomo </i
                    >{{ libroPartida }}<i> al folio </i>{{ folioPartida
                    }}<i> num. </i>{{ numeroPartida
                    }}<i>
                      de este Archivo Parroquial se encuentra una partida que
                      dice así:</i
                    ></b
                  >
                  <p class="texto">
                    El dia {{ fechaCelebracionDia }} de
                    {{ fechaCelebracion | date: "MMMM" }} de
                     {{ fechaCelebracionAnio }}<!--, a las
                    {{ horaCelebracion }} horas-->, en la iglesia de 
                    {{ parroquiaNombre }} de {{ ubicacionParroquia }}, diócesis
                    de Valencia, provincia de Valencia, y ante el Rvdo. D.
                    {{ parrocoSacramento }}, con jurisdicción {{jurisdiccion}} para el caso, contrajeron
                    matrimonio canónico D. {{ nombreMarido }}
                    {{ apellidosMarido }}, de estado {{ estadoCivil.toLowerCase() }} hijo de
                    D. {{ nombrePadreMarido }} y de
                    Dª {{ nombreMadreMarido }},
                    nacido el dia, {{ nacimientoMarido | date:"d" }} de
                    {{ nacimientoMarido | date:"MMMM" }} de
                    {{ nacimientoMarido | date:"yyyy"}} y bautizado en
                    {{ bautizoMarido }} de {{ bautizoLugarMarido }} (tomo
                    {{ libroMarido }} folio {{ folioMarido }}) y Dª
                    {{ nombreEsposa }} {{ apellidosEsposa }} de estado
                    {{ estadoCivilEsposa.toLowerCase() }}, hija de D. {{ nombrePadreEsposa }} 
                    y de Dª {{ nombreMadreEsposa }}, nacida el dia
                    {{ fechaNacimientoEsposa.split("-")[2] }} de
                    {{ fechaNacimientoEsposa.split("-")[1] }} de
                    {{ fechaNacimientoEsposa.split("-")[0] }} y bautizada en
                    {{ bautizoEsposa }} de {{ bautizoLugarEsposa }} (tomo
                    {{ libroEsposa }} folio {{ folioEsposa }}).
                  </p>
                  <p>
                    Fueron testigos: D. {{ nombrePadrino }}
                    {{ apellidosPadrino }}, vecino de {{ vecinoPadrino }} y Dª
                    {{ nombreMadrina }} {{ apellidosMadrina }}, vecina de
                    {{ vecinoMadrina }}
                  </p>

                  <p class="texto">De que como cura certifico</p>
                </div>
              </div>
              <br />

              <p class="arreglo">
                <b
                  ><i
                    >Así resulta del libro indicado a que refiero. Y para que
                    conste, {{parroco}} firma y sella la presente en Valencia a</i
                  >
                  {{ hoy.dia }}<i> de </i>{{ hoy.mesNombre() }}<i> de </i
                  >{{ hoy.year }}</b
                >
              </p>
              <div class="firma">
                <img [src]="imgSelect" alt="" />
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="buttonsContainer">
      <div>
        <button type="button" (click)="atras()">Atrás</button>
        <button
          type="button"
          (click)="siguiente()"
          *ngIf="pasoCreacionPartida != 6"
        >
          Siguiente
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 6 && !editar"
          [class.disabled]="
            !paso0Valido ||
            !paso1Valido ||
            !paso2Valido ||
            !paso3Valido ||
            !paso4Valido
          "
          (click)="sendData('nuevo')"
        >
          Crear partida
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 6 && editar"
          [class.disabled]="
            !paso0Valido ||
            !paso1Valido ||
            !paso2Valido ||
            !paso3Valido ||
            !paso4Valido
          "
          (click)="sendData('nuevo')"
        >
          Editar partida
        </button>
      </div>
    </div>
  </div>
</div>
<!-- FIN COPIAR-->
