import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.css']
})
export class BusquedaComponent implements OnInit {
  @Input() tipoBusqueda = 'fiel'; 
  @Input() nombreFiel = 'fiel'; 
  @Output()
  buscar = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }
reloadData(){
 this.buscar.emit(true)
}
}
