<div class="main" (click)="clickfuera(); clickfuera2()">
  <div>
    <span (click)="backClicked()">
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 32 32"
        style="enable-background: new 0 0 32 32"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #5d366b;
          }

          .st1 {
            fill: none;
            stroke: #ffffff;
            stroke-width: 2;
          }
        </style>
        <g id="a"></g>
        <g id="b">
          <g id="c">
            <g id="d" transform="translate(-364 -52)">
              <circle id="e" class="st0" cx="380" cy="68" r="16" />
              <path id="f" class="st1" d="M382.2,61.5l-6.5,6.5l6.5,6.5" />
            </g>
          </g>
        </g>
      </svg>
      <label for="">Volver</label>
    </span>
    <div class="panelizquierda">
      <div class="fondoimg">
        <svg
          class="imagen"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 78.74 103.03"
        >
          <defs>
            <style>
              .cls-1 {
                fill: none;
                stroke: #311c38;
                stroke-miterlimit: 10;
              }

              .cls-2 {
                font-size: 27.13px;
                font-family: IBMPlexSans-Medium, IBM Plex Sans;
                font-weight: 500;
              }
            </style>
          </defs>
          <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
              <path
                class="cls-1"
                d="M78.24,16.57V98.48a4,4,0,0,1-4.06,4H4.56a4,4,0,0,1-4.06-4V4.56A4.06,4.06,0,0,1,4.56.5h59.6Z"
              />
              <path
                class="cls-1"
                d="M64.16.5V13a3.54,3.54,0,0,0,3.55,3.54H78.24"
              />
              <line class="cls-1" x1="13.95" y1="26.68" x2="65.32" y2="26.68" />
              <line class="cls-1" x1="13.95" y1="35.64" x2="65.32" y2="35.64" />
              <line class="cls-1" x1="13.95" y1="44.61" x2="65.32" y2="44.61" />
              <line class="cls-1" x1="13.95" y1="53.57" x2="65.32" y2="53.57" />
              <text class="cls-2" transform="translate(13.95 84.85)">PDF</text>
            </g>
          </g>
        </svg>
      </div>
      <div class="firmador">
        <div class="campoEspecial">
          <label>Firma: </label>
          <ng-select
            class="secundaryTheme fix_height"
            [placeholder]="'Selecciona una firma'"
            [items]="firmas"
            [(ngModel)]="firmaSelect"
            [clearable]="false"
            bindLabel="nombre"
            (change)="seleccionarFirma()"
            bindValue="id"
          ></ng-select>
        </div>
      </div>
      <div class="botones">
        <button
          (click)="$event.stopPropagation(); clickdentro2()"
          type="submit"
          class="buttonsinimg"
        >
          Descargar
        </button>
        <div class="containerHistorico">
          <div
            class="floating_submenu"
            *ngIf="apareceDescargar"
            (click)="clickfuera2()"
          >
            <button
              *ngFor="let documento of documentos"
              type="submit"
              class="buttonoculto"
              (click)="downloadAsPDF(documento.id)"
            >
              {{ documento.tipo }}
            </button>
          </div>
        </div>
        <button (click)="editarPartida()" type="submit" class="buttonsinimg">
          Editar
        </button>
        <svg
          class="botontres"
          (click)="$event.stopPropagation(); clickdentro()"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 55.37 55.37"
        >
          <defs>
            <style>
              .cls-2 {
                stroke: #ffffff;
              }
            </style>
          </defs>
          <g id="Capa_2" data-name="Capa 2">
            <g data-name="Capa 1">
              <path
                id="Trazado_602"
                data-name="Trazado 602"
                class="boton3"
                d="M9.23,0H46.14a9.23,9.23,0,0,1,9.23,9.23V46.14a9.23,9.23,0,0,1-9.23,9.23H9.23A9.23,9.23,0,0,1,0,46.14V9.23A9.23,9.23,0,0,1,9.23,0Z"
              />
              <g id="Grupo_1485" data-name="Grupo 1485">
                <circle
                  id="Elipse_558"
                  data-name="Elipse 558"
                  class="cls-2"
                  cx="17.3"
                  cy="27.69"
                  r="3.46"
                />
                <circle
                  id="Elipse_559"
                  data-name="Elipse 559"
                  class="cls-2"
                  cx="27.69"
                  cy="27.69"
                  r="3.46"
                />
                <circle
                  id="Elipse_560"
                  data-name="Elipse 560"
                  class="cls-2"
                  cx="38.07"
                  cy="27.69"
                  r="3.46"
                />
              </g>
            </g>
          </g>
        </svg>
        <div class="containerHistorico">
          <div class="floating_submenu" *ngIf="aparece" (click)="clickfuera()">
            <button (click)="cambiar()" type="submit" class="buttonoculto">
              Histórico de modificaciones
            </button>
            <button (click)="eliminarDoc()" type="submit" class="buttonoculto">
              Eliminar documento
            </button>
          </div>
        </div>
      </div>
      <div class="descripcion">
        <label for="" class="titulo"
          >Partida {{ sacramentoNombre }} de {{ nombre }}</label
        >
      </div>
      <div *ngIf="false" class="botonessacramentos">
        <label for="" class="otras">Otras partidas del laico:</label><br />
        <button
          [routerLink]="['/explorar', 'Bautizo', id]"
          (click)="seleccionarBtn(1)"
          type="button"
          [class.normal]="sacramento == -1 || sacramento != 1"
          [class.pulsado]="sacramento == 1 || tipo == 'Bautizo'"
        >
          Bautizo
        </button>
        <button
          *ngIf="false"
          [routerLink]="['/explorar', 'Comunión', id]"
          (click)="seleccionarBtn(2)"
          type="button"
          [class.normal]="sacramento == -1 || sacramento != 2"
          [class.pulsado]="sacramento == 2 || tipo == 'Comunión'"
        >
          Comunión
        </button>
        <button
          [routerLink]="['/explorar', 'Confirmación', id]"
          (click)="seleccionarBtn(3)"
          type="button"
          [class.normal]="sacramento == -1 || sacramento != 3"
          [class.pulsado]="sacramento == 3 || tipo == 'Confirmación'"
        >
          Confirmación
        </button>
        <button
          [routerLink]="['/explorar', 'Matrimonio', id]"
          (click)="seleccionarBtn(4)"
          type="button"
          [class.normal]="sacramento == -1 || sacramento != 4"
          [class.pulsado]="sacramento == 4 || tipo == 'Matrimonio'"
        >
          Matrimonio
        </button>
        <button
          [routerLink]="['/explorar', 'Defunción', id]"
          (click)="seleccionarBtn(5)"
          type="button"
          [class.normal]="sacramento == -1 || sacramento != 5"
          [class.pulsado]="sacramento == 5 || tipo == 'Defunción'"
        >
          Defunción
        </button>
        <button
          *ngIf="false"
          [routerLink]="['/explorar', 'Nulidad Matrimonial', id]"
          (click)="seleccionarBtn(6)"
          type="button"
          [class.normal]="sacramento == -1 || sacramento != 6"
          [class.pulsado]="sacramento == 6 || tipo == 'Nulidad Matrimonial'"
        >
          Nulidad Matrimonial
        </button>
      </div>
    </div>
  </div>
  <div class="panelderecha">
    <div class="tipoDocumentos">
      <span
        *ngFor="let documento of documentos"
        [class.activo]="documento.id == mostrar"
        class="TipoDoc"
        (click)="seleccionado(documento.id)"
        >{{ documento.tipo }}</span
      >
    </div>
    <div
      *ngIf="documento != null"
      class="documentoGenerado"
      [class.oculto]="mostrar != 1"
    >
      <iframe [src]="documento | safe"></iframe>
    </div>
    <ng-container *ngIf="idSacramento == 1">
      <div
        class="documentoGenerado"
        #documentoImprimir
        [class.oculto]="mostrar != 3"
      >
        <header>
          <b>Parroquía</b> {{ parroquiaNombre }} <b>de</b>
          {{ ubicacionParroquia }}
        </header>
        <p>
          <b><i>El infrafirmado D.</i></b> {{ infrafirmado }}<br>
          Cura párroco
        </p>
        <div class="columnas">
          <div class="col_izq">
            <b>CERTIFICO:</b>

            <div class="feligres">
              <div class="campo">
                <span>{{ primerApellido }}</span>
                <label><i>primer apellido</i></label>
              </div>
              <div class="campo">
                <span>{{ segundoApellido }}</span>
                <label><i>segundo apellido</i></label>
              </div>
              <div class="campo">
                <span>{{ nombreBautizado }}</span>
                <label><i>nombre</i></label>
              </div>
            </div>
            <div class="notas">
              <p>Notas marginales:</p>
              <p>{{ notas }}</p>
            </div>
          </div>
          <div class="col_der">
            <b
              ><i>Que en el Libro de Bautismos, tomo </i>{{ libro
              }}<i> al folio </i>{{ folio }}<i> num. </i>{{ numeroPartida
              }}<i>
                de este Archivo Parroquial se encuentra una partida que dice
                así:</i
              ></b
            >
            <p class="texto">
              <i>En la Iglesia Parroquial de </i>{{ parroquiaNombre }}
              <i>de</i> {{ ubicacionParroquia
              }}<i>, diócesis de Valencia, provincia de Valencia, el día </i
              >{{ fechaCelebracion | date: "d" }} <i>de</i>
              {{ fechaCelebracion | date: "MMMM" }} <i>de</i>
              {{ fechaCelebracion | date: "yyyy" }}<i>; Don</i> {{ parrocoSacramento }}
              <i>bautizó solemnemente  a un(a)</i> {{ sexoBautizado=="Niño" ? "niño":"niña" }}
              <i>y le puso por nombre</i> {{ nombreBautizado
              }}<i>, que nació el día</i> {{ dia }} <i>de</i>
              {{ fechaNac | date: "MMMM" }} <i>del año</i>
              {{ anio }}<i> a las </i
              >{{ fechaNac | date: "HH:mm" }}<i> horas, en </i
              >{{ lugarNacimientoBautizado }}<i>, calle {{ calleNac }}, num </i
              >{{ numeroNac }}<i>, hijo(a) de </i>{{ nombrePadre }}
              {{ apellidosPadre }}<i> natural de </i>{{ lugarNacimientoPadre
              }}<i>, y de </i>{{ nombreMadre }} {{ apellidosMadre
              }}<i> natural de </i>{{ lugarNacimientoMadre
              }}{{casadosFeligreses()}}{{textoDinamico}}
            </p>
            <span><i>Abuelos paternos: </i></span>
            <div class="abuelos">
              <span>D. {{ abueloPaterno }}</span>
              <br />
              <span>Dª. {{ abuelaPaterna }}</span>
            </div>

            <span><i>Abuelos maternos: </i></span>
            <div class="abuelos">
              <span>D. {{ abueloMaterno }}</span>
              <br />
              <span>Dª. {{ abuelaMaterna }}</span>
            </div>

            <span><i>Padrinos: </i></span>
            <div class="abuelos">
              <span>D. {{ nombrePadrino }} {{ apellidosPadrino }}</span>
              <br />
              <span>Dª. {{ nombreMadrina }} {{ apellidosMadrina }}</span>
            </div>
            <p><i>De que, como Cura Certifico.</i></p>
            <p style="text-align: left">Firmado: </p>
            <b><i>Y para que conste, declaramos auténtica la firma que la autoriza. {{firmante}} firma y sella la presente en Valencia a</i>
              {{ hoy.dia }}<i> de </i>{{ hoy.mesNombre() }}<i> de </i
              >{{ hoy.year }}</b>
            <p style="text-align: left">{{parroco}} </p>
            <div class="firma">
              <img [src]="imgSelect" alt="" />
            </div>
          </div>
        </div>
        <p>
        </p>
      </div>
      <div
        class="documentoGenerado cedula"
        [class.oculto]="mostrar != 2"
        #documentoCedula
      >
        <header>
          <p
            style="
              text-align: center;
              width: 100% !important;
              font-size: 20pt !important;
              margin-bottom: 0;
            "
          >
            CÉDULA DE BAUTISMO
          </p>
        </header>
        <p>Parroquia de {{ parroquiaNombre }} de {{ ubicacionParroquia }}</p>
        <br /><br />
        <p>
          {{ nombreBautizado }} {{ primerApellido }}
          {{ segundoApellido }} que nació el día
          {{ fechaNac | date: "d" }} de {{ fechaNac | date: "MMMM" }} del año
          {{ fechaNac | date: "yyyy" }} fue bautizado el día
          {{ fechaCelebracion | date: "d" }} de
          {{ fechaCelebracion | date: "MMMM" }} del año
          {{ fechaCelebracion | date: "yyyy" }} en esta Parroquia, hijo de
          {{ nombrePadre }} {{ apellidosPadre }} y de {{ nombreMadre }}
          {{ apellidosMadre }} según consta en el libro de bautismos de este
          archivo del año {{ anyo }}, libro {{ libro }}, folio {{ folio }}.
        </p>
        <br />
        <p>
          <b
            >Y para que conste, firmo y sello la presente en Valencia a
            {{ hoy.dia }} de {{ hoy.mesNombre() }} de {{ hoy.year }}</b
          >
        </p>
        <div class="firma">
          <img [src]="imgSelect" alt="" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="idSacramento == 3">
      <div
        class="documentoGenerado"
        [class.oculto]="mostrar != 2"
        #documentoCedula
      >
        <header>
          <p
            style="
              text-align: center;
              width: 100% !important;
              font-size: 20pt !important;
              margin-bottom: 0;
            "
          >
            CÉDULA DE CONFIRMACIÓN
          </p>
        </header>
        <p>Parroquía {{ parroquiaNombre }} de {{ ubicacionParroquia }}</p>
        <p>El infrafirmado D. {{ infrafirmado }}</p>
        <p [ngStyle]="{ 'font-size': '32px', 'font-weight': 'bold' }">
          CERTIFICO:
        </p>
        <p class="texto">
          Que {{ nombreLaico }} {{ apellidosLaico }},
          bautizado en la parroquia {{ parroquiaBautizado }} de
          {{ lugarParroquia }} de {{lugarNacimientoBautizado}}
        </p>
        <p class="texto">
          Ha recibido el <b>SACRAMENTO DE LA CONFIRMACIÓN</b> en esta parroquía
          el dia {{fechaCelebracion | date:"d"}} de {{fechaCelebracion | date:"MMMM"}} de {{fechaCelebracion | date:"yyyy"}} de manos de
          {{ parrocoSacramento }} según consta en el Libro de confirmaciones de este
          Archivo.
        </p>
        
        <p>Fueron padres: <i *ngIf="nombrePadre">{{ nombrePadre }}</i><i *ngIf="!nombrePadre">_________</i> y <i *ngIf="nombreMadre">{{ nombreMadre }}</i> <i *ngIf="!nombreMadre">_________</i></p>
        <p>Fueron padrinos: <i *ngIf="nombrePadrino">{{ nombrePadrino }}</i><i *ngIf="!nombrePadrino">_________</i> y <i *ngIf="nombreMadrina">{{ nombreMadrina }}</i> <i *ngIf="!nombreMadrina">_________</i></p>
        <p>Tomo {{tomoConfirmaicon}} Folio {{folioConfirmacion}}</p>
        
        <br />
        <p class="texto">
          Y para que conste, {{firmante }}firma y sella el presente certificado en:
        </p>
        <p class="texto">
          {{ dayName }} , {{ hoy.dia }} de {{ hoy.mesNombre() }},
          {{ hoy.year }}
        </p>
        <div class="firma">
          <img [src]="imgSelect" alt="" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="idSacramento == 5">
      <div
        class="documentoGenerado"
        [class.oculto]="mostrar != 3"
        #documentoImprimir
      >
        <header>
          <b>Parroquía</b> {{ parroquiaNombre }} <b>de</b>
          {{ ubicacionParroquia }}
        </header>
        <p>
          <b><i>El infrafirmado D.</i></b> {{ infrafirmado }} <b><i>de</i></b>
          {{ parroquiaNombre }}
        </p>
        <div class="columnas">
          <div class="col_izq">
            <b>CERTIFICO:</b>

            <div class="feligres">
              <div class="campo">
                <p>Nº {{ numeroPartida }}</p>
              </div>
              <div class="campo">
                <p>Año {{ anyo }}</p>
              </div>
              <div class="campo">
                <p>Folio {{ folio }}</p>
              </div>
              <div class="campo">
                <p>Libro {{ libro }}</p>
              </div>
            </div>
            <div class="notas">
              <p>Notas marginales:</p>
              <p>{{ notas }}</p>
            </div>
          </div>
          <div class="col_der">
            <b
              ><i>Que en el Libro de Defunciones, tomo </i>{{ libro
              }}<i> al folio </i>{{ folio }}<i> num. </i>{{ numeroPartida
              }}<i>
                de este Archivo Parroquial se encuentra una partida que dice
                así:</i
              ></b
            >
            <p class="texto">
              "El dia {{ fechaDefuncion | date: "d" }} de
              {{ fechaDefuncion | date: "MMMM" }} de
              {{ fechaDefuncion | date: "YYYY" }} a las {{ horaDefuncion }},
              según certificación facultativa que se exhibió, falleció en la
              parroquia {{ parroquiaNombre }}, calle de
              {{ ubicacionParroquia }} 
            </p>
            <p class="texto">
              D./Dª {{ nombreDifunto }} hijo/hija de D. {{ nombrePadre }}
              {{ apellidosPadre }} y Dª {{ nombreMadre }} {{ apellidosMadre }},
              de estado {{ estado }}, de edad de {{ edad }}, con direccion {{ direccionDifunto }} {{ sacramentosRecibidos == null || " " ? "":", recibio los Sacramentos de "+sacramentosRecibidos+" que le administro D. "+parrocoSacramento}}

            </p>
            <p class="texto">
              Fué enterrado en el Cementerio de {{ enterrado }}
            </p>

            <p class="texto">De que como cura certifico, firmado originalmente por {{curaOriginal}}"</p>
          </div>
        </div>
        <br />

        <p>
          <b
            ><i
              >Así resulta del libro indicado a que refiero. Y para que conste,
              {{firmante}} firma y sella la presente en Valencia a</i
            >
            {{ hoy.dia }}<i> de </i>{{ hoy.mesNombre() }}<i> de </i
            >{{ hoy.year }}</b
          >
        </p>
        <div class="firma">
          <img [src]="imgSelect" alt="" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="idSacramento == 4 && matrimonioMixto">
      {{ mostrar }}
      <div class="documentoGenerado" #documentoImprimir>
        <p [ngStyle]="{ 'text-align': 'center', 'font-size': '32px' }">
          <b
            >SOLCITUD DE AUTORIZACIÓN DE LICENCIA DE MATRIMONIO DE MIXTA
            RELIGIÓN</b
          >
        </p>
        <br />
        <p class="texto">Ilmo. Sr. Vicario General</p>
        <p class="texto">
          D./Dña. {{ nombreLaico }} {{ apellidoLaico }} natural de
          {{ vecinoLaico }} y feligrés(a) de {{ feligresParroquia }} de hijo(a)
          de {{ nombrePadre }} {{ apellidosPadre }} y de {{ nombreMadre }}
          {{ apellidosMadre }} de religión católica, con el debido respeto
          EXPONE:
        </p>
        <p class="texto">
          Que tiene convenido matrimonio canónico con D./Dña.
          {{ nombreOtraReligion }} {{ apellidosOtraReligion }} natural de
          {{ vecinoOtraReligion }} y perteneciente a {{ religion }} por lo que,
        </p>
        <p class="texto">
          Solcitia se le conceda la debida LICENCIA para dicho matrimonio
        </p>
        <p class="texto">Es gracia que espera alcanzar de V. Ilma:</p>
        <br />
        <p>
          Valencia, a {{ hoy.dia }} de {{ hoy.mesNombre() }} de
          {{ hoy.year }}
        </p>
        <div class="firma">
          <img [src]="imgSelect" alt="" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="idSacramento == 4 && !matrimonioMixto">
      <div
        class="documentoGenerado"
        [class.oculto]="mostrar != 3"
        #documentoImprimir
      >
        <header>
          <b>Parroquía</b> {{ parroquiaNombre }} <b>de</b>
          {{ ubicacionParroquia }}
        </header>
        <p>
          <b><i>El infrafirmado D.</i></b> {{ infrafirmado }} <b><i>de</i></b>
          {{ parroquiaNombre }}
        </p>
        <div class="columnas">
          <div class="col_izq">
            <b>CERTIFICO:</b>

            <div class="feligres">
              <div class="campo">
                <p>Nº {{ numeroPartida }}</p>
              </div>
              <div class="campo">
                <p>Año {{ anyo }}</p>
              </div>
              <div class="campo">
                <p>Folio {{ folio }}</p>
              </div>
              <div class="campo">
                <p>Libro {{ libro }}</p>
              </div>
            </div>
            <div class="notas">
              <p>Notas marginales:</p>
              <p>{{ notas }}</p>
            </div>
          </div>
          <div class="col_der">
            <b
              ><i>Que en el Libro de Matrimonios, tomo </i>{{ libro
              }}<i> al folio </i>{{ folio }}<i> num. </i>{{ numeroPartida
              }}<i>
                de este Archivo Parroquial se encuentra una partida que dice
                así:</i
              ></b
            >
            <p class="texto">
              "El dia {{ dia }} de
              {{ fechaCelebracion | date: "MMMM" }} de
              {{ anio }},<!-- a las {{ hora }} horas,--> en
              la iglesia de {{ parroquiaNombre }} de {{ ubicacionParroquia }},
              diócesis de Valencia, provincia de Valencia, y ante el Rvdo. D.
              {{ parrocoSacramento }}, con jurisdicción {{ jurisdiccion }} para el caso, contrajeron
              matrimonio canónico D. {{ nombreMarido }} {{ apellidosMarido }},
              de estado {{ estadoCivil.toLowerCase() }} hijo de D. {{ nombrePadreMarido }}
              y de Dª {{ nombreMadreMarido }}, nacido el dia
              {{diaNac}} de
              {{nacimientoMarido | date:"MMMM"}} de
              {{anioNac}} y bautizado en
              {{ bautizoMarido }} de {{ bautizoLugarMarido }} (tomo
              {{ libroMarido }} folio {{ folioMarido }}) y Dª
              {{ nombreEsposa }} {{ apellidosEsposa }} de estado
              {{ estadoCivilEsposa.toLowerCase() }}, hija de D. {{ nombrePadreEsposa }} y de Dª {{ nombreMadreEsposa }}, nacida el dia
              {{diaNacEsposa}} de
              {{fechaNacimientoEsposa | date:"MMMM"}} de
              {{anioNacEsposa}} y bautizada en
              {{ bautizoEsposa }} de {{ bautizoLugarEsposa }} (tomo
              {{ libroEsposa }} folio {{ folioEsposa }}).
            </p>
            <p>
              Fueron testigos: D. {{ nombrePadrino }} {{ apellidosPadrino }},
              vecino de {{ vecinoPadrino }} y Dª {{ nombreMadrina }}
              {{ apellidosMadrina }}, vecina de {{ vecinoMadrina }}
            </p>

            <p class="texto">De que como cura certifico"</p>
          </div>
        </div>
        <br />

        <p>
          <b
            ><i
              >Así resulta del libro indicado a que refiero. Y para que
              conste, {{firmante}} firma y sella la presente en Valencia a</i
            >
            {{ hoy.dia }}<i> de </i>{{ hoy.mesNombre() }}<i> de </i
            >{{ hoy.year }}</b
          >
        </p>
        <div class="firma">
          <img [src]="imgSelect" alt="" />
        </div>
      </div>
      <div
        class="documentoGenerado nota"
        [class.oculto]="mostrar != 4"
        #documentoNota
      >
        <u>DIÓCESIS DE VALENCIA</u>
        <p><b>Nota marginal de matrimonio celebrado, para la Parroquia</b></p>
        <b>Titular {{ firmante }} de {{ parroquiaNombre }} </b>
        <b>Provincia {{ ubicacionParroquia }} Diócesis Valencia</b>

        <div class="columnas">
          <div class="col_izq">
            <div class="feligres">
              <div class="campo">
                <span>Partida:</span>
              </div>
              <div class="campo">
                <p>Año {{ anyo }}</p>
              </div>
              <div class="campo">
                <p>Libro {{ libro }}</p>
              </div>
              <div class="campo">
                <p>Folio {{ folio }}</p>
              </div>
              <div class="campo">
                <p>Nº {{ numeroPartida }}</p>
              </div>
            </div>
            <div class="notas">
              <p>Notas marginales:</p>
              <p>{{ notas }}</p>
            </div>
          </div>
          <div class="col_der">
            <p class="texto">
              En el día de hoy han contraido matrimonio en esta iglesia
              parroquial, D. {{ nombreMarido }} {{ apellidosMarido }} hijo de D.
              {{ nombrePadreMarido }} {{ apellidosPadreMarido }} y de Dª
              {{ nombreMadreMarido }} {{ apellidosMadreMarido }} bautizado en
              ésa, el día _______ de _________ de
            </p>

            <p>con</p>

            <p>{{ nombreEsposa }} {{ apellidosEsposa }}</p>

            <p>
              Lo comunico a V. a los efectos de la nota marginal prescrita en
              los can. 1.121 y 1.122 y C.S.V. Art. 589, rogándole me comunique
              su cumplimiento.
            </p>
            <p>Dios guarde a V. muchos años.</p>
            <p>Iglesia Parroquial de {{ parroquiaNombre }}</p>
            <br />
            <p>a {{ hoy.dia }} de {{ hoy.mesNombre() }} de {{ hoy.year }}</p>
            <p>El párroco</p>
            <div class="firma">
              <img [src]="imgSelect" alt="" />
            </div>
            <br />
            <p>
              <b>Cumplimiento:</b> En el día de hoy ha quedado cumplimentada la
              comunicación que antecede.
            </p>
            <p>Iglesia Parroquial de _______________________</p>
            <br />
            <p>a _______ de _____________ de _________</p>
            <p>El párroco</p>
          </div>
        </div>
        <br />
      </div>
      <div
        class="documentoGenerado amonestacion"
        [class.oculto]="mostrar != 5"
        #documentoAmonestacion
      >
        <div class="columnas separacion">
          <div class="col_izq">
            <p>Publicadas estas amonestaiones:</p>
            <p>1.ª el día ____ de ________ de _______</p>
            <p>2.ª el día ____ de ________ de _______</p>
            <p>
              a las _____ horas de la ____________ y transcurridos tres días
              después de la última
            </p>
            <p>_________________________________</p>
            <p>_________________________________</p>
            <p>_________________________________</p>
            <p>_________________________________</p>
            <p>_________________________________</p>
            <p>_________________________________</p>
            <p>De que certifico: Iglesdia parroquial de</p>
            <p>_________________________________</p>
            <p>a ____ de _________ de ________</p>
            <p>Firma y Sello</p>
          </div>
          <div class="col_der">
            <p class="texto">
              <b
                ><i
                  >Sirvase V.R. publicar en esa Iglesia y según prescribe el
                  Derecho, las dos canónicas moniciones para el matrimonio que
                  desean contraer</i
                ></b
              >
            </p>

            <p><b>D. </b>{{ nombreMarido }} {{ apellidosMarido }}</p>
            <p><b>de estado </b>{{ estadoCivil }}</p>
            <p>
              <b>hijo de </b>{{ nombrePadreMarido }} {{ apellidosPadreMarido }}
            </p>
            <p>
              <b>y de </b>{{ nombreMadreMarido }} {{ apellidosMadreMarido }}
            </p>
            <p><b>natural de </b>{{ bautizoLugarMarido }}</p>
            <p><b>feligrés de </b>{{ bautizoMarido }}</p>
            <p style="text-align: center"><b>CON</b></p>

            <p><b>Dña. </b>{{ nombreEsposa }} {{ apellidosEsposa }}</p>
            <p><b>de estado </b>{{ estadoCivilEsposa }}</p>
            <p>
              <b>hijo de </b>{{ nombrePadreEsposa }} {{ apellidosPadreEsposa }}
            </p>
            <p>
              <b>y de </b>{{ nombreMadreEsposa }} {{ apellidosMadreEsposa }}
            </p>
            <p><b>natural de </b>{{ bautizoLugarEsposa }}</p>
            <p><b>feligresa de </b>{{ bautizoEsposa }}</p>
            <p>
              <b
                ><i
                  >y transcurrido el debido tiempo después de la última,
                  certificas las publicatas y resultas.</i
                ></b
              >
            </p>
            <p>
              <b><i>Dios guarde a V.R. muchos años.</i></b>
            </p>
            <p>
              Iglesia parorquial {{ parroquiaNombre }}, a {{ hoy.dia }} de
              {{ hoy.mesNombre() }} de {{ hoy.year }}
            </p>
            <p>Firma y sello</p>
            <div class="firma">
              <img [src]="imgSelect" alt="" />
            </div>
          </div>
        </div>
        <br />

        <p class="texto">
          <b>Rvdo. Sr. Cura de la Parroquia de ____________________</b>
        </p>
      </div>
    </ng-container>
    <!-- <p *ngIf="documento == null">No existe documento previo para esta partida</p> -->
  </div>
</div>

<app-navbar-documentos-modificaciones
  (eventoHijo)="onMensajeHijo($event)"
  [idDocumento]="id"
  *ngIf="cambio"
>
</app-navbar-documentos-modificaciones>
