import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firmaFormPopup',
  templateUrl: './firmaFormPopup.component.html',
  styleUrls: ['./firmaFormPopup.component.css']
})
export class FirmaFormPopupComponent implements OnInit {
  file=["data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="];
  defaultData=["data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="];
  defaultText="";
  disabledFirma=true;
  nombreFirma="";
  urlImages=environment.urlImg
  action="Crear";
  parroquiaSelect;
  defaultParroquiaSelect;
  parroquias=[];

  constructor(public dialogRef: MatDialogRef<FirmaFormPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  closePopup(returnValue){
    let p={returnValue:returnValue};
    this.dialogRef.close(p);
  }

  ngOnInit() {
    if(this.modalData!=null&&this.modalData.parroquias!=null){
      this.parroquias=this.modalData.parroquias;
    }
    if(this.modalData!=null&&this.modalData.firma!=null){
      this.nombreFirma=this.modalData.firma.nombre;
      this.defaultText=this.nombreFirma;
      this.file[0]=this.urlImages+this.modalData.firma.url;
      this.defaultData[0]=this.urlImages+this.modalData.firma.url;
      this.action="Editar";
      this.parroquiaSelect=this.modalData.firma.parroquia;
      this.defaultParroquiaSelect=this.modalData.firma.parroquia;
      this.checkIsToSend();
    }
  }
  sendFirma(){
    this.closePopup({url:this.file[0],nombre:this.nombreFirma,parroquia:this.parroquiaSelect});
  }
  checkIsToSend(){
    if(this.action=="Editar"){
      if((this.nombreFirma!=""&&this.nombreFirma!=this.defaultText)||this.file[0]!=this.defaultData[0]||(this.parroquiaSelect!=null&&this.parroquiaSelect.id!=this.defaultParroquiaSelect.id)){
        this.disabledFirma=false;
      }else{
        this.disabledFirma=true;
      }
    }else{
      if(this.nombreFirma!=""&&this.file[0]!=this.defaultData[0]&&this.parroquiaSelect!=null){
        this.disabledFirma=false;
      }else{
        this.disabledFirma=true;
      }
    }
  }

}
