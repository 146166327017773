<div class="crearPartidaGeneralContainer">
  <ng-container *ngIf="pasoCreacionPartida == 0">
    <div class="paso0TituloContainer">
      <p>Crear una nueva partida</p>
    </div>
    <div class="paso0Line">
      <span class="active"></span>
      <div></div>
      <span></span>
    </div>
    <div class="paso0NombrePaso">
      <p [class.active]="pasoCreacionPartida == 0">Escoger sacramento</p>
      <p>Tipo de documento</p>
    </div>
    <div class="tiposPartida">
      <div>
        <span (click)="setTipoPartida('Bautizo',1)" [class.selected]="sacramento.nombre == 'Bautizo'">Bautizo</span>
        <!-- <span (click)="setTipoPartida('Comunión',2)" [class.selected]="sacramento.nombre == 'Comunión'">Comunión</span> -->
        <span (click)="setTipoPartida('Confirmación',3)"
          [class.selected]="sacramento.nombre == 'Confirmación'">Confirmación</span>
      </div>
      <div>
        <span (click)="setTipoPartida('Matrimonio',4)"
          [class.selected]="sacramento.nombre == 'Matrimonio'">Matrimonio</span>
        <span (click)="setTipoPartida('Defunción',5)"
          [class.selected]="sacramento.nombre == 'Defunción'">Defunción</span>
        <!-- <span (click)="setTipoPartida('Nulidad Matrimonial',6)"
          [class.selected]="sacramento.nombre == 'Nulidad Matrimonial'">Nulidad Matrimonial</span> -->
      </div>

    </div>
    <div class="buttonsContainer">
      <div>
        <button type="button" (click)="cancel()">Cancelar</button>
        <button type="button" [class.disabled]="sacramento.id == -1" (click)="siguiente()" >Siguiente</button>
      </div>
      <button type="button"  (click)="navigateTo()">Ver borradores</button>
    </div>
  </ng-container>
  <ng-container *ngIf="pasoCreacionPartida == 1">
    <div class="paso0TituloContainer">
      <p>Crear una nueva partida</p>
    </div>
    <div class="paso0Line">
      <span class="activePrevio"></span>
      <div></div>
      <span class="active"></span>
    </div>
    <div class="paso0NombrePaso">
      <p>Escoger sacramento</p>
      <p [class.active]="pasoCreacionPartida == 1">Tipo de documento</p>
    </div>
    <div class="documentoPrevio" *ngIf="sacramento.id == 4" [ngStyle]="{'margin-bottom': '2em'}">
      <p>Selecciona un tipo de matrimonio</p>
      <div class="opcionesDocumento">
        <div>
          <input type="radio" name="matrimonio" (click)="setTipoMatrimonio('catolico')">
          <label for="">Católico</label>
        </div>
        <div>
          <input type="radio" name="matrimonio" (click)="setTipoMatrimonio('mixto')">
          <label for="">Mixto</label>
        </div>
      </div>
    </div>
    <div class="documentoPrevio">
      <p *ngIf="sacramento.id != 2 && sacramento.id != 6">¿Existe un documento en físico previo?</p>
      <div class="opcionesDocumento" *ngIf="sacramento.id != 2 && sacramento.id != 6">
        <div>
          <input type="radio" id="siActivo" name="existe" [(ngModel)]="botonActivoPrevio" value="si" (click)="botonActivo = true">
          <label for="siActivo">Sí</label>
        </div>
        <div>
          <input type="radio" id="noActivo" name="existe" [(ngModel)]="botonActivoPrevio" value="no" (click)="botonActivo = true">
          <label for="noActivo">No</label>
        </div>
      </div>
      <button type="button" *ngIf="sacramento.id != 2 && sacramento.id != 6" [class.disabled]="botonActivoPrevio=='no'" (click)="changeDocumentoPrevio()">Adjuntar documento</button>
      <button type="button" *ngIf="sacramento.id == 2 || sacramento.id == 6" (click)="changeDocumentoPrevio()">Adjuntar documento</button>
    </div>
    <div class="buttonsContainer">
      <div>
        <button type="button" (click)="cancel()">Cancelar</button>
        <button type="button" *ngIf="sacramento.id != 2 && sacramento.id != 6" (click)="siguiente()">Siguiente</button>
        <button type="button" *ngIf="sacramento.id == 2 || sacramento.id == 6" (click)="siguiente()">Guardar partida</button>
      </div>
    </div>
  </ng-container>
</div>