import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Partida } from 'src/app/objects/Partidas';
import { resultHttp } from 'src/app/objects/resultHttp';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root',
})
export class PartidaService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
    this._result = new BehaviorSubject<Array<Partida> | null>(null);
    this._resultIndividual = new BehaviorSubject<Partida | null>(null);
  }

  getAll(partidaId?) {
    let buscar = '';
    if (partidaId !== null) {
      buscar = '?sacramento=' + partidaId;
    }
    this.http
      .get(this.urlHost + 'api/partida' + buscar, this.getHeader())
      .subscribe({
        next: (data) => {
          let partidas: Array<any> = [];
          for (let s of Object.keys(data)) {
            if (data[s].borrador == false) {
              let emp: any = data[s];
              partidas.push(emp);
            }
          }
          this._result.next(partidas);
        },
        error: (error) => {
          let resHt = new resultHttp();
          resHt.data = error;
          resHt.mensaje = error.error.message;
          resHt.ok = false;
          resHt.status = error.status;
          this.sendNextResult('resultError', resHt, {
            method: this.getAll,
            args: [],
          });
        },
      });
    return this._result;
  }
  getAllWithOutFilter() {
    this.http.get(this.urlHost + 'api/partida/', this.getHeader()).subscribe({
      next: (data) => {
        let partidas: Array<any> = [];
        for (let s of Object.keys(data)) {
          if (data[s].borrador == false) {
            let emp: any = data[s];
            partidas.push(emp);
          }
        }
        this._result.next(partidas);
      },
      error: (error) => {
        let resHt = new resultHttp();
        resHt.data = error;
        resHt.mensaje = error.error.message;
        resHt.ok = false;
        resHt.status = error.status;
        this.sendNextResult('resultError', resHt, {
          method: this.getAll,
          args: [],
        });
      },
    });
    return this._result;
  }
  getAllBySacramentos() {
    var dataFiltros = JSON.parse(String(localStorage.getItem('filtros')));

    this.http.get(this.urlHost + 'api/partida/', this.getHeader()).subscribe({
      next: (data) => {
        let partidas: Array<any> = [];
        for (let s of Object.keys(data)) {
          if (data[s].borrador == false) {
            dataFiltros.sacramento.forEach((element) => {
              if (element.id == data[s].sacramento.id) {
                let emp: any = data[s];
                partidas.push(emp);
              }
            });
          }
        }
        this._result.next(partidas);
      },
      error: (error) => {
        let resHt = new resultHttp();
        resHt.data = error;
        resHt.mensaje = error.error.message;
        resHt.ok = false;
        resHt.status = error.status;
        this.sendNextResult('resultError', resHt, {
          method: this.getAll,
          args: [],
        });
      },
    });
    return this._result;
  }
  getAllBorradores() {
    this.http.get(this.urlHost + 'api/partida', this.getHeader()).subscribe({
      next: (data) => {
        let partidas: Array<any> = [];
        for (let s of Object.keys(data)) {
          if (data[s].borrador == true) {
            let emp: any = data[s];
            partidas.push(emp);
          }
        }
        this._result.next(partidas);
      },
      error: (error) => {
        let resHt = new resultHttp();
        resHt.data = error;
        resHt.mensaje = error.error.message;
        resHt.ok = false;
        resHt.status = error.status;
        this.sendNextResult('resultError', resHt, {
          method: this.getAll,
          args: [],
        });
      },
    });
    return this._result;
  }
  getIndividual(ID) {
    this.http
      .get(this.urlHost + 'api/partida/' + ID, this.getHeader())
      .subscribe({
        next: (data) => {
          let emp = data;
          this._resultIndividual.next(emp);
        },
        error: (error) => {
          let resHt = new resultHttp();
          resHt.data = error;
          resHt.mensaje = error.error.message;
          resHt.ok = false;
          resHt.status = error.status;
          this.sendNextResult('resultIndividualError', resHt, {
            method: this.getIndividual,
            args: [ID],
          });
        },
      });
  }
  create(partida) {
    this.http
      .post(this.urlHost + 'api/partida', partida, this.getHeader())
      .subscribe({
        next: (data) => {
          this.sendNextResult('resultUpdate', data);
        },
        error: (error) => {
          let resHt = new resultHttp();
          resHt.data = error;
          resHt.mensaje = error.error.message;
          resHt.ok = false;
          resHt.status = error.status;
          this.sendNextResult('resultUpdateError', resHt, {
            method: this.create,
            args: [partida],
          });
        },
      });
  }
  update(ID, partida: Partida | any) {
    this.http
      .put(this.urlHost + 'api/partida/' + ID, partida, this.getHeader())
      .subscribe({
        next: (data) => {
          let emp: Partida = Partida.convertFrontObject(data['data']);
          this.sendNextResult('resultUpdate', emp);
        },
        error: (error) => {
          let resHt = new resultHttp();
          resHt.data = error;
          resHt.mensaje = error.error.message;
          resHt.ok = false;
          resHt.status = error.status;
          this.sendNextResult('resultUpdateError', resHt, {
            method: this.update,
            args: [ID, partida],
          });
        },
      });
  }
  delete(IDs: Array<any>) {
    let idOj = this.convertArrayObject(IDs, 'partidas_ids');
    this.http
      .delete(this.urlHost + 'api/partida', this.sendBodyOptions(idOj))
      .subscribe({
        next: (data) => {
          let resHt = new resultHttp();
          resHt.data = data['data'];
          resHt.mensaje = data['message'];
          resHt.ok = true;
          resHt.status = data['status'];
          this.sendNextResult('resultDelete', resHt);
        },
        error: (error) => {
          let resHt = new resultHttp();
          resHt.data = error;
          resHt.mensaje = error.error.message;
          resHt.ok = false;
          resHt.status = error.status;
          this.sendNextResult('resultDeleteError', resHt, {
            method: this.delete,
            args: [IDs],
          });
        },
      });
  }
}
