import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-parroquiaFormPopup',
  templateUrl: './parroquiaFormPopup.component.html',
  styleUrls: ['./parroquiaFormPopup.component.css']
})
export class ParroquiaFormPopupComponent implements OnInit {
  defaultNombre="";
  defaultUbicacion="";
  nombreParroquia="";
  ubicacionParroquia="";
  action="Crear";
  disabledParroquia=true;

  constructor(public dialogRef: MatDialogRef<ParroquiaFormPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {
    if(this.modalData!=null){
      this.nombreParroquia=this.modalData.nombre;
      this.defaultNombre=this.nombreParroquia;
      this.ubicacionParroquia=this.modalData.ubicacion;
      this.defaultUbicacion=this.ubicacionParroquia;
      this.action="Editar";
      this.checkIsToSend();
    }
  }
  closePopup(returnValue){
    let p={returnValue:returnValue};
    this.dialogRef.close(p);
  }
  sendParroquia(){
    this.closePopup({nombre:this.nombreParroquia,ubicacion:this.ubicacionParroquia});
  }
  checkIsToSend(){
    if(this.action=="Editar"){
      if((this.nombreParroquia!=""&&this.nombreParroquia!=this.defaultNombre)||(this.ubicacionParroquia!=""&&this.ubicacionParroquia!=this.defaultUbicacion)){
        this.disabledParroquia=false;
      }else{
        this.disabledParroquia=true;
      }
    }else{
      if(this.nombreParroquia!=""&&this.ubicacionParroquia!=""){
        this.disabledParroquia=false;
      }else{
        this.disabledParroquia=true;
      }
    }
  }

}
