import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { createCustomInputControlValueAccessor, InputComponent } from 'src/app/objects/inputControlAccessor';

@Component({
  selector: 'checkRadioBox',
  templateUrl: './checkRadioBox.component.html',
  styleUrls: ['./checkRadioBox.component.css'],
  providers: [createCustomInputControlValueAccessor(CheckRadioBoxComponent)]
})
export class CheckRadioBoxComponent extends InputComponent {

  @Input("name") name:string="";
  @Input("type") type = "checkbox";
  @Output("change") change = new EventEmitter<any>();
  @Input("id") id:string = "";

  constructor() {
    super();
  }

  checkType(typeCheck:"checkbox"){
    if(typeCheck=="checkbox"){
      return true;
    }else{
      return false;
    }
  }
  choose(ev){
    this.change.emit(this.value);
  }

}
