import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/api/Login.service';

@Component({
  selector: 'app-navbar-documentos',
  templateUrl: './navbar-documentos.component.html',
  styleUrls: ['./navbar-documentos.component.css'],
})
export class NavbarDocumentosComponent implements OnInit {
  tabActivo = false;

  constructor(private loginSE: LoginService) {}

  cambioActivo() {
    this.tabActivo = true;
  }
  setSacramento(sacramento) {
    switch (sacramento) {
      case 'Bautizo':
        localStorage.setItem(
          'filtros',
          '{"nombreFiel":"","fechaDesde":"0001-12","fechaHasta":"9999-12","parroquiaFiltro":-1,"nombrePadre":"","nombreMadre":"","nombrePadrino":"","nombreParroco":"","sacramento":[{"id":1}]}'
        );
        break;
      case 'Comunion':
        localStorage.setItem(
          'filtros',
          '{"nombreFiel":"","fechaDesde":"0001-12","fechaHasta":"9999-12","parroquiaFiltro":-1,"nombrePadre":"","nombreMadre":"","nombrePadrino":"","nombreParroco":"","sacramento":[{"id":2}]}'
        );
        break;
      case 'Confirmación':
        localStorage.setItem(
          'filtros',
          '{"nombreFiel":"","fechaDesde":"0001-12","fechaHasta":"9999-12","parroquiaFiltro":-1,"nombrePadre":"","nombreMadre":"","nombrePadrino":"","nombreParroco":"","sacramento":[{"id":3}]}'
        );
        break;
      case 'Matrimonio':
        localStorage.setItem(
          'filtros',
          '{"nombreFiel":"","fechaDesde":"0001-12","fechaHasta":"9999-12","parroquiaFiltro":-1,"nombrePadre":"","nombreMadre":"","nombrePadrino":"","nombreParroco":"","sacramento":[{"id":4}]}'
        );
        break;
      case 'Defunciones':
        localStorage.setItem(
          'filtros',
          '{"nombreFiel":"","fechaDesde":"0001-12","fechaHasta":"9999-12","parroquiaFiltro":-1,"nombrePadre":"","nombreMadre":"","nombrePadrino":"","nombreParroco":"","sacramento":[{"id":5}]}'
        );
        break;
      case 'Nulidad Matrimonial':
        localStorage.setItem(
          'filtros',
          '{"nombreFiel":"","fechaDesde":"0001-12","fechaHasta":"9999-12","parroquiaFiltro":-1,"nombrePadre":"","nombreMadre":"","nombrePadrino":"","nombreParroco":"","sacramento":[{"id":6}]}'
        );
        break;
    }
  }
  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logout();
    }
  }
}
