import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/api/Login.service';

@Component({
  selector: 'app-navbar-ajustes',
  templateUrl: './navbar-ajustes.component.html',
  styleUrls: ['./navbar-ajustes.component.css']
})
export class NavbarAjustesComponent implements OnInit {

  tabActivo = 1;

  constructor(private routeActive: ActivatedRoute, private route: Router, private loginSE: LoginService) { }

  ngOnInit(): void {
    if (this.loginSE.isLogin()) {
      let rout = this.routeActive.snapshot.params['tipo'];
      if (rout != null) {
        switch (rout) {
          case "firmas":
            this.tabActivo = 2;
            break;
          case "parroquias":
            this.tabActivo = 3;
            break;
          case "soporte":
            this.tabActivo = 4;
            break;
        }
      }
    } else {
      this.loginSE.logout();
    }

  }

  cambioActivo(route, index: number) {
    let extraRoute = "";
    if (route != '') {
      extraRoute = "/" + route;
    }
    let rou = "/ajustes" + extraRoute;
    this.route.navigate([rou]);
    this.tabActivo = index;
    //this.tabActivo = id;
  }

}
