import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LoadingComponent } from 'src/app/popUps/loading/loading.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  protected iniciando:MatDialogRef<unknown, any> | undefined;
  constructor(private dialog: MatDialog) {
  }
  open(){
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus= false;
    dialogConfig.panelClass="loadingPopup";
    this.iniciando = this.dialog.open(LoadingComponent, dialogConfig);
    document.body.classList.add("loaderActive");
  }

  close(){
    if(this.iniciando != undefined){
      this.iniciando!.close();
      document.body.classList.remove("loaderActive");
    }
  }
  closeSlow(){
    if(this.iniciando != undefined){
      setTimeout(() => {
        this.iniciando!.close();
        document.body.classList.remove("loaderActive");
      }, 450);
    }
  }
}
