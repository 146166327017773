import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-navbar-anyadir-cancelar',
  templateUrl: './navbar-anyadir-cancelar.component.html',
  styleUrls: ['./navbar-anyadir-cancelar.component.css']
})
export class NavbarAnyadirCancelarComponent implements OnInit {

  tabActivo = 1

  constructor() { }

  @Output()
  eventoHijo = new EventEmitter<boolean>();
  @Output()
  eventoHijoBorrador = new EventEmitter<boolean>();

  enviarPadre() {
    this.eventoHijo.emit(false)
  }
  enviarPadrePartida(){
    this.eventoHijoBorrador.emit(true)
  }

  cambioActivo(id:number){
    
    this.tabActivo = id;
  }

  ngOnInit(): void {
  }
}
