import { BaseObject } from "./Base";
import { Fecha } from "./Fecha";
import { Partida } from "./Partidas";
import { User } from "./User";

export interface Cambios{
    id?: number,
    fecha: Fecha,
    hora: string,
    campo: string,
    valor: string,
    user: User | number,
    partida: Partida | number,
}
export class Cambios extends BaseObject implements Cambios{
    static convertFrontObject(data){
        let firm= new Cambios();
        firm.id=data.id;
        firm.fecha=Fecha.phpConvert(data.fecha);
        firm.hora=data.hora;
        firm.campo=data.campo;
        firm.valor=data.valor;
        firm.user=User.convertFrontObject(data.user);
        firm.partida=Partida.convertFrontObject(data.partida);
        return firm;
    }
}