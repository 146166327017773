import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Cambios } from 'src/app/objects/Cambios';
import { resultHttp } from 'src/app/objects/resultHttp';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class CambiosService extends BaseService{

  constructor(protected http:HttpClient) {
    super();
    this._result=new BehaviorSubject<Array<Cambios>|null>(null);
    this._resultIndividual=new BehaviorSubject<Cambios|null>(null);
  }

  getAll(){
    this.http.get(this.urlHost+"api/cambios/",this.getHeader()).subscribe({next:data=>{
      let cambios:Array<Cambios>=[];
      for(let s of Object.keys(data)){
        let emp:Cambios=Cambios.convertFrontObject(data[s]);
        cambios.push(emp);
      }
      this._result.next(cambios);
    },error:error=>{
      let resHt=new resultHttp();
      resHt.data=error;
      resHt.mensaje=error.error.message;
      resHt.ok=false;
      resHt.status=error.status; 
      this.sendNextResult('resultError',resHt,{method:this.getAll,args:[]});
    }});
  }
  create(cambios:Cambios){
    this.http.post(this.urlHost+"api/cambios",cambios,this.getHeader()).subscribe({next:data=>{
      let emp:Cambios=Cambios.convertFrontObject(data['data']);
      this.sendNextResult('resultUpdate',emp);
    },error:error=>{
      let resHt=new resultHttp();
      resHt.data=error;
      resHt.mensaje=error.error.message;
      resHt.ok=false;
      resHt.status=error.status; 
      this.sendNextResult('resultUpdateError',resHt,{method:this.create,args:[cambios]});
    }});
  }
  getIndividual(id){
    this.http.get(this.urlHost+"api/cambios/"+id,this.getHeader()).subscribe({next:data=>{
      let cambios:Array<Cambios>=[];
      for(let s of Object.keys(data)){
        let emp:Cambios=Cambios.convertFrontObject(data[s]);
        cambios.push(emp);
      }
      this._resultIndividual.next(cambios);
    },error:error=>{
      let resHt=new resultHttp();
      resHt.data=error;
      resHt.mensaje=error.error.message;
      resHt.ok=false;
      resHt.status=error.status; 
      this.sendNextResult('resultIndividualError',resHt,{method:this.getAll,args:[]});
    }});
  }
}
