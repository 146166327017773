import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parroquia',
  templateUrl: './parroquia.component.html',
  styleUrls: ['./parroquia.component.css']
})
export class ParroquiaComponent implements OnInit {

  aparece = false;

  constructor() { }

  ngOnInit(): void {
  }

  clickdentro(){
    this.aparece = true;
  }
  clickfuera(){
    this.aparece = false;
  }

}
