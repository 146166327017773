import { Component, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Parroquia } from 'src/app/objects/Parroquia';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';

@Component({
  selector: 'app-navbar-inicio-avanzado-lateral',
  templateUrl: './navbar-inicio-avanzado-lateral.component.html',
  styleUrls: ['./navbar-inicio-avanzado-lateral.component.css']
})
export class NavbarInicioAvanzadoLateralComponent implements OnInit, OnDestroy {

  parroquias:Array<Parroquia>;
  nombreFiel: string = "";
  sacramento: Array<any> = [];
  year = new Date().getFullYear()
  fechaDesde = "1922-12"
  fechaHasta = this.year + "-12"
  parroquiaFiltro: Number | undefined = -1
  nombrePadre = ""
  nombreMadre = ""
  nombrePadrino = ""
  nombreParroco = ""
  constructor(private parroquiaSe:ParroquiaService) {
    this.parroquias = new Array<Parroquia>()
  }
  ngOnDestroy(): void {
    document.body.classList.remove("avanzadoLateral")
  }


  @Output()
  eventoHijo = new EventEmitter<boolean>();

  bool = false

  enviarPadre() {
    this.eventoHijo.emit(this.bool)

  }
  busqueda(){
    var filtros = {
      nombreFiel: this.nombreFiel,
      sacramento: this.sacramento,
      fechaDesde: this.fechaDesde,
      fechaHasta: this.fechaHasta,
      parroquiaFiltro: this.parroquiaFiltro,
      nombrePadre: this.nombrePadre,
      nombreMadre: this.nombreMadre,
      nombrePadrino: this.nombrePadrino,
      nombreParroco: this.nombreParroco
    }
    localStorage.setItem("filtros", JSON.stringify(filtros))
    location.reload()
  }
  setSacramento(sacramento) {
    var existe = false;
    if (this.sacramento.length == 0) {
      this.sacramento.push(sacramento);
    } else {
      this.sacramento.forEach(element => {
        if (element.nombre == sacramento.nombre && element.id == sacramento.id) {
          this.sacramento.splice(this.sacramento.indexOf(element), 1)
          existe = true;
        }
      })
      if (!existe) {
        this.sacramento.push(sacramento)
      }
    }
  }
  ngOnInit(): void {
    var dataFiltros = JSON.parse(String(localStorage.getItem("filtros")))

    this.parroquiaSe.getAll().subscribe((result) => {
      this.parroquias = result
    })
    document.body.classList.add("avanzadoLateral")
    this.nombreMadre = dataFiltros.nombreMadre;
    this.nombrePadre = dataFiltros.nombrePadre;
    this.nombrePadrino = dataFiltros.nombrePadrino;
    this.nombreParroco = dataFiltros.nombreParroco;
    this.fechaDesde = dataFiltros.fechaDesde;
    this.fechaHasta = dataFiltros.fechaHasta;
    this.parroquiaFiltro = dataFiltros.parroquiaFiltro;
  }
}

