import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-ajustes-apariencia',
  templateUrl: './navbar-ajustes-apariencia.component.html',
  styleUrls: ['./navbar-ajustes-apariencia.component.css']
})
export class NavbarAjustesAparienciaComponent implements OnInit {

  constructor() { }

  color = "morado";
  optionTamanyo=[
    {id:1, label:"Pequeño"},
    {id:2, label:"Mediano"},
    {id:3, label:"Grande"}
  ];
  tamanyo = this.optionTamanyo[1];

  ngOnInit(): void {
    this.color=localStorage.getItem("color")!;
    this.tamanyo = this.optionTamanyo[parseInt(localStorage.getItem("sizeLetter")!)-1];
  }

  cambioletra(indexSize:number){
    if(indexSize==1){
      document.documentElement.style.setProperty('--tamanyo-letra', '14px');
    }
    if(indexSize==2){
      document.documentElement.style.setProperty('--tamanyo-letra', '16px');
    }
    if(indexSize==3){
      document.documentElement.style.setProperty('--tamanyo-letra', '20px');
    }
  }
  modifyLetter(){
    localStorage.setItem("sizeLetter",this.tamanyo.id.toString());
    this.cambioletra(this.tamanyo.id);
  }
  modifyColor(){
    localStorage.setItem("color",this.color);
    this.cambioColor(this.color);
  }

  cambioColor(tema: string){
    if(tema == "verde"){
      document.documentElement.style.setProperty('--color-principal', "#3F5632");
      document.documentElement.style.setProperty('--color-fondo-encima', "#a4b29a");
      document.documentElement.style.setProperty('--color-suave', "#EFF6EE");
      document.documentElement.style.setProperty('--color-oscuro', "#1F281A");
      document.documentElement.style.setProperty('--color-fondo-popUp', "#53614880");
      document.documentElement.style.setProperty('--color-muysuave', "#f6faf3");
    }
    else if(tema == "morado"){
      document.documentElement.style.setProperty('--color-principal', "#2f1d4b");
      document.documentElement.style.setProperty('--color-fondo-encima', "#a594af");
      document.documentElement.style.setProperty('--color-suave', "#e5e1e6");
      document.documentElement.style.setProperty('--color-oscuro', "#6b357f");
      document.documentElement.style.setProperty('--color-fondo-popUp', "#e5e1e680");
      document.documentElement.style.setProperty('--color-muysuave', "#fcf8ff");
    }
    else if(tema == "azul"){
      document.documentElement.style.setProperty('--color-principal', "#00367f");
      document.documentElement.style.setProperty('--color-fondo-encima', "#8e9fad");
      document.documentElement.style.setProperty('--color-suave', "#eaf2fc");
      document.documentElement.style.setProperty('--color-oscuro', "#2b64b1");
      document.documentElement.style.setProperty('--color-fondo-popUp', "#79a0d080");
      document.documentElement.style.setProperty('--color-muysuave', "#f3faff");
    }
  }
}
