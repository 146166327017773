import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs';
import { Firma } from 'src/app/objects/Firma';
import { Parroquia } from 'src/app/objects/Parroquia';
import { Partida } from 'src/app/objects/Partidas';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popUps/confirmPopup/confirmPopup.component';
import { PopupFileComponent } from 'src/app/popUps/popupFile/popupFile.component';
import { FirmaService } from 'src/app/services/api/firma.service';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';
import { PartidaService } from 'src/app/services/api/partida.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';
import { PopupService } from 'src/app/services/util/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-crearPartida',
  templateUrl: './crearPartida.component.html',
  styleUrls: ['./crearPartida.component.css']
})
export class CrearPartidaComponent extends UnsubscribesDestroy implements OnInit {
  pasoCreacionPartida = 0;
  tiposPartidas = ["Bautizo", "Comunión", "Confirmación", "Matrimonio", "Defunción", "Nulidad Matrimonial"]
  file = ["data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="];

  botonActivo = false;
  botonActivoPrevio = "no";
  sacramento = {
    id: -1,
    nombre: ""
  }
  tipoMatrimonio = "catolico";

  constructor(private router: Router, private firmaSe: FirmaService, private notificaciones: NotificacionesService, private parroquiaSe: ParroquiaService, private partidaSE: PartidaService, private popupSE: PopupService, private route: ActivatedRoute,
    private loader: LoaderService) {
    super();
  }

  setTipoPartida(tipoPartida, id) {
    this.sacramento.nombre = tipoPartida
    this.sacramento.id = id
  }
  setTipoMatrimonio(tipo) {
    this.tipoMatrimonio = tipo
  }
  navigateTo() {
    this.router.navigate(["/crear/borradores"])
  }
  cancel() {
    this.router.navigate(["/inicio"])
  }
  siguiente() {
    if (this.pasoCreacionPartida < 1) {
      this.pasoCreacionPartida++;
    } else {
      switch (this.sacramento.id) {
        case 1:
          this.router.navigate(["/crear-partida/bautizo"])
          break;
        case 3:
          this.router.navigate(["/crear-partida/confirmacion"])
          break;
        case 4:
          if (this.tipoMatrimonio == "catolico") {
            this.router.navigate(["/crear-partida/matrimonio-catolico"])
          }
          if (this.tipoMatrimonio == "mixto") {
            this.router.navigate(["/crear-partida/matrimonio-mixto"])
          }
          break;
        case 5:
          this.router.navigate(["/crear-partida/defuncion"])
          break;
      }
    }
  }
  changeDocumentoPrevio() {
    let fileFromSessionStorage = sessionStorage.getItem("documentoAdjunto")
    this.popupSE.openPopup(PopupFileComponent, { action: "Añadir", file:fileFromSessionStorage })

  }

  ngOnInit() {

  }

}
