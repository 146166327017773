import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-anyadir-guardadoexito',
  templateUrl: './navbar-anyadir-guardadoexito.component.html',
  styleUrls: ['./navbar-anyadir-guardadoexito.component.css']
})
export class NavbarAnyadirGuardadoexitoComponent implements OnInit {

  tabActivo = false

  constructor() { }

  cambioActivo(){
    
    this.tabActivo = true;
  }

  ngOnInit(): void {
  }

}
