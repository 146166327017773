import { BaseObject } from "./Base";

export interface Sacramento{
    id?: number | null,
    nombre: string | null
}
export class Sacramento extends BaseObject implements Sacramento{
    static convertFrontObject(data){
        let sacr= new Sacramento();
        sacr.id=data.id;
        sacr.nombre=data.nombre;
        return sacr;
    }

    static getIdSacramento(nombre:string){
        switch(nombre){
            case "Bautismo":
            return 1;
            break;
            case "Comunion":
            return 2;
            break;
            case "Confirmacion":
            return 3;
            break;
            case "Matrimonio":
            return 4;
            break;
            case "Nulidad Matrimonial":
            return 5;
            break;
            case "Defuncion":
            return 6;
            break;
            default:
            return -1;
        }
    }
}