import { BaseObject } from "./Base";
import { Fecha } from "./Fecha";
import { Fiel } from "./Fiel";
import { Firma } from "./Firma";
import { Parroquia } from "./Parroquia";
import { Sacramento } from "./Sacramento";
import { User } from "./User";

export interface Partida{
    id?: number,
    fecha: Fecha,
    numero: number,
    anyo: number,
    hora: string,
    testigos: string,
    borrador: boolean,
    parroco: string,
    firma: Firma | number,
    sacramento: Sacramento | number,
    parroquia: Parroquia | number,
    fiel: Fiel | number,
    user?: User | number,
    canonico?: String,
    extraData?: any
}

export class Partida extends BaseObject implements Partida{
    static convertFrontObject(data){
        let part= new Partida();
        part.id=data.id;
        part.fecha=Fecha.phpConvert(data.fecha);
        part.numero=data.numero;
        part.anyo=data.anyo;
        part.hora=data.hora;
        part.parroco = data.parroco
        part.testigos=data.testigos;
        part.borrador=data.borrador;
        if(data.firma!=null){
            part.firma=Firma.convertFrontObject(data.firma);
        }
        if(data.sacramento!=null){
            part.sacramento=Sacramento.convertFrontObject(data.sacramento);
        }
        if(data.parroquia!=null){
            part.parroquia=Parroquia.convertFrontObject(data.parroquia);
        }
        if(data.fiel!=null){
            part.fiel=Fiel.convertFrontObject(data.fiel);
        }
        if(data.user!=null){
            part.user=User.convertFrontObject(data.user);
        }
        return part;
    }
}