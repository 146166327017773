import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { resultHttp } from 'src/app/objects/resultHttp';
import { BaseService } from './Base.service';
import { StaticUtilitiesServiceService } from './StaticUtilitiesService.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {
  private activeRefreshToken = false;

  constructor(protected http:HttpClient,protected router:Router) {
    super();
    this.activeRefreshToken = false;
  }

  checkLogin(user,pass){
    this.http.post(this.urlHost+"api/login_check",{username:user,password:pass}).subscribe({next:data=>{
      this.sendNextResult('result',data);
      localStorage.setItem('token',data["token"]);
    },error:error=>{
      let reError=new resultHttp();
      reError.data=error;
      reError.mensaje=error.error.message;
      reError.ok=false;
      reError.status=error.status; 
      this.sendNextResult('resultError',reError);
    }});
  }
  updatePassword(pass,newpass,renewpass){
    this.http.post(this.urlHost+"users/v1/updatePassword",{password:pass,new_password:newpass,re_new_password:renewpass},this.getHeader()).subscribe({
      next:(data)=>{
        this.sendNextResult('resultUpdate',data);
      },
      error:(error)=>{
        let reError=new resultHttp();
        reError.data=error;
        reError.mensaje=error.error.message;
        reError.ok=false;
        reError.status=error.status;
        this.sendNextResult('resultUpdateError',reError,{method:this.updatePassword,args:[pass,newpass,renewpass]});
      }
    })
  }
  refreshToken(){
    if(!this.activeRefreshToken){
      this.activeRefreshToken=true;
      this.http.post(this.urlHost + 'api/refrescarToken', null, this.getHeader()).subscribe({
          next: (data) => {
            this.activeRefreshToken=false;
            localStorage.setItem('token', data['token']);
            StaticUtilitiesServiceService.completeRefreshToken();
          },
          error: (error) => {
            this.activeRefreshToken=false;
            StaticUtilitiesServiceService.tokenLost();
          },
        });
    }
  }
  lossPassword(email){
    this.http.post(this.urlHost + "recuperarCuenta",{email:email},this.getHeader()).subscribe({
      next:(data)=>{
        this.sendNextResult('resultUpdate',data);
      },
      error:(error)=>{
        let reError=new resultHttp();
        reError.data=error;
        reError.mensaje=error.error.message;
        reError.ok=false;
        reError.status=error.status;
        this.sendNextResult('resultUpdateError',reError,{method:this.lossPassword,args:[email]});
      }
    })
  }
  changePassword(pass,token){
    token=token.replaceAll(" ","+");
    token=token.replaceAll("%20","+");
    this.http.post(this.urlHost + "cambiarPassword",{new_password:pass,recover_token:token},this.getHeader()).subscribe({
      next:(data)=>{
        this.sendNextResult('resultUpdate',data);
      },
      error:(error)=>{
        let reError=new resultHttp();
        reError.data=error;
        reError.mensaje=error.error.message;
        reError.ok=false;
        reError.status=error.status;
        this.sendNextResult('resultUpdateError',reError,{method:this.changePassword,args:[pass,token]});
      }
    })
  }
  logout(){
    localStorage.removeItem("token");
    localStorage.removeItem('sacramento')
    localStorage.removeItem('filtros')

    this.router.navigate(["/login"])
  }
  isLogin(){
    if(localStorage.getItem("token") != null){return true;}
    return false;
  }
}
