<div class="main">
    <div class="panel"> 
        <div class="cambioList">
            <div *ngFor="let cambio of listIndividual">
                <div class="cambioType">
                    <label for="">Modificación realizada el {{parseFecha(cambio)}}</label><br>
                    <label for="" class="italic">Cambio {{getCambios(cambio)}}</label>
                </div>
            </div>
        </div>
        <div class="typeNothing" *ngIf="listIndividual!=null&&listIndividual.length==0">No existe ningún cambio</div>
        <button (click)="enviarPadre()" type="submit" class="buttonsinimg">Cerrar</button>
    </div>
</div>