<div class="parroquia">
    <label for="" class="labelparroquia">Parroquia Santiago Apóstol</label>
    <svg (click)="clickdentro()" class="svgparroquia" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.37 55.37">
        <defs>
            <style>.cls-2{stroke: #FFFFFF;}</style>
        </defs>
        <g id="Capa_2" data-name="Capa 2">
            <g data-name="Capa 1">
                <path id="Trazado_602" data-name="Trazado 602" class="boton3" d="M9.23,0H46.14a9.23,9.23,0,0,1,9.23,9.23V46.14a9.23,9.23,0,0,1-9.23,9.23H9.23A9.23,9.23,0,0,1,0,46.14V9.23A9.23,9.23,0,0,1,9.23,0Z"/>
                <g id="Grupo_1485" data-name="Grupo 1485">
                    <circle id="Elipse_558" data-name="Elipse 558" class="cls-2" cx="17.3" cy="27.69" r="3.46"/>
                    <circle id="Elipse_559" data-name="Elipse 559" class="cls-2" cx="27.69" cy="27.69" r="3.46"/>
                    <circle id="Elipse_560" data-name="Elipse 560" class="cls-2" cx="38.07" cy="27.69" r="3.46"/>
                </g>
            </g>
        </g>
    </svg>
    <div [class.divoculto]="aparece == false" [class.divaparece]="aparece == true" (click)="clickfuera()">
        <button type="submit" class="buttonoculto">Editar firma</button>
        <button type="submit" class="buttonoculto">Eliminar firma</button>
    </div>
</div>
