import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/api/Login.service';
import { PartidaService } from 'src/app/services/api/partida.service';
import { LoaderService } from 'src/app/services/util/loader.service';

@Component({
  selector: 'app-navbar-inicio-resultados',
  templateUrl: './navbar-inicio-resultados.component.html',
  styleUrls: ['./navbar-inicio-resultados.component.css'],
})
export class NavbarInicioResultadosComponent implements OnInit {
  cambio = false;
  partidas = [];

  nombreFiel = '';
  year = new Date().getFullYear();
  fechaDesde = '1922-12';
  fechaHasta = this.year + '-12';
  nombrePadre = '';
  nombreMadre = '';
  nombrePadrino1 = '';
  nombrePadrino2 = '';
  parroco = '';
  parroquiaElegida: number = -1;

  dataFiltros = JSON.parse(String(localStorage.getItem('filtros')));
  constructor(
    private loginSE: LoginService,
    private partidaSE: PartidaService,
    private router: Router,
    private loader: LoaderService
  ) {}

  onMensajeHijo(mostrar: boolean) {
    this.cambio = mostrar;
  }

  cambiar() {
    this.cambio = !this.cambio;
  }
  removeFiltros() {
    localStorage.removeItem('filtros');
    this.router.navigate(['/inicio']);
  }
  ngOnInit(): void {
    this.loader.open();
    if (!this.loginSE.isLogin()) {
      this.loginSE.logout();
    } else {
      this.nombreFiel = this.dataFiltros.nombreFiel;
    }
  }

  pararCarga() {
    this.loader.closeSlow();
  }
}
