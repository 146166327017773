import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoaderService } from '../util/loader.service';
import { NotificacionesService } from '../util/notificaciones.service';
import { LoginService } from './Login.service';

@Injectable({
  providedIn: 'root'
})
export abstract class StaticUtilitiesServiceService {
  protected static router:Router;
  protected static loaderSe:LoaderService;
  protected static notificaciones:NotificacionesService;
  protected static _refreshTokenComplete=new Subject<any>();
  protected static _startTokenRefresh=new Subject<any>();
  constructor(){}

  public static tokenLost(){
    localStorage.removeItem('token');
    this.router.navigate(['']);
    this.loaderSe.closeSlow();
    this.notificaciones.showError('Sesión caducada','Es necesario volver a hacer login');
  }
  public static startTokenRefresh(){
    this._startTokenRefresh.next(true);
  }
  public static listenerStartRefreshToken(){
    return this._startTokenRefresh;
  }
  public static listenerRefreshToken(){
    return this._refreshTokenComplete;
  }
  public static completeRefreshToken(){
    this._refreshTokenComplete.next(true);
  }

  public static initValues(rout:Router,load:LoaderService,noti:NotificacionesService){
    this.router=rout;
    this.loaderSe=load;
    this.notificaciones=noti;
  }

}
@Injectable({
  providedIn: 'root'
})
export class UtilitiesStaticInitValues{
  constructor(private router:Router,private loaderSe:LoaderService,private notificaciones:NotificacionesService){}
  initValues(){
    StaticUtilitiesServiceService.initValues(this.router,this.loaderSe,this.notificaciones);
  }
}
