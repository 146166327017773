import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartidaService } from 'src/app/services/api/partida.service';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { Partida } from 'src/app/objects/Partidas';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-grid-resultados',
  templateUrl: './grid-resultados.component.html',
  styleUrls: ['./grid-resultados.component.css'],
})
export class GridResultadosComponent
  extends UnsubscribesDestroy
  implements OnInit, OnChanges
{
  partidas: Array<Partida>;
  nombre = '';
  @Input() padre = '';
  @Input() sacramento = 0;
  @Input() nombreFiel = '';
  @Input() parroquia: Number = -1;
  @Input() fechaDesde: string = '';
  @Input() fechaHasta: string = '';
  @Input() nombrePadre: string = '';
  @Input() nombreMadre: string = '';
  @Input() nombrePadrino1: string = '';
  @Input() nombrePadrino2: string = '';
  @Input() parroco: string = '';
  @Input() cambio: boolean = true;
  @Output('loader') loader = new EventEmitter<boolean>();

  dataFiltros = JSON.parse(String(localStorage.getItem('filtros')));

  partidasAuxiliar: Array<Partida> = [];
  constructor(
    private rutaActiva: ActivatedRoute,
    public partidaSe: PartidaService,
    private router: Router
  ) {
    super();
    this.partidas = new Array<Partida>();
    this.partidasAuxiliar = new Array<Partida>();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['parroquia'] != undefined) {
      this.parroquia = changes['parroquia'].currentValue;
    }
    if (this.parroquia > -1) {
      this.filtroConParroquia();
    } else {
      this.filtroSinParroquia();
    }
  }

  ngOnInit(): void {
    this.partidasAuxiliar = [];
    if (this.padre == 'borradores') {
      this.partidaSe.getAllBorradores().subscribe((result) => {
        if (result == null) {
          return;
        }
        this.partidas = result;
        this.partidasAuxiliar = result;
        this.loader.emit(true);
      });
      this.nombre = '(Borrador)';
    } else {
      let buscar: any = null;
      if (this.dataFiltros !== null) {
        let ids: any = [];
        let sacramentos: any = this.dataFiltros.sacramento;
        sacramentos.forEach((element) => {
          ids.push(element.id);
        });
        console.log(ids);
        if (ids.length > 0) {
          buscar = JSON.stringify(ids);
        }
      }
      this.partidaSe.clearResult();
      this.partidaSe.getAll(buscar).subscribe((result) => {
        if (result == null) {
          return;
        }
        this.partidas = result;
        this.filtroInicio();
      });
      this.nombre = '';
    }
  }
  viewFile(id) {
    this.router.navigate([
      '/explorar/' + location.href.split('/')[4] + '/' + id,
    ]);
  }
  filtroInicio() {
    if (this.dataFiltros.parroquiaFiltro > -1) {
      this.filtroInicioConParroquia();
    } else {
      this.filtroInicioSinParroquia();
    }
    this.loader.emit(true);
  }

  filtroInicioSinParroquia() {
    var date_1 = new Date(
      Number(this.dataFiltros.fechaDesde.split('-')[0]),
      Number(this.dataFiltros.fechaDesde.split('-')[1]) - 1,
      Number('31')
    );
    var date_2 = new Date(
      Number(this.dataFiltros.fechaHasta.split('-')[0]),
      Number(this.dataFiltros.fechaHasta.split('-')[1]) - 1,
      Number('31')
    );

    this.partidasAuxiliar = this.partidas.filter((e) => {
      return (
        e.fiel['nombre']
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombreFiel.toLocaleLowerCase()) &&
        date_1.getTime() <
          new Date(
            Number(String(e.fecha).split('T')[0].split('-')[0]),
            Number(String(e.fecha).split('T')[0].split('-')[1]),
            Number(String(e.fecha).split('T')[0].split('-')[2])
          ).getTime() &&
        date_2.getTime() >
          new Date(
            Number(String(e.fecha).split('T')[0].split('-')[0]),
            Number(String(e.fecha).split('T')[0].split('-')[1]),
            Number(String(e.fecha).split('T')[0].split('-')[2])
          ).getTime() &&
        e.fiel['padre']
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombrePadre.toLocaleLowerCase()) &&
        e.fiel['madre']
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombreMadre.toLocaleLowerCase()) &&
        e.fiel['padrinos']
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombrePadrino.toLocaleLowerCase()) &&
        e.parroco
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombreParroco.toLocaleLowerCase())
      );
    });
  }
  filtroInicioConParroquia() {
    var date_1 = new Date(
      Number(this.dataFiltros.fechaDesde.split('-')[0]),
      Number(this.dataFiltros.fechaDesde.split('-')[1]) - 1,
      Number('31')
    );
    var date_2 = new Date(
      Number(this.dataFiltros.fechaHasta.split('-')[0]),
      Number(this.dataFiltros.fechaHasta.split('-')[1]) - 1,
      Number('31')
    );

    this.partidasAuxiliar = this.partidas.filter((e) => {
      return (
        e.fiel['nombre']
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombreFiel.toLocaleLowerCase()) &&
        e.parroquia['id'] == this.dataFiltros.parroquiaFiltro &&
        date_1.getTime() <
          new Date(
            Number(String(e.fecha).split('T')[0].split('-')[0]),
            Number(String(e.fecha).split('T')[0].split('-')[1]),
            Number(String(e.fecha).split('T')[0].split('-')[2])
          ).getTime() &&
        date_2.getTime() >
          new Date(
            Number(String(e.fecha).split('T')[0].split('-')[0]),
            Number(String(e.fecha).split('T')[0].split('-')[1]),
            Number(String(e.fecha).split('T')[0].split('-')[2])
          ).getTime() &&
        e.fiel['padre']
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombrePadre.toLocaleLowerCase()) &&
        e.fiel['madre']
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombreMadre.toLocaleLowerCase()) &&
        e.fiel['padrinos']
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombrePadrino.toLocaleLowerCase()) &&
        e.parroco
          .toLocaleLowerCase()
          .includes(this.dataFiltros.nombreParroco.toLocaleLowerCase())
      );
    });
  }

  filtroSinParroquia() {
    setTimeout(() => {
      var date_1 = new Date(
        Number(this.fechaDesde.split('-')[0]),
        Number(this.fechaDesde.split('-')[1]) - 1,
        Number('31')
      );
      var date_2 = new Date(
        Number(this.fechaHasta.split('-')[0]),
        Number(this.fechaHasta.split('-')[1]) - 1,
        Number('31')
      );

      this.partidasAuxiliar = this.partidas.filter((e) => {
        return (
          e.fiel['nombre']
            .toLocaleLowerCase()
            .includes(this.nombreFiel.toLocaleLowerCase()) &&
          date_1.getTime() <
            new Date(
              Number(String(e.fecha).split('T')[0].split('-')[0]),
              Number(String(e.fecha).split('T')[0].split('-')[1]),
              Number(String(e.fecha).split('T')[0].split('-')[2])
            ).getTime() &&
          date_2.getTime() >
            new Date(
              Number(String(e.fecha).split('T')[0].split('-')[0]),
              Number(String(e.fecha).split('T')[0].split('-')[1]),
              Number(String(e.fecha).split('T')[0].split('-')[2])
            ).getTime() &&
          e.fiel['padre']
            .toLocaleLowerCase()
            .includes(this.nombrePadre.toLocaleLowerCase()) &&
          e.fiel['madre']
            .toLocaleLowerCase()
            .includes(this.nombreMadre.toLocaleLowerCase()) &&
          e.fiel['padrinos']
            .toLocaleLowerCase()
            .includes(this.nombrePadrino1.toLocaleLowerCase()) &&
          e.fiel['padrinos']
            .toLocaleLowerCase()
            .includes(this.nombrePadrino2.toLocaleLowerCase()) &&
          e.parroco
            .toLocaleLowerCase()
            .includes(this.parroco.toLocaleLowerCase())
        );
      });
    }, 100);
  }
  filtroConParroquia() {
    setTimeout(() => {
      var date_1 = new Date(
        Number(this.fechaDesde.split('-')[0]),
        Number(this.fechaDesde.split('-')[1]) - 1,
        Number('31')
      );
      var date_2 = new Date(
        Number(this.fechaHasta.split('-')[0]),
        Number(this.fechaHasta.split('-')[1]) - 1,
        Number('31')
      );

      this.partidasAuxiliar = this.partidas.filter((e) => {
        return (
          e.fiel['nombre']
            .toLocaleLowerCase()
            .includes(this.nombreFiel.toLocaleLowerCase()) &&
          e.parroquia['id'] == this.parroquia &&
          date_1.getTime() <
            new Date(
              Number(String(e.fecha).split('T')[0].split('-')[0]),
              Number(String(e.fecha).split('T')[0].split('-')[1]),
              Number(String(e.fecha).split('T')[0].split('-')[2])
            ).getTime() &&
          date_2.getTime() >
            new Date(
              Number(String(e.fecha).split('T')[0].split('-')[0]),
              Number(String(e.fecha).split('T')[0].split('-')[1]),
              Number(String(e.fecha).split('T')[0].split('-')[2])
            ).getTime() &&
          e.fiel['padre']
            .toLocaleLowerCase()
            .includes(this.nombrePadre.toLocaleLowerCase()) &&
          e.fiel['madre']
            .toLocaleLowerCase()
            .includes(this.nombreMadre.toLocaleLowerCase()) &&
          e.fiel['padrinos']
            .toLocaleLowerCase()
            .includes(this.nombrePadrino1.toLocaleLowerCase()) &&
          e.fiel['padrinos']
            .toLocaleLowerCase()
            .includes(this.nombrePadrino2.toLocaleLowerCase()) &&
          e.parroco
            .toLocaleLowerCase()
            .includes(this.parroco.toLocaleLowerCase())
        );
      });
    }, 100);
  }
}
