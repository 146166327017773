import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmPopup',
  templateUrl: './confirmPopup.component.html',
  styleUrls: ['./confirmPopup.component.css']
})
export class ConfirmPopupComponent implements OnInit {

  titulo="";
  description="";
  accion="";
  tipoPopup=""
  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  closePopup(returnValue){
    let p={returnValue:returnValue};
    this.dialogRef.close(p);
  }

  ngOnInit() {
    this.titulo=this.modalData.titulo;
    this.description=this.modalData.description;
    this.accion=this.modalData.accion;
    this.tipoPopup = this.modalData.tipoPopup
  }

}
