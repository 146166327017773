<div class="navbarGeneralContainer" *ngIf="this.router.url !== '/login' && this.router.url !== '/lossPassword'">
    <div class="titleContainer">
        <p>PARROQUIA SANTIAGO APÓSTOL</p>
    </div>
    <div class="buttonsContainer">
        <button type="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">
                <defs>
                    <style>
                        .cls-1 {
                            fill: #fff;
                            stroke-miterlimit: 10;
                        }

                        .cls-1,
                        .cls-2 {
                            stroke: #311c38;
                        }

                        .cls-2 {
                            fill: none;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                        }
                    </style>
                </defs>
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1" routerLink="/inicio" routerLinkActive="inicioActivo"
                        [routerLinkActiveOptions]="{exact: false}">
                        <rect class="cls-1" x="0.5" y="0.5" width="48" height="48" rx="8" />
                        <polygon class="cls-2"
                            points="35.14 22.55 33.46 22.55 33.46 34.93 26.63 34.93 26.63 28.44 22.37 28.44 22.37 34.93 15.54 34.93 15.54 22.55 13.86 22.55 19.18 18.31 24.5 14.07 29.82 18.31 35.14 22.55" />
                    </g>
                </g>
            </svg>
        </button>
        <button type="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">
                <defs>
                    <style>
                        .cls-1 {
                            fill: #fff;
                            stroke-miterlimit: 10;
                        }

                        .cls-1,
                        .cls-2 {
                            stroke: #311c38;
                        }

                        .cls-2 {
                            fill: none;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                        }
                    </style>
                </defs>
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1" routerLinkActive="inicioActivo"
                        [routerLinkActiveOptions]="{exact: false}" routerLink="/crear-partida">
                        <rect class="cls-1" x="0.5" y="0.5" width="48" height="48" rx="8" />
                        <line class="cls-2" x1="24.5" y1="14.5" x2="24.5" y2="34.5" />
                        <line class="cls-2" x1="14.5" y1="24.5" x2="34.5" y2="24.5" />
                    </g>
                </g>
            </svg>
        </button>
        <button type="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">
                <defs>
                    <style>
                        .cls-1 {
                            fill: #fff;
                        }

                        .cls-1,
                        .cls-2 {
                            stroke: #311c38;
                            stroke-miterlimit: 10;
                        }

                        .cls-2 {
                            fill: none;
                            stroke-width: 1.5px;
                        }
                    </style>
                </defs>
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1" routerLinkActive="inicioActivo"
                        [routerLinkActiveOptions]="{exact: false}" routerLink="/explorar">
                        <rect class="cls-1" x="0.5" y="0.5" width="48" height="48" rx="8" />
                        <path class="cls-2"
                            d="M35.31,34H15.69a1.52,1.52,0,0,1-1.51-1.51v-16A1.52,1.52,0,0,1,15.69,15h7.75a1.51,1.51,0,0,1,1.09.47l1.69,1.79a1.5,1.5,0,0,0,1.09.47l8,0a1.51,1.51,0,0,1,1.5,1.51V32.49A1.52,1.52,0,0,1,35.31,34Z" />
                    </g>
                </g>
            </svg>
        </button>
        <button type="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">
                <defs>
                    <style>
                        .cls-1 {
                            fill: #fff;
                            stroke-miterlimit: 10;
                        }

                        .cls-1,
                        .cls-2 {
                            stroke: #311c38;
                        }

                        .cls-2 {
                            fill: none;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                        }
                    </style>
                </defs>
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1" routerLinkActive="inicioActivo"
                        [routerLinkActiveOptions]="{exact: false}" routerLink="/ajustes">
                        <rect class="cls-1" x="0.5" y="0.5" width="48" height="48" rx="8" />
                        <path class="cls-2"
                            d="M34.37,27.55l3.05-1.34a13.35,13.35,0,0,0,0-3.42l-3.05-1.34a10.24,10.24,0,0,0-2.29-4l.36-3.31a13,13,0,0,0-3-1.71l-2.69,2a10.21,10.21,0,0,0-4.58,0l-2.69-2a13,13,0,0,0-3,1.71l.36,3.31a10.24,10.24,0,0,0-2.29,4l-3.05,1.34a13.35,13.35,0,0,0,0,3.42l3.05,1.34a10.24,10.24,0,0,0,2.29,4l-.36,3.31a13,13,0,0,0,3,1.71l2.69-2a10.21,10.21,0,0,0,4.58,0l2.69,2a13,13,0,0,0,3-1.71l-.36-3.31A10.24,10.24,0,0,0,34.37,27.55Z" />
                        <circle class="cls-2" cx="24.5" cy="24.5" r="6.33" />
                    </g>
                </g>
            </svg>
        </button>
        <button type="button" (click)="cerrarSesion()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">
                <defs>
                    <style>
                        .cls-1 {
                            fill: #fff;
                        }

                        .cls-1,
                        .cls-2 {
                            stroke: #311c38;
                            stroke-miterlimit: 10;
                        }

                        .cls-2 {
                            fill: none;
                            stroke-linecap: round;
                            stroke-width: 1.5px;
                        }
                    </style>
                </defs>
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Capa_1-2" data-name="Capa 1" routerLinkActive="cerrarActivo"
                        [routerLinkActiveOptions]="{exact: false}">
                        <rect class="cls-1" x="0.5" y="0.5" width="48" height="48" rx="8" />
                        <path class="cls-2"
                            d="M28.54,17.05a10.36,10.36,0,0,1,5.52,5.52,10.37,10.37,0,1,1-19.12,0,10.36,10.36,0,0,1,5.52-5.52" />
                        <line class="cls-2" x1="24.5" y1="12.02" x2="24.5" y2="27.02" />
                    </g>
                </g>
            </svg>
        </button>
    </div>
</div>

<router-outlet></router-outlet>