<div class="globalPopupContainer">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent confirmarPopups">
    <div class="popupHead">
      <h3 class="popupTitle">{{action}} firma</h3>
    </div>
    <div class="popupBody popupCenterHeightContainer flexColumn">
      <div class="loadImage apaisado"><img [src]="file[0]"></div>
      <div>
        <ng-select placeholder="Parroquia" [items]="parroquias" bindLabel="nombre" [(ngModel)]="parroquiaSelect" (change)="checkIsToSend()" [clearable]="false"></ng-select>
        <input type="text" class="input full_width inputMargin" placeholder="Nombre de la firma" (input)="checkIsToSend()" [(ngModel)]="nombreFirma">
        <button class="btn buttonMargin full_width" fileInput [accept]="['image/png', 'image/jpeg']" [(fichero)]="file" (ficheroChange)="checkIsToSend()">Subir imágen</button>
      </div>
    </div>
    <div class="popupButtons">
      <button class="btn btn_secondary" [attr.disabled]="disabledFirma ? '' : null" (click)="sendFirma()">{{action}} firma</button>
    </div>
  </div>
</div>