import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/objects/Fecha';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { CambiosService } from 'src/app/services/api/cambios.service';

@Component({
  selector: 'app-navbar-documentos-modificaciones',
  templateUrl: './navbar-documentos-modificaciones.component.html',
  styleUrls: ['./navbar-documentos-modificaciones.component.css']
})
export class NavbarDocumentosModificacionesComponent extends UnsubscribesDestroy implements OnInit {

  bool = false
  listIndividual=[];

  @Output()
  eventoHijo = new EventEmitter<boolean>();

  @Input("idDocumento") idDocumento;

  constructor(private cambiosSe:CambiosService){
    super();
  }

  enviarPadre() {
    this.eventoHijo.emit(this.bool)
  }
  ngOnInit(): void {
    this.cambiosSe.getIndividual(this.idDocumento);
    this.cambiosSe.getResultIndividual().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return;}
      this.listIndividual=value;
    })
  }
  parseFecha(cambio){
    return cambio.fecha.dia+"/"+cambio.fecha.mes+"/"+cambio.fecha.year;
  }
  getCambios(cambio){
    let cal=cambio.campo;
    if(cal=="Url"){
      cal="fichero adjunto";
      return cal;
    }else{
      return cambio.campo+" ("+cambio.valor+")";
    }
  }

}
