import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/api/Login.service';
import { PartidaService } from 'src/app/services/api/partida.service';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { environment } from 'src/environments/environment';
import { Fecha } from 'src/app/objects/Fecha';
import { FirmaService } from 'src/app/services/api/firma.service';
import { Firma } from 'src/app/objects/Firma';

@Component({
  selector: 'app-navbar-documentos-documento',
  templateUrl: './navbar-documentos-documento.component.html',
  styleUrls: ['./navbar-documentos-documento.component.css'],
})
export class NavbarDocumentosDocumentoComponent
  extends UnsubscribesDestroy
  implements OnInit {
  sacramento = -1;
  sacramentoNombre = '';
  aparece = false;
  cambio = false;
  id: number | undefined;
  tipo = ' ';
  previousUrl = ' ';
  idSacramento: number = -1;
  nombre = '';
  matrimonioMixto = false;
  documento;
  libro;
  folio;
  fechaCelebracion: string = '';
  imgSelect =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  @ViewChild('documentoImprimir') documentoImprimir!: ElementRef;
  @ViewChild('documentoCedula') documentoCedula!: ElementRef;
  @ViewChild('documentoAmonestacion') documentoAmonestacion!: ElementRef;
  @ViewChild('documentoNota') documentoNota!: ElementRef;

  today = new Date();
  mesActual = '';
  objFecha: Fecha = new Fecha();
  days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  dayName = this.days[new Date().getDay()];
  textoDinamico: string | null = null;
  //DIO-ANIO TEXTO
  dia: string = '';
  anio: string = '';
  diaNac:string= '';
  anioNac:string='';
  diaNacEsposa:string= '';
  anioNacEsposa:string='';

  /*BAUTIZO*/
  fechaNac: string = '';
  numeroPartida: number | null = null;
  anyo: number | null = null;
  ubicacionParroquiaCeremonia: string | null = null;

  nombreBautizado: string | null = null;
  primerApellido: string | null = null;
  segundoApellido: string | null = null;
  sexoBautizado: string | null = 'Niño';
  lugarNacimientoBautizado: string | null = null;
  calleNac: string | null = null;
  numeroNac: string | null = null;
  nombrePadre: string | null = null;
  apellidosPadre: string | null = null;
  lugarNacimientoPadre: string | null = null;
  nombreMadre: string | null = null;
  apellidosMadre: string | null = null;
  lugarNacimientoMadre: string | null = null;
  feligresesParroquia: string | null = null;
  ubicacionParroquiaFeligreses: string | null = null;
  casadosParroquia: string | null = null;
  abueloMaterno: string | null = null;
  abuelaMaterna: string | null = null;
  abueloPaterno: string | null = null;
  abuelaPaterna: string | null = null;
  nombreMadrina: string | null = null;
  apellidosMadrina: string | null = null;
  vecinoMadrina: string | null = null;
  nombrePadrino: string | null = null;
  apellidosPadrino: string | null = null;
  vecinoPadrino: string | null = null;
  firmaSelect: number | null = null;
  parroco: string | null = null;
  infrafirmado: string | null = null;
  parrocoSacramento: string | null = null;
  /*FIN BAUTIZO*/

  /*CONFIRMACIÓN*/
  nombreLaico: String | null = null;
  padrinos: String | null = null;
  padre: String | null = null;
  madre: String | null = null;
  apellidosLaico: String | null = null;
  fechaNacimiento: string = '';
  lugarNacimiento: String | null = null;
  parroquiaBautizado: String | null = null;
  lugarParroquia: String | null = null;
  lugarParroquiaBautizo: String| null = null;
  tomoConfirmaicon:any=null;
  folioConfirmacion:any=null;
  /*FIN CONFIRMACIÓN*/

  /*DEFUNCIÓN*/
  nombreDifunto: string | null = null;
  apellidosDifunto: string | null = null;
  edad: number | null = null;
  estado: string | null = null;
  fechaDefuncion: string | null = null;
  horaDefuncion: string | null = null;
  enterrado: string | null = null;
  sacramentosRecibidos: string | null = null;
  direccionDifunto: string | null = null;
  curaOriginal: string | null = null;
  /*FIN DEFUNCIÓN*/

  /*MATRIMONIO MIXTO*/
  apellidoLaico: String | null = null;
  vecinoLaico: String | null = null;
  feligresParroquia: String | null = null;
  sexo: string | null = null;
  nombreOtraReligion: string | null = null;
  apellidosOtraReligion: string | null = null;
  vecinoOtraReligion: string | null = null;
  religion: string | null = null;
  /*FIN MATRIMONIO MIXTO*/

  /*MATRIMONIO CATÓLICO*/
  nombreMarido: string | null = null;
  apellidosMarido: string | null = null;
  estadoCivil: string = '';
  nacimientoMarido: any = null;
  bautizoMarido: string | null = null;
  nombreEsposa: string | null = null;
  apellidosEsposa: string | null = null;
  estadoCivilEsposa: string = '';
  fechaNacimientoEsposa: any = null;
  bautizoLugarMarido: string | null = null;
  libroMarido: string | null = null;
  folioMarido: string | null = null;
  bautizoLugarEsposa: string | null = null;
  libroEsposa: string | null = null;
  folioEsposa: string | null = null;
  bautizoEsposa: string | null = null;
  nombrePadreMarido: string | null = null;
  apellidosPadreMarido: string | null = null;
  nombreMadreMarido: string | null = null;
  apellidosMadreMarido: string | null = null;
  nombrePadreEsposa: string | null = null;
  apellidosPadreEsposa: string | null = null;
  nombreMadreEsposa: string | null = null;
  apellidosMadreEsposa: string | null = null;
  // hora: string | null = null;
  jurisdiccion: string | null = null;

  /*FIN MATRIMONIO CATÓLICO*/

  parroquiaNombre: string = '';
  ubicacionParroquia: string = '';
  parroquiaId: number | null = null;
  constructor(
    private _location: Location,
    private rutaActiva: ActivatedRoute,
    private loginSE: LoginService,
    private partidaSe: PartidaService,
    private notificaciones: NotificacionesService,
    private router: Router,
    private firmaSe: FirmaService,
    private loader: LoaderService
  ) {
    super();
  }
  trocearFecha(fecha: any){
    console.log(fecha)
    this.anio=fecha.split('T')[0]
    this.dia=this.anio.split('-')[2]
    this.anio=this.anio.split('-')[0]
    this.anio=this.convertirAnioATexto(this.anio);
    this.dia=this.convertirDiaATexto(this.dia)
  }
  trocearFechaNacimiento(fecha: any){
    console.log(fecha)
    this.anioNac=fecha.split('T')[0]
    this.diaNac=this.anioNac.split('-')[2]
    this.anioNac=this.anioNac.split('-')[0]
    this.anioNac=this.convertirAnioATexto(this.anioNac);
    this.diaNac=this.convertirDiaATexto(this.diaNac)
  }
  trocearFechaNacimientoEsposa(fecha: any){
    console.log(fecha)
    this.anioNacEsposa=fecha.split('T')[0]
    this.diaNacEsposa=this.anioNacEsposa.split('-')[2]
    this.anioNacEsposa=this.anioNacEsposa.split('-')[0]
    this.anioNacEsposa=this.convertirAnioATexto(this.anioNacEsposa);
    this.diaNacEsposa=this.convertirDiaATexto(this.diaNacEsposa)
  }
  convertirAnioATexto(numero: string): string {
    const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const decenas = ['', 'diez', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const especiales = ['','diez','once','doce','trece','catorce','quince'];
    let anioTexto = '';
    let anioMil = '';
    let anioCent = '';
    let anioDec = '';
    let anioUni = '';
  
    if(parseInt(numero[0])==1){
      anioMil = " mil "
    }else{
      anioMil = unidades[parseInt(numero[0])] + " mil "
    }
  if(parseInt(numero[1])!=0){
    if(parseInt(numero[1])==1){
      anioCent = "ciento "
    }else if(parseInt(numero[1])==5){
      anioCent = "quinientos "
    }else if(parseInt(numero[1])==7){
      anioCent = "setecientos "
    }else{
      anioCent = unidades[parseInt(numero[1])] +"cientos "
    }
  }
  
    if(parseInt(numero.slice(-2))<16){
      anioDec = especiales[parseInt(numero[1])]
    }else if(parseInt(numero[2])==1){
      anioDec="dieci"
      anioUni=unidades[parseInt(numero[3])]
    }else if(parseInt(numero[2])==2){
      anioDec="venti"
      anioUni=unidades[parseInt(numero[3])]
    }else{
      anioDec=decenas[parseInt(numero[2])]
      if(parseInt(numero[3])!=0){

        anioUni=" y "+unidades[parseInt(numero[3])]
        
      }else{
        anioUni=unidades[parseInt(numero[3])]
      }
    }
  
    anioTexto= anioMil+anioCent+anioDec+anioUni
  
    return anioTexto;
  }
  convertirDiaATexto(numero: string): string {
    const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const especiales = { '10': 'diez', '11': 'once', '12': 'doce', '13': 'trece', '14': 'catorce', '15': 'quince', '20': 'veinte', '30': 'treinta', '31': 'treinta y uno'};
    let diaTexto = '';
    if(numero.startsWith("0")) { // si el número tiene un cero al inicio, omitirlo
      numero=numero.split("0")[1]
      console.log(numero)
    }
    if(numero.length<2){
      diaTexto=unidades[parseInt(numero)]
    }else if(numero in especiales) { // números especiales del 10 al 15
      diaTexto = especiales[numero];
    } else if(parseInt(numero) < 20) { // números del 16 al 19
      diaTexto = 'dieci' + unidades[parseInt(numero) % 10];
    } else if(parseInt(numero) < 30) { // números del 20 al 29
      diaTexto = 'veinti' + unidades[parseInt(numero) % 10];
    } else if(parseInt(numero) % 10 == 0) { // múltiplos de 10
      diaTexto = unidades[parseInt(numero) / 10] + 'enta';
    } else { // otros números
      diaTexto = unidades[Math.floor(parseInt(numero) / 10)] + 'enta y ' + unidades[parseInt(numero) % 10];
    }
    return diaTexto;
  }
  hoy: Fecha = new Fecha();
  ngOnInit(): void {
    this.hoy = Fecha.javascriptConvert(new Date());
    if (this.loginSE.isLogin()) {
      this.id = this.rutaActiva.snapshot.params['id'];
      this.tipo = this.rutaActiva.snapshot.params['tipo'];
      this.seleccionarBtn(Number(localStorage.getItem('sacramento')));
      this.partidaSe.getIndividual(this.id);
      this.partidaSe.clearResultIndividual();
      this.loader.open();
      this.partidaSe
        .getResultIndividual()
        .pipe(takeUntil(this._unsubInd2))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          console.log(value);
          if (value.url == null) {
            this.documento = null;
          } else {
            this.documento = environment.urlDocs + value.url;
            this.documentos.push({ id: 1, tipo: 'Original' });
          }

          if (value.parroquia != null) {
            this.parroquiaNombre = value.parroquia.nombre;
            this.ubicacionParroquia = value.parroquia.ubicacion;
          }
          this.libro = value.libro;
          this.folio = value.folio;
          this.anyo = value.anyo;

          if (value.fecha != null) {
            this.fechaCelebracion = value.fecha;
            console.log(this.fechaCelebracion);
          }
          if (value.parroco) {
            this.infrafirmado = value.parroco.split(',')[0];
            this.parrocoSacramento = value.parroco.split(',')[1];
            this.parroco = value.parroco.split(',')[2];
          }
          if (value.sacramento.id == 1) {
            this.dataBautizo(value);
          }
          if (value.sacramento.id == 3) {
            this.dataConfirmacion(value);
          }
          if (value.sacramento.id == 5) {
            this.dataDefuncion(value);
          }
          if (value.sacramento.id == 4) {
            if (value.anyo == -1) {
              this.matrimonioMixto = true;
              this.dataMatrimonioMixto(value);
            } else {
              this.matrimonioMixto = false;
              this.dataMatrimonioCatolico(value);
            }
          }

          this.sacramentoNombre = value.sacramento.nombre;
          this.idSacramento = value.sacramento.id;

          this.nombre = value.fiel.nombre;
          this.loader.closeSlow();
          this._unsubInd2.next('');
          this.partidaSe.clearResultIndividual();
        });
    } else {
      this.loginSE.logout();
    }

    this.firmaSe.getAll();
    this.firmaSe
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.firmas = value;
        this.seleccionarFirma();
      });
  }
  firmas: Array<Firma> = [];
  downloadAsPDF(id: number) {
    let pdfTable;
    switch (id) {
      case 2:
        pdfTable = this.documentoCedula.nativeElement;
        break;
      case 3:
        pdfTable = this.documentoImprimir.nativeElement;
        break;
      case 4:
        pdfTable = this.documentoNota.nativeElement;
        break;
      case 5:
        pdfTable = this.documentoAmonestacion.nativeElement;
        break;
    }
    if (id > 1) {
      var divContents = pdfTable.innerHTML;
      var a = window.open('', '', 'height=500, width=500')!;
      a.document.write(
        '<html> <style>body{padding:3em 2em}.abuelos{margin-top:1em;margin-bottom:1em;display:flex;flex-direction:column;}.firma{width:100% !important;display:flex !important;justify-content:center;align-items:center;flex-direction:column}.firma img{width:30%;margin-top:3em}header{border-bottom:solid 1px;padding-bottom:25px;font-size:15pt;display:flex;justify-content:space-between;position:relative;}.columnas{display:flex;gap:25px;}.columnas .col_izq{width:270px !important;}.columnas .col_der{width:100% !important;}.campo > *{display:block;text-align:center;width:100%;}.campo span{text-transform:uppercase;font-size:14pt;border-bottom:solid 1px;margin-bottom:5px;}.campo{margin-bottom:15px;}.feligres{margin-top:140px;height:auto!important;margin-bottom:50px;}p.texto{text-align:justify;}u{display:block;font-weight:600;font-size:14pt;}u+p{font-size:13pt;}.columnas.separacion .col_izq{border-right:solid 2px;padding-right:15px;}.notas>p{word-break: break-word;}</style>'
      );
      a.document.write('<body >');
      a.document.write(divContents);
      a.document.write('</body></html>');
      a.document.close();

      a.document.title = this.sacramentoNombre + '-' + this.nombre;
      a.onload = function () {
        a.print();
      };
    } else {
      var a = window.open(this.documento, '', 'height=500, width=500')!;
    }
  }
  documentos: any = [];
  mostrar = 3;
  seleccionado(tipo) {
    this.mostrar = tipo;
  }
  firmante = '';
  seleccionarFirma() {
    this.firmas.forEach((element) => {
      if (element.id == this.firmaSelect) {
        this.parroquiaId = element.parroquia['id'];
        this.parroquiaNombre = element.parroquia['nombre'];
        this.ubicacionParroquia = element.parroquia['ubicacion'];
        this.imgSelect = environment.urlDocs + element.url;
        this.firmante = element.nombre;
      }
    });
  }
  casadosFeligreses() {
    if ((this.casadosParroquia == null || this.casadosParroquia == "") && (this.feligresesParroquia == null || this.feligresesParroquia == "")) {
      this.textoDinamico = '';

    } else if (this.casadosParroquia != null && (this.feligresesParroquia == null || this.feligresesParroquia == "")) {
      this.textoDinamico = ', casados según declararon, en ' + this.casadosParroquia;

    } else if ((this.casadosParroquia == null || this.casadosParroquia == "") && this.feligresesParroquia != null && (this.ubicacionParroquiaFeligreses == null || this.ubicacionParroquiaFeligreses == "")) {
      this.textoDinamico = ', feligreses de la parroquia de ' + this.feligresesParroquia;

    } else if ((this.casadosParroquia == null || this.casadosParroquia == "") && this.feligresesParroquia != null) {
      this.textoDinamico = ', feligreses de la parroquia de ' + this.feligresesParroquia + ' de ' + this.ubicacionParroquiaFeligreses;

    } else if (this.casadosParroquia != null && this.feligresesParroquia != null && (this.ubicacionParroquiaFeligreses == null || this.ubicacionParroquiaFeligreses == "")) {
      this.textoDinamico = ', casados según declararon, en ' + this.casadosParroquia + ' y feligreses de la parroquia de ' + this.feligresesParroquia;


    } else if (this.casadosParroquia != null && this.feligresesParroquia != null) {
      this.textoDinamico = ', casados según declararon, en ' + this.casadosParroquia + ' y feligreses de la parroquia de ' + this.feligresesParroquia + ' de ' + this.ubicacionParroquiaFeligreses;

    }
  }
  dataMatrimonioCatolico(value) {
    this.documentos.push({ id: 3, tipo: 'Partida' });
    this.documentos.push({ id: 4, tipo: 'Nota marginal' });
    this.documentos.push({ id: 5, tipo: 'Amonestación' });
    if (value.extra_data != null) {
      var extraData = JSON.parse(value.extra_data);
      this.notas = extraData.notas;
      this.estadoCivil = extraData.estadoCivilMarido;
      this.nacimientoMarido = extraData.fechaNacimient;
      this.bautizoMarido = extraData.bautizadoMarido;
      this.estadoCivilEsposa = extraData.estadoCivilEsposa;
      this.fechaNacimientoEsposa = extraData.fechaNacimientoEsposa;
      this.bautizoLugarMarido = extraData.bautizoLugarMarido;
      this.libroMarido = extraData.libroMarido;
      this.folioMarido = extraData.folioMarido;
      this.bautizoLugarEsposa = extraData.bautizoLugarEsposa;
      this.libroEsposa = extraData.libroEsposa;
      this.folioEsposa = extraData.folioEsposa;
      this.bautizoEsposa = extraData.bautizoEsposa;
      this.vecinoPadrino = extraData.vecinoPadrino;
      this.vecinoMadrina = extraData.vecinoMadrina;
      this.jurisdiccion = extraData.jurisdiccion;
    }

    // if (value.hora != null) {
    //   this.hora = value.hora;
    // }
    if (value.numero != null) {
      this.numeroPartida = value.numero;
    }
    if (value.fiel != null) {
      this.nombreMarido = value.fiel.nombre.split(',')[0].split(' ')[0];
      if (value.fiel.nombre.split(',')[0].split(' ')[2] != null) {
        this.apellidosMarido =
          value.fiel.nombre.split(',')[0].split(' ')[1] +
          ' ' +
          value.fiel.nombre.split(',')[0].split(' ')[2];
      } else {
        this.apellidosMarido = value.fiel.nombre.split(',')[0].split(' ')[1];
      }

      this.nombreEsposa = value.fiel.nombre.split(',')[1].split(' ')[0];
      if (value.fiel.nombre.split(',')[1].split(' ')[2] != null) {
        this.apellidosEsposa =
          value.fiel.nombre.split(',')[1].split(' ')[1] +
          ' ' +
          value.fiel.nombre.split(',')[1].split(' ')[2];
      } else {
        this.apellidosEsposa = value.fiel.nombre.split(',')[1].split(' ')[1];
      }

      this.nombrePadreMarido = value.fiel.padre.split(',')[0].split(' ')[0];
      if (value.fiel.padre.split(',')[0].split(' ')[2] != null) {
        this.apellidosPadreMarido =
          value.fiel.padre.split(',')[0].split(' ')[1] +
          ' ' +
          value.fiel.padre.split(',')[0].split(' ')[2];
      } else {
        this.apellidosPadreMarido = value.fiel.padre
          .split(',')[0]
          .split(' ')[1];
      }
      this.nombreMadreMarido = value.fiel.madre.split(',')[0].split(' ')[0];
      if (value.fiel.madre.split(',')[0].split(' ')[2] != null) {
        this.apellidosMadreMarido =
          value.fiel.madre.split(',')[0].split(' ')[1] +
          ' ' +
          value.fiel.madre.split(',')[0].split(' ')[2];
      } else {
        this.apellidosMadreMarido = value.fiel.madre
          .split(',')[0]
          .split(' ')[1];
      }

      this.nombrePadreEsposa = value.fiel.padre.split(',')[1].split(' ')[0];
      if (value.fiel.padre.split(',')[1].split(' ')[2] != null) {
        this.apellidosPadreEsposa =
          value.fiel.padre.split(',')[1].split(' ')[1] +
          ' ' +
          value.fiel.padre.split(',')[1].split(' ')[2];
      } else {
        this.apellidosPadreEsposa = value.fiel.padre
          .split(',')[1]
          .split(' ')[1];
      }

      this.nombreMadreEsposa = value.fiel.madre.split(',')[1].split(' ')[0];
      if (value.fiel.madre.split(',')[1].split(' ')[2] != null) {
        this.apellidosMadreEsposa =
          value.fiel.madre.split(',')[1].split(' ')[1] +
          ' ' +
          value.fiel.madre.split(',')[1].split(' ')[2];
      } else {
        this.apellidosMadreEsposa = value.fiel.madre
          .split(',')[1]
          .split(' ')[1];
      }
    }
    if (value.testigos != null) {
      this.nombrePadrino = value.testigos.split(',')[0].split(' ')[0];
      if (value.testigos.split(',')[0].split(' ')[2] != null) {
        this.apellidosPadrino =
          value.testigos.split(',')[0].split(' ')[1] +
          ' ' +
          value.testigos.split(',')[0].split(' ')[2];
      } else {
        this.apellidosPadrino = value.testigos.split(',')[0].split(' ')[1];
      }

      this.nombreMadrina = value.testigos.split(',')[1].split(' ')[0];
      if (value.testigos.split(',')[1].split(' ')[2] != null) {
        this.apellidosMadrina =
          value.testigos.split(',')[1].split(' ')[1] +
          ' ' +
          value.testigos.split(',')[1].split(' ')[2];
      } else {
        this.apellidosMadrina = value.testigos.split(',')[1].split(' ')[1];
      }
    }
    if (value.firma) {
      this.firmaSelect = value.firma.id;
      this.imgSelect = environment.urlDocs + value.firma.url;
    }
    this.trocearFecha(this.fechaCelebracion);
    console.log(this.fechaNacimiento)
    this.trocearFechaNacimiento(this.nacimientoMarido);
    this.trocearFechaNacimientoEsposa(this.fechaNacimientoEsposa);
  }
  dataMatrimonioMixto(value) {
    this.documentos.push({ id: 3, tipo: 'Solicitud' });
    this.mostrar = 3;
    if (value.extra_data != null) {
      var extraData = JSON.parse(value.extra_data);
      this.vecinoLaico = extraData.vecinoLaico;
      this.feligresParroquia = extraData.feligresParroquia;
      this.sexo = extraData.sexoOtraReligion;
      this.nombreOtraReligion = extraData.nombreOtraReligion;
      this.apellidosOtraReligion = extraData.apellidosOtraReligion;
      this.vecinoOtraReligion = extraData.vecinoOtraReligion;
      this.religion = extraData.religion;
    }
    if (value.fiel != null) {
      this.nombreLaico = value.fiel.nombre.split(' ')[0];
      if (value.fiel.nombre.split(' ')[2] != null) {
        this.apellidoLaico =
          value.fiel.nombre.split(' ')[1] +
          ' ' +
          value.fiel.nombre.split(' ')[2];
      } else {
        this.apellidoLaico = value.fiel.nombre.split(' ')[1];
      }

      this.nombrePadre = value.fiel.padre.split(' ')[0];
      if (value.fiel.padre.split(' ')[2] != null) {
        this.apellidosPadre =
          value.fiel.padre.split(' ')[1] + ' ' + value.fiel.padre.split(' ')[2];
      } else {
        this.apellidosPadre = value.fiel.padre.split(' ')[1];
      }
      this.nombreMadre = value.fiel.padre.split(' ')[0];
      if (value.fiel.madre.split(' ')[2] != null) {
        this.apellidosMadre =
          value.fiel.madre.split(' ')[1] + ' ' + value.fiel.madre.split(' ')[2];
      } else {
        this.apellidosMadre = value.fiel.madre.split(' ')[1];
      }
    }
    if (value.firma != null) {
      this.firmaSelect = value.firma.id;
      this.imgSelect = environment.urlDocs + value.firma.url;
    }
  }
  notas = '';
  dataDefuncion(value) {
    this.documentos.push({ id: 3, tipo: 'Partida' });
    this.mostrar = 3;
    if (value.extra_data != null) {
      var extraData = JSON.parse(value.extra_data);
      this.edad = extraData.edadDifunto;
      this.notas = extraData.notas;
      this.estado = extraData.estadoDifunto;
      this.sacramentosRecibidos = extraData.sacramentos;
      this.enterrado = extraData.enterradoEn;
      this.curaOriginal = extraData.curaOriginal
    }
    if (value.numero != null) {
      this.numeroPartida = value.numero;
    }
    if (value.fiel != null) {
      this.nombreDifunto = value.fiel.nombre.split(' ')[0];
      if (value.fiel.nombre.split(' ')[2] != null) {
        this.apellidosDifunto =
          value.fiel.nombre.split(' ')[1] +
          ' ' +
          value.fiel.nombre.split(' ')[2];
      } else {
        this.apellidosDifunto = value.fiel.nombre.split(' ')[1];
      }
      this.direccionDifunto = value.fiel.direccion;
      this.nombrePadre = value.fiel.padre.split(' ')[0];
      if (value.fiel.padre.split(' ')[2] != null) {
        this.apellidosPadre =
          value.fiel.padre.split(' ')[1] + ' ' + value.fiel.padre.split(' ')[2];
      } else {
        this.apellidosPadre = value.fiel.padre.split(' ')[1];
      }
      this.nombreMadre = value.fiel.madre.split(' ')[0];
      if (value.fiel.madre.split(' ')[2] != null) {
        this.apellidosMadre =
          value.fiel.madre.split(' ')[1] + ' ' + value.fiel.madre.split(' ')[2];
      } else {
        this.apellidosMadre = value.fiel.madre.split(' ')[1];
      }
    }
    if (value.fecha != null) {
      this.fechaDefuncion = value.fecha.split('T')[0];
    }
    if (value.hora != null) {
      this.horaDefuncion = value.hora;
    }
    if (value.parroco != null) {
      this.infrafirmado = value.parroco.split(',')[0];
      this.parrocoSacramento = value.parroco.split(',')[1];
      this.parroco = value.parroco.split(',')[3];
    }

    if (value.firma != null) {
      this.firmaSelect = value.firma.id;
      this.imgSelect = environment.urlDocs + value.firma.url;
    }
  }
  dataBautizo(value) {
    if (value.extra_data != null) {
      var extraData = JSON.parse(value.extra_data);
      this.notas = extraData.notas;
    }

    this.documentos.push({ id: 2, tipo: 'Cedula' });
    this.documentos.push({ id: 3, tipo: 'Partida' });
    if (value.numero != null) {
      this.numeroPartida = value.numero;
    }
    if (value.firma != null) {
      this.ubicacionParroquiaCeremonia = value.firma.parroquia.ubicacion;
    }
    if (value.fiel != null) {
      this.nombreBautizado = value.fiel.nombre.split(' ')[0];
      this.primerApellido = value.fiel.apellido1;
      this.segundoApellido = value.fiel.apellido2;
      this.sexoBautizado = value.fiel.sexo;
      this.nombrePadre = value.fiel.padre.split(' ')[0];
      if (value.fiel.padre.split(' ')[2] != null) {
        this.apellidosPadre =
          value.fiel.padre.split(' ')[1] + ' ' + value.fiel.padre.split(' ')[2];
      } else {
        this.apellidosPadre = value.fiel.padre.split(' ')[1];
      }
      this.lugarNacimientoPadre = value.fiel.lugarnacimientopadre;
      this.lugarNacimientoMadre = value.fiel.lugarnacimientomadre;

      this.nombreMadre = value.fiel.madre.split(' ')[0];
      if (value.fiel.madre.split(' ')[2] != null) {
        this.apellidosMadre =
          value.fiel.madre.split(' ')[1] + ' ' + value.fiel.madre.split(' ')[2];
      } else {
        this.apellidosMadre = value.fiel.madre.split(' ')[1];
      }
      this.abueloPaterno = value.fiel.abuelospaternales.split(',')[0];
      this.abuelaPaterna = value.fiel.abuelospaternales.split(',')[1];

      this.abueloMaterno = value.fiel.abuelosmaternales.split(',')[0];
      this.abuelaMaterna = value.fiel.abuelosmaternales.split(',')[1];
    }

    if (value.extra_data != null) {
      this.lugarNacimientoBautizado = extraData.lugarNacimientoBautizado;

      this.feligresesParroquia = extraData.feligresesParroquia;
      this.ubicacionParroquiaFeligreses =
        extraData.ubicacionParroquiaFeligreses;
      this.casadosParroquia = extraData.casadosParroquia;
      this.vecinoPadrino = extraData.padrinoVecinoDe;
      this.vecinoMadrina = extraData.madrinaVecinoDe;

      this.fechaNac = extraData.cuandoNacimientoBautizado;
      this.calleNac = extraData.calleNacimientoBautizado;
      this.numeroNac = extraData.numeroNacimientoBautizado;
    }

    if (value.testigos != null) {
      this.nombrePadrino = value.testigos.split(',')[0].split(' ')[0];
      if (value.testigos.split(',')[0].split(' ')[2] != null) {
        this.apellidosPadrino =
          value.testigos.split(',')[0].split(' ')[1] +
          ' ' +
          value.testigos.split(',')[0].split(' ')[2];
      } else {
        this.apellidosPadrino = value.testigos.split(',')[0].split(' ')[1];
      }

      this.nombreMadrina = value.testigos.split(',')[1].split(' ')[0];
      if (value.testigos.split(',')[1].split(' ')[2] != null) {
        this.apellidosMadrina =
          value.testigos.split(',')[1].split(' ')[1] +
          ' ' +
          value.testigos.split(',')[1].split(' ')[2];
      } else {
        this.apellidosMadrina = value.testigos.split(',')[1].split(' ')[1];
      }
    }

    if (value.parroco != null) {
      this.infrafirmado = value.parroco.split(',')[0];
      this.parrocoSacramento = value.parroco.split(',')[1];
      this.parroco = value.parroco.split(',')[2];
    }

    if (value.firma != null) {
      this.firmaSelect = value.firma.id;
      this.firmas.forEach((element) => {
        if (element.id == this.firmaSelect) {
          this.parroco = element.nombre;
        }
      });
      this.imgSelect = environment.urlDocs + value.firma.url;
    }
    this.trocearFecha(this.fechaNac)
  }
  dataConfirmacion(value) {
    this.documentos.push({ id: 2, tipo: 'Cedula' });
    this.mostrar = 2;
    if (value.extra_data != null) {
      var extraData = JSON.parse(value.extra_data);
      this.fechaNacimiento = extraData.nacimientoLaico;
      this.lugarNacimiento = extraData.lugarNacimientoLaico;
      this.parroquiaBautizado = extraData.bautizadoEn;
      this.lugarParroquia = extraData.lugarParroquia;
      this.lugarParroquiaBautizo = extraData.direccionParroquiaBautizo
    }
    if (value.numero != null) {
      this.numeroPartida = value.numero;
    }
    if (value.fiel != null) {
      this.nombreLaico = value.fiel.nombre.split(' ')[0];
      if (value.fiel.nombre.split(' ')[2] != null) {
        this.apellidosLaico =
          value.fiel.nombre.split(' ')[1] +
          ' ' +
          value.fiel.nombre.split(' ')[2];
      } else {
        this.apellidosLaico = value.fiel.nombre.split(' ')[1];
      }
      this.nombreMadre = value.fiel.madre;
      this.nombrePadre = value.fiel.padre;
      if (value.fiel.padrinos.split(',')[0] != undefined ||
        value.fiel.padrinos.split(',')[0] != null) {
        this.nombrePadrino = value.fiel.padrinos.split(',')[0];
      }
      if (value.fiel.padrinos.split(',')[1] != undefined ||
        value.fiel.padrinos.split(',')[1] != null) {
        this.nombreMadrina = value.fiel.padrinos.split(',')[1];
      }

    }
    if (value.parroco != null) {
      this.infrafirmado = value.parroco.split(',')[0];
      this.parrocoSacramento = value.parroco.split(',')[1];
      this.parroco = value.parroco.split(',')[2];
    }
    if (value.firma != null) {
      this.firmaSelect = value.firma.id;
      this.imgSelect = environment.urlDocs + value.firma.url;
    }
    this.tomoConfirmaicon=value.libro;
    this.folioConfirmacion=value.folio
  }
  editarPartida() {
    switch (this.idSacramento) {
      case 1:
        this.router.navigate(['/editar-partida/bautizo/' + this.id]);
        break;
      case 3:
        this.router.navigate(['/editar-partida/confirmacion/' + this.id]);
        break;
      case 4:
        if (this.matrimonioMixto) {
          this.router.navigate(['/editar-partida/matrimonio-mixto/' + this.id]);
        } else {
          this.router.navigate([
            '/editar-partida/matrimonio-catolico/' + this.id,
          ]);
        }
        break;
      case 5:
        this.router.navigate(['/editar-partida/defuncion/' + this.id]);
        break;
    }
  }
  backClicked() {
    // this.router.navigate(['/explorar/' + this.tipo]);
    this._location.back();
  }

  seleccionarBtn(valor: number) {
    this.sacramento = valor;

    switch (this.sacramento) {
      case 1:
        this.tipo = 'Bautizo';
        break;
      case 2:
        this.tipo = 'Comunión';
        break;
      case 3:
        this.tipo = 'Confirmación';
        break;
      case 4:
        this.tipo = 'Matrimonio';
        break;
      case 5:
        this.tipo = 'Defunción';
        break;
      case 6:
        this.tipo = 'Nulidad Matrimonial';
        break;
    }
  }

  apareceDescargar: boolean = false;

  clickdentro2() {
    console.log('test');
    this.apareceDescargar = true;
  }
  clickfuera2() {
    this.apareceDescargar = false;
  }

  clickdentro() {
    this.aparece = true;
  }
  clickfuera() {
    this.aparece = false;
  }

  onMensajeHijo(mostrar: boolean) {
    this.cambio = mostrar;
  }

  cambiar() {
    this.cambio = !this.cambio;
  }

  eliminarDoc() {
    this.partidaSe.delete([this.id]);
    this.partidaSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this._unsubInd.next('');
        this.notificaciones.showFeedBack('Eliminación del documento exitosto');
        this.router.navigate(['/explorar']);
      });
    this.partidaSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this._unsubInd.next('');
        this.notificaciones.showError(value.mensaje);
      });
  }
}
