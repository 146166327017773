<div class="main">
    <div class="panel">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.09 72.09">
            <defs>
                <style>.f{fill:#fff;}.g{fill:#311c38;}</style>
            </defs>
            <g id="a"/>
            <g id="b">
                <g id="c">
                    <g>
                        <g id="d">
                            <circle class="g" cx="36.05" cy="36.05" r="36.05"/>
                        </g>
                        <g id="e">
                            <path class="f" d="M28.51,49.42c-.64,0-1.28-.24-1.77-.73l-6.67-6.67c-.98-.98-.98-2.56,0-3.54s2.56-.98,3.54,0l4.91,4.91,19.98-19.98c.98-.98,2.56-.98,3.54,0s.98,2.56,0,3.54l-21.74,21.74c-.49,.49-1.13,.73-1.77,.73Z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <br><br>
        <label for="">Documento firmado exitosamente</label><br>
        <div class="abajo">
            <label for="">¿Quieres descargarlo localmente?</label><br><br>
            <button routerLink="/inicio" type="submit" class="buttonsinimg" (click)="enviarPadre()">Sí, descargar</button>
            <button routerLink="/inicio" type="submit" class="buttonsinimg" (click)="enviarPadre()">No, finalizar</button>
        </div>
    </div>
</div>
