<div class="globalPopupContainer">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent confirmarPopups">
    <div class="popupHead">
      <h3 class="popupTitle">{{action}} documento</h3>
    </div>
    <div class="popupBody popupCenterHeightContainer flexColumn" *ngIf="dataFromSessionStorage">
    <p>Ya te hemos subido el documento, mi rey</p>
    </div>
    <div class="popupBody popupCenterHeightContainer flexColumn" *ngIf="!dataFromSessionStorage">
      <iframe *ngIf="action == 'Añadir'" [src]="file[0]| safe"></iframe>
      <iframe *ngIf="action == 'Modificar' && !modalData.url " [src]="file[0] | safe"></iframe>
      <iframe *ngIf="action == 'Modificar' && modalData.url " [src]="file[0] | safe"></iframe>

      <div>

         <button class="btn buttonMargin full_width" fileInput [accept]="['application/pdf' ,'image/png', 'image/jpeg']" [(fichero)]="file" (ficheroChange)="checkIsToSend()">Seleccionar documento</button>
      </div>
    </div>
    <div class="popupButtons">
      <button class="btn btn_secondary" [attr.disabled]="disabledFile ? '' : null" (click)="sendFile()">Subir documento</button>
    </div>
  </div>
</div>