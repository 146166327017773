import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Parroquia } from 'src/app/objects/Parroquia';
import { LoginService } from 'src/app/services/api/Login.service';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';
import { LoaderService } from 'src/app/services/util/loader.service';

@Component({
  selector: 'app-navbar-documentos-pulsado',
  templateUrl: './navbar-documentos-pulsado.component.html',
  styleUrls: ['./navbar-documentos-pulsado.component.css'],
})
export class NavbarDocumentosPulsadoComponent implements OnInit {
  sacramento = -1;
  tabActivo = 1;
  titulo = ' ';
  filtroNombreFiel = '';
  parroquias: Array<Parroquia>;
  parroquiaElegida: number = -1;
  advancedFiltersVisible = false;
  active;
  year = new Date().getFullYear();
  fechaDesde = '1922-12';
  fechaHasta = this.year + '-12';
  nombrePadre = '';
  nombreMadre = '';
  nombrePadrino1 = '';
  nombrePadrino2 = '';
  parroco = '';
  otrosArchivos = [
    { nombre: 'Bautizo', id: 1 },
    { nombre: 'Confirmación', id: 3 },
    { nombre: 'Matrimonio', id: 4 },
    { nombre: 'Defunciones', id: 5 },
  ];
  seleccionarBtn(valor: number) {
    this.sacramento = valor;
    localStorage.setItem('sacramento', String(valor));
  }
  pararCarga() {
    this.loader.closeSlow();
  }
  cambioActivo(id: number) {
    this.tabActivo = id;
  }

  constructor(
    private rutaActiva: ActivatedRoute,
    private parroquiaSe: ParroquiaService,
    private loginSE: LoginService,
    private router: Router,
    private loader: LoaderService
  ) {
    this.parroquias = new Array<Parroquia>();
  }

  ngOnInit(): void {
    if (this.loginSE.isLogin()) {
      this.loader.open();
      this.parroquiaSe.getAll().subscribe((result) => {
        if (result == null) {
          return;
        }
        this.parroquias = result;
      });
      this.titulo = this.rutaActiva.snapshot.params['tipo'];
    } else {
      this.loginSE.logout();
    }
  }
  showAdvancedFilters() {
    if (!this.advancedFiltersVisible) {
      this.advancedFiltersVisible = true;
    } else {
      this.advancedFiltersVisible = false;
    }
  }
  navigate(route) {
    location.href = '/explorar/' + route;
  }

  changeParroquia() {}
}
