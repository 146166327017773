import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popupFile',
  templateUrl: './popupFile.component.html',
  styleUrls: ['./popupFile.component.css']
})
export class PopupFileComponent implements OnInit {
  action="Añadir";
  file = ["data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="];
  defaultData=["data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="];
  disabledFile=true;
  url="";
  dataFromSessionStorage:any=null;

  constructor(public dialogRef: MatDialogRef<PopupFileComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.action = this.modalData.action

    this.checkIsToSend();
    if (sessionStorage.getItem("documentoAdjunto") != null || sessionStorage.getItem("documentoAdjunto") != undefined) {
      this.file = [sessionStorage.getItem("documentoAdjunto")!];
      console.log(this.file)
    }
    if (this.modalData.url != "") {
      this.file=[this.url]
    }
    // this.getInfoFromSessionStorage();
  }

  // getInfoFromSessionStorage(){
  //   this.dataFromSessionStorage = sessionStorage.getItem("documentoAdjunto");
  // }

  sendFile(){
    this.closePopup({url:this.file[0]});
    sessionStorage.setItem("documentoAdjunto",this.file[0]);
  }
  changeDocumentoPrevio(){
    this.file = []
    this.file.push(this.file[0]);
    this.checkIsToSend()
  }
  closePopup(returnValue){
    let p={returnValue:returnValue};
    this.dialogRef.close(p);
  }
  checkIsToSend(){
  
    if(this.action=="Editar"){
      if(this.file[0]!=this.defaultData[0]){
        this.disabledFile=false;
      }else{
        this.disabledFile=true;
      }
    }else{
      
      if(this.file[0]!=this.defaultData[0]){
        this.disabledFile=false;
      }else{
        this.disabledFile=true;
      }
    }
  }
}
