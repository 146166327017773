<div class="globalPopupContainer">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent confirmarPopups">
    <div class="popupHead">
      <h3 class="popupTitle">{{action}} parroquia</h3>
    </div>
    <div class="popupBody popupCenterHeightContainer flexColumn">
      <div>
        <input type="text" class="input full_width inputMargin" placeholder="Nombre de la parroquia" (input)="checkIsToSend()" [(ngModel)]="nombreParroquia">
        <input type="text" class="input full_width inputMargin" placeholder="Ubicación de la  parroquia" (input)="checkIsToSend()" [(ngModel)]="ubicacionParroquia">
      </div>
    </div>
    <div class="popupButtons">
      <button class="btn btn_secondary" [attr.disabled]="disabledParroquia ? '' : null" (click)="sendParroquia()">{{action}} parroquia</button>
    </div>
  </div>
</div>