import { BaseObject } from "./Base";

export interface Fiel{
    id?: number,
    nombre: string,
    sexo: string,
    padrinos: string,
    padre: string,
    madre: string,
    lugarnacimientomadre: string,
    lugarnacimientopadre: string,
    abuelosmaternales: string,
    abuelospaternales: string
}
export class Fiel extends BaseObject implements Fiel{
    static convertFrontObject(data){
        let fiel= new Fiel();
        fiel.id=data.id;
        fiel.nombre=data.nombre;
        fiel.sexo=data.sexo;
        fiel.padrinos=data.padrinos;
        fiel.padre=data.padre;
        fiel.madre=data.madre;
        fiel.lugarnacimientomadre=data.lugarnacimientomadre;
        fiel.lugarnacimientopadre=data.lugarnacimientopadre;
        fiel.abuelosmaternales=data.abuelosmaternales;
        fiel.abuelospaternales=data.abuelospaternales;
        
        return fiel;
    }
}