<div class="main">
    <div class="panel">
        <svg (click)="enviarPadre()" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 39 39" style="enable-background:new 0 0 39 39;" xml:space="preserve">
            <style type="text/css">
                .st0{fill:none;stroke:#5D366B;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;}
            </style>
            <g id="a">
            </g>
            <g id="b">
                <g id="c">
                    <g id="d" transform="translate(-639.793 -357.793)">
                        <line id="e" class="st0" x1="641.3" y1="359.3" x2="677.3" y2="395.3"/>
                        <line id="f" class="st0" x1="641.3" y1="395.3" x2="677.3" y2="359.3"/>
                    </g>
                </g>
            </g>
        </svg>
        <br>
        <label for="">¿Quieres guardar los cambios?</label><br>
        <button routerLink="/inicio" type="submit" class="buttonsinimg">No</button>
        <button (click)="cambioActivo(3)" (click)="enviarPadrePartida()" type="submit" class="buttonsinimg">Sí</button>
    </div> 
</div>

<app-navbar-anyadir-guardadoexito *ngIf="tabActivo == 3"></app-navbar-anyadir-guardadoexito>
