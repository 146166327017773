import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Parroquia } from 'src/app/objects/Parroquia';
import { LoginService } from 'src/app/services/api/Login.service';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';
import { LoaderService } from 'src/app/services/util/loader.service';

@Component({
  selector: 'app-navbar-inicio',
  templateUrl: './navbar-inicio.component.html',
  styleUrls: ['./navbar-inicio.component.css']
})
export class NavbarInicioComponent implements OnInit {

  tabActivo = false
  parroquias: Array<Parroquia>

  nombreFiel: string = "";
  sacramento: Array<any> = [];
  year = new Date().getFullYear()
  fechaDesde = "1922-12"
  fechaHasta = this.year + "-12"
  parroquiaFiltro: Number | undefined = -1
  nombrePadre = ""
  nombreMadre = ""
  nombrePadrino = ""
  nombreParroco = ""
  constructor(private parroquiaSe: ParroquiaService, private loginSE: LoginService, private router: Router, private loader: LoaderService) {
    this.parroquias = new Array<Parroquia>()
  }

  cambioActivo() {

    this.tabActivo = !this.tabActivo;
  }
  filterData() {
    var filtros = {
      nombreFiel: this.nombreFiel,
      sacramento: this.sacramento,
      fechaDesde: this.fechaDesde,
      fechaHasta: this.fechaHasta,
      parroquiaFiltro: this.parroquiaFiltro,
      nombrePadre: this.nombrePadre,
      nombreMadre: this.nombreMadre,
      nombrePadrino: this.nombrePadrino,
      nombreParroco: this.nombreParroco
    }
    localStorage.setItem("filtros", JSON.stringify(filtros))
    this.router.navigate(["/inicio/busqueda"]);
  }
  setSacramento(sacramento) {
    var existe = false;
    if (this.sacramento.length == 0) {
      this.sacramento.push(sacramento);
    } else {
      this.sacramento.forEach(element => {
        if (element.nombre == sacramento.nombre && element.id == sacramento.id) {
          this.sacramento.splice(this.sacramento.indexOf(element), 1)
          existe = true;
        }
      })
      if (!existe) {
        this.sacramento.push(sacramento)
      }
    }
  }

  ngOnInit(): void {   
    if (this.loginSE.isLogin()) {
      this.parroquiaSe.getAll().subscribe((result) => {
        if (result == null) { return }
        this.parroquias = result
      })
      this.loader.closeSlow();
    } else {
      this.loginSE.logout();
    }

  }
}
