<!--COPIAR-->
<div class="crearPartidaGeneralContainer">
  <div class="cancelarGuardarContainer">
    <span (click)="sendData('borrador')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8.621"
        height="14.414"
        viewBox="0 0 8.621 14.414"
      >
        <path
          id="Trazado_125"
          data-name="Trazado 125"
          d="M-9108.06,2156.32l-6.5,6.5,6.5,6.5"
          transform="translate(9115.974 -2155.613)"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
      </svg>
    </span>
    <label for="">Cancelar y guardar</label>
  </div>
  <div class="headerCrearPartidaContainer">
    <div>
      <div class="etiquetaPartida">
        <label for="">Defunción</label>
      </div>
      <span *ngIf="!editar">Nueva partida</span>
      <span *ngIf="editar">Editar partida</span>
    </div>
    <div>
      <button
        type="buttton"
        class="modificarDocumento"
        (click)="changeDocumentoPrevio()"
      >
        Modificar documento adjunto
      </button>
    </div>
  </div>
  <div class="dataGeneralContainer">
    <div class="dataContainer">
      <div class="timelineContainer">
        <div>
          <span [class.active]="pasoCreacionPartida >= 0"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 1"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 2"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 3"></span>
        </div>
        <div>
          <span
            [class.active]="pasoCreacionPartida == 0"
            [class.error]="!paso0Valido"
            >Información difunto</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 1"
            [class.error]="!paso1Valido"
            >Entierro y parroquia</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 2"
            [class.error]="!paso2Valido"
            >Parrocos implicados y Firma</span
          >
          <div></div>
          <span [class.active]="pasoCreacionPartida == 3"
            >Revisar y finalizar</span
          >
        </div>
      </div>
      <div class="formContainer">
        <ng-container *ngIf="pasoCreacionPartida == 0">
          <div class="paso0Container">
            <div>
              <div>
                <label for="" [class.error]="numeroPartidaVacio"
                  >Nº de partida:
                </label>
                <input
                  type="number"
                  min="0"
                  (input)="
                    validateNegativeNumbers(numeroPartida, 'numeroPartida')
                  "
                  [class.error]="numeroPartidaVacio"
                  class="input"
                  [(ngModel)]="numeroPartida"
                />
              </div>

              <div>
                <label for="" [class.error]="libroPartidaVacio"
                  >Tomo:
                </label>
                <input
                  type="text"
                  min="0"
                  [class.error]="libroPartidaVacio"
                  class="input"
                  [(ngModel)]="libroPartida"
                />
              </div>

              <div>
                <label for="" [class.error]="folioPartidaVacio"
                  >Numero de folio:
                </label>
                <input
                  type="number"
                  min="0"
                  (input)="
                    validateNegativeNumbers(folioPartida, 'folioPartida')
                  "
                  [class.error]="folioPartidaVacio"
                  class="input"
                  [(ngModel)]="folioPartida"
                />
              </div>

              <div>
                <label for="" [class.error]="anyoVacio">Año: </label>
                <input
                  type="number"
                  min="0"
                  (input)="validateNegativeNumbers(anyo, 'anyo')"
                  [class.error]="anyoVacio"
                  [(ngModel)]="anyo"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div>
                <label for="" [class.error]="nombreDifuntoVacio"
                  >Nombre del difunto:
                </label>
                <input
                  type="text"
                  [class.error]="nombreDifuntoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  class="input"
                  [(ngModel)]="nombreDifunto"
                />
              </div>
              <div>
                <label for=" " [class.error]="primerApellidoVacio"
                  >Primer apellido del difunto:
                </label>
                <input
                  type="text"
                  [class.error]="primerApellidoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  class="input"
                  [(ngModel)]="primerApellido"
                />
              </div>
              <div>
                <label for=" " [class.error]="segundoApellidoVacio"
                  >Segundo apellido del difunto:
                </label>
                <input
                  type="text"
                  [class.error]="segundoApellidoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  class="input"
                  [(ngModel)]="segundoApellido"
                />
              </div>
            </div>
            <div>
              <div class="">
                <label for="" [class.error]="firmaVacio">Parroquia de la celebracion: </label>
                <ng-select
                  [class.error]="parroquiaVacio"
                  class="secundaryTheme fix_height"
                  [placeholder]="'Selecciona una Parroquia'"
                  [items]="parroquias"
                  [(ngModel)]="parroquiaSelect"
                  [clearable]="false"
                  bindLabel="nombre"
                  bindValue="id"
                ></ng-select>
              </div>
              <div>
                <label for="" [class.error]="edadVacio"
                  >Edad del difunto:
                </label>
                <input
                  type="number"
                  min="0"
                  (input)="validateNegativeNumbers(edad, 'edad')"
                  [class.error]="edadVacio"
                  class="input"
                  [(ngModel)]="edad"
                />
              </div>
              <div>
                <label for="" [class.error]="estadoVacio"
                  >Estado del difunto:
                </label>
                <input
                  type="text"
                  [class.error]="estadoVacio"
                  class="input"
                  [(ngModel)]="estado"
                />
              </div>
            </div>
            <div>
            <div>
              <label for="" [class.error]="direccionDifuntoVacio">
              Dirección del difunto:
            </label>
            <input
              type="text"
              [class.error]="direccionDifuntoVacio"
              class="input"
              [(ngModel)]="direccionDifunto"
            />
            </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="nombrePadreVacio"
                  >Nombre del padre:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.726"
                  height="20.726"
                  viewBox="0 0 20.726 20.726"
                >
                  <path
                    id="male"
                    d="M16.116,4.75h6.946m0,0V11.7m0-6.946-9.156,9.156m1.579,3.789a5.367,5.367,0,1,1-5.367-5.367A5.367,5.367,0,0,1,15.485,17.695Z"
                    transform="translate(-3.75 -3.336)"
                    fill="none"
                    stroke="#0d1423"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
                <input
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  type="text"
                  [class.error]="nombrePadreVacio"
                  class="input"
                  [(ngModel)]="nombrePadre"
                />
              </div>

            </div>
            <div>
              <div class="campoEspecial">
                <label [class.error]="nombreMadreVacio" for=""
                  >Nombre de la madre:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.344"
                  height="27.306"
                  viewBox="0 0 13.344 27.306"
                >
                  <path
                    id="female"
                    d="M14.422,30.056V16.094m0,0A5.672,5.672,0,1,0,8.75,10.422,5.672,5.672,0,0,0,14.422,16.094ZM8.75,23.948H20.094"
                    transform="translate(-7.75 -3.75)"
                    fill="none"
                    stroke="#0d1423"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
                <input
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  type="text"
                  [class.error]="nombreMadreVacio"
                  class="input"
                  [(ngModel)]="nombreMadre"
                />
              </div>

            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 1">
          <div class="paso0Container">
            <div>
              <div>
                <label for="" [class.error]="fechaDefuncionVacio"
                  >Fecha de la defunción:
                </label>
                <input
                  type="date"
                  [class.error]="fechaDefuncionVacio"
                  class="input"
                  (change)="cambioFecha()"
                  [(ngModel)]="fechaDefuncion"
                />
              </div>
              <div class="campoEspecial">
                <label for="" [class.error]="horaDefuncionVacio"
                  >Hora de la defunción:
                </label>
                <input
                  type="time"
                  class="input"
                  [class.error]="horaDefuncionVacio"
                  [(ngModel)]="horaDefuncion"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="enterradoVacio"
                  >Enterrado en el cementerio de:
                </label>
                <svg
                  id="Grupo_1689"
                  data-name="Grupo 1689"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectángulo_3320"
                        data-name="Rectángulo 3320"
                        width="14"
                        height="16"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Grupo_1672"
                    data-name="Grupo 1672"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Trazado_765"
                      data-name="Trazado 765"
                      d="M13.25,7c0,4-6.25,8.25-6.25,8.25S.75,11,.75,7A6.308,6.308,0,0,1,7,.75,6.308,6.308,0,0,1,13.25,7Z"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <circle
                      id="Elipse_591"
                      data-name="Elipse 591"
                      cx="2.25"
                      cy="2.25"
                      r="2.25"
                      transform="translate(4.75 4.75)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <input
                  [class.error]="enterradoVacio"
                  type="text"
                  class="input"
                  [(ngModel)]="enterrado"
                />
              </div>
              
            </div>
            <div>
              <div>
                <label for="" [class.error]="sacramentosVacio"
                  >Sacramentos recibidos:
                </label>
                <input
                  type="text"
                  [class.error]="sacramentosVacio"
                  class="input"
                  [(ngModel)]="sacramentos"
                />
              </div>
            </div>
            <div>
              <div>
                <label for="">Notas marginales: </label>
                <textarea
                  style="
                    resize: none;
                    height: 100px;
                    background: var(--color-suave);
                  "
                  [(ngModel)]="notas"
                  (keyup)="splitNotas()"
                  class="input"
                ></textarea>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 2">
          <div class="paso0Container">
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="infrafirmadoVacio"
                  >Infrafirmado:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  type="text"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="infrafirmadoVacio"
                  [(ngModel)]="infrafirmado"
                  class="input"
                />
              </div>
              <div class="campoEspecial">
                <label for="" [class.error]="curaOriginalVacio"
                  >Cura que firmo la partida originalmente:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  type="text"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="curaOriginalVacio"
                  [(ngModel)]="curaOriginal"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="firmaVacio">Nombre y firma del sacerdote encargado del oficio: </label>
                <ng-select
                  [class.error]="firmaVacio"
                  class="secundaryTheme fix_height"
                  [placeholder]="'Selecciona una firma'"
                  [items]="firmas"
                  [(ngModel)]="firmaSelect"
                  [clearable]="false"
                  bindLabel="nombre"
                  bindValue="id"
                ></ng-select>
              </div>
            </div>
            <div class="firma">
              <ng-container *ngFor="let image of firmas">
                <img
                  *ngIf="firmaSelect == image.id"
                  [src]="urlImages + image.url"
                />
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 3">
          <div class="documentoGeneradoContainer">
            <div class="documentoGenerado" #documentoImprimir>
              <header>
                <b>Parroquía</b> {{ parroquiaNombre }} <b>de</b>
                {{ ubicacionParroquia }}
              </header>
              <p>
                <b><i>El infrafirmado D.</i></b> {{ infrafirmado }}
                <b><i>de</i></b>
                {{ parroquiaNombre }}
              </p>
              <div class="columnas">
                <div class="col_izq">
                  <b>CERTIFICO:</b>

                  <div class="feligres">
                    <div class="campo">
                      <p>Nº {{ numeroPartida }}</p>
                    </div>
                    <div class="campo">
                      <p>Año {{ anyo }}</p>
                    </div>
                    <div class="campo">
                      <p>Folio {{ folioPartida }}</p>
                    </div>
                    <div class="campo">
                      <p>Libro {{ libroPartida }}</p>
                    </div>
                  </div>
                  <div class="notas">
                    <p>Notas marginales:</p>
                    <p *ngFor="let nota of notasArray">{{ nota }}</p>
                  </div>
                </div>
                <div class="col_der">
                  <b
                    ><i>Que en el Libro de Defunciones, tomo </i
                    >{{ libroPartida }}<i> al </i>{{ folioPartida }}<i> num. </i
                    >{{ numeroPartida
                    }}<i>
                      de este Archivo Parroquial se encuentra una partida que
                      dice así:</i
                    ></b
                  >
                  <p class="texto">
                    "El dia {{ objFecha.dia }} de {{ objFecha.mesNombre() }} de
                    {{ objFecha.year }} a las {{ horaDefuncion }}, según
                    certificación facultativa que se exhibió, falleció en la
                    parroquia {{ parroquiaNombre }}, calle de
                    {{ ubicacionParroquia }}
                  </p>
                  <p class="texto">
                    D./Dª {{ nombreDifunto }} hijo/hija de D. {{ nombrePadre }}
                     y Dª {{ nombreMadre }}, de estado {{ estado }}, de edad de 
                    {{ edad }} y con dirección {{direccionDifunto}} {{ sacramentos == null || " " ? "":", recibio los Sacramentos de "+sacramentos+" que le administro D. "+parrocoSacramento}}.
                  </p>
                  <p class="texto">
                    Fué enterrado en el Cementerio de {{ enterrado }}
                  </p>

                  <p class="texto">De que como cura certifico, firmado originalmente por {{curaOriginal}}</p>
                </div>
              </div>
              <br />

              <p>
                <b
                  ><i
                    >Así resulta del libro indicado a que refiero. Y para que
                    conste, {{parroco}} firma y sella la presente en Valencia a</i
                  >
                  {{ hoy.dia }}<i> de </i>{{ hoy.mesNombre() }}<i> de </i
                  >{{ hoy.year }}</b
                >
              </p>
              <div class="firma">
                <img [src]="urlImages + imgSelect" alt="" />
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="buttonsContainer">
      <div>
        <button type="button" (click)="atras()">Atrás</button>
        <button
          type="button"
          (click)="siguiente()"
          *ngIf="pasoCreacionPartida != 3"
        >
          Siguiente
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 3 && !editar"
          [class.disabled]="!paso0Valido || !paso1Valido || !paso2Valido"
          (click)="sendData('nuevo')"
        >
          Crear partida
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 3 && editar"
          [class.disabled]="!paso0Valido || !paso1Valido || !paso2Valido"
          (click)="sendData('nuevo')"
        >
          Editar partida
        </button>
      </div>
    </div>
  </div>
</div>
<!-- FIN COPIAR-->
