<div class="resultados" [class]="padre">
    <!-- <div class="pdfresultado" [routerLink]="['/explorar', sacramento(partida.sacramento), partida.id]" *ngFor="let partida of partidas">
        <svg class="pdf" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.74 103.03">
            <defs>
                <style>.cls-1{fill:none;stroke:#311c38;stroke-miterlimit:10;}.cls-2{font-size:27.13px;font-family:IBMPlexSans-Medium, IBM Plex Sans;font-weight:500;}
                </style>
            </defs>
            <g id="Capa_2" data-name="Capa 2">
                <g id="Capa_1-2" data-name="Capa 1">
                    <path class="cls-1" d="M78.24,16.57V98.48a4,4,0,0,1-4.06,4H4.56a4,4,0,0,1-4.06-4V4.56A4.06,4.06,0,0,1,4.56.5h59.6Z"/>
                    <path class="cls-1" d="M64.16.5V13a3.54,3.54,0,0,0,3.55,3.54H78.24"/>
                    <line class="cls-1" x1="13.95" y1="26.68" x2="65.32" y2="26.68"/>
                    <line class="cls-1" x1="13.95" y1="35.64" x2="65.32" y2="35.64"/>
                    <line class="cls-1" x1="13.95" y1="44.61" x2="65.32" y2="44.61"/>
                    <line class="cls-1" x1="13.95" y1="53.57" x2="65.32" y2="53.57"/>
                    <text class="cls-2" transform="translate(13.95 84.85)">PDF</text>
                </g>
            </g>
        </svg>
        <br>
        <label  for="" class="letraspdf">Ficha {{sacramento(partida.sacramento)}} Vicente Mayoral</label>
    </div> -->
    <filecard (click)="viewFile(partida.id)" *ngFor="let partida of partidasAuxiliar" class="fileCard" [nombre]="nombre + ' Partida de ' +partida.sacramento['nombre']+ ' de ' + partida.fiel['nombre']"></filecard>
</div>
<h2 *ngIf="partidasAuxiliar.length == 0" class="noExistSacramento">No hay partidas en este sacramento</h2>