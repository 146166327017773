<div class="main">
  <span (click)="removeFiltros()">
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      style="enable-background: new 0 0 32 32"
      xml:space="preserve"
    >
      <style type="text/css">
        .st0 {
          fill: #5d366b;
        }
        .st1 {
          fill: none;
          stroke: #ffffff;
          stroke-width: 2;
        }
      </style>
      <g id="a"></g>
      <g id="b">
        <g id="c">
          <g id="d" transform="translate(-364 -52)">
            <circle id="e" class="st0" cx="380" cy="68" r="16" />
            <path id="f" class="st1" d="M382.2,61.5l-6.5,6.5l6.5,6.5" />
          </g>
        </g>
      </g>
    </svg>
    <label for="">Volver</label>
  </span>
  <div class="arriba">
    <input
      type="text"
      class="nombre"
      [(ngModel)]="nombreFiel"
      placeholder="Nombre y apellidos del fiel ..."
    />
    <svg
      class="buscaravanzado"
      (click)="cambiar()"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 55.3 54.2"
      style="enable-background: new 0 0 55.3 54.2"
      xml:space="preserve"
    >
      <style type="text/css">
        .st0 {
          fill: #311c38;
        }
        .st1 {
          fill: none;
          stroke: #ffffff;
          stroke-width: 2;
          stroke-linecap: round;
        }
        .st2 {
          fill: #ffffff;
        }
        .st3 {
          fill: none;
          stroke: #ffffff;
        }
      </style>
      <path
        id="e"
        class="st0"
        d="M8.8,0h38c4.8,0,8.7,3.9,8.7,8.7v36.9c0,4.8-3.9,8.7-8.7,8.7h-38c-4.8,0-8.7-3.9-8.7-8.7V8.7
                C0.1,3.9,4,0,8.8,0z"
      />
      <g>
        <line id="g" class="st1" x1="13" y1="20.2" x2="42" y2="20.2" />
        <line id="h" class="st1" x1="13" y1="34" x2="42" y2="34" />
        <g id="i" transform="translate(21.138 -0.591)">
          <circle class="st2" cx="20.4" cy="20.8" r="3.5" />
          <circle class="st3" cx="20.4" cy="20.8" r="3" />
        </g>
        <g id="j" transform="translate(-1.263 11.694)">
          <circle class="st2" cx="15.2" cy="22.3" r="3.5" />
          <circle class="st3" cx="15.2" cy="22.3" r="3" />
        </g>
      </g>
    </svg>
  </div>
  <br />

  <label for="" class="titulo">Resultados de la búsqueda</label>
  <app-grid-resultados
    (loader)="pararCarga()"
    [parroquia]="dataFiltros.parroquiaFiltro"
    [parroco]="parroco"
    [nombrePadrino1]="nombrePadrino1"
    [nombrePadrino2]="nombrePadrino2"
    [nombreFiel]="nombreFiel"
    [fechaDesde]="fechaDesde"
    [fechaHasta]="fechaHasta"
    [nombrePadre]="nombrePadre"
    [nombreMadre]="nombreMadre"
    [padre]="'inicio'"
  ></app-grid-resultados>
</div>

<app-navbar-inicio-avanzado-lateral
  (eventoHijo)="onMensajeHijo($event)"
  *ngIf="cambio"
></app-navbar-inicio-avanzado-lateral>
