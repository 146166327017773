<div class="inicio" [class.iniciopequenyo]="!tabActivo">
  <div *ngIf="false" for="" class="capacidad">% Capacidad</div>
  <div class="arriba">
    <input
      type="text"
      class="nombre"
      placeholder="Nombre y apellidos del fiel ..."
      [(ngModel)]="nombreFiel"
    />
    <svg
      (click)="cambioActivo()"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 55.3 54.2"
      style="enable-background: new 0 0 55.3 54.2"
      xml:space="preserve"
    >
      <style type="text/css">
        .st0 {
          fill: #311c38;
        }
        .st1 {
          fill: none;
          stroke: #ffffff;
          stroke-width: 2;
          stroke-linecap: round;
        }
        .st2 {
          fill: #ffffff;
        }
        .st3 {
          fill: none;
          stroke: #ffffff;
        }
      </style>
      <path
        id="e"
        class="st0"
        d="M8.8,0h38c4.8,0,8.7,3.9,8.7,8.7v36.9c0,4.8-3.9,8.7-8.7,8.7h-38c-4.8,0-8.7-3.9-8.7-8.7V8.7
                C0.1,3.9,4,0,8.8,0z"
      />
      <g>
        <line id="g" class="st1" x1="13" y1="20.2" x2="42" y2="20.2" />
        <line id="h" class="st1" x1="13" y1="34" x2="42" y2="34" />
        <g id="i" transform="translate(21.138 -0.591)">
          <circle class="st2" cx="20.4" cy="20.8" r="3.5" />
          <circle class="st3" cx="20.4" cy="20.8" r="3" />
        </g>
        <g id="j" transform="translate(-1.263 11.694)">
          <circle class="st2" cx="15.2" cy="22.3" r="3.5" />
          <circle class="st3" cx="15.2" cy="22.3" r="3" />
        </g>
      </g>
    </svg>
    <input
      (click)="filterData()"
      type="submit"
      class="buttonsinimg"
      value="Buscar"
    /><br />
    <input
      type="submit"
      routerLink="/crear-partida"
      class="nueva"
      value="Crear nueva partida"
      *ngIf="!tabActivo"
    />
  </div>
  <div class="abajo" *ngIf="tabActivo">
    <div class="check">
      <span>
        <checkRadioBox
          [id]="'bautismo'"
          name="sacramento"
          (click)="setSacramento({ nombre: 'Bautizo', id: 1 })"
        ></checkRadioBox>
        <label for="bautismo">Bautismo</label>
      </span>
      <!-- <span>
                <checkRadioBox [id]="'comunion'" name="sacramento" (click)="setSacramento({nombre:'Comunión', id: 2})"></checkRadioBox>
                <label for="comunion">Comunión</label>
            </span> -->
      <span>
        <checkRadioBox
          [id]="'confirmacion'"
          name="sacramento"
          (click)="setSacramento({ nombre: 'Confirmación', id: 3 })"
        ></checkRadioBox>
        <label for="confirmacion">Confirmación</label>
      </span>
      <span>
        <checkRadioBox
          [id]="'matrimonio'"
          name="sacramento"
          (click)="setSacramento({ nombre: 'Matrimonio', id: 4 })"
        ></checkRadioBox>
        <label for="matrimonio">Matrimonio</label>
      </span>
      <span>
        <checkRadioBox
          [id]="'defuncion'"
          name="sacramento"
          (click)="setSacramento({ nombre: 'Defunción', id: 5 })"
        ></checkRadioBox>
        <label for="defuncion">Defunción</label>
      </span>
      <!-- <span class="nulidad">
                <checkRadioBox [id]="'nulidad'" name="sacramento" (click)="setSacramento({nombre:'Nulidad Matrimonial', id: 6})"></checkRadioBox>
                <label for="nulidad">Nulidad matrimonial</label>
            </span> -->
    </div>
    <div class="buscador">
      <label class="bold">De</label>
      <label class="bold">Hasta</label>
      <label class="bold">Parroquia</label>
      <input
        class="inputde"
        [(ngModel)]="fechaDesde"
        type="month"
        name=""
        id=""
      />
      <input
        class="inputhasta"
        [(ngModel)]="fechaHasta"
        type="month"
        name=""
        id=""
      />
      <ng-select
        class="fix_height"
        [items]="parroquias"
        [(ngModel)]="parroquiaFiltro"
        bindLabel="nombre"
        bindValue="id"
        [clearable]="false"
      ></ng-select>
      <input
        type="text"
        class="buscadores"
        [(ngModel)]="nombrePadre"
        placeholder="Nombre y apellidos del padre ..."
      />
      <input
        type="text"
        class="buscadores"
        [(ngModel)]="nombreMadre"
        placeholder="Nombre y apellidos de la madre ..."
      />
      <input
        type="text"
        class="buscadores"
        [(ngModel)]="nombrePadrino"
        placeholder="Nombre y apellidos de un padrino o testigo ..."
      />
      <input
        type="text"
        class="buscadores"
        [(ngModel)]="nombreParroco"
        placeholder="Párroco responsable del oficio ..."
      />
    </div>
  </div>
</div>
