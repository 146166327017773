import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { LoginService } from 'src/app/services/api/Login.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';

@Component({
  selector: 'app-lossPassword',
  templateUrl: './lossPassword.component.html',
  styleUrls: ['./lossPassword.component.css']
})
export class LossPasswordComponent extends UnsubscribesDestroy implements OnInit {

  email="";
  haveToken=false;
  token="";
  pass="";
  repass="";

  constructor(private login:LoginService,private notification:NotificacionesService, private loader:LoaderService, private activeRoute:ActivatedRoute, private router:Router) {
    super();
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      if(params["token"]!=null){
        this.haveToken=true;
        this.token=params["token"];
      }
    })
  }

  lostPassword(){
    this.loader.open();
    this.login.lossPassword(this.email);
    this.login.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this.loader.closeSlow();
      this.notification.showFeedBack("Email de recuperación enviado satisfactoriamente");
      this._unsubInd.next(" ");
    })
    this.login.getResultUpdateError().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this.loader.closeSlow();
      this.notification.showError(value.mensaje);
      this._unsubInd.next(" ");
    })
  }
  sendNewPass(){
    if(this.repass!=this.pass){
      this.notification.showError("Las contraseñas no coinciden");
      return;
    }
    this.loader.open();
    this.login.changePassword(this.pass,this.token);
    this.login.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this.loader.closeSlow();
      this.notification.showFeedBack("Contraseña cambiada exitosamente");
      this._unsubInd.next(" ");
      this.router.navigate(['/login']);
    })
    this.login.getResultUpdateError().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      if(value==null){return;}
      this.loader.closeSlow();
      this.notification.showError(value.mensaje);
      this._unsubInd.next(" ");
    })
  }

}
