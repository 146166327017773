<div class="main">
  <div class="panelizquierda">
    <span routerLink="/explorar">
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 32 32"
        style="enable-background: new 0 0 32 32"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #5d366b;
          }

          .st1 {
            fill: none;
            stroke: #ffffff;
            stroke-width: 2;
          }
        </style>
        <g id="a"></g>
        <g id="b">
          <g id="c">
            <g id="d" transform="translate(-364 -52)">
              <circle id="e" class="st0" cx="380" cy="68" r="16" />
              <path id="f" class="st1" d="M382.2,61.5l-6.5,6.5l6.5,6.5" />
            </g>
          </g>
        </g>
      </svg>
      <label for="">{{ titulo }}</label>
    </span>
    <div class="nombreContainer">
      <input
        type="text"
        [(ngModel)]="filtroNombreFiel"
        class="input"
        placeholder="Nombre y apellidos del fiel ..."
      />
    </div>
    <div class="filtrosGeneralContainer">
      <label for="">Filtros</label>
      <div class="fechasContainer">
        <div class="desdeContainer">
          <label for="">Desde</label>
          <input class="input" type="month" [(ngModel)]="fechaDesde" />
        </div>
        <div class="hastaContainer">
          <label for="">Hasta</label>
          <input class="input" type="month" [(ngModel)]="fechaHasta" />
        </div>
      </div>
      <div class="parroquiaContainer">
        <label for="">Parroquia</label>
        <ng-select
          class="secundaryTheme fix_height documentos"
          [items]="parroquias"
          [(ngModel)]="parroquiaElegida"
          (change)="changeParroquia()"
          bindLabel="nombre"
          bindValue="id"
          [clearable]="false"
        ></ng-select>
      </div>
      <div class="filtrosavanzadosContainer">
        <div>
          <label for="">Filtros Avanzados</label>
          <span (click)="showAdvancedFilters()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.414"
              height="8.621"
              viewBox="0 0 14.414 8.621"
            >
              <path
                id="Trazado_428"
                data-name="Trazado 428"
                d="M-9108.06,2156.32l-6.5,6.5,6.5,6.5"
                transform="translate(-2155.613 -9107.353) rotate(-90)"
                fill="none"
                stroke="#2e1d36"
                stroke-width="2"
              />
            </svg>
          </span>
        </div>
        <div class="camposContainer" *ngIf="advancedFiltersVisible">
          <div>
            <label for="">Padre</label>
            <input
              type="text"
              class="input"
              [(ngModel)]="nombrePadre"
              placeholder="Nombre del padre ..."
            />
          </div>
          <div>
            <label for="">Madre</label>
            <input
              type="text"
              class="input"
              [(ngModel)]="nombreMadre"
              placeholder="Nombre de la madre ..."
            />
          </div>
          <div>
            <label for="">Párroco responsable del oficio</label>
            <input
              type="text"
              class="input"
              [(ngModel)]="parroco"
              placeholder="Nombre o apellido del párroco ..."
            />
          </div>
          <div>
            <label for="">Testigos o Padrinos</label>
            <input
              type="text"
              class="input"
              [(ngModel)]="nombrePadrino1"
              placeholder="Nombre y apellido ..."
            />
            <input
              type="text"
              class="input"
              [(ngModel)]="nombrePadrino2"
              placeholder="Nombre y apellido ..."
            />
          </div>
        </div>
      </div>
      <div class="otroCamposContainer">
        <label for="">Navegar a otro archivo</label>
        <div class="opciones">
          <div
            *ngFor="let otroArchivo of otrosArchivos"
            (click)="
              active = otroArchivo.nombre;
              navigate(otroArchivo.nombre);
              seleccionarBtn(otroArchivo.id)
            "
            [class.active]="
              active == otroArchivo.nombre || titulo == otroArchivo.nombre
            "
          >
            {{ otroArchivo.nombre }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panelderecha">
    <app-grid-resultados
      (loader)="pararCarga()"
      [parroquia]="parroquiaElegida"
      [parroco]="parroco"
      [nombrePadrino1]="nombrePadrino1"
      [nombrePadrino2]="nombrePadrino2"
      [nombreFiel]="filtroNombreFiel"
      [fechaDesde]="fechaDesde"
      [fechaHasta]="fechaHasta"
      [nombrePadre]="nombrePadre"
      [nombreMadre]="nombreMadre"
    ></app-grid-resultados>
  </div>
</div>
