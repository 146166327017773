import { Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-navbar-anyadir-examinar',
  templateUrl: './navbar-anyadir-examinar.component.html',
  styleUrls: ['./navbar-anyadir-examinar.component.css']
})
export class NavbarAnyadirExaminarComponent implements OnInit {

  @Output()
  eventoHijo = new EventEmitter<boolean>();

  bool = false

  enviarPadre() {
    this.eventoHijo.emit(this.bool)
  }

  constructor() { }

  ngOnInit() {
  }
}
