<div class="main borradores">
    <span routerLink="/crear-partida">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
            <style type="text/css">
                .st0{fill:#5D366B;}
                .st1{fill:none;stroke:#FFFFFF;stroke-width:2;}
            </style>
            <g id="a">
            </g>
            <g id="b">
                <g id="c">
                    <g id="d" transform="translate(-364 -52)">
                        <circle id="e" class="st0" cx="380" cy="68" r="16"/>
                        <path id="f" class="st1" d="M382.2,61.5l-6.5,6.5l6.5,6.5"/>
                    </g>
                </g>
            </g>
        </svg>
        <label for="">Volver</label>
    </span>

    <label for="" class="titulo">Borradores guardados</label>
    <app-grid-resultados [padre]="'borradores'"></app-grid-resultados>
</div>
