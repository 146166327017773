import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/objects/Fecha';
import { Firma } from 'src/app/objects/Firma';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popUps/confirmPopup/confirmPopup.component';
import { PopupFileComponent } from 'src/app/popUps/popupFile/popupFile.component';
import { FirmaService } from 'src/app/services/api/firma.service';
import { PartidaService } from 'src/app/services/api/partida.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';
import { PopupService } from 'src/app/services/util/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-matrimonioMixto',
  templateUrl: './matrimonioMixto.component.html',
  styleUrls: ['./matrimonioMixto.component.css'],
})
export class MatrimonioMixtoComponent
  extends UnsubscribesDestroy
  implements OnInit
{
  pasoCreacionPartida = 0;
  openedPadre = false;
  openedMadre = false;
  generos = ['Mujer', 'Hombre'];
  @ViewChild('documentoImprimir') documentoImprimir!: ElementRef;
  firmas: Array<Firma> = [];
  urlImages = environment.urlImg;
  imgSelect =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  today = new Date();
  mesActual = '';
  objFecha: Fecha = new Fecha();
  days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  editar = false;
  dayName = this.days[new Date().getDay()];
  idFiel = -1;
  /*VALIDACIONES PASO 0*/
  nombreLaico: String | null = null;
  apellidoLaico: String | null = null;
  vecinoLaico: String | null = null;
  feligresParroquia: String | null = null;
  nombrePadre: String | null = null;
  apellidosPadre: String | null = null;
  nombreMadre: String | null = null;
  apellidosMadre: string | null = null;

  nombreLaicoVacio: boolean = false;
  apellidosLaicoVacio: boolean = false;
  vecinoLaicoVacio: boolean = false;
  feligresParroquiaVacio: boolean = false;
  nombrePadreVacio: boolean = false;
  apellidosPadreVacio: boolean = false;
  nombreMadreVacio: boolean = false;
  apellidosMadreVacio: boolean = false;

  paso0Valido: boolean = true;
  /*FIN VALIDACIONES PASO 0*/

  /*VALIDACiONES PASO 1*/
  sexo: string | null = null;
  nombreOtraReligion: string | null = null;
  apellidosOtraReligion: string | null = null;
  vecinoOtraReligion: string | null = null;
  religion: string | null = null;

  sexoVacio: boolean = false;
  nombreOtraReligionVacio: boolean = false;
  apellidosOtraReligionVacio: boolean = false;
  vecinoOtraReligionVacio: boolean = false;
  religionVacio: boolean = false;

  paso1Valido: boolean = true;
  /*FIN VALIDACIONES PASO 1*/

  /*VALIDACIONES PASO 2*/
  firmaSelect: number | null = null;
  parroco: string | null = null;

  firmaVacio: boolean = false;
  parrocoVacio: boolean = false;

  paso2Valido: boolean = true;
  /*FIN VALIDACIONES PASO 2*/

  parroquiaNombre: string = '';
  ubicacionParroquia: string = '';
  parroquiaId: number | null = null;
  constructor(
    private firmaSe: FirmaService,
    private notificaciones: NotificacionesService,
    private router: Router,
    private popupSE: PopupService,
    private partidaSE: PartidaService,
    private route: ActivatedRoute,
    private loader: LoaderService
  ) {
    super();
  }
  checkData(paso) {
    switch (paso) {
      case 0:
        if (this.nombreLaico == null) {
          this.nombreLaicoVacio = true;
          this.paso0Valido = false;
        } else {
          this.nombreLaicoVacio = false;
        }
        if (this.apellidoLaico == null) {
          this.apellidosLaicoVacio = true;
          this.paso0Valido = false;
        } else {
          this.apellidosLaicoVacio = false;
        }
        if (this.vecinoLaico == null) {
          this.vecinoLaicoVacio = true;
          this.paso0Valido = false;
        } else {
          this.vecinoLaicoVacio = false;
        }
        if (this.feligresParroquia == null) {
          this.feligresParroquiaVacio = true;
          this.paso0Valido = false;
        } else {
          this.feligresParroquiaVacio = false;
        }
        if (this.nombrePadre == null) {
          this.nombrePadreVacio = true;
          this.paso0Valido = false;
        } else {
          this.nombrePadreVacio = false;
        }
        if (this.apellidosPadre == null) {
          this.apellidosPadreVacio = true;
          this.paso0Valido = false;
        } else {
          this.apellidosPadreVacio = false;
        }
        if (this.nombreMadre == null) {
          this.nombreMadreVacio = true;
          this.paso0Valido = false;
        } else {
          this.nombreMadreVacio = false;
        }
        if (this.apellidosMadre == null) {
          this.apellidosMadreVacio = true;
          this.paso0Valido = false;
        } else {
          this.apellidosMadreVacio = false;
        }

        if (
          !this.nombreLaicoVacio &&
          !this.feligresParroquiaVacio &&
          !this.apellidosLaicoVacio &&
          !this.vecinoLaicoVacio &&
          !this.nombrePadreVacio &&
          !this.apellidosPadreVacio &&
          !this.nombreMadreVacio &&
          !this.apellidosMadreVacio
        ) {
          this.paso0Valido = true;
        }
        break;

      case 1:
        if (this.sexo == null) {
          this.sexoVacio = true;
          this.paso1Valido = false;
        } else {
          this.sexoVacio = false;
        }
        if (this.nombreOtraReligion == null) {
          this.apellidosOtraReligionVacio = true;
          this.paso1Valido = false;
        } else {
          this.apellidosOtraReligionVacio = false;
        }
        if (this.vecinoOtraReligion == null) {
          this.vecinoOtraReligionVacio = true;
          this.paso1Valido = false;
        } else {
          this.vecinoOtraReligionVacio = false;
        }
        if (this.religion == null) {
          this.religionVacio = true;
          this.paso1Valido = false;
        } else {
          this.religionVacio = false;
        }
        if (
          !this.sexoVacio &&
          !this.nombreOtraReligionVacio &&
          !this.apellidosOtraReligionVacio &&
          !this.vecinoOtraReligionVacio &&
          !this.religionVacio
        ) {
          this.paso1Valido = true;
        }
        break;
      case 2:
        if (this.firmaSelect == null) {
          this.firmaVacio = true;
          this.paso2Valido = false;
        } else {
          this.firmaVacio = false;

          this.firmas.forEach((element) => {
            if (element.id == this.firmaSelect) {
              this.parroquiaId = this.firmas.filter((e) => {
                return e.id == this.firmaSelect;
              })[0].parroquia['id'];
              this.parroquiaNombre = this.firmas.filter((e) => {
                return e.id == this.firmaSelect;
              })[0].parroquia['nombre'];
              this.ubicacionParroquia = this.firmas.filter((e) => {
                return e.id == this.firmaSelect;
              })[0].parroquia['ubicacion'];
              this.imgSelect =
                this.urlImages +
                this.firmas.filter((e) => {
                  return e.id == this.firmaSelect;
                })[0].url;
            }
          });
        }
        if (this.parroco == null) {
          this.parrocoVacio = true;
          this.paso2Valido = false;
        } else {
          this.parrocoVacio = false;
        }
        if (!this.parrocoVacio && !this.firmaVacio) {
          this.paso2Valido = true;
        }
        break;
    }
  }
  siguiente() {
    if (this.pasoCreacionPartida != 3) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida++;
    }
  }

  atras() {
    if (this.pasoCreacionPartida > 0) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida--;
    }
  }

  openPadre() {
    if (!this.openedPadre) {
      this.openedPadre = true;
    } else {
      this.openedPadre = false;
    }
  }
  openMadre() {
    if (!this.openedMadre) {
      this.openedMadre = true;
    } else {
      this.openedMadre = false;
    }
  }
  downloadAsPDF() {
    const pdfTable = this.documentoImprimir.nativeElement;
    var divContents = pdfTable.innerHTML;
    var a = window.open('', '', 'height=500, width=500')!;
    a.document.write(
      '<html> <style>body{padding:3em 2em}.abuelos{margin-top:1em;margin-bottom:1em;display:flex;flex-direction:column;}.firma{width:100% !important;display:flex !important;justify-content:center;align-items:center;flex-direction:column}.firma img{width:30%;margin-top:3em}header{border-bottom:solid 1px;padding-bottom:25px;font-size:15pt;display:flex;justify-content:space-between;position:relative;}.columnas{display:flex;gap:25px;}.columnas .col_izq{width:270px !important;}.columnas .col_der{width:100% !important;}.campo > *{display:block;text-align:center;width:100%;}.campo span{text-transform:uppercase;font-size:14pt;border-bottom:solid 1px;margin-bottom:5px;}.campo{margin-bottom:15px;}.feligres{margin-top:140px;}p.texto{text-align:justify;}u{display:block;font-weight:600;font-size:14pt;}u+p{font-size:13pt;}.columnas.separacion .col_izq{border-right:solid 2px;padding-right:15px;}</style>'
    );
    a.document.write('<body >');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.onload = function () {
      a.print();
    };
  }
  sendData(tipo) {
    var borrador;
    if (tipo == 'borrador') {
      borrador = true;
    } else {
      borrador = false;
    }
    var partida = {
      fecha: '',
      numero: -1,
      anyo: -1,
      hora: '',
      parroco: this.parroco,
      testigos: '',
      borrador: borrador,
      firma: this.firmaSelect,
      url: sessionStorage.getItem('documentoAdjunto'),
      sacramento: 4,
      parroquia: this.parroquiaId,
      fiel: {
        nombre: this.nombreLaico + ' ' + this.apellidoLaico,
        sexo: '',
        padrinos: '',
        padre: this.nombrePadre + ' ' + this.apellidosPadre,
        madre: this.nombreMadre + ' ' + this.apellidosMadre,
        lugarnacimientomadre: '',
        lugarnacimientopadre: '',
        abuelosmaternales: '',
        abuelospaternales: '',
      },
      extraData: JSON.stringify({
        vecinoLaico: this.vecinoLaico,
        feligresParroquia: this.feligresParroquia,
        sexoOtraReligion: this.sexo,
        nombreOtraReligion: this.nombreOtraReligion,
        apellidosOtraReligion: this.apellidosOtraReligion,
        vecinoOtraReligion: this.vecinoOtraReligion,
        religion: this.religion,
      }),
    };
    if (tipo == 'borrador') {
      this.popupSE.openPopup(ConfirmPopupComponent, {
        titulo: 'Guardar borrador',
        description: 'quieres guardar el borrador',
        accion: 'Guardar',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd2))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this._unsubInd2.next('');
          if (value.returnValue) {
            this.partidaSE.create(partida);
            this.partidaSE
              .getResultUpdate()
              .pipe(takeUntil(this._unsubInd))
              .subscribe((value) => {
                if (value == null) {
                  return;
                }
                this.notificaciones.showFeedBack(value.message);
                this.router.navigate(['/inicio']);
                this._unsubInd.next('');
              });
          } else {
            this.router.navigate(['/inicio']);
          }
        });
    } else if (!this.editar) {
      this.partidaSE.create(partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
                this.router.navigate(['/inicio']);
              } else {
                this.router.navigate(['/inicio']);
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    } else {
      partida.fiel['id'] = this.idFiel;
      console.log(partida);
      this.partidaSE.update(this.route.snapshot.params['id'], partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
                this.router.navigate(['/inicio']);
              } else {
                this.router.navigate(['/inicio']);
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    }
  }
  changeDocumentoPrevio() {
    this.popupSE.openPopup(PopupFileComponent, { action: 'Añadir' });
  }
  override ngOnDestroy() {
    sessionStorage.removeItem('documentoAdjunto');
  }
  ngOnInit() {
    if (this.route.snapshot.params['id'] != undefined) {
      this.partidaSE.getIndividual(this.route.snapshot.params['id']);
      this.loader.open();
      this.partidaSE
        .getResultIndividual()
        .pipe(takeUntil(this._unsubInd5))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.loader.closeSlow();
          var extraData = JSON.parse(value.extra_data);
          if (extraData !== null) {
            this.vecinoLaico = extraData.vecinoLaico;
            this.feligresParroquia = extraData.feligresParroquia;
            this.sexo = extraData.sexoOtraReligion;
            this.nombreOtraReligion = extraData.nombreOtraReligion;
            this.apellidosOtraReligion = extraData.apellidosOtraReligion;
            this.vecinoOtraReligion = extraData.vecinoOtraReligion;
            this.religion = extraData.religion;
          }

          console.log(value);
          if (value.fiel !== null) {
            this.nombreLaico = value.fiel.nombre.split(' ')[0];
            if (
              value.fiel.nombre.split(' ')[2] != undefined ||
              value.fiel.nombre.split(' ')[2] != null
            ) {
              this.apellidoLaico =
                value.fiel.nombre.split(' ')[1] +
                ' ' +
                value.fiel.nombre.split(' ')[2];
            } else {
              this.apellidoLaico = value.fiel.nombre.split(' ')[1];
            }
            this.nombrePadre = value.fiel.padre.split(' ')[0];
            if (
              value.fiel.padre.split(' ')[2] != undefined ||
              value.fiel.padre.split(' ')[2] != null
            ) {
              this.apellidosPadre =
                value.fiel.padre.split(' ')[1] +
                ' ' +
                value.fiel.padre.split(' ')[2];
            } else {
              this.apellidosPadre = value.fiel.padre.split(' ')[1];
            }

            this.nombreMadre = value.fiel.madre.split(' ')[0];
            if (
              value.fiel.padre.split(' ')[2] != undefined ||
              value.fiel.madre.split(' ')[2] != null
            ) {
              this.apellidosMadre =
                value.fiel.madre.split(' ')[1] +
                ' ' +
                value.fiel.madre.split(' ')[2];
            } else {
              this.apellidosMadre = value.fiel.madre.split(' ')[1];
            }
            this.idFiel = value.fiel.id;
          }
          this.parroco = value.parroco;
          if (value.firma !== null) {
            this.firmaSelect = value.firma.id;
          }
          this._unsubInd5.next('');
        });
    }
    this.objFecha.year = this.today.getFullYear();
    this.objFecha.mes = this.today.getMonth() + 1;
    this.objFecha.dia = this.today.getDate();
    this.mesActual = this.objFecha.mesNombre();
    this.firmaSe.getAll();
    this.firmaSe
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.firmas = value;
      });
  }
}
