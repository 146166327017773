<!--COPIAR-->
<div class="crearPartidaGeneralContainer">
  <div class="cancelarGuardarContainer">
    <span (click)="sendData('borrador')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8.621"
        height="14.414"
        viewBox="0 0 8.621 14.414"
      >
        <path
          id="Trazado_125"
          data-name="Trazado 125"
          d="M-9108.06,2156.32l-6.5,6.5,6.5,6.5"
          transform="translate(9115.974 -2155.613)"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
      </svg>
    </span>
    <label for="">Cancelar y guardar</label>
  </div>
  <div class="headerCrearPartidaContainer">
    <div>
      <div class="etiquetaPartida">
        <label for="">Confirmación</label>
      </div>
      <span *ngIf="!editar">Nueva partida</span>
      <span *ngIf="editar">Editar partida</span>
    </div>
    <div>
      <button
        type="buttton"
        class="modificarDocumento"
        (click)="changeDocumentoPrevio()"
      >
        Modificar documento adjunto
      </button>
    </div>
  </div>
  <div class="dataGeneralContainer">
    <div class="dataContainer">
      <div class="timelineContainer">
        <div>
          <span [class.active]="pasoCreacionPartida >= 0"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 1"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 2"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 3"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 4"></span>
        </div>
        <div>
          <span
            [class.active]="pasoCreacionPartida == 0"
            [class.error]="!paso0Valido"
            >Información confirmado</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 1"
            [class.error]="!paso1Valido"
            >Párroco y fecha</span
          >

          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 2"
            >Padrinos y padres </span
          >

          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 3"
            [class.error]="!paso3Valido"
            >Parrocos implicados y Firma</span
          >
          <div></div>
          <span [class.active]="pasoCreacionPartida == 4"
            >Revisar y finalizar</span
          >
        </div>
      </div>
      <div class="formContainer">
        <ng-container *ngIf="pasoCreacionPartida == 0">
          <div class="paso0Container">
            <div>
              <div>
                <label for="" [class.error]="numeroPartidaVacio"
                  >Nº de partida:
                </label>
                <input
                  type="number"
                  min="0"
                  (input)="
                    validateNegativeNumbers(numeroPartida, 'numeroPartida')
                  "
                  [class.error]="numeroPartidaVacio"
                  class="input"
                  [(ngModel)]="numeroPartida"
                />
              </div>
              <div>
                <label for="" [class.error]="libroPartidaVacio"
                  >Tomo:
                </label>
                <input
                  type="text"
                  min="0"
                  [class.error]="libroPartidaVacio"
                  class="input"
                  [(ngModel)]="libroPartida"
                />
              </div>

              <div>
                <label for="" [class.error]="folioPartidaVacio"
                  >Número de folio:
                </label>
                <input
                  type="number"
                  min="0"
                  (input)="
                    validateNegativeNumbers(folioPartida, 'folioPartida')
                  "
                  [class.error]="folioPartidaVacio"
                  class="input"
                  [(ngModel)]="folioPartida"
                />
              </div>
              <div>
                <label for="" [class.error]="anyoVacio">Año: </label>
                <input
                  type="number"
                  min="0"
                  (input)="validateNegativeNumbers(anyo, 'anyo')"
                  [class.error]="anyoVacio"
                  [(ngModel)]="anyo"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div>
                <label for="" [class.error]="nombreLaicoVacio"
                  >Nombre del laico:
                </label>
                <input
                  type="text"
                  class="input"
                  [class.error]="nombreLaicoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="nombreLaico"
                />
              </div>
              <div>
                <label for="" [class.error]="primerApellidoVacio"
                  >Primer apellido del Laico:
                </label>
                <input
                  type="text"
                  class="input"
                  [class.error]="primerApellidoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="primerApellido"
                />
              </div>
              <div>
                <label for="" [class.error]="segundoApellidoVacio"
                  >Segundo apellido del Laico:
                </label>
                <input
                  type="text"
                  class="input"
                  [class.error]="segundoApellidoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="segundoApellido"
                />
              </div>
            </div>
            <!-- <div> -->
              <!-- <div>
                <label for="" [class.error]="fechaNacimientoVacio"
                  >Fecha de nacimiento del laico:
                </label>
                <input
                  type="date"
                  class="input"
                  [class.error]="fechaNacimientoVacio"
                  [(ngModel)]="fechaNacimiento"
                />
              </div>
              <div class="campoEspecial">
                <label for="" [class.error]="lugarNacimientoVacio"
                  >Lugar de nacimiento del laico:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectángulo_3320"
                        data-name="Rectángulo 3320"
                        width="14"
                        height="16"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Grupo_1672"
                    data-name="Grupo 1672"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Trazado_765"
                      data-name="Trazado 765"
                      d="M13.25,7c0,4-6.25,8.25-6.25,8.25S.75,11,.75,7A6.308,6.308,0,0,1,7,.75,6.308,6.308,0,0,1,13.25,7Z"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <circle
                      id="Elipse_591"
                      data-name="Elipse 591"
                      cx="2.25"
                      cy="2.25"
                      r="2.25"
                      transform="translate(4.75 4.75)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <input
                  type="text"
                  class="input"
                  [class.error]="lugarNacimientoVacio"
                  [(ngModel)]="lugarNacimiento"
                />
              </div>
            </div> -->
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="parroquiaBautizadoVacio"
                  >Bautizado en la parroquia:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                >
                  <path
                    id="crucifix"
                    d="M12,4.75v5m0,0v9.5m0-9.5H7.75m4.25,0h4.25"
                    transform="translate(-7 -4)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>

                <input
                  type="text"
                  class="input"
                  [class.error]="parroquiaBautizadoVacio"
                  [(ngModel)]="parroquiaBautizado"
                />
              </div>
              <div class="campoEspecial">
                <label for="" [class.error]="lugarParroquiaVacio"
                  >Ciudad de la parroquia:
                </label>
                <input
                  type="text"
                  class="input"
                  [class.error]="lugarParroquiaVacio"
                  [(ngModel)]="lugarParroquia"
                />
              </div>
              <div class="">
                <label for="" [class.error]="firmaVacio">Parroquia de la celebracion: </label>
                <ng-select
                  [class.error]="parroquiaVacio"
                  class="secundaryTheme fix_height"
                  [placeholder]="'Selecciona una Parroquia'"
                  [items]="parroquias"
                  [(ngModel)]="parroquiaSelect"
                  [clearable]="false"
                  bindLabel="nombre"
                  bindValue="id"
                ></ng-select>
              </div>
              <!-- <div class="campoEspecial">
                <label for="" [class.error]="lugarParroquiaVacio"
                  >Direccion de la parroquia:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectángulo_3320"
                        data-name="Rectángulo 3320"
                        width="14"
                        height="16"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Grupo_1672"
                    data-name="Grupo 1672"
                    clip-path="url(#clip-path)"
                  >
                    <path
                      id="Trazado_765"
                      data-name="Trazado 765"
                      d="M13.25,7c0,4-6.25,8.25-6.25,8.25S.75,11,.75,7A6.308,6.308,0,0,1,7,.75,6.308,6.308,0,0,1,13.25,7Z"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <circle
                      id="Elipse_591"
                      data-name="Elipse 591"
                      cx="2.25"
                      cy="2.25"
                      r="2.25"
                      transform="translate(4.75 4.75)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <input
                  type="text"
                  class="input"
                  [class.error]="lugarParroquiaVacio"
                  [(ngModel)]="lugarParroquia"
                />
              </div> -->
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 1">
          <div class="paso0Container">
            <div>
              <div>
                <label for="" [class.error]="fechaCelebracionCeremoniaVacio"
                  >Fecha de celebración de ceremonia de confirmación:
                </label>
                <input
                  type="date"
                  [class.error]="fechaCelebracionCeremoniaVacio"
                  class="input"
                  [(ngModel)]="fechaCelebracionCeremonia"
                />
              </div>
              <div class="campoEspecial">
                <label for="" [class.error]="parrocoVacio"
                  >Sacerdote que ofició la misa:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  [class.error]="parrocoVacio"
                  type="text"
                  class="input"
                  [(ngModel)]="parroco"
                />
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 2">
          <div class="paso0Container">
            <div>
              <div>
                <label for=""
                  >Nombre del padrino:
                </label>
                <input
                  type="text"
                  class="input"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="nombrePadrino"
                />
              </div>
              <div>
                <label for=""
                  >Nombre de la madrina:
                </label>
                <input
                  type="text"
                  class="input"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="nombreMadrina"
                />
              </div>
              </div>
              <div>
              <div>
                <label for=""
                  >Nombre de la madre:
                </label>
                <input
                  type="text"
                  class="input"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="nombreMadre"
                />
              </div>
              <div>
                <label for=""
                  >Nombre del padre:
                </label>
                <input
                  type="text"
                  class="input"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="nombrePadre"
                />
              </div>
            </div>
          </div>
       
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 3">
          <div class="paso0Container">
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="infrafirmadoVacio"
                  >Infrafirmado:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  type="text"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="infrafirmadoVacio"
                  [(ngModel)]="infrafirmado"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="parrocoSacramentoVacio"
                  >Sacerdote que realizó el sacramento:
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  type="text"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [class.error]="parrocoSacramentoVacio"
                  [(ngModel)]="parrocoSacramento"
                  class="input"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="" [class.error]="firmaVacio">Nombre y firma del sacerdote encargado del oficio: </label>
                <ng-select
                  [class.error]="firmaVacio"
                  class="secundaryTheme fix_height"
                  [placeholder]="'Selecciona una firma'"
                  [items]="firmas"
                  [(ngModel)]="firmaSelect"
                  [clearable]="false"
                  bindLabel="nombre"
                  bindValue="id"
                ></ng-select>
              </div>
            </div>
            <div class="firma">
              <ng-container *ngFor="let image of firmas">
                <img
                  *ngIf="firmaSelect == image.id"
                  [src]="urlImages + image.url"
                />
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 4">
          <div class="documentoGeneradoContainer">
            <div class="documentoGenerado" #documentoImprimir>
              <header>
                <p
                  style="
                    text-align: center;
                    width: 100% !important;
                    font-size: 20pt !important;
                    margin-bottom: 0;
                  "
                >
                  CÉDULA DE CONFIRMACIÓN
                </p>
              </header>
              <p>Parroquía {{ parroquiaNombre }} de {{ ubicacionParroquia }}</p>
              <p>El infrafirmado D. {{ infrafirmado }}</p>
              <p [ngStyle]="{ 'font-size': '32px', 'font-weight': 'bold' }">
                CERTIFICO:
              </p>
              <p class="texto">
                 Que {{ nombreLaico }} {{ primerApellido }} {{segundoApellido}},<!-- nacido en
                {{ lugarNacimiento }} el
                {{ fechaNacimiento | date: "d/M/yyyy" }},--> bautizado en la 
                parroquia {{ parroquiaBautizado }} de {{lugarParroquia}}
              </p>
              <p class="texto">
                Ha recibido el <b>SACRAMENTO DE LA CONFIRMACIÓN</b> en esta
                parroquía el dia
                {{ fechaCelebracionCeremonia | date: "d/M/yyyy" }} de manos de
                {{ parrocoSacramento }} según consta en el Libro de confirmaciones de este
                Archivo.
              </p>
              
              <p>Fueron padres: <span *ngIf="nombrePadre">{{ nombrePadre }}</span><span *ngIf="!nombrePadre">_________</span> y <span *ngIf="nombreMadre">{{ nombreMadre }}</span> <span *ngIf="!nombreMadre">_________</span></p>
              <p>Fueron padrinos: <span *ngIf="nombrePadrino">{{ nombrePadrino }}</span><span *ngIf="!nombrePadrino">_________</span> y <span *ngIf="nombreMadrina">{{ nombreMadrina }}</span> <span *ngIf="!nombreMadrina">_________</span></p>
              
              <p>Tomo {{ libroPartida }} Folio {{ folioPartida }}</p>
              <br />
              <p class="texto">
                Y para que conste, {{parroco}} firma y sella el presente certificado en:
              </p>
              <p class="texto">
                {{ dayName }} , {{ hoy.dia }} de {{ hoy.mesNombre() }},
                {{ hoy.year }}
              </p>
              <div class="firma">
                <img [src]="imgSelect" alt="" />
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="buttonsContainer">
      <div>
        <button type="button" (click)="atras()">Atrás</button>
        <button
          type="button"
          (click)="siguiente()"
          *ngIf="pasoCreacionPartida != 4"
        >
          Siguiente
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 4 && !editar"
          [class.disabled]="!paso0Valido || !paso1Valido || !paso3Valido"
          (click)="sendData('nuevo')"
        >
          Crear partida
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 4 && editar"
          [class.disabled]="!paso0Valido || !paso1Valido || !paso3Valido"
          (click)="sendData('nuevo')"
        >
          Editar partida
        </button>
      </div>
    </div>
  </div>
</div>
<!-- FIN COPIAR-->
