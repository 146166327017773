import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { Fecha } from 'src/app/objects/Fecha';
import { Firma } from 'src/app/objects/Firma';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { ConfirmPopupComponent } from 'src/app/popUps/confirmPopup/confirmPopup.component';
import { PopupFileComponent } from 'src/app/popUps/popupFile/popupFile.component';
import { FirmaService } from 'src/app/services/api/firma.service';
import { PartidaService } from 'src/app/services/api/partida.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';
import { PopupService } from 'src/app/services/util/popup.service';
import { environment } from 'src/environments/environment';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';
import { Parroquia } from 'src/app/objects/Parroquia';

@Component({
  selector: 'app-matrimonioCatolico',
  templateUrl: './matrimonioCatolico.component.html',
  styleUrls: ['./matrimonioCatolico.component.css'],
})
export class MatrimonioCatolicoComponent
  extends UnsubscribesDestroy
  implements OnInit {
  @ViewChild('documentoImprimir') documentoImprimir!: ElementRef;

  estadosCiviles = ["Soltero", "Casado", "Viudo"];
  notasArray: String[] = [];
  estadosCivilesEsposa = ["Soltera", "Casada", "Viuda"];
  pasoCreacionPartida = 0;
  openedMarido = false;
  openedEsposa = false;
  openedPadresMarido = false;
  openedPadresEsposa = false;
  openedPadrino = false;
  openedMadrina = false;
  firmas: Array<Firma> = [];
  parroquias: Array<Parroquia> = [];
  juridiscciones: string[] = ["Propia", "Delegada"];
  urlImages = environment.urlImg;
  imgSelect =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  today = new Date();
  mesActual = '';
  objFecha: Fecha = new Fecha();
  days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  dayName = this.days[new Date().getDay()];
  /*VALIDADCIONES PASO 0*/
  numeroPartida: number | null = null;
  folioPartida: number | null = null;
  libroPartida: string | null = null;
  anyo: number | null = null;
  fechaCelebracion: any = null;
  // horaCelebracion: any = null;
  parroquiaSelect: number | null = null;

  numeroPartidaVacio: boolean = false;
  folioPartidaVacio: boolean = false;
  libroPartidaVacio: boolean = false;
  anyoVacio: boolean = false;
  fechaCelebracionVacio: boolean = false;
  // horaCelebracionVacio: boolean = false;
  parroquiaVacio: boolean = false;

  paso0Valido = true;
  /*FIN VALIDACIONES PASO 0*/

  /*VALIDADCIONES PASO 1*/
  nombreMarido: string | null = null;
  apellidosMarido: string | null = null;
  estadoCivil: string = "";
  nacimientoMarido: any = null;
  bautizoMarido: string | null = null;
  bautizoLugarMarido: string | null = null;
  libroMarido: string | null = null;
  folioMarido: string | null = null;
  bautizoLugarEsposa: string | null = null;
  libroEsposa: string | null = null;
  folioEsposa: string | null = null;

  nombreEsposa: string | null = null;
  apellidosEsposa: string | null = null;
  estadoCivilEsposa: string = "";
  fechaNacimientoEsposa: any = null;
  bautizoEsposa: string | null = null;

  nombreMaridoVacio: boolean = false;
  apellidosMaridoVacio: boolean = false;
  estadoCivilVacio: boolean = false;
  nacimientoMaridoVacio: boolean = false;
  bautizoMaridoVacio: boolean = false;
  bautizoLugarMaridoVacio: boolean = false;
  libroMaridoVacio: boolean = false;
  folioMaridoVacio: boolean = false;
  bautizoLugarEsposaVacio: boolean = false;
  libroEsposaVacio: boolean = false;
  folioEsposaVacio: boolean = false;

  nombreEsposaVacio: boolean = false;
  apellidosEsposaVacio: boolean = false;
  estadoCivilEsposaVacio: boolean = false;
  fechaNacimientoEsposaVacio: boolean = false;
  bautizoEsposaVacio: boolean = false;

  paso1Valido = true;
  /*FIN VALIDACIONES PASO 1*/

  /*VALIDACIONES PASO 2*/
  nombrePadreMarido: string | null = null;
  apellidosPadreMarido: string | null = null;
  nombreMadreMarido: string | null = null;
  apellidosMadreMarido: string | null = null;

  nombrePadreEsposa: string | null = null;
  apellidosPadreEsposa: string | null = null;
  nombreMadreEsposa: string | null = null;
  apellidosMadreEsposa: string | null = null;

  nombrePadreMaridoVacio: boolean = false;
  apellidosPadreMaridoVacio: boolean = false;
  nombreMadreMaridoVacio: boolean = false;
  apellidosMadreMaridoVacio: boolean = false;

  nombrePadreEsposaVacio: boolean = false;
  apellidosPadreEsposaVacio: boolean = false;
  nombreMadreEsposaVacio: boolean = false;
  apellidosMadreEsposaVacio: boolean = false;

  paso2Valido = true;
  /*FIN VALIDACIONES PASO 2*/

  /*VALIDACIONES PASO 3*/
  nombrePadrino: string | null = null;
  apellidosPadrino: string | null = null;
  vecinoPadrino: string | null = null;

  nombreMadrina: string | null = null;
  apellidosMadrina: string | null = null;
  vecinoMadrina: string | null = null;

  notas: string = '';

  nombrePadrinoVacio: boolean = false;
  apellidosPadrinoVacio: boolean = false;
  vecinoPadrinoVacio: boolean = false;

  nombreMadrinaVacio: boolean = false;
  apellidosMadrinaVacio: boolean = false;
  vecinoMadrinaVacio: boolean = false;

  paso3Valido = true;
  /*FIN VALIDACIONES PASO 3*/

  /*VALIDACIONES PASO 4*/
  firmaSelect: number | null = null;
  parroco: string | null = null;
  parrocoSacramento: string | null = null;
  infrafirmado: string | null = null;

  firmaVacio: boolean = false;
  parrocoVacio: boolean = false;
  parrocoSacramentoVacio: boolean = false;
  infrafirmadoVacio: boolean = false;

  paso4Valido: boolean = true;
  /*FIN VALIDACIONES PASO 4*/

  //VALIDACIONES PASO 5
  jurisdisccionSelect: string | null = null;

  jurisdiccion: string = ""

  jurisdiccionVacio: boolean = false;

  paso5Valido: boolean = true;
  //FIN VALIDACIONES PASO 5

  //FECHA SACRAMENTO A TEXTO
  fechaCelebracionDia: string = '';
  fechaCelebracionAnio: string = '';
  nacimientoMaridoDia: string = '';
  nacimientoMaridoAnio: string = '';
  nacimientoEsposaDia: string = '';
  nacimientoEsposaAnio: string = '';
  //FIN FECHA

  parroquiaNombre: string = '';
  ubicacionParroquia: string = '';
  parroquiaId: number | null = null;
  editar = false;
  idFiel = -1;
  constructor(
    private firmaSe: FirmaService,
    private notificaciones: NotificacionesService,
    private router: Router,
    private popupSE: PopupService,
    private partidaSE: PartidaService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private parroquiaSE: ParroquiaService
  ) {
    super();
  }
  trocearFecha(fecha: any) {
    console.log(fecha)
    this.nacimientoMaridoAnio = fecha.split('T')[0]
    this.nacimientoMaridoDia = this.nacimientoMaridoAnio.split('-')[2]
    this.nacimientoMaridoAnio = this.nacimientoMaridoAnio.split('-')[0]
    this.nacimientoMaridoAnio = this.convertirAnioATexto(this.nacimientoMaridoAnio);
    this.nacimientoMaridoDia = this.convertirDiaATexto(this.nacimientoMaridoDia)
  }
  trocearFechaNacimientoMarido(fecha: any) {
    console.log(fecha)
    this.nacimientoEsposaAnio = fecha.split('T')[0]
    this.nacimientoEsposaDia = this.nacimientoEsposaAnio.split('-')[2]
    this.nacimientoEsposaAnio = this.nacimientoEsposaAnio.split('-')[0]
    this.nacimientoEsposaAnio = this.convertirAnioATexto(this.nacimientoEsposaAnio);
    this.nacimientoEsposaDia = this.convertirDiaATexto(this.nacimientoEsposaDia)
  }
  trocearFechaNacimientoEsposa(fecha: any) {
    console.log(fecha)
    this.fechaCelebracionAnio = fecha.split('T')[0]
    this.fechaCelebracionDia = this.fechaCelebracionAnio.split('-')[2]
    this.fechaCelebracionAnio = this.fechaCelebracionAnio.split('-')[0]
    this.fechaCelebracionAnio = this.convertirAnioATexto(this.fechaCelebracionAnio);
    this.fechaCelebracionDia = this.convertirDiaATexto(this.fechaCelebracionDia)
  }
  convertirAnioATexto(numero: string): string {
    const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const decenas = ['', 'diez', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const especiales = ['', 'diez', 'once', 'doce', 'trece', 'catorce', 'quince'];
    let anioTexto = '';
    let anioMil = '';
    let anioCent = '';
    let anioDec = '';
    let anioUni = '';

    if (parseInt(numero[0]) == 1) {
      anioMil = "mil "
    } else {
      anioMil = unidades[parseInt(numero[0])] + " mil "
    }

    if(parseInt(numero[1])!=0){
      if (parseInt(numero[1]) == 1) {
        anioCent = "ciento "
      } else if (parseInt(numero[1]) == 5) {
        anioCent = "quinientos "
      } else if (parseInt(numero[1]) == 7) {
        anioCent = "setecientos "
      } else {
        anioCent = unidades[parseInt(numero[1])] + "cientos "
      }
    }

    if (parseInt(numero.slice(-2)) < 16) {
      anioDec = especiales[parseInt(numero[1])]
    } else if (parseInt(numero[2]) == 1) {
      anioDec = "dieci"
      anioUni = unidades[parseInt(numero[3])]
    } else if (parseInt(numero[2]) == 2) {
      anioDec = "venti"
      anioUni = unidades[parseInt(numero[3])]
    } else {
      anioDec = decenas[parseInt(numero[2])]
      if (parseInt(numero[3]) != 0) {

        anioUni = " y " + unidades[parseInt(numero[3])]

      } else {
        anioUni = unidades[parseInt(numero[3])]
      }
    }

    anioTexto = anioMil + anioCent + anioDec + anioUni

    return anioTexto;
  }
  convertirDiaATexto(numero: string): string {
    const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const especiales = { '10': 'diez', '11': 'once', '12': 'doce', '13': 'trece', '14': 'catorce', '15': 'quince', '20': 'veinte', '30': 'treinta', '31': 'treinta y uno'};
    let diaTexto = '';
    if (numero.startsWith("0")) { // si el número tiene un cero al inicio, omitirlo
      numero = numero.split("0")[1]
      console.log(numero)
    }
    if (numero.length < 2) {
      diaTexto = unidades[parseInt(numero)]
    } else if (numero in especiales) { // números especiales del 10 al 15
      diaTexto = especiales[numero];
    } else if (parseInt(numero) < 20) { // números del 16 al 19
      diaTexto = 'dieci' + unidades[parseInt(numero) % 10];
    } else if (parseInt(numero) < 30) { // números del 20 al 29
      diaTexto = 'veinti' + unidades[parseInt(numero) % 10];
    } else if (parseInt(numero) % 10 == 0) { // múltiplos de 10
      diaTexto = unidades[parseInt(numero) / 10] + 'enta';
    } else { // otros números
      diaTexto = unidades[Math.floor(parseInt(numero) / 10)] + 'enta y ' + unidades[parseInt(numero) % 10];
    }
    return diaTexto;
  }
  splitNotas() {
    this.notasArray = this.notas.split("\n");
  }
  siguiente() {
    if (this.pasoCreacionPartida != 6) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida++;
    }
  }

  atras() {
    if (this.pasoCreacionPartida > 0) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida--;
    }
  }
  openMarido() {
    if (!this.openedMarido) {
      this.openedMarido = true;
    } else {
      this.openedMarido = false;
    }
  }
  openEsposa() {
    if (!this.openedEsposa) {
      this.openedEsposa = true;
    } else {
      this.openedEsposa = false;
    }
  }
  openPadresMarido() {
    if (!this.openedPadresMarido) {
      this.openedPadresMarido = true;
    } else {
      this.openedPadresMarido = false;
    }
  }
  openPadresEsposa() {
    if (!this.openedPadresEsposa) {
      this.openedPadresEsposa = true;
    } else {
      this.openedPadresEsposa = false;
    }
  }
  openPadrino() {
    if (!this.openedPadrino) {
      this.openedPadrino = true;
    } else {
      this.openedPadrino = false;
    }
  }
  openMadrina() {
    if (!this.openedMadrina) {
      this.openedMadrina = true;
    } else {
      this.openedMadrina = false;
    }
  }
  downloadAsPDF() {
    const pdfTable = this.documentoImprimir.nativeElement;
    var divContents = pdfTable.innerHTML;
    var a = window.open('', '', 'height=500, width=500')!;
    a.document.write(
      '<html> <style>body{padding:3em 2em}.abuelos{margin-top:1em;margin-bottom:1em;display:flex;flex-direction:column;}.firma{width:100% !important;display:flex !important;justify-content:center;align-items:center;flex-direction:column}.firma img{width:30%;margin-top:3em}header{border-bottom:solid 1px;padding-bottom:25px;font-size:15pt;display:flex;justify-content:space-between;position:relative;}.columnas{display:flex;gap:25px;}.columnas .col_izq{width:270px !important;}.columnas .col_der{width:100% !important;}.campo > *{display:block;text-align:center;width:100%;}.campo span{text-transform:uppercase;font-size:14pt;border-bottom:solid 1px;margin-bottom:5px;}.campo{margin-bottom:15px;}.feligres{margin-top:140px;height:auto!important;margin-bottom:50px;}p.texto{text-align:justify;}u{display:block;font-weight:600;font-size:14pt;}u+p{font-size:13pt;}.columnas.separacion .col_izq{border-right:solid 2px;padding-right:15px;}.notas>p{word-break: break-word;}</style>'
    );
    a.document.write('<body >');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.onload = function () {
      a.print();
    };
  }

  checkData(paso) {
    switch (paso) {
      case 0:
        if (this.numeroPartida == null) {
          this.numeroPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.numeroPartidaVacio = false;
        }
        if (this.folioPartida == null) {
          this.folioPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.folioPartidaVacio = false;
        }
        if (this.libroPartida == null) {
          this.libroPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.libroPartidaVacio = false;
        }
        if (this.anyo == null) {
          this.anyoVacio = true;
          this.paso0Valido = false;
        } else {
          this.anyoVacio = false;
        }
        if (this.fechaCelebracion == null) {
          this.fechaCelebracionVacio = true;
          this.paso0Valido = false;
        } else {
          this.fechaCelebracionVacio = false;
        }
        // if (this.horaCelebracion == null) {
        //   this.horaCelebracionVacio = true;
        //   this.paso0Valido = false;
        // } else {
        //   this.horaCelebracionVacio = false;
        // }
        if (this.parroquiaSelect == null) {
          this.parroquiaVacio = true;
          this.paso0Valido = false;

        } else {
          this.parroquiaVacio = false;

          this.parroquias.forEach((element) => {
            if (element.id == this.parroquiaSelect) {
              this.parroquiaId = element['id'];
              this.parroquiaNombre = element['nombre'];
              this.ubicacionParroquia = element['ubicacion'];
            }
          });
        }
        if (
          !this.numeroPartidaVacio &&
          !this.folioPartidaVacio &&
          !this.libroPartidaVacio &&
          !this.parroquiaVacio &&
          !this.anyoVacio &&
          !this.fechaCelebracionVacio// &&
          // !this.horaCelebracionVacio
        ) {
          this.paso0Valido = true;
        }
        break;

      case 1:
        if (this.nombreMarido == null) {
          this.nombreMaridoVacio = true;
          this.paso1Valido = false;
        } else {
          this.nombreMaridoVacio = false;
        }
        if (this.apellidosMarido == null) {
          this.apellidosMaridoVacio = true;
          this.paso1Valido = false;
        } else {
          this.apellidosMaridoVacio = false;
        }
        if (this.estadoCivil == "") {
          this.estadoCivilVacio = true;
          this.paso1Valido = false;
        } else {
          this.estadoCivilVacio = false;
        }
        if (this.nacimientoMarido == null) {
          this.nacimientoMaridoVacio = true;
          this.paso1Valido = false;
        } else {
          this.nacimientoMaridoVacio = false;
        }
        if (this.bautizoMarido == null) {
          this.bautizoMaridoVacio = true;
          this.paso1Valido = false;
        } else {
          this.bautizoMaridoVacio = false;
        }

        if (this.bautizoLugarMarido == null) {
          this.bautizoLugarMaridoVacio = true;
          this.paso1Valido = false;
        } else {
          this.bautizoLugarMaridoVacio = false;
        }
        if (this.libroMarido == null) {
          this.libroMaridoVacio = true;
          this.paso1Valido = false;
        } else {
          this.libroMaridoVacio = false;
        }
        if (this.folioMarido == null) {
          this.folioMaridoVacio = true;
          this.paso1Valido = false;
        } else {
          this.folioMaridoVacio = false;
        }

        if (this.nombreEsposa == null) {
          this.nombreEsposaVacio = true;
          this.paso1Valido = false;
        } else {
          this.nombreEsposaVacio = false;
        }
        if (this.apellidosEsposa == null) {
          this.apellidosEsposaVacio = true;
          this.paso1Valido = false;
        } else {
          this.apellidosEsposaVacio = false;
        }
        if (this.estadoCivilEsposa == "") {
          this.estadoCivilEsposaVacio = true;
          this.paso1Valido = false;
        } else {
          this.estadoCivilEsposaVacio = false;
        }
        if (this.fechaNacimientoEsposa == null) {
          this.fechaNacimientoEsposaVacio = true;
          this.paso1Valido = false;
        } else {
          this.fechaNacimientoEsposaVacio = false;
        }
        if (this.bautizoEsposa == null) {
          this.bautizoEsposaVacio = true;
          this.paso1Valido = false;
        } else {
          this.bautizoEsposaVacio = false;
        }

        if (this.bautizoLugarEsposa == null) {
          this.bautizoLugarEsposaVacio = true;
          this.paso1Valido = false;
        } else {
          this.bautizoLugarEsposaVacio = false;
        }
        if (this.libroEsposa == null) {
          this.libroEsposaVacio = true;
          this.paso1Valido = false;
        } else {
          this.libroEsposaVacio = false;
        }
        if (this.folioEsposa == null) {
          this.folioEsposaVacio = true;
          this.paso1Valido = false;
        } else {
          this.folioEsposaVacio = false;
        }

        if (
          !this.nombreMaridoVacio &&
          !this.apellidosMaridoVacio &&
          !this.estadoCivilVacio &&
          !this.nacimientoMaridoVacio &&
          !this.bautizoMaridoVacio &&
          !this.bautizoLugarMaridoVacio &&
          !this.libroMaridoVacio &&
          !this.folioMaridoVacio &&
          !this.nombreEsposaVacio &&
          !this.apellidosEsposaVacio &&
          !this.estadoCivilEsposaVacio &&
          !this.fechaNacimientoEsposaVacio &&
          !this.bautizoLugarEsposaVacio &&
          !this.libroEsposaVacio &&
          !this.folioEsposaVacio &&
          !this.bautizoEsposaVacio
        ) {
          this.paso1Valido = true;
        }
        break;
      case 2:
        if (this.nombrePadreMarido == null) {
          this.nombrePadreMaridoVacio = true;
          this.paso2Valido = false;
        } else {
          this.nombrePadreMaridoVacio = false;
        }
        /*if (this.apellidosPadreMarido == null) {
          this.apellidosPadreMaridoVacio = true;
          this.paso2Valido = false;
        } else {
          this.apellidosPadreMaridoVacio = false;
        }*/
        if (this.nombreMadreMarido == null) {
          this.nombreMadreMaridoVacio = true;
          this.paso2Valido = false;
        } else {
          this.nombreMadreMaridoVacio = false;
        }
        /*if (this.apellidosMadreMarido == null) {
          this.apellidosMadreMaridoVacio = true;
          this.paso2Valido = false;
        } else {
          this.apellidosMadreMaridoVacio = false;
        }*/

        if (this.nombrePadreEsposa == null) {
          this.nombrePadreEsposaVacio = true;
          this.paso2Valido = false;
        } else {
          this.nombrePadreEsposaVacio = false;
        }
        /*if (this.apellidosPadreEsposa == null) {
          this.apellidosPadreEsposaVacio = true;
          this.paso2Valido = false;
        } else {
          this.apellidosPadreEsposaVacio = false;
        }*/
        if (this.nombreMadreEsposa == null) {
          this.nombreMadreEsposaVacio = true;
          this.paso2Valido = false;
        } else {
          this.nombreMadreEsposaVacio = false;
        }
        /*if (this.apellidosMadreEsposa == null) {
          this.apellidosMadreEsposaVacio = true;
          this.paso2Valido = false;
        } else {
          this.apellidosMadreEsposaVacio = false;
        }*/
        if (
          !this.nombrePadreMaridoVacio &&
          !this.nombreMadreMaridoVacio &&
          !this.nombrePadreEsposaVacio &&
          !this.nombreMadreEsposaVacio
        ) {
          this.paso2Valido = true;
        }
        break;
      case 3:
        if (this.nombrePadrino == null) {
          this.nombrePadrinoVacio = true;
          this.paso3Valido = false;
        } else {
          this.nombrePadrinoVacio = false;
        }
        if (this.apellidosPadrino == null) {
          this.apellidosPadrinoVacio = true;
          this.paso3Valido = false;
        } else {
          this.apellidosPadrinoVacio = false;
        }
        if (this.vecinoPadrino == null) {
          this.vecinoPadrinoVacio = true;
          this.paso3Valido = false;
        } else {
          this.vecinoPadrinoVacio = false;
        }
        if (this.nombreMadrina == null) {
          this.nombreMadrinaVacio = true;
          this.paso3Valido = false;
        } else {
          this.nombreMadrinaVacio = false;
        }
        if (this.apellidosMadrina == null) {
          this.apellidosMadrinaVacio = true;
          this.paso3Valido = false;
        } else {
          this.apellidosMadrinaVacio = false;
        }
        if (this.vecinoMadrina == null) {
          this.vecinoMadrinaVacio = true;
          this.paso3Valido = false;
        } else {
          this.vecinoMadrinaVacio = false;
        }
        if (
          !this.nombrePadrinoVacio &&
          !this.apellidosPadrinoVacio &&
          !this.vecinoPadrinoVacio &&
          !this.nombreMadrinaVacio &&
          !this.apellidosMadrinaVacio &&
          !this.vecinoMadrinaVacio
        ) {
          this.paso3Valido = true;
        }
        break;

      case 4:
        if (this.firmaSelect == null) {
          this.firmaVacio = true;
          this.paso4Valido = false;
        } else {
          this.firmaVacio = false;

          this.firmas.forEach((element) => {
            if (element.id == this.firmaSelect) {
              this.parroco = element.nombre;
              // this.parroquiaId = this.firmas.filter((e) => {
              //   return e.id == this.firmaSelect;
              // })[0].parroquia['id'];
              // this.parroquiaNombre = this.firmas.filter((e) => {
              //   return e.id == this.firmaSelect;
              // })[0].parroquia['nombre'];
              // this.ubicacionParroquia = this.firmas.filter((e) => {
              //   return e.id == this.firmaSelect;
              // })[0].parroquia['ubicacion'];
              this.imgSelect =
                this.urlImages +
                this.firmas.filter((e) => {
                  return e.id == this.firmaSelect;
                })[0].url;
            }
          });
        }
        if (this.parroco == null) {
          this.parrocoVacio = true;
          this.paso4Valido = false;
        } else {
          this.parrocoVacio = false;
        }
        if (this.parrocoSacramento == null) {
          this.parrocoSacramentoVacio = true;
          this.paso4Valido = false;
        } else {
          this.parrocoSacramentoVacio = false;
        }
        if (this.infrafirmado == null) {
          this.infrafirmadoVacio = true;
          this.paso4Valido = false;
        } else {
          this.infrafirmadoVacio = false;
        }
        if (!this.parrocoVacio && !this.firmaVacio && !this.infrafirmadoVacio && !this.parrocoSacramentoVacio) {
          this.paso4Valido = true;
        }
        break;
      case 5:
        if (this.jurisdisccionSelect == null) {
          this.jurisdiccionVacio = true;
          this.paso5Valido = false;
        } else {
          this.jurisdiccion = this.jurisdisccionSelect.toLowerCase();
          this.jurisdiccionVacio = false;
          this.paso5Valido = true;
        }
    }
  }
  sendData(tipo) {
    var borrador;
    if (tipo == 'borrador') {
      borrador = true;
    } else {
      borrador = false;
    }
    var partida = {
      fecha: this.fechaCelebracion,
      numero: this.numeroPartida,
      folio: this.folioPartida, //
      libro: this.libroPartida, //
      anyo: this.anyo,
      // hora: this.horaCelebracion,
      parroco: this.infrafirmado + ',' + this.parrocoSacramento + ',' + this.parroco,
      testigos:
        this.nombrePadrino +
        ' ' +
        this.apellidosPadrino +
        ',' +
        this.nombreMadrina +
        ' ' +
        this.apellidosMadrina,
      borrador: borrador,
      firma: this.firmaSelect,
      url: sessionStorage.getItem('documentoAdjunto'),
      sacramento: 4,
      parroquia: this.parroquiaId,
      fiel: {
        nombre:
          this.nombreMarido +
          ' ' +
          this.apellidosMarido +
          ',' +
          this.nombreEsposa +
          ' ' +
          this.apellidosEsposa,
        sexo: 'Masculino',
        padrinos:
          this.nombrePadrino +
          ' ' +
          this.apellidosPadrino +
          ',' +
          this.nombreMadrina +
          ' ' +
          this.apellidosMadrina,
        padre:
          this.nombrePadreMarido +
          //' ' +
          //this.apellidosPadreMarido +
          ',' +
          this.nombrePadreEsposa //+
        //' ' +
        // this.apellidosPadreEsposa,
        ,
        madre:
          this.nombreMadreMarido +
          //' ' +
          //this.apellidosMadreMarido +
          ',' +
          this.nombreMadreEsposa //+
        //' ' +
        //this.apellidosMadreEsposa,
        ,
        lugarnacimientomadre: '',
        lugarnacimientopadre: '',
        abuelosmaternales: '',
        abuelospaternales: '',
        apellido1: '',
        apellido2: '',
        direccion: ''
      },
      extraData: JSON.stringify({
        estadoCivilMarido: this.estadoCivil,
        fechaNacimient: this.nacimientoMarido,
        bautizadoMarido: this.bautizoMarido,
        bautizoLugarMarido: this.bautizoLugarMarido,
        libroMarido: this.libroMarido,
        folioMarido: this.folioMarido,
        estadoCivilEsposa: this.estadoCivilEsposa,
        fechaNacimientoEsposa: this.fechaNacimientoEsposa,
        bautizoEsposa: this.bautizoEsposa,
        bautizoLugarEsposa: this.bautizoLugarEsposa,
        libroEsposa: this.libroEsposa,
        folioEsposa: this.folioEsposa,
        vecinoPadrino: this.vecinoPadrino,
        vecinoMadrina: this.vecinoMadrina,
        notas: this.notas,
        jurisdiccion: this.jurisdisccionSelect
      }),
    };
    if (tipo == 'borrador') {
      this.popupSE.openPopup(ConfirmPopupComponent, {
        titulo: 'Guardar borrador',
        description: 'quieres guardar el borrador',
        accion: 'Guardar',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd2))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this._unsubInd2.next('');
          if (value.returnValue) {
            this.partidaSE.create(partida);
            this.partidaSE
              .getResultUpdate()
              .pipe(takeUntil(this._unsubInd))
              .subscribe((value) => {
                if (value == null) {
                  return;
                }
                this.notificaciones.showFeedBack(value.message);
                this.router.navigate(['/inicio']);
                this._unsubInd.next('');
              });
          } else {
            this.router.navigate(['/inicio']);
          }
        });
    } else if (!this.editar) {
      this.partidaSE.create(partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
                this.router.navigate(['/inicio']);
              } else {
                this.router.navigate(['/inicio']);
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    } else {
      partida.fiel['id'] = this.idFiel;
      console.log(partida);
      this.partidaSE.update(this.route.snapshot.params['id'], partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
                this.router.navigate(['/inicio']);
              } else {
                this.router.navigate(['/inicio']);
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    }
  }
  validateNegativeNumbers(number, tipo) {
    console.log(number);
    switch (tipo) {
      case 'numeroPartida':
        if (number != null && number < 0) {
          this.numeroPartida = 0;
        }
        break;
      case 'folioPartida':
        if (number != null && number < 0) {
          this.folioPartida = 0;
        }
        break;

      case 'anyo':
        if (number != null && number < 0) {
          this.anyo = 0;
        }
        break;
    }
  }

  changeDocumentoPrevio() {
    this.popupSE.openPopup(PopupFileComponent, { action: 'Añadir' });
  }
  override ngOnDestroy() {
    sessionStorage.removeItem('documentoAdjunto');
  }
  hoy: Fecha = new Fecha();
  ngOnInit() {
    if (this.route.snapshot.params['id'] != undefined) {
      this.partidaSE.getIndividual(this.route.snapshot.params['id']);
      this.loader.open();
      this.partidaSE
        .getResultIndividual()
        .pipe(takeUntil(this._unsubInd5))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.loader.closeSlow();
          var extraData = JSON.parse(value.extra_data);
          console.log(value);
          this.editar = true;
          this.numeroPartida = value.numero;
          this.libroPartida = value.libro;
          this.folioPartida = value.folio;
          this.anyo = value.anyo;
          if (value.fecha !== null) {
            this.fechaCelebracion = value.fecha.split('T')[0];
          }
          // this.horaCelebracion = value.hora;

          if (value.parroquia !== null) {
            this.parroquiaSelect = value.parroquia.id;
          }

          if (value.fiel !== null) {
            this.nombreMarido = value.fiel.nombre.split(',')[0].split(' ')[0];
            if (value.fiel.nombre.split(',')[0].split(' ')[2] != null) {
              this.apellidosMarido =
                value.fiel.nombre.split(',')[0].split(' ')[1] +
                ' ' +
                value.fiel.nombre.split(',')[0].split(' ')[2];
            } else {
              this.apellidosMarido = value.fiel.nombre
                .split(',')[0]
                .split(' ')[1];
            }

            this.nombreEsposa = value.fiel.nombre.split(',')[1].split(' ')[0];
            console.log(value.fiel.nombre.split(',')[1].split(' ')[0]);
            if (value.fiel.nombre.split(',')[1].split(' ')[2] != null) {
              this.apellidosEsposa =
                value.fiel.nombre.split(',')[1].split(' ')[1] +
                ' ' +
                value.fiel.nombre.split(',')[1].split(' ')[2];
            } else {
              this.apellidosEsposa = value.fiel.nombre
                .split(',')[1]
                .split(' ')[1];
            }
            this.nombrePadreMarido = value.fiel.padre
              .split(',')[0]
              .split(' ')[0];
            if (value.fiel.padre.split(',')[0].split(' ')[2] != null) {
              this.apellidosPadreMarido =
                value.fiel.padre.split(',')[0].split(' ')[1] +
                ' ' +
                value.fiel.padre.split(',')[0].split(' ')[2];
            } else {
              this.apellidosPadreMarido = value.fiel.padre
                .split(',')[0]
                .split(' ')[1];
            }
            this.nombreMadreMarido = value.fiel.madre
              .split(',')[0]
              .split(' ')[0];
            if (value.fiel.madre.split(',')[0].split(' ')[2] != null) {
              this.apellidosMadreMarido =
                value.fiel.madre.split(',')[0].split(' ')[1] +
                ' ' +
                value.fiel.madre.split(',')[0].split(' ')[2];
            } else {
              this.apellidosMadreMarido = value.fiel.madre
                .split(',')[0]
                .split(' ')[1];
            }

            this.nombrePadreEsposa = value.fiel.padre
              .split(',')[1]
              .split(' ')[0];
            if (value.fiel.padre.split(',')[1].split(' ')[2] != null) {
              this.apellidosPadreEsposa =
                value.fiel.padre.split(',')[1].split(' ')[1] +
                ' ' +
                value.fiel.padre.split(',')[1].split(' ')[2];
            } else {
              this.apellidosPadreEsposa = value.fiel.padre
                .split(',')[1]
                .split(' ')[1];
            }

            this.nombreMadreEsposa = value.fiel.madre
              .split(',')[1]
              .split(' ')[0];
            if (value.fiel.madre.split(',')[1].split(' ')[2] != null) {
              this.apellidosMadreEsposa =
                value.fiel.madre.split(',')[1].split(' ')[1] +
                ' ' +
                value.fiel.madre.split(',')[1].split(' ')[2];
            } else {
              this.apellidosMadreEsposa = value.fiel.madre
                .split(',')[1]
                .split(' ')[1];
            }
            this.idFiel = value.fiel.id;
          }

          if (value.extraData !== null) {
            this.estadoCivilEsposa = extraData.estadoCivilEsposa;
            this.estadoCivil = extraData.estadoCivilMarido;
            this.bautizoEsposa = extraData.bautizoEsposa;
            this.bautizoMarido = extraData.bautizadoMarido;
            this.bautizoLugarMarido = extraData.bautizoLugarMarido;
            this.libroMarido = extraData.libroMarido;
            this.folioMarido = extraData.folioMarido;
            this.bautizoLugarEsposa = extraData.bautizoLugarEsposa;
            this.libroEsposa = extraData.libroEsposa;
            this.folioEsposa = extraData.folioEsposa;
            this.fechaNacimientoEsposa = extraData.fechaNacimientoEsposa;
            this.nacimientoMarido = extraData.fechaNacimient;
            this.vecinoPadrino = extraData.vecinoPadrino;
            this.vecinoMadrina = extraData.vecinoMadrina;
            this.jurisdisccionSelect = extraData.jurisdiccion;
          }

          if (value.testigos !== null) {
            this.nombrePadrino = value.testigos.split(',')[0].split(' ')[0];
            if (value.testigos.split(',')[0].split(' ')[2] != null) {
              this.apellidosPadrino =
                value.testigos.split(',')[0].split(' ')[1] +
                ' ' +
                value.testigos.split(',')[0].split(' ')[2];
            } else {
              this.apellidosPadrino = value.testigos
                .split(',')[0]
                .split(' ')[1];
            }

            this.nombreMadrina = value.testigos.split(',')[1].split(' ')[0];
            if (value.testigos.split(',')[1].split(' ')[2] != null) {
              this.apellidosMadrina =
                value.testigos.split(',')[1].split(' ')[1] +
                ' ' +
                value.testigos.split(',')[1].split(' ')[2];
            } else {
              this.apellidosMadrina = value.testigos
                .split(',')[1]
                .split(' ')[1];
            }
          }
          this.infrafirmado = value.parroco.split(',')[0];
          this.parrocoSacramento = value.parroco.split(',')[1];
          this.parroco = value.parroco.split(',')[2];
          if (value.firma !== null) {
            this.firmaSelect = value.firma.id;
          }
          this._unsubInd5.next('');
        });
    }
    this.hoy = Fecha.javascriptConvert(new Date());
    this.objFecha.year = this.today.getFullYear();
    this.objFecha.mes = this.today.getMonth() + 1;
    this.objFecha.dia = this.today.getDate();
    this.mesActual = this.objFecha.mesNombre();
    this.firmaSe.getAll();
    this.firmaSe
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.firmas = value;
      });
    this.parroquiaSE.getAll();
    this.parroquiaSE
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.parroquias = value;
      })

  }
}
