import { Component, OnInit} from '@angular/core'
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/api/Login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  
  constructor(public router: Router, private loginSE: LoginService) {}

  ngOnInit(): void {
  }
  cerrarSesion(){
    this.loginSE.logout();
  }
}
