import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { LoginService } from 'src/app/services/api/Login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar-anyadir-verborradores',
  templateUrl: './navbar-anyadir-verborradores.component.html',
  styleUrls: ['./navbar-anyadir-verborradores.component.css']
})
export class NavbarAnyadirVerborradoresComponent implements OnInit {

  tabActivo = false

  constructor(private _location: Location, private loginSE: LoginService,private router: Router) { }

  cambioActivo(){
    
    this.tabActivo = true;
  }

  backClicked() {
    this.router.navigate(["/crear-partida"])
  }
  
  ngOnInit(): void {
    if (!this.loginSE.isLogin()) {
      this.loginSE.logout();
    }
  }

}
