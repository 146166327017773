<div class="centrado">
    <div class="individualNavigator">
        <button [class.activo]="tabActivo == 1" (click)="cambioActivo('',1)">Apariencia</button>
        <button [class.activo]="tabActivo == 2" (click)="cambioActivo('firmas',2)">Firmas electrónicas</button>
        <button [class.activo]="tabActivo == 3" (click)="cambioActivo('parroquias',3)">Parroquias</button>
        <button [class.activo]="tabActivo == 4" (click)="cambioActivo('soporte',4)">Soporte</button>
    </div>
    <div>
        <app-navbar-ajustes-apariencia *ngIf="tabActivo == 1"></app-navbar-ajustes-apariencia>
        <app-navbar-ajustes-firmas *ngIf="tabActivo == 2"></app-navbar-ajustes-firmas>
        <app-navbar-ajustes-parroquias *ngIf="tabActivo == 3"></app-navbar-ajustes-parroquias>
        <app-navbar-ajustes-soporte *ngIf="tabActivo == 4"></app-navbar-ajustes-soporte>
    </div>
</div>


