import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavbarAnyadirVerborradoresComponent } from './documentos/navbar-anyadir-verborradores/navbar-anyadir-verborradores.component';
import { NavbarDocumentosDocumentoComponent } from './documentos/navbar-documentos-documento/navbar-documentos-documento.component';
import { NavbarDocumentosPulsadoComponent } from './documentos/navbar-documentos-pulsado/navbar-documentos-pulsado.component';
import { NavbarInicioResultadosComponent } from './documentos/navbar-inicio-resultados/navbar-inicio-resultados.component';
import { FormularioComponent } from './navegacion/formulario/formulario.component';
import { NavbarAjustesComponent } from './navegacion/navbar-ajustes/navbar-ajustes.component';
import { NavbarDocumentosComponent } from './navegacion/navbar-documentos/navbar-documentos.component';
import { NavbarInicioComponent } from './navegacion/navbar-inicio/navbar-inicio.component';
import { LossPasswordComponent } from './pages/lossPassword/lossPassword.component';
import { CrearPartidaComponent } from './Pages/crearPartida/crearPartida.component';

import { BautizoComponent } from './pages/crearPartida/bautizo/bautizo.component';
import { DefuncionComponent } from './pages/crearPartida/defuncion/defuncion.component';
import { MatrimonioMixtoComponent } from './pages/crearPartida/matrimonioMixto/matrimonioMixto.component';
import { MatrimonioCatolicoComponent } from './pages/crearPartida/matrimonioCatolico/matrimonioCatolico.component';
import { ConfirmacionComponent } from './pages/crearPartida/confirmacion/confirmacion.component';


const routes: Routes = [
  {path: 'login', component: FormularioComponent},
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'lossPassword', component: LossPasswordComponent},
  {path: 'inicio', component: NavbarInicioComponent},
  {path: 'inicio/:url', component: NavbarInicioResultadosComponent},
  {path: 'crear-partida', component: CrearPartidaComponent},
  {path: 'crear-partida/bautizo', component: BautizoComponent},
  {path: 'crear-partida/defuncion', component: DefuncionComponent},
  {path: 'crear-partida/matrimonio-mixto', component: MatrimonioMixtoComponent},
  {path: 'crear-partida/matrimonio-catolico', component: MatrimonioCatolicoComponent},  
  {path: 'crear-partida/confirmacion', component: ConfirmacionComponent},  
  {path: 'editar-partida/bautizo/:id', component: BautizoComponent},
  {path: 'editar-partida/defuncion/:id', component: DefuncionComponent},
  {path: 'editar-partida/matrimonio-mixto/:id', component: MatrimonioMixtoComponent},
  {path: 'editar-partida/matrimonio-catolico/:id', component: MatrimonioCatolicoComponent},  
  {path: 'editar-partida/confirmacion/:id', component: ConfirmacionComponent},  
  {path: 'crear/borradores', component: NavbarAnyadirVerborradoresComponent},
  {path: 'explorar', component: NavbarDocumentosComponent},
  {path: 'explorar/:tipo', component: NavbarDocumentosPulsadoComponent},
  {path: 'explorar/:tipo/:id', component: NavbarDocumentosDocumentoComponent},
  {path: 'ajustes', component: NavbarAjustesComponent},
  {path: 'ajustes/:tipo', component: NavbarAjustesComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
