import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navbar-anyadir-final',
  templateUrl: './navbar-anyadir-final.component.html',
  styleUrls: ['./navbar-anyadir-final.component.css']
})
export class NavbarAnyadirFinalComponent implements OnInit {


  @Output()
  eventoHijoPartida = new EventEmitter<boolean>();

  tabActivo = false

  constructor() { }

  cambioActivo(){
    
    this.tabActivo = true;
  }

  enviarPadre(){
    this.eventoHijoPartida.emit(true)
  }

  ngOnInit(): void {
  }

}
