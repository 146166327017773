<div class="documentos">
    <label class="titulo" for="">Explora las partidas según el sacramento o tipo</label>
    <div class="grid">
        <div [routerLink]="['/explorar', 'Bautizo']" (click)="setSacramento('Bautizo')"class="fondo">
            <svg class="img1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.76 50.76"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M20.35,9H29.6v2.92l3.57,2.79,3.58,2.8c0,6.33-6.33,10.23-11.82,10.23-5.8,0-11.82-3.94-11.82-10l3.58-3.06,3.59-3.06,0-1.29Z"/><line class="cls-1" x1="24.93" y1="13.44" x2="24.93" y2="27.71"/><line class="cls-1" x1="20.27" y1="17.98" x2="16.6" y2="23.86"/><line class="cls-1" x1="29.65" y1="17.94" x2="33.33" y2="23.81"/><path class="cls-1" d="M24.85,32.35s-6.94,10.84,0,10.84S24.85,32.35,24.85,32.35Z"/><circle class="cls-1" cx="25.38" cy="25.38" r="23.88"/></g></g></svg>
            <label class="sacramentos1" for="">Bautizo</label>
            <div class="overlayBackground">
                <svg class="img1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.76 50.76"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M20.35,9H29.6v2.92l3.57,2.79,3.58,2.8c0,6.33-6.33,10.23-11.82,10.23-5.8,0-11.82-3.94-11.82-10l3.58-3.06,3.59-3.06,0-1.29Z"/><line class="cls-1" x1="24.93" y1="13.44" x2="24.93" y2="27.71"/><line class="cls-1" x1="20.27" y1="17.98" x2="16.6" y2="23.86"/><line class="cls-1" x1="29.65" y1="17.94" x2="33.33" y2="23.81"/><path class="cls-1" d="M24.85,32.35s-6.94,10.84,0,10.84S24.85,32.35,24.85,32.35Z"/><circle class="cls-1" cx="25.38" cy="25.38" r="23.88"/></g></g></svg>
            </div>
        </div>
        <div *ngIf="false" [routerLink]="['/explorar', 'Comunión']" (click)="setSacramento('Comunion')"class="fondo">
            <svg class="img2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.85 46.02"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M23.8,5S23.49,1.5,12.6,1.5,1.5,5,1.5,5,2.94,8.18,12.65,8.18,23.8,5,23.8,5Z"/><path class="cls-1" d="M23.8,5V17.74c0,7.12-6.28,9.68-11.15,9.66S1.5,24.86,1.5,17.74V5"/><path class="cls-1" d="M13.93,36V27.31H11.06V36c-4.81.32-8.48,2.08-8.48,4.22,0,2.36,4.51,4.28,10.07,4.28s10.07-1.92,10.07-4.28C22.72,38.06,18.89,36.26,13.93,36Z"/><line class="cls-1" x1="8.73" y1="16.02" x2="16.57" y2="16.02"/><line class="cls-1" x1="12.65" y1="12.64" x2="12.65" y2="21.94"/><ellipse class="cls-1" cx="37.77" cy="27.4" rx="13.85" ry="8.72" transform="translate(-8.31 34.73) rotate(-45)"/><path class="cls-1" d="M34,30a5.2,5.2,0,0,0-5-4.8"/><path class="cls-1" d="M38.23,27s-.39-5.52-6-5.81"/><path class="cls-1" d="M41.47,22.93a5.21,5.21,0,0,0-5-4.79"/></g></g></svg>
            <label class="sacramentos2" for="">Comunión</label>
            <div class="overlayBackground">
                <svg class="img2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.85 46.02"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M23.8,5S23.49,1.5,12.6,1.5,1.5,5,1.5,5,2.94,8.18,12.65,8.18,23.8,5,23.8,5Z"/><path class="cls-1" d="M23.8,5V17.74c0,7.12-6.28,9.68-11.15,9.66S1.5,24.86,1.5,17.74V5"/><path class="cls-1" d="M13.93,36V27.31H11.06V36c-4.81.32-8.48,2.08-8.48,4.22,0,2.36,4.51,4.28,10.07,4.28s10.07-1.92,10.07-4.28C22.72,38.06,18.89,36.26,13.93,36Z"/><line class="cls-1" x1="8.73" y1="16.02" x2="16.57" y2="16.02"/><line class="cls-1" x1="12.65" y1="12.64" x2="12.65" y2="21.94"/><ellipse class="cls-1" cx="37.77" cy="27.4" rx="13.85" ry="8.72" transform="translate(-8.31 34.73) rotate(-45)"/><path class="cls-1" d="M34,30a5.2,5.2,0,0,0-5-4.8"/><path class="cls-1" d="M38.23,27s-.39-5.52-6-5.81"/><path class="cls-1" d="M41.47,22.93a5.21,5.21,0,0,0-5-4.79"/></g></g></svg>
            </div>
        </div>
        <div [routerLink]="['/explorar', 'Confirmación']" (click)="setSacramento('Confirmación')"class="fondo">
            <svg class="img3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.06 57.85"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M24.29,48.21c-4.26,1.48-4.87,8-4.87,8A9.08,9.08,0,0,1,8.8,49.08s8.71-2.49,9.77-6.6"/><path class="cls-1" d="M21,46.73c11.49,3,18.79-4,18.79-12,0,0-1.39-9.48,3.83-9.83,0,0-3.92-.55-4.88-1.63s-5.93-3.53-9.09,3.46"/><path class="cls-1" d="M32.29,29.82s-5.47-5-10.54-6.31S8.8,28.42,1.5,23.51c0,0-.36,24.7,25,17.74"/><circle class="cls-1" cx="35.5" cy="25.97" r="0.16"/><path class="cls-1" d="M2,28A10.55,10.55,0,0,0,8.8,29"/><path class="cls-1" d="M3.57,33.19s2,1.34,5.23,0"/><path class="cls-1" d="M6.45,37.71s3.16-.1,4.54-1.3"/><path class="cls-1" d="M11.6,41.3s2.09-.93,2.51-2.07"/><path class="cls-1" d="M44.36,30.41s1.14-18.19-14-22.27"/><path class="cls-1" d="M48.44,10c-.64-2-4.26,4.14-3.6,7.27S49.28,12.64,48.44,10Z"/><path class="cls-1" d="M37.4,15.09c1.55,1.41-5.54,2.16-8.1.26S35.35,13.23,37.4,15.09Z"/><path class="cls-1" d="M39,9.63c-.73,2-4.06-4.34-3.25-7.43S39.91,7,39,9.63Z"/></g></g></svg>
            <label class="sacramentos3" for="">Confirmación</label>
            <div class="overlayBackground">
                <svg class="img3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.06 57.85"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M24.29,48.21c-4.26,1.48-4.87,8-4.87,8A9.08,9.08,0,0,1,8.8,49.08s8.71-2.49,9.77-6.6"/><path class="cls-1" d="M21,46.73c11.49,3,18.79-4,18.79-12,0,0-1.39-9.48,3.83-9.83,0,0-3.92-.55-4.88-1.63s-5.93-3.53-9.09,3.46"/><path class="cls-1" d="M32.29,29.82s-5.47-5-10.54-6.31S8.8,28.42,1.5,23.51c0,0-.36,24.7,25,17.74"/><circle class="cls-1" cx="35.5" cy="25.97" r="0.16"/><path class="cls-1" d="M2,28A10.55,10.55,0,0,0,8.8,29"/><path class="cls-1" d="M3.57,33.19s2,1.34,5.23,0"/><path class="cls-1" d="M6.45,37.71s3.16-.1,4.54-1.3"/><path class="cls-1" d="M11.6,41.3s2.09-.93,2.51-2.07"/><path class="cls-1" d="M44.36,30.41s1.14-18.19-14-22.27"/><path class="cls-1" d="M48.44,10c-.64-2-4.26,4.14-3.6,7.27S49.28,12.64,48.44,10Z"/><path class="cls-1" d="M37.4,15.09c1.55,1.41-5.54,2.16-8.1.26S35.35,13.23,37.4,15.09Z"/><path class="cls-1" d="M39,9.63c-.73,2-4.06-4.34-3.25-7.43S39.91,7,39,9.63Z"/></g></g></svg>
            </div>
        </div>
        <div [routerLink]="['/explorar', 'Matrimonio']" (click)="setSacramento('Matrimonio')" class="fondo">
            <svg class="img4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.62 45.15"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><circle class="cls-1" cx="17.21" cy="27.94" r="15.71"/><circle class="cls-1" cx="36.41" cy="27.94" r="15.71"/><polygon class="cls-1" points="49.91 6.29 46.71 1.5 41.16 3.06 40.94 8.82 46.34 10.82 49.91 6.29"/></g></g></svg>
            <label class="sacramentos4" for="">Matrimonio</label>
            <div class="overlayBackground">
                <svg class="img4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.62 45.15"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><circle class="cls-1" cx="17.21" cy="27.94" r="15.71"/><circle class="cls-1" cx="36.41" cy="27.94" r="15.71"/><polygon class="cls-1" points="49.91 6.29 46.71 1.5 41.16 3.06 40.94 8.82 46.34 10.82 49.91 6.29"/></g></g></svg>
            </div>
        </div>
        <div *ngIf="false" [routerLink]="['/explorar', 'Nulidad Matrimonial']" (click)="setSacramento('Nulidad Matrimonial')"class="fondo">
            <svg class="img5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.15 43.87"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M24.16,8.65S27.24,1.5,32.68,1.5c6.17,0,11,4.38,11,10.34,0,17.56-21.07,30.53-21.07,30.53S1.5,29.67,1.5,11.84c0-6,4.8-10.34,11-10.34,6.89,0,8.81,8.63,8.81,8.63l2.88,5.29L20.73,21l2.67,8.52.42-7.61,4.1-5.18Z"/></g></g></svg>
            <label class="sacramentos5" for="">Nulidad Matrimonial</label>
            <div class="overlayBackground">
                <svg class="img5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.15 43.87"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M24.16,8.65S27.24,1.5,32.68,1.5c6.17,0,11,4.38,11,10.34,0,17.56-21.07,30.53-21.07,30.53S1.5,29.67,1.5,11.84c0-6,4.8-10.34,11-10.34,6.89,0,8.81,8.63,8.81,8.63l2.88,5.29L20.73,21l2.67,8.52.42-7.61,4.1-5.18Z"/></g></g></svg>
            </div>
        </div>
        <div [routerLink]="['/explorar', 'Defunciones']" (click)="setSacramento('Defunciones')"class="fondo">
            <svg class="img6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.21 52.46"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M48.71,25.1a23.61,23.61,0,0,0-47.21,0V51H48.71Z"/><line class="cls-1" x1="17.28" y1="25.84" x2="32.93" y2="25.84"/><line class="cls-1" x1="25.1" y1="19.09" x2="25.1" y2="37.66"/></g></g></svg>
            <label class="sacramentos6" for="">Defunciones</label>
            <div class="overlayBackground">
                <svg class="img6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.21 52.46"><defs><style>.cls-1{fill:none;stroke:#311c38;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px;}</style></defs><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M48.71,25.1a23.61,23.61,0,0,0-47.21,0V51H48.71Z"/><line class="cls-1" x1="17.28" y1="25.84" x2="32.93" y2="25.84"/><line class="cls-1" x1="25.1" y1="19.09" x2="25.1" y2="37.66"/></g></g></svg>
            </div>
        </div>
    </div>
</div>