import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Parroquia } from 'src/app/objects/Parroquia';
import { resultHttp } from 'src/app/objects/resultHttp';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class ParroquiaService extends BaseService{

  constructor(protected http:HttpClient) {
    super();
    this._result=new BehaviorSubject<Array<Parroquia>|null>(null);
    this._resultIndividual=new BehaviorSubject<Parroquia|null>(null);
  }

  getAll(){
    this.http.get(this.urlHost+"api/parroquia/",this.getHeader()).subscribe({next:data=>{
      let parroquias:Array<Parroquia>=[];
      for(let s of Object.keys(data)){
        let emp:Parroquia=Parroquia.convertFrontObject(data[s]);
        parroquias.push(emp);
      }
      this._result.next(parroquias);
    },error:error=>{
      let resHt=new resultHttp();
      resHt.data=error;
      resHt.mensaje=error.error.message;
      resHt.ok=false;
      resHt.status=error.status; 
      this.sendNextResult('resultError',resHt,{method:this.getAll,args:[]});
    }});
    return this._result;
  }
  getIndividual(ID){
    this.http.get(this.urlHost+"api/parroquia/"+ID,this.getHeader()).subscribe({next:data=>{
      let emp:Parroquia=Parroquia.convertFrontObject(data);
      this._resultIndividual.next(emp);
    },error:error=>{
      let resHt=new resultHttp();
      resHt.data=error;
      resHt.mensaje=error.error.message;
      resHt.ok=false;
      resHt.status=error.status; 
      this.sendNextResult('resultIndividualError',resHt,{method:this.getIndividual,args:[ID]});
    }});
  }
  create(parroquia:Parroquia){
    this.http.post(this.urlHost+"api/parroquia",parroquia,this.getHeader()).subscribe({next:data=>{
      let emp:Parroquia=Parroquia.convertFrontObject(data['data']);
      this.sendNextResult('resultUpdate',emp);
    },error:error=>{
      let resHt=new resultHttp();
      resHt.data=error;
      resHt.mensaje=error.error.message;
      resHt.ok=false;
      resHt.status=error.status; 
      this.sendNextResult('resultUpdateError',resHt,{method:this.create,args:[parroquia]});
    }});
  }
  update(ID,parroquia:Parroquia){
    this.http.put(this.urlHost+"api/parroquia/"+ID,parroquia,this.getHeader()).subscribe({next:data=>{
      let emp:Parroquia=Parroquia.convertFrontObject(data['data']);
      this.sendNextResult('resultUpdate',emp);
    },error:error=>{
      let resHt=new resultHttp();
      resHt.data=error;
      resHt.mensaje=error.error.message;
      resHt.ok=false;
      resHt.status=error.status; 
      this.sendNextResult('resultUpdateError',resHt,{method:this.update,args:[ID,parroquia]});
    }});
  }
  delete(IDs:Array<any>){
    let idOj = this.convertArrayObject(IDs,"parroquias_ids");
    this.http.delete(this.urlHost+"api/parroquia",this.sendBodyOptions(idOj)).subscribe({next:data=>{
      let resHt=new resultHttp();
      resHt.data=data['data'];
      resHt.mensaje=data['message'];
      resHt.ok=true;
      resHt.status=data['status'];
      this.sendNextResult('resultDelete',resHt);
    },error:error=>{
      let resHt=new resultHttp();
      resHt.data=error;
      resHt.mensaje=error.error.message;
      resHt.ok=false;
      resHt.status=error.status; 
      this.sendNextResult('resultDeleteError',resHt,{method:this.delete,args:[IDs]});
    }});
  }
}
