import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private _returnData=new BehaviorSubject<any>(null);
  private _returnCropImage=new BehaviorSubject<any>(null);
  
  constructor(private dialog: MatDialog) { }
  protected open(element,data?,addClass?):MatDialogRef<unknown, any>{
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    if(data!=null){
      dialogConfig.data=data;
    }
    dialogConfig.autoFocus=false;
    if(addClass!=null){
      dialogConfig.panelClass=addClass;
    }
    let modalDialog = this.dialog.open(element, dialogConfig);
    return modalDialog;
  }
  protected listenerWhenClose(name,popup:MatDialogRef<unknown, any>){
    popup.beforeClosed().subscribe(result=>{
      let j=document.body.getElementsByClassName("tooltipContainerBody")[0];
      if(j!=null){
        j.remove();
      }
    });
    popup.afterClosed().subscribe(result=>{
      if(result==null){return;}
      result.popupName=name;
      this._returnData.next(result);
      setTimeout(() => {
        this.clearData();
      }, 1500);
    });
  }

  openPopup(element,data?):{name:string,data:any}{
    let popup=this.open(element,data);
    this.listenerWhenClose(element.name,popup);
    return {name:element.name,data:data};
  }
  openFullPopup(element,data?){
    let popup=this.open(element,data,"fullSizePopup");
    this.listenerWhenClose(element.name,popup);
  }

  returnData(){
    return this._returnData;
  }
  returnDataCropper(){
    return this._returnCropImage;
  }
  clearData(){
    this._returnData.next(null);
  }
  clearDataCropper(){
    this._returnCropImage.next(null);
  }
}
