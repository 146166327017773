<div class="fileCard-generalContainer">
  <div class="fileCard-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="64.137" height="85.756" viewBox="0 0 64.137 85.756">
      <g id="Grupo_1252" data-name="Grupo 1252" transform="translate(-908 -286.888)">
        <path id="Trazado_426" data-name="Trazado 426" d="M2954.984,582.128V665h63.137V594.82l-12.692-12.692Z" transform="translate(-2046.484 -292.855)" fill="#fff" stroke="#4d4d4d" stroke-width="1"/>
        <line id="Línea_103" data-name="Línea 103" x2="41.421" transform="translate(919.424 308.56)" fill="none" stroke="#4d4d4d" stroke-width="1"/>
        <line id="Línea_104" data-name="Línea 104" x2="41.421" transform="translate(919.424 315.387)" fill="none" stroke="#4d4d4d" stroke-width="1"/>
        <line id="Línea_105" data-name="Línea 105" x2="41.421" transform="translate(919.424 322.215)" fill="none" stroke="#4d4d4d" stroke-width="1"/>
        <line id="Línea_106" data-name="Línea 106" x2="41.421" transform="translate(919.424 329.042)" fill="none" stroke="#4d4d4d" stroke-width="1"/>
        <g id="Trazado_427" data-name="Trazado 427" transform="matrix(0.966, -0.259, 0.259, 0.966, 956.217, 290.035)" fill="#fff">
          <path d="M 11.12195110321045 14.61790084838867 L 0.6123723387718201 11.80187225341797 L 3.427632331848145 1.295162558555603 L 11.12195110321045 14.61790084838867 Z" stroke="none"/>
          <path d="M 3.598237991333008 2.590332984924316 L 1.224753379821777 11.44831371307373 L 10.0851526260376 13.82244682312012 L 3.598237991333008 2.590332984924316 M 3.257033348083496 3.814697265625e-06 L 12.15874290466309 15.41334342956543 L 3.814697265625e-06 12.15542411804199 L 3.257033348083496 3.814697265625e-06 Z" stroke="none" fill="#4d4d4d"/>
        </g>
        <text id="PDF" transform="translate(918 358)" fill="#4d4d4d" stroke="#4d4d4d" stroke-width="1" font-size="24" font-family="IBMPlexSans-Medium, IBM Plex Sans" font-weight="500"><tspan x="0" y="0">PDF</tspan></text>
      </g>
    </svg>    
  </div>
  <p>{{nombre}}</p>
</div>