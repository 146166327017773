import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { LoginService } from 'src/app/services/api/Login.service';
import { LoaderService } from 'src/app/services/util/loader.service';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent extends UnsubscribesDestroy implements OnInit {
  tipo = "bautismo";
  pass = "";
  email = "";

  constructor(public loginSe:LoginService, public notificiaciones:NotificacionesService, public loader:LoaderService, public route:Router) {
    super();
  }

  ngOnInit(): void {
  }

  sendLogin(){
    // Primero comprobamos si los campos están incompletos
    if(this.pass=="" || this.email==""){
      this.notificiaciones.showError("Campos incompletos");
      return;
    }
    // Abrimos el loader
    this.loader.open();
    // Lamamos al método del servicio
    this.loginSe.checkLogin(this.email,this.pass);


    let adf=[];
    adf.filter((e)=>{return true;})

    // En los servicios hay 4 tipos de devoluciones
    // getResult           -> Generalmente sirve para recoger los datos principales del servicio. 
    //                        Por ejemplo: Servicio departamentos -> getter de todos los departamentos
    // getResultIndividual -> Generalmente sirve para recoger UN dato principal del servicio.
    //                        Por ejemplo: Servicio departamentos -> getter de UN solo departamento
    // getUpdate           -> Generalmente sirve para recoger cuando llamas a un UPDATE o CREATE de un elemento
    //                        Por ejemplo: Servicio departamentos -> actualiza los estados de un departamento
    //                                                            -> actualiza el nombre de un solo departamento ...
    //                                                            -> crea un nuevo departamento
    // getDelete           -> Generalmente sirve para recoger cuando llamas a un DELETE de un elemento
    //                        Por ejemplo: Servicio departamentos -> elimina todos los departamentos que sean activos
    //                                                            -> elimina UN solo departamento
    // ----------------
    // Cada tipo de devolución tiene su contraparte de error, quiere decir que hay:
    // getResultError, getResultIndividualError, getUpdateError, getDeleteError
    // 
    // Que devuelve de si la llamada falla
    // ----------------
    // Cada tipo de devolución con su tipo de error (getResult, getResultError, getResultIndividual, getResultIndividualError...)
    // tiene para limpiar setear de nuevo a un valor nulo.
    //
    // ¿Porque seteamos a nulo?
    // El listener devuelve siempre el valor que tenía antes, esto es muy util para que rápidamente haya contenido cuando haces un
    // getter, pero en la mayoría de casos queremos que esté nulo para que la siguiente llamada no tenga datos.
    // Por ejemplo: Una llamada update
    //
    // Debido a esto, el valor inicial siempre está a nulo, si es nulo significa que es la 1º vez que se ha hecho esa llamada y es
    // muy importante que se limpie cada vez que sea una llamada de 1 sola vez. (Update y deletes suelen ser los más frecuentes)
    //
    // ----------------
    // TAKEUNTIL
    // Los take until nos sirve para cerrar un listener, por defecto cuando extiendes de UnsubscribeDestroy tienes 3 subscribes
    // _unsub               -> Para subscribes que solo se van ha cerrar cuando sales de la página
    //                               Por ejemplo: Un getter que está en el ngOnInit
    // _unsubInd     -> Para subscribes que se van ha cerrar dentro de un solo bloque
    //                               Por ejemplo: Cuando le das a un botón de actualizar, crear o eliminar
    // _unsubInd2    -> Para subscribes que se van ha cerrar dentro de un solo bloque y en el mismo bloque ya se está utilizando _unsubInd
    //                               Por ejemplo: Abrir un popup de confirmación y esperar a que retorne datos, 
    //                                            entonces borrar ese elemento si le ha dado que sí

    // Hacemos un listener de si se completa la llamada
    this.loginSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      // SIEMPRE tiene un valor nulo por defecto, por lo que si es nulo => lo ignoramos (debido a que la llamada HTTP si que te devuelve un valor string o json)
      if(value==null){return;}
      // Enviamos una notificación al usuario
      this.notificiaciones.showFeedBack("Inicio de sesión exitoso");
      // Ponemos el valor a nulo (Ya que para la proxima llamada será nulo)
      this.loginSe.clearResult();
      // Cerramos el loader de forma lenta (Debido a que si tarda demasiado poco el usuario no se entera de que está cargando)
      this.loader.closeSlow();
      // Rompemos ambos listeners, el de error y el de exito (Dejan de escuchar)
      this._unsubInd.next("");
      // Vamos a la ruta que queramos
      this.route.navigate(['/inicio']);
    });


    // Hacemos un listener de si la llamda da ERROR
    this.loginSe.getResultError().pipe(takeUntil(this._unsubInd)).subscribe(value=>{
      // SIEMPRE tiene un valor nulo por defecto, por lo que si es nulo => lo ignoramos (debido a que la llamada HTTP si que te devuelve un valor string o json)
      if(value==null){return;}
      // Enviamos una notificación al usuario
      this.notificiaciones.showError(value.mensaje);
      // Ponemos el valor a nulo (Ya que para la proxima llamada será nulo)
      this.loginSe.clearResultError();
      // Cerramos el loader de forma lenta (Debido a que si tarda demasiado poco el usuario no se entera de que está cargando)
      this.loader.closeSlow();
      // Rompemos ambos listeners, el de error y el de exito (Dejan de escuchar)
      this._unsubInd.next("");
    });
  }
}
