<div class="panel">
    <button type="submit" class="buttonsinimg" (click)="openParroquiaForm()">Añadir nueva parroquia</button>
    <div class="panelparroquias">
        <div class="parroquia" *ngFor="let parroquia of parroquias; let i = index" [ngClass]="{openDesplegable: activeDesplegable == i, active: parroquiaSelect==i}" (click)="selectParroquia(i)">
            <span class="parroquiaNombreText">{{parroquia.nombre}}</span>
            <div class="parroquiaContextMenu">
                <span class="iconSubmenu" (click)="toggleDesplegable(i)"><span class="material-icons" translate="no">more_horiz</span></span>
                <div class="actionsList">
                    <div (click)="editParroquia(parroquia)">Editar parroquia</div>
                    <div (click)="deleteParroquia(parroquia)">Eliminar parroquia</div>
                </div>
            </div>
        </div>
    </div>
</div>