import { BaseObject } from "./Base";

export interface User{
    id?: number,
    username: string,
    password: string,
    can_access: boolean,
}
export class User extends BaseObject implements User{
    static convertFrontObject(data){
        let usr= new User();
        usr.id=data.id;
        usr.username=data.username;
        usr.password=data.password;
        usr.can_access=data.can_access;
        return usr;
    }
}