<!--COPIAR-->
<div class="crearPartidaGeneralContainer">
  <div class="cancelarGuardarContainer">
    <span (click)="sendData('borrador')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8.621"
        height="14.414"
        viewBox="0 0 8.621 14.414"
      >
        <path
          id="Trazado_125"
          data-name="Trazado 125"
          d="M-9108.06,2156.32l-6.5,6.5,6.5,6.5"
          transform="translate(9115.974 -2155.613)"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
      </svg>
    </span>
    <label for="">Cancelar y guardar</label>
  </div>
  <div class="headerCrearPartidaContainer">
    <div>
      <span
        >Solicitud de autorización de licencia de matrimonio de mixta
        religión</span
      >
    </div>
    <div>
      <button
        type="buttton"
        class="modificarDocumento"
        (click)="changeDocumentoPrevio()"
      >
        Modificar documento adjunto
      </button>
    </div>
  </div>
  <div class="dataGeneralContainer">
    <div class="dataContainer">
      <div class="timelineContainer">
        <div>
          <span [class.active]="pasoCreacionPartida >= 0"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 1"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 2"></span>
          <div></div>
          <span [class.active]="pasoCreacionPartida >= 3"></span>
        </div>
        <div>
          <span
            [class.active]="pasoCreacionPartida == 0"
            [class.error]="!paso0Valido"
            >Información personal solicitante</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 1"
            [class.error]="!paso1Valido"
            >Nombre de sujeto de la otra religión</span
          >
          <div></div>
          <span
            [class.active]="pasoCreacionPartida == 2"
            [class.error]="!paso2Valido"
            >Firma</span
          >
          <div></div>
          <span [class.active]="pasoCreacionPartida == 3">Revisar</span>
        </div>
      </div>
      <div class="formContainer">
        <ng-container *ngIf="pasoCreacionPartida == 0">
          <div class="paso0Container">
            <div>
              <div>
                <label [class.error]="nombreLaicoVacio" for=""
                  >Nombre del laico:
                </label>
                <input
                  [class.error]="nombreLaicoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  type="text"
                  class="input"
                  [(ngModel)]="nombreLaico"
                />
              </div>
              <div>
                <label for="" [class.error]="apellidosLaicoVacio"
                  >Apellidos del laico:
                </label>
                <input
                  type="text"
                  [class.error]="apellidosLaicoVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  class="input"
                  [(ngModel)]="apellidoLaico"
                />
              </div>
            </div>
            <div>
              <div>
                <label for="" [class.error]="vecinoLaicoVacio"
                  >Vecino de:
                </label>
                <input
                  type="text"
                  [class.error]="vecinoLaicoVacio"
                  class="input"
                  [(ngModel)]="vecinoLaico"
                />
              </div>
              <div>
                <label for="" [class.error]="feligresParroquiaVacio"
                  >Feligrés de la parroquia:
                </label>
                <input
                  type="text"
                  [class.error]="feligresParroquiaVacio"
                  class="input"
                  [(ngModel)]="feligresParroquia"
                />
              </div>
            </div>
          </div>
          <div class="desplegable">
            <div class="infoContainer">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g
                    id="Grupo_1695"
                    data-name="Grupo 1695"
                    transform="translate(-1094 -584)"
                  >
                    <line
                      id="Línea_213"
                      data-name="Línea 213"
                      y2="2"
                      transform="translate(1102 593)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_857"
                      data-name="Trazado 857"
                      d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                      transform="translate(1094 584)"
                      fill="#0d1423"
                    />
                    <circle
                      id="Elipse_603"
                      data-name="Elipse 603"
                      cx="7.25"
                      cy="7.25"
                      r="7.25"
                      transform="translate(1094.75 584.75)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <label for="">Información Padre</label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.726"
                  height="20.726"
                  viewBox="0 0 20.726 20.726"
                >
                  <path
                    id="male"
                    d="M16.116,4.75h6.946m0,0V11.7m0-6.946-9.156,9.156m1.579,3.789a5.367,5.367,0,1,1-5.367-5.367A5.367,5.367,0,0,1,15.485,17.695Z"
                    transform="translate(-3.75 -3.336)"
                    fill="none"
                    stroke="#0d1423"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div>
                <span (click)="openPadre()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.828"
                    height="8.414"
                    viewBox="0 0 14.828 8.414"
                  >
                    <path
                      id="Trazado_855"
                      data-name="Trazado 855"
                      d="M-6345.379-17421.977l6,6,6-6"
                      transform="translate(6346.793 17423.391)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="camposContainer" *ngIf="openedPadre">
              <div>
                <div>
                  <label for="" [class.error]="nombrePadreVacio"
                    >Nombre del padre:
                  </label>
                  <input
                    type="text"
                    class="input"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [class.error]="nombrePadreVacio"
                    [(ngModel)]="nombrePadre"
                  />
                </div>
                <div class="campoEspecial">
                  <label for="" [class.error]="apellidosPadreVacio"
                    >Apellidos del padre:
                  </label>
                  <input
                    type="text"
                    class="input"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    [class.error]="apellidosPadreVacio"
                    [(ngModel)]="apellidosPadre"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="desplegable">
            <div class="infoContainer">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <g
                    id="Grupo_1695"
                    data-name="Grupo 1695"
                    transform="translate(-1094 -584)"
                  >
                    <line
                      id="Línea_213"
                      data-name="Línea 213"
                      y2="2"
                      transform="translate(1102 593)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Trazado_857"
                      data-name="Trazado 857"
                      d="M8,4A1,1,0,1,1,7,5,1,1,0,0,1,8,4"
                      transform="translate(1094 584)"
                      fill="#0d1423"
                    />
                    <circle
                      id="Elipse_603"
                      data-name="Elipse 603"
                      cx="7.25"
                      cy="7.25"
                      r="7.25"
                      transform="translate(1094.75 584.75)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <label for="">Información Madre</label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.344"
                  height="27.306"
                  viewBox="0 0 13.344 27.306"
                >
                  <path
                    id="female"
                    d="M14.422,30.056V16.094m0,0A5.672,5.672,0,1,0,8.75,10.422,5.672,5.672,0,0,0,14.422,16.094ZM8.75,23.948H20.094"
                    transform="translate(-7.75 -3.75)"
                    fill="none"
                    stroke="#0d1423"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div>
                <span (click)="openMadre()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.828"
                    height="8.414"
                    viewBox="0 0 14.828 8.414"
                  >
                    <path
                      id="Trazado_855"
                      data-name="Trazado 855"
                      d="M-6345.379-17421.977l6,6,6-6"
                      transform="translate(6346.793 17423.391)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="camposContainer" *ngIf="openedMadre">
              <div>
                <div>
                  <label for="" [class.error]="nombreMadreVacio"
                    >Nombre de la madre:
                  </label>
                  <input
                    type="text"
                    [class.error]="nombreMadreVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    class="input"
                    [(ngModel)]="nombreMadre"
                  />
                </div>
                <div class="campoEspecial">
                  <label for="" [class.error]="apellidosMadreVacio"
                    >Apellidos de la madre:
                  </label>
                  <input
                    type="text"
                    [class.error]="apellidosMadreVacio"
                    [ngStyle]="{ 'text-transform': 'capitalize' }"
                    class="input"
                    [(ngModel)]="apellidosMadre"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 1">
          <div class="paso0Container">
            <div>
              <div class="campoEspecial sexo">
                <label for="" [class.error]="sexoVacio">Sexo: </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.236"
                  height="14.996"
                  viewBox="0 0 9.236 14.996"
                >
                  <path
                    id="female"
                    d="M11.069,15.1V9.388m0,0A2.319,2.319,0,1,0,8.75,7.069,2.319,2.319,0,0,0,11.069,9.388ZM8.75,12.6h4.638"
                    transform="translate(-8.25 -0.599)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="male"
                    d="M9.734,4.75h3.045m0,0V7.8m0-3.045L8.765,8.765m.692,1.661A2.353,2.353,0,1,1,7.1,8.072,2.353,2.353,0,0,1,9.457,10.426Z"
                    transform="translate(-4.25 -4.043)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </svg>

                <ng-select
                  class="fix_height"
                  [class.error]="sexoVacio"
                  [items]="generos"
                  [clearable]="false"
                  [(ngModel)]="sexo"
                ></ng-select>
              </div>
              <div></div>
            </div>
            <div>
              <div>
                <label for="" [class.error]="nombreOtraReligionVacio"
                  >Nombre:
                </label>
                <input
                  type="text"
                  [class.error]="nombreOtraReligionVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  class="input"
                  [(ngModel)]="nombreOtraReligion"
                />
              </div>
              <div>
                <label for="" [class.error]="apellidosOtraReligionVacio"
                  >Apellidos:
                </label>
                <input
                  type="text"
                  [class.error]="apellidosOtraReligionVacio"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  class="input"
                  [(ngModel)]="apellidosOtraReligion"
                />
              </div>
            </div>
            <div>
              <div>
                <label for="" [class.error]="vecinoOtraReligionVacio"
                  >Vecino de:
                </label>
                <input
                  type="text"
                  [class.error]="vecinoOtraReligionVacio"
                  class="input"
                  [(ngModel)]="vecinoOtraReligion"
                />
              </div>
              <div>
                <label for="" [class.error]="religionVacio"
                  >Perteneciente a la religión:
                </label>
                <input
                  type="text"
                  class="input"
                  [(ngModel)]="religion"
                  [class.error]="religionVacio"
                />
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 2">
          <div class="paso0Container">
            <div>
              <div class="campoEspecial">
                <label for="">Nombre del sacerdote que autoriza: </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.763"
                  height="15.703"
                  viewBox="0 0 12.763 15.703"
                >
                  <g
                    id="Grupo_1701"
                    data-name="Grupo 1701"
                    transform="translate(-1146.372 -479.301)"
                  >
                    <path
                      id="Trazado_861"
                      data-name="Trazado 861"
                      d="M-5737.562-13068.124h6.6a11.887,11.887,0,0,0,2.444-6.883c0-3.934-6.148-7.82-6.148-7.82s-5.621,3.854-5.614,7.82A11.182,11.182,0,0,0-5737.562-13068.124Z"
                      transform="translate(6887.151 13562.628)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <g
                      id="Grupo_1700"
                      data-name="Grupo 1700"
                      transform="translate(1151.651 487.152)"
                    >
                      <line
                        id="Línea_218"
                        data-name="Línea 218"
                        y1="2.941"
                        transform="translate(1.103)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        id="Línea_219"
                        data-name="Línea 219"
                        y1="2.206"
                        transform="translate(2.206 0.735) rotate(90)"
                        fill="none"
                        stroke="#0e1422"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                    <line
                      id="Línea_220"
                      data-name="Línea 220"
                      x2="9.557"
                      transform="translate(1148.069 492.078)"
                      fill="none"
                      stroke="#0e1422"
                      stroke-linecap="round"
                      stroke-width="1"
                    />
                  </g>
                </svg>

                <input
                  type="text"
                  class="input"
                  [ngStyle]="{ 'text-transform': 'capitalize' }"
                  [(ngModel)]="parroco"
                />
              </div>
            </div>
            <div>
              <div class="campoEspecial">
                <label for="">Firma: </label>
                <ng-select
                  class="secundaryTheme fix_height"
                  [placeholder]="'Selecciona una firma'"
                  [items]="firmas"
                  [(ngModel)]="firmaSelect"
                  [clearable]="false"
                  bindLabel="nombre"
                  bindValue="id"
                ></ng-select>
              </div>
            </div>
            <div class="firma">
              <ng-container *ngFor="let image of firmas">
                <img
                  *ngIf="firmaSelect == image.id"
                  [src]="urlImages + image.url"
                />
              </ng-container>
            </div>
            <div></div>
          </div>
        </ng-container>
        <ng-container *ngIf="pasoCreacionPartida == 3">
          <div class="documentoGeneradoContainer">
            <div class="documentoGenerado" #documentoImprimir>
              <p [ngStyle]="{ 'text-align': 'center', 'font-size': '32px' }">
                <b
                  >SOLCITUD DE AUTORIZACIÓN DE LICENCIA DE MATRIMONIO DE MIXTA
                  RELIGIÓN</b
                >
              </p>
              <br />
              <p class="texto">Ilmo. Sr. Vicario General</p>
              <p class="texto">
                D./Dña. {{ nombreLaico }} {{ apellidoLaico }} natural de
                {{ vecinoLaico }} y feligrés(a) de {{ feligresParroquia }} de
                hijo(a) de {{ nombrePadre }} {{ apellidosPadre }} y de
                {{ nombreMadre }} {{ apellidosMadre }} de religión católica, con
                el debido respeto EXPONE:
              </p>
              <p class="texto">
                Que tiene convenido matrimonio canónico con D./Dña.
                {{ nombreOtraReligion }} {{ apellidosOtraReligion }} natural de
                {{ vecinoOtraReligion }} y perteneciente a {{ religion }} por lo
                que,
              </p>
              <p class="texto">
                Solcitia se le conceda la debida LICENCIA para dicho matrimonio
              </p>
              <p class="texto">Es gracia que espera alcanzar de V. Ilma:</p>
              <br />
              <p>
                Valencia, a {{ objFecha.dia }} de {{ mesActual }} de
                {{ objFecha.year }}
              </p>
              <div class="firma">
                <img [src]="imgSelect" alt="" />
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="buttonsContainer">
      <div>
        <button type="button" (click)="atras()">Atrás</button>
        <button
          type="button"
          (click)="siguiente()"
          *ngIf="pasoCreacionPartida != 3"
        >
          Siguiente
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 3 && !editar"
          [class.disabled]="!paso0Valido || !paso1Valido || !paso2Valido"
          (click)="sendData('nuevo')"
        >
          Crear partida
        </button>
        <button
          type="button"
          *ngIf="pasoCreacionPartida == 3 && editar"
          [class.disabled]="!paso0Valido || !paso1Valido || !paso2Valido"
          (click)="sendData('nuevo')"
        >
          Editar partida
        </button>
      </div>
    </div>
  </div>
</div>
<!-- FIN COPIAR-->
