import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs';
import { Firma } from 'src/app/objects/Firma';
import { UnsubscribesDestroy } from 'src/app/objects/unsubscribesDestroy';
import { FirmaService } from 'src/app/services/api/firma.service';
import { environment } from 'src/environments/environment';
import { Fecha } from 'src/app/objects/Fecha';
import { Parroquia } from 'src/app/objects/Parroquia';
import { ConfirmPopupComponent } from 'src/app/popUps/confirmPopup/confirmPopup.component';
import { NotificacionesService } from 'src/app/services/util/notificaciones.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupService } from 'src/app/services/util/popup.service';
import { PartidaService } from 'src/app/services/api/partida.service';
import { PopupFileComponent } from 'src/app/popUps/popupFile/popupFile.component';
import { LoaderService } from 'src/app/services/util/loader.service';
import { ParroquiaService } from 'src/app/services/api/parroquia.service';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.css'],
})
export class ConfirmacionComponent
  extends UnsubscribesDestroy
  implements OnInit, OnDestroy
{
  pasoCreacionPartida = 0;
  firmas: Array<Firma> = [];
  parroquias: Array<Parroquia> =[];
  urlImages = environment.urlImg;
  imgSelect =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  @ViewChild('documentoImprimir') documentoImprimir!: ElementRef;
  today = new Date();
  mesActual = '';
  objFecha: Fecha = new Fecha();
  days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  dayName = this.days[new Date().getDay()];
  /*VALIDACIONES PASO 0*/
  numeroPartida: Number | null = null;
  folioPartida: number | null = null;
  libroPartida: string | null = null;
  parroquiaSelect: number | null = null;
  anyo: Number | null = null;
  nombreLaico: string | null = null;
  primerApellido: string | null = null;
  segundoApellido: string | null = null;
  // fechaNacimiento: string = '';
  // lugarNacimiento: string | null = null;
  parroquiaBautizado: string | null = null;
  lugarParroquia: string | null = null;

  numeroPartidaVacio: boolean = false;
  folioPartidaVacio: boolean = false;
  libroPartidaVacio: boolean = false;
  anyoVacio: boolean = false;
  nombreLaicoVacio: boolean = false;
  primerApellidoVacio: boolean = false;
  segundoApellidoVacio: boolean = false;
  parroquiaVacio: boolean = false;
  // fechaNacimientoVacio: boolean = false;
  // lugarNacimientoVacio: boolean = false;
  parroquiaBautizadoVacio: boolean = false;
  lugarParroquiaVacio: boolean = false;

  paso0Valido: boolean = true;
  /*FIN VALIDACIONES PASO 0*/

  /*VALIDACIONES PASO 1*/
  fechaCelebracionCeremonia: string = '';
  parroco: String | null = null;
  infrafirmado: String | null = null;
  parrocoSacramento: String | null = null;

  fechaCelebracionCeremoniaVacio: boolean = false;
  parrocoVacio: boolean = false;
  infrafirmadoVacio: boolean = false;
  parrocoSacramentoVacio: boolean = false;

  paso1Valido: boolean = true;
  /*FIN VALIDACIONES PASO 1*/

  /*VALIDACIONES PASO 2*/
  nombrePadrino: string | null = null;

  nombreMadrina: string | null = null;

  nombreMadre: string | null = null;

  nombrePadre: string | null = null;
  /*FIN VALIDACIONES PASO 2*/

  /*VALIDACIONES PASO 3*/
  firmaSelect: number | null = null;

  firmaVacio: boolean = false;

  paso3Valido: boolean = true;
  /*FIN VALIDACIONES PASO 2*/

  parroquiaNombre: string = '';
  ubicacionParroquia: string = '';
  parroquiaId: number | null = null;
  editar = false;
  idFiel = -1;
  constructor(
    private firmaSe: FirmaService,
    private notificaciones: NotificacionesService,
    private router: Router,
    private popupSE: PopupService,
    private partidaSE: PartidaService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private parroquiaSE: ParroquiaService
  ) {
    super();
  }

  siguiente() {
    if (this.pasoCreacionPartida != 4) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida++;
    }
  }

  atras() {
    if (this.pasoCreacionPartida > 0) {
      this.checkData(this.pasoCreacionPartida);
      this.pasoCreacionPartida--;
    }
  }

  checkData(paso) {
    switch (paso) {
      case 0:
        if (this.numeroPartida == null) {
          this.numeroPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.numeroPartidaVacio = false;
        }
        if (this.folioPartida == null) {
          this.folioPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.folioPartidaVacio = false;
        }
        if (this.libroPartida == null) {
          this.libroPartidaVacio = true;
          this.paso0Valido = false;
        } else {
          this.libroPartidaVacio = false;
        }
        if (this.anyo == null) {
          this.anyoVacio = true;
          this.paso0Valido = false;
        } else {
          this.anyoVacio = false;
        }
        if (this.nombreLaico == null) {
          this.nombreLaicoVacio = true;
          this.paso0Valido = false;
        } else {
          this.nombreLaicoVacio = false;
        }
        if (this.primerApellido == null) {
          this.primerApellidoVacio = true;
          this.paso0Valido = false;
        } else {
          this.primerApellidoVacio = false;
        }
        if (this.segundoApellido == null) {
          this.segundoApellidoVacio = true;
          this.paso0Valido = false;
        } else {
          this.segundoApellidoVacio = false;
        }
        // if (this.fechaNacimiento == '') {
        //   this.fechaNacimientoVacio = true;
        //   this.paso0Valido = false;
        // } else {
        //   this.fechaNacimientoVacio = false;
        // }
        // if (this.lugarNacimiento == null) {
        //   this.lugarNacimientoVacio = true;
        //   this.paso0Valido = false;
        // } else {
        //   this.lugarNacimientoVacio = false;
        // }
        if (this.parroquiaBautizado == null) {
          this.parroquiaBautizadoVacio = true;
          this.paso0Valido = false;
        } else {
          this.parroquiaBautizadoVacio = false;
        }
        if (this.lugarParroquia == null) {
          this.lugarParroquiaVacio = true;
          this.paso0Valido = false;
        } else {
          this.lugarParroquiaVacio = false;
        }
        if (this.parroquiaSelect == null) {
          this.parroquiaVacio = true;
          this.paso0Valido = false;
        } else {
          this.parroquiaVacio = false;

          this.parroquias.forEach((element) => {
            if (element.id == this.parroquiaSelect) {
              this.parroquiaId = element['id'];
              this.parroquiaNombre = element['nombre'];
              this.ubicacionParroquia = element['ubicacion'];
            }
          });
        }

        if (
          !this.numeroPartidaVacio &&
          !this.anyoVacio &&
          !this.nombreLaicoVacio &&
          !this.primerApellidoVacio &&
          !this.segundoApellidoVacio &&
          // !this.fechaNacimientoVacio &&
          // !this.lugarNacimientoVacio &&
          !this.parroquiaBautizadoVacio &&
          !this.lugarParroquiaVacio &&
          !this.folioPartidaVacio &&
          !this.libroPartidaVacio
        ) {
          this.paso0Valido = true;
        }
        break;

      case 1:
        if (this.fechaCelebracionCeremonia == '') {
          this.fechaCelebracionCeremoniaVacio = true;
          this.paso1Valido = false;
        } else {
          this.fechaCelebracionCeremoniaVacio = false;
        }
        if (this.parroco == null) {
          this.parrocoVacio = true;
          this.paso1Valido = false;
        } else {
          this.parrocoVacio = false;
        }
        if (!this.parrocoVacio && !this.fechaCelebracionCeremoniaVacio) {
          this.paso1Valido = true;
        }
        break;
      case 3:
        if (this.infrafirmado == null) {
          this.infrafirmadoVacio = true;
          this.paso1Valido = false;
        } else {
          this.infrafirmadoVacio = false;
        }
        if (this.parrocoSacramento == null) {
          this.parrocoSacramentoVacio = true;
          this.paso1Valido = false;
        } else {
          this.parrocoSacramentoVacio = false;
        }
        if (this.firmaSelect == null) {
          this.firmaVacio = true;
          this.paso3Valido = false;
        } else {
          this.firmaVacio = false;
          this.paso3Valido = true;

          this.firmas.forEach((element) => {
            if (element.id == this.firmaSelect) {
              this.parroco=element.nombre;
              // this.parroquiaId = this.firmas.filter((e) => {
              //   return e.id == this.firmaSelect;
              // })[0].parroquia['id'];
              // this.parroquiaNombre = this.firmas.filter((e) => {
              //   return e.id == this.firmaSelect;
              // })[0].parroquia['nombre'];
              // this.ubicacionParroquia = this.firmas.filter((e) => {
              //   return e.id == this.firmaSelect;
              // })[0].parroquia['ubicacion'];
              this.imgSelect =
                this.urlImages +
                this.firmas.filter((e) => {
                  return e.id == this.firmaSelect;
                })[0].url;
            }
          });
        }
        break;
    }
  }
  sendData(tipo) {
    var borrador;
    if (tipo == 'borrador') {
      borrador = true;
    } else {
      borrador = false;
    }
    var partida = {
      fecha: this.fechaCelebracionCeremonia,
      numero: this.numeroPartida,
      folio: this.folioPartida, //
      libro: this.libroPartida, //
      anyo: this.anyo,
      hora: '',
      parroco: this.infrafirmado+','+this.parrocoSacramento+','+this.parroco,
      testigos: '',
      borrador: borrador,
      firma: this.firmaSelect,
      sacramento: 3,
      parroquia: this.parroquiaId,
      url: sessionStorage.getItem('documentoAdjunto'),
      fiel: {
        nombre: this.nombreLaico,
        apellido1: this.primerApellido,
        apellido2: this.segundoApellido,
        sexo: '',
        padrinos: this.nombrePadrino + ',' + this.nombreMadrina,
        padre: this.nombrePadre,
        madre: this.nombreMadre,
        lugarnacimientomadre: '',
        lugarnacimientopadre: '',
        abuelosmaternales: '',
        abuelospaternales: '',
        direccion: ''
      },
      extraData: JSON.stringify({
        // nacimientoLaico: this.fechaNacimiento,
        // lugarNacimientoLaico: this.lugarNacimiento,
        bautizadoEn: this.parroquiaBautizado,
        lugarParroquia: this.ubicacionParroquia,
        direccionParroquiaBautizo: this.lugarParroquia,
      }),
    };
    if (tipo == 'borrador') {
      this.popupSE.openPopup(ConfirmPopupComponent, {
        titulo: 'Guardar borrador',
        description: 'quieres guardar el borrador',
        accion: 'Guardar',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd2))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this._unsubInd2.next('');
          if (value.returnValue) {
            this.partidaSE.create(partida);
            this.partidaSE
              .getResultUpdate()
              .pipe(takeUntil(this._unsubInd))
              .subscribe((value) => {
                if (value == null) {
                  return;
                }
                this.notificaciones.showFeedBack(value.message);
                this.router.navigate(['/inicio']);
                sessionStorage.removeItem('documentoAdjunto');
                this._unsubInd.next('');
              });
          } else {
            this.router.navigate(['/inicio']);
            sessionStorage.removeItem('documentoAdjunto');
          }
        });
    } else if (!this.editar) {
      this.partidaSE.create(partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
              } else {
                sessionStorage.removeItem('documentoAdjunto');
                this.router.navigate(['/inicio']);
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    } else {
      partida.fiel['id'] = this.idFiel;
      this.partidaSE.update(this.route.snapshot.params['id'], partida);
      this.partidaSE
        .getResultUpdate()
        .pipe(takeUntil(this._unsubInd3))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.popupSE.openPopup(ConfirmPopupComponent, {
            tipoPopup: 'creacionPartida',
            accion: 'descargar',
          });
          this.popupSE
            .returnData()
            .pipe(takeUntil(this._unsubInd4))
            .subscribe((returnData) => {
              if (returnData == null) {
                return;
              }
              if (returnData.returnValue) {
                this.downloadAsPDF();
                this._unsubInd4.next('');
                this.router.navigate(['/inicio']);
              } else {
                this.router.navigate(['/inicio']);
                this._unsubInd4.next('');
              }
            });
          this._unsubInd3.next('');
        });
    }
  }
  validateNegativeNumbers(number, tipo) {
    console.log(number);
    switch (tipo) {
      case 'numeroPartida':
        if (number != null && number < 0) {
          this.numeroPartida = 0;
        }
        break;
      case 'anyo':
        if (number != null && number < 0) {
          this.anyo = 0;
        }
        break;
    }
  }
  downloadAsPDF() {
    const pdfTable = this.documentoImprimir.nativeElement;
    var divContents = pdfTable.innerHTML;
    var a = window.open('', '', 'height=500, width=500')!;
    a.document.write(
      '<html> <style>body{padding:3em 2em}.abuelos{margin-top:1em;margin-bottom:1em;display:flex;flex-direction:column;}.firma{width:100% !important;display:flex !important;justify-content:center;align-items:center;flex-direction:column}.firma img{width:30%;margin-top:3em}header{border-bottom:solid 1px;padding-bottom:25px;font-size:15pt;display:flex;justify-content:space-between;position:relative;}.columnas{display:flex;gap:25px;}.columnas .col_izq{width:270px !important;}.columnas .col_der{width:100% !important;}.campo > *{display:block;text-align:center;width:100%;}.campo span{text-transform:uppercase;font-size:14pt;border-bottom:solid 1px;margin-bottom:5px;}.campo{margin-bottom:15px;}.feligres{margin-top:140px;}p.texto{text-align:justify;}u{display:block;font-weight:600;font-size:14pt;}u+p{font-size:13pt;}.columnas.separacion .col_izq{border-right:solid 2px;padding-right:15px;}</style>'
    );
    a.document.write('<body >');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.onload = function () {
      a.print();
    };
  }
  changeDocumentoPrevio() {
    this.popupSE.openPopup(PopupFileComponent, { action: 'Añadir' });
  }
  override ngOnDestroy() {
    sessionStorage.removeItem('documentoAdjunto');
  }
  hoy: Fecha = new Fecha();
  ngOnInit() {
    if (this.route.snapshot.params['id'] != undefined) {
      this.editar = true;
      this.partidaSE.getIndividual(this.route.snapshot.params['id']);
      this.loader.open();
      this.partidaSE
        .getResultIndividual()
        .pipe(takeUntil(this._unsubInd5))
        .subscribe((value) => {
          if (value == null) {
            return;
          }
          this.loader.closeSlow();
          var extraData = JSON.parse(value.extra_data);
          if (extraData !== undefined) {
            // this.fechaNacimiento = extraData.nacimientoLaico;
            // this.lugarNacimiento = extraData.lugarNacimientoLaico;
            this.parroquiaBautizado = extraData.bautizadoEn;
            // this.lugarParroquia = extraData.direccionParroquiaBautizo;
          }
          console.log(value);
          this.numeroPartida = value.numero;
          this.libroPartida = value.libro;
          this.folioPartida = value.folio;
          this.anyo = value.anyo;
          if (value.fiel !== null) {
            this.nombreLaico = value.fiel.nombre;
            this.primerApellido = value.fiel.apellido1;
            this.segundoApellido = value.fiel.apellido2;
            this.idFiel = value.fiel.id;
            this.nombreMadre=value.fiel.madre;
            this.nombrePadre=value.fiel.padre;
            if(value.fiel.padrinos.split(',')[0] != undefined ||
            value.fiel.padrinos.split(',')[0] != null){
              this.nombrePadrino =value.fiel.padrinos.split(',')[0];
            }
            if(value.fiel.padrinos.split(',')[1] != undefined ||
            value.fiel.padrinos.split(',')[1] != null){
              this.nombreMadrina =value.fiel.padrinos.split(',')[1];
            }
          }
          if (value.fecha !== null) {
            this.fechaCelebracionCeremonia = value.fecha.split('T')[0];
          }
          this.infrafirmado=value.parroco.split(',')[0];
          this.parrocoSacramento=value.parroco.split(',')[1];
          this.parroco=value.parroco.split(',')[2];
          if (value.firma !== null) {
            this.firmaSelect = value.firma.id;
          }
          this._unsubInd5.next('');
          if (value.parroquia !== null) {
            this.parroquiaSelect = value.parroquia.id;
          }
        });
    }
    this.hoy = Fecha.javascriptConvert(new Date());
    this.objFecha.year = this.today.getFullYear();
    this.objFecha.mes = this.today.getMonth() + 1;
    this.objFecha.dia = this.today.getDate();
    this.mesActual = this.objFecha.mesNombre();
    this.firmaSe.getAll();
    this.firmaSe
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value) => {
        if (value == null) {
          return;
        }
        this.firmas = value;
      });
    this.parroquiaSE.getAll();
    this.parroquiaSE
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((value)=>{
        if(value == null) {
          return;
        }
        this.parroquias = value;
      })
  
  }
}
